import { IsEmpty } from "./helper";
import jwt_decode from "jwt-decode";
import * as utilConstants from "./../constants/utilConstants";

export function IsAuthenticated() {
  let token = localStorage.getItem(utilConstants.ACCESS_TOKEN);
  if (IsEmpty(token)) return false;
  else return true;

  //   if (token !== undefined && token !== null && token !== "") {
  //     try {
  //       let decoded = jwt_decode(token);
  //       const currentTime = Date.now() / 1000;
  //       if (decoded.exp < currentTime) {
  //         localStorage.removeItem(utilConstants.ACCESS_TOKEN);
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     } catch (error) {
  //       localStorage.clear();
  //     }
  //   } else {
  //     return false;
  //   }
}

export function getAuthentionInfo() {
  let token = localStorage.getItem(utilConstants.ACCESS_TOKEN);
  if (token !== undefined && token !== null && token !== "" && token!=false) {
    try {
      let decoded = jwt_decode(token);
      if (IsAuthenticated()) return decoded;
      else return false;
    } catch (error) {
      localStorage.clear();
    }
  } else {
    return false;
  }
}
