import React from 'react'
import InsightCard from "../../../assets/images/insight-card.svg";
import Image1 from "../../../assets/images/Cyber-Security-Activities.jpg";
import Image2 from "../../../assets/images/Need-a-Degree.webp";
import Image3 from "../../../assets/images/Work-from-Home.jpg";
import ForwardArrow from "../../../assets/images/forward-arrow.svg";
const JobInsights = () => {
    const handleReadMoreButtonClick = (link) => {
        window.open(link, '_blank');
    };
    return (
        <>
            <div className="job-insight-section">
                <div className="container">
                    <h2 className="section-title">
                        Cyber Security{" "}
                        <span style={{ color: "#278E69" }}>Jobs</span>{" "}
                        <span style={{ color: "#FE7650" }}>Insights</span>
                    </h2>
                    <div className="row">
                        <div className="col-md-4 col-12 ">
                            <div className='insight-card'>
                                <img width={380} height={249} src={Image1} alt='' />
                                <div className='insight-card-data'>
                                    <h4>20 of the Best Cyber Security Activities for Students!</h4>
                                    <p>We've compiled a list of the 20 best cyber security activities for students to help them learn about security in a fun and engaging way! </p>
                                    <button onClick={() => handleReadMoreButtonClick("https://myturn.careers/blog/the-best-cyber-security-activities-for-students/")} className='tertiary-button'>Read more <img src={ForwardArrow} /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 ">
                            <div className='insight-card'>
                                <img width={380} height={249} src={Image2} alt='' />
                                <div className='insight-card-data'>
                                    <h4>Do You Need a Degree for Cyber Security? ABSOLUTELY NOT!</h4>
                                    <p>Do you need a degree for cyber security? and to land a job as a professional IT security expert? Read on to find out more... </p>
                                    <button onClick={() => handleReadMoreButtonClick("https://myturn.careers/blog/do-you-need-a-degree-for-cyber-security/")} className='tertiary-button'>Read more <img src={ForwardArrow} /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 ">
                            <div className='insight-card'>
                                <img width={380} height={249} src={Image3} alt='' />
                                <div className='insight-card-data'>
                                    <h4>Can You Work from Home in Cyber Security?</h4>
                                    <p>Can you work from home in cyber security? Learn what important factors employers consider when deciding if you're allowed to work remotely. </p>
                                    <button onClick={() => handleReadMoreButtonClick("https://myturn.careers/blog/are-cyber-security-jobs-remote/")} className='tertiary-button'>Read more <img src={ForwardArrow} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobInsights