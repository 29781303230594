import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "./App.scss";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import Routes from "./router/routes";
import { getCookie, setCookie } from "./utility/helper/helper";
import { AuthProvider } from "context/authContext";
import { CommonProvider } from "context/commonContext";


function App() {
  const [accept, setaccept] = useState(false);
  const [cookievalue, setcookievalue] = useState("");
  useEffect(() => {
    const cookie2 = getCookie("data");
    setcookievalue(cookie2);
  }, [accept]);
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <CommonProvider>
          <Switch>
            <Route path="/" component={Routes}></Route>
          </Switch>
          </CommonProvider>
        </AuthProvider>
        <NotificationContainer />
      </BrowserRouter>
      {cookievalue === null && (
        <div className="  cookies-wrap">
          <div>
            By using our website you agree to MyTurn&apos;s use of cookies and
            other tracking technologies to improve your browsing experience on
            our website,to show you personalized content and target ads,to
            analyze our website traffic, and to understand where our visitors
            are coming from
          </div>
          <button
            onClick={() => {
              setCookie("data", window.origin);
              console.log("cookie", getCookie("data"));
              setaccept(true);
            }}
          >
            Accept
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
