import React,{useState,useEffect} from 'react';//eslint-disable-line
import { FormControl, InputGroup, Modal, Toast } from 'react-bootstrap'; //eslint-disable-line
import ReactNotifications from '../reactNotifications/ReactNotifications';
// import ReactNotifications from '../reactNotifications/ReactNotifications';
import * as employerActions from './../../../components/employer/employerActions';
import * as utilConstants from './../../../utility/constants/utilConstants';
const EditModal = ({show,onHide,id,name,onupdate}) => {
  console.log("pro",id,name);
    const [projecterror, setprojecterror] = useState(""); //eslint-disable-line
    const [projectname, setprojectname] = useState(name);

  const handleSubmit = () => {
    if(projectname ===""){
     setprojecterror("Please add a project name")
    }
    if(projectname === name){
      onHide()
      console.log("ok");
    }
    if(projectname !=="" && projectname !==name){
      employerActions.updateProject({
        id,
        name:projectname
      },
      (response) => {
        console.log("res",response);
        if (response.status === utilConstants.sucessState200){
          onupdate(projectname)
        }
        else {
          ReactNotifications(response.message, utilConstants.getErrorStatus);
        }
       
        // ReactNotifications(
        //   'Project Update successfully',
        //   utilConstants.getSuccessStatus
        // );
      }
      )
    }
   
    }
  
  const handleErrorRemove = () => {
    setprojecterror("")
  };
//   useEffect(() => {
//     if(EmployerCardAddMessage !==""){
//      notification(EmployerCardAddMessage,"success")
//      setaddCarddata({
//       card_name: "",
//       card_number:"",
//       exp_month:"",
//       exp_year:"",
//      })
//      onHide();
//      dispatch(EmployerDefaultCard(employerid))
//     }
  
//     return  () => {
//       dispatch(clearEmployerCardAddMessage())
//     }
//   }, [EmployerCardAddMessage]);
//   console.log("change",addCarddata);
//   console.log("inputErrordata",inputErrorData);
  return (
    <Modal
    show={show}
    onHide={onHide}
    className="payment-details-modal"
    centered
  >
    <Modal.Header>
      <Modal.Title className='editmodal-heading'>
        Edit Project Details
        {/* <button>Delete</button> */}
        
       
      </Modal.Title>
      <button type="button" className="btn-close" aria-label="Close"  onClick={()=>onHide()}></button>
    </Modal.Header>
    <Modal.Body className='editmodal-body'>
    <div className="input-row w-570 mx-auto d-flex two-col-row mb-1">
                 <InputGroup className="w-275 me-2">
                
                   <FormControl
                    placeholder="Project name"
                    className="ps-3"
                    name="card_name"
                    value={projectname}
                     onChange={(e)=>setprojectname(e.target.value)}
                     maxLength="20"
                    onFocus={handleErrorRemove}
                    autoComplete="off"
                  />
             
                </InputGroup>
             
          
              </div>
              {!!projecterror && (
                    <div className='invalid-error'>{projecterror}</div>
                  )}
              
    </Modal.Body>

    <Modal.Footer className='border-none'>
    <button className='custom-button cta-type-3 w-168 h-40 br-16 mx-auto btn btn-primary' onClick={()=>onHide()}>
        Cancel
        </button>
        <button onClick={handleSubmit} className='custom-button cta-type-2 w-168 h-40 br-16 mx-auto btn btn-primary'>
            Save
        </button>
     
    </Modal.Footer>
  </Modal>
  )
}

export default EditModal