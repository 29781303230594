import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Filters from "./Filters";
import {
  Col,
  Container,
  Row,
  Offcanvas,
  Button,
  Dropdown,
} from "react-bootstrap";
import CandidateCard from "../employer/saved-candidate/CandidateCard";
import * as commonActions from "../../actions/commonActions";
import * as employerActions from "../employer/employerActions";
import * as candidateActions from "../candidate/candidateActions";
import * as utilConstants from "../../utility/constants/utilConstants";
import { IsEmpty } from "../../utility/helper/helper";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import InputSearch from "../common/inputSearch/InputSearch";
function SearchResults(props) {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [reduxscroll, setreduxscroll] = useState("");
  const [resetKeywordPress, SetResetKeyWordPress] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [suggestion, setSuggestion] = useState([]);
  const [users, setUsers] = useState([]);
  const [scrollValue, setScrollValue] = useState(0);
  const [isUsersLoaded, setUsersLoaded] = useState(false);
  const [candidateCount, setTotalCandidate] = useState(0);
  const [errorMessage, seterrorMessage] = useState("");
  const [searchCriteria, setSearchCriteria] = useState({
    limit: 10,
    skip: 0,
    oldlimit: 0,
  });
  
  useEffect(() => {
    getSuggestion();
    console.log("props scroll", props.state.employerReducer.scrolldata);
    if (!IsEmpty(props.state.employerReducer.searchCriteria))
      props.employerActions.candidateRemove();
    console.log(
      "props.state.employer",
      props.state.employerReducer.searchCriteria
    );
    setSearchCriteria({
      limit:
        props.state.employerReducer.searchCriteria?.skip +
        props.state.employerReducer.searchCriteria?.limit,
      oldlimit: 0,
      searchText: props.state.employerReducer.searchCriteria?.searchText,
      skip: 0,
    });
  }, []);
  const getSuggestion = () => {
    commonActions.searchKeywords({}, (response) => {
      if (response)
        if (response.status === utilConstants.sucessState200)
          setSuggestion(response.data.rows);
    });
  };
  console.log("propssearch", props.state.employerReducer);
  useEffect(() => {
    if (props.state.employerReducer.scrolldata !== undefined) {
      setreduxscroll(props.state.employerReducer.scrolldata);
    }
  }, [props.state.employerReducer.scrolldata]);

  useEffect(() => {
    let { error, searchCandidate, totalCandidate } =
      props.state.employerReducer;
    console.log("search", searchCandidate, searchCriteria.searchText);
    if (!IsEmpty(error)) {
      seterrorMessage("No Candidates Found");
    } else {
      if (searchCandidate) {
        setUsersLoaded(true);
      }
      if (
        searchCriteria.searchText !== undefined &&
        searchCandidate !== undefined
      ) {
        setUsers([...users, ...searchCandidate] || []);

        console.log("ok34", props.state.employerReducer.scrolldata);
        window.scrollTo(10, reduxscroll);
      }
      setTotalCandidate(totalCandidate || 0);
    }
  }, [props.state.employerReducer]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  console.log("total", candidateCount);

  useEffect(() => {
    if (users.length > 0) {
      let uniquearr = getUniqueListBy(users, "id");
      setUsers(uniquearr);
    }
  }, [users.length]);

  useEffect(() => {
    if (!IsEmpty(searchCriteria) && searchCriteria.searchText)
      props.employerActions.candidateSearch(searchCriteria);
    if (props.state.employerReducer.searchCriteria !== searchCriteria)
      props.employerActions.saveSearchCriteraLocal(searchCriteria);
  }, [props.employerActions, searchCriteria]);

  const saveCandidate = (value, callBack = () => {}) => {
    commonActions.saveCandidate(value, (res) => {
      callBack(res);
    });
  };

  const filterOptionFormate = () => {
    let tempArray = [];
    Object.keys(searchCriteria).map((sc) => {
      if (!["limit", "skip"].includes(sc)) {
        switch (sc) {
          case "range":
            break;
          case "degree":
            searchCriteria[sc].split(",").map((key) => {
              tempArray.push(utilConstants.educationLevel[key]);
            });
            break;
          case "searchText":
            searchCriteria[sc] &&
              searchCriteria[sc].split(",").map((key) => {
                tempArray.push(key);
              });
            break;
          case "desired_employment":
            searchCriteria[sc].split(",").map((key) => {
              tempArray.push(utilConstants.employmentType[key]);
            });
            break;
          case "job_shift_type":
            searchCriteria[sc].split(",").map((key) => {
              tempArray.push(utilConstants.jobShiftType[key]);
            });
            break;
          case "notice_period":
            searchCriteria[sc].split(",").map((key) => {
              tempArray.push(utilConstants.startType[key]);
            });
            break;
          case "need_visa_sponsorship":
            searchCriteria[sc] && tempArray.push("Required US sponsorship");
            break;
          case "willing_relocate":
            searchCriteria[sc] && tempArray.push("Willing to relocate");
            break;
          case "willing_remote_work":
            searchCriteria[sc] && tempArray.push("Remote work preference");
            break;
          case "willing_travel":
            searchCriteria[sc] && tempArray.push("Willingness to travel");
            break;
          case "willing_hazardous":
            searchCriteria[sc] &&
              tempArray.push("Work in hazardous conditions");
            break;
          case "willing_heavy_lift":
            searchCriteria[sc] &&
              tempArray.push("Work a job requiring heavy lifting");
            break;
          case "sortBy":
            tempArray.push(utilConstants.sortBy[searchCriteria[sc]]);
            break;

          default:
            tempArray.push(searchCriteria[sc] > "" && searchCriteria[sc]);
        }
      }
    });
    tempArray = tempArray.filter((k) => k !== false);
    return tempArray;
  };

  function isNumber(char) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const first_letter = char.trim().charAt(0);
    if (typeof char !== "string") {
      return true;
    }

    if (char.trim() === "") {
      return true;
    }
    if (char.trim().length === 1 && specialChars.test(first_letter)) {
      return true;
    }

    return !isNaN(char);
  }

  const getSearchKeywords = () => {
    let tempArray = [];
    Object.keys(searchCriteria).map((sc) => {
      if (!["limit", "skip"].includes(sc)) {
        switch (sc) {
          case "searchText":
            let finalCategory =
              searchCriteria[sc] &&
              searchCriteria[sc]
                .split(",")
                .reduce(
                  (c, n) => (c.find((el) => el == n.trim()) ? c : [...c, n]),
                  []
                );

            finalCategory &&
              finalCategory.map((key) => {
                tempArray.push(key);
              });
            break;

          default:
            "";
        }
      }
    });
    tempArray = tempArray.filter((k) => isNumber(k) !== true);
    return tempArray;
  };

  const onfilterChange = (v) => {
    setSelectedSuggestion(v);
  };

  const handleView = () => {
    setSearchCriteria({
      ...searchCriteria,
      skip: searchCriteria.skip + 10,
      oldlimit: searchCriteria.limit,
    });
    props.employerActions.getScrolldata(scrollValue);
  };
  useEffect(() => {
    if (searchCriteria.searchText === "") {
      setSearchCriteria({
        limit: 10,
        skip: 0,
        oldlimit: 0,
      });
      setTotalCandidate(0);
      setUsers([]);
    }
  }, [searchCriteria.searchText]);
  const handlefirstSearch = (id) => {
    commonActions.searchKeywords({ S: 0, l: 10, id: id }, (response) => {
      if (Object.keys(response.data.rows[0].search_condition).length > 2) {
        setSearchCriteria({
          limit: 10,
          skip: 0,
          oldlimit: 0,
          ...JSON.parse(response.data.rows[0].search_condition),
        });
        setUsersLoaded(true);
      } else {
        seterrorMessage("No Candidate Found");
      }
    });
  };
  const handleKeywordPress = () => {
    SetResetKeyWordPress(false);
  };

  useEffect(() => {
    const onScroll = (e) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollValue]);

  console.log("scrollvalue", scrollValue);
  return (
    <div className="search-results">
      <div className=" d-sm-block d-md-block d-lg-none d-xl-none">
        <Offcanvas
          show={isSideMenuOpen}
          onHide={() => {
            setIsSideMenuOpen(false);
          }}
          placement="start"
        >
          <Offcanvas.Body className="search-results nav">
            <Filters
              filterOptionFormate={getSearchKeywords}
              keywordpress={resetKeywordPress}
              propskeywordpress={handleKeywordPress}
              filterChange={(value, isDelete) => {
                setUsers([]);
                if (isDelete === "filter-change") {
                  setSearchCriteria({
                    limit: 10,
                    skip: 0,
                    oldlimit: 0,
                    ...JSON.parse(value),
                  });
                } else if (isDelete) {
                  let tempSearchCriteria = { ...searchCriteria };
                  delete tempSearchCriteria[Object.keys(value)[0]];
                  delete tempSearchCriteria[Object.keys(value)[0]];
                  delete tempSearchCriteria.limit;
                  delete tempSearchCriteria.skip;
                  delete tempSearchCriteria.oldlimit;
                  setSearchCriteria({
                    limit: 10,
                    skip: 0,
                    oldlimit: 0,
                    ...tempSearchCriteria,
                  });
                } else if (value && isDelete === undefined) {
                  let tempSearchCriteria = { ...searchCriteria };
                  delete tempSearchCriteria.limit;
                  delete tempSearchCriteria.skip;
                  delete tempSearchCriteria.oldlimit;
                  setSearchCriteria({
                    limit: 10,
                    skip: 0,
                    oldlimit: 0,
                    ...tempSearchCriteria,
                    ...value,
                  });
                }
              }}
              activeFilter={searchCriteria}
              suggestion={suggestion}
              clearFilter={() => {
                setSearchCriteria({
                  limit: 10,
                  skip: 0,
                  oldlimit: 0,
                  skill: searchCriteria.skill,
                  searchText: searchCriteria.searchText,
                });
                setSelectedSuggestion(null);
                props.employerActions.saveSearchCriteraLocal({});
              }}
              selectedSuggestion={selectedSuggestion}
              selectedFilter={onfilterChange}
              updateSugesstion={(response) =>
                response ? setSuggestion(response) : getSuggestion()
              }
              setIsSideMenuOpen={setIsSideMenuOpen}
            ></Filters>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Container>
        {!isUsersLoaded ? (
          <Row>
            <Col
              xxl={{ span: 6, offset: 3 }}
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 10, offset: 1 }}
              md={12}
              sm={12}
              xs={12}
            >
              <div className="before-search-view">
                <label className="searchWrapper-lg w-100">
                  <InputSearch
                    callBackOnSearch={(text, callBack) => {
                      if (text) setSelectedSuggestion(null);
                      SetResetKeyWordPress(true);
                      candidateActions.getSearchKeyWord(
                        { st: text },
                        (response) => {
                          if (
                            response.status === utilConstants.sucessState200
                          ) {
                            var options = [];
                            response.data.map((v) => {
                              options.push({
                                id: v,
                                name: v,
                              });
                            });

                            callBack(options);
                          }
                        }
                      );
                    }}
                    onSuggestionSelect={(value) => {
                      if (value) {
                        setSearchCriteria({
                          limit: 10,
                          oldlimit: 0,
                          skip: 0,
                          searchText: value,
                        });
                      } else {
                        ReactNotifications(
                          "Please enter some keyword to search",
                          utilConstants.getInfoStatus
                        );
                      }
                    }}
                  />
                </label>
                {errorMessage && (
                  <div className="d-flex align-items-center justify-content-center p-2">
                    {errorMessage}
                  </div>
                )}
                {!IsEmpty(suggestion) && (
                  <>
                    <ul className=" d-lg-block indicatorLabels text-center mt-4">
                      {suggestion.map((sug, index) => (
                        <li
                          title="search with saved criteria"
                          className="cursor-pointer"
                          key={index}
                          onClick={() => {
                            setSelectedSuggestion(sug.id);
                            handlefirstSearch(sug.id);
                          }}
                        >
                          <label
                            title={sug.title}
                            className={`${
                              utilConstants.lableCssClass[
                                index % utilConstants.lableCssClass.length
                              ]
                            } cursor-pointer`}
                          >
                            <img
                              src="/images/search-dark.png"
                              alt="close"
                              className="searchImg"
                            />
                            {sug.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={0}
              md={0}
              lg={4}
              className="d-none d-sm-none d-md-none d-lg-block d-xl-block "
            >
              <Filters
                filterOptionFormate={getSearchKeywords}
                keywordpress={resetKeywordPress}
                propskeywordpress={handleKeywordPress}
                filterChange={(value, isDelete) => {
                  setUsers([]);
                  console.log("ismongo", isDelete, value);
                  if (isDelete === "filter-change") {
                    setSearchCriteria({
                      limit: 10,
                      skip: 0,
                      oldlimit: 0,
                      ...JSON.parse(value),
                    });
                  }
                  else if (isDelete) {
                    let tempSearchCriteria = { ...searchCriteria };
                    delete tempSearchCriteria[Object.keys(value)[0]];
                    delete tempSearchCriteria.limit;
                    delete tempSearchCriteria.skip;
                    delete tempSearchCriteria.oldlimit;
                    setSearchCriteria({
                      limit: 10,
                      skip: 0,
                      oldlimit: 0,
                      ...tempSearchCriteria,
                    });
                  } else if (value && isDelete === undefined) {
                    console.log("searchvalue", searchCriteria);
                    let tempSearchCriteria = { ...searchCriteria };
                    delete tempSearchCriteria.limit;
                    delete tempSearchCriteria.skip;
                    delete tempSearchCriteria.oldlimit;
                    setSearchCriteria({
                      limit: 10,
                      skip: 0,
                      oldlimit: 0,
                      ...tempSearchCriteria,
                      ...value,
                    });
                  }
                }}
                activeFilter={searchCriteria}
                suggestion={suggestion}
                clearFilter={() => {
                  setSearchCriteria({
                    limit: 10,
                    skip: 0,
                    oldlimit: 0,
                    skill: searchCriteria.skill,
                    searchText: searchCriteria.searchText,
                  });
                  setSelectedSuggestion(null);
                  props.employerActions.saveSearchCriteraLocal({});
                }}
                selectedSuggestion={selectedSuggestion}
                selectedFilter={onfilterChange}
                updateSugesstion={(response) =>
                  response ? setSuggestion(response) : getSuggestion()
                }
              ></Filters>
            </Col>
            <Col md={12} lg={8}>
              <div className="tap-panel-wrap">
                <div className="d-flex justify-content-between align-items-center mb-md-2 topPanel">
                  <h1 className="mb-0 search-results-header">Search results</h1>
                  <label className="searchWrapper w-100">
                    <InputSearch
                      defaultValue={searchCriteria.searchText}
                      callBackOnSearch={(text, callBack) => {
                        setSelectedSuggestion(null);
                        SetResetKeyWordPress(true);
                        candidateActions.getSearchKeyWord(
                          { st: text },
                          (response) => {
                            if (
                              response.status === utilConstants.sucessState200
                            ) {
                              var options = [];
                              response.data.map((v) => {
                                options.push({
                                  id: v,
                                  name: v,
                                });
                              });
                              callBack(options);
                            }
                          }
                        );
                      }}
                      onSuggestionSelect={(value) => {
                        console.log("ok search cloicked", value);
                        setSelectedSuggestion(null);
                        setUsersLoaded(true);
                        SetResetKeyWordPress(true);
                        setUsers([]);
                        setSearchCriteria({
                          limit: 10,
                          oldlimit: 0,
                          skip: 0,
                          searchText: value,
                        });
                      }}
                    />
                  </label>
                </div>
                <ul className="d-none d-sm-none d-md-none d-lg-block indicatorLabels mt-4">
                  {getSearchKeywords().map((fltOpt, index) => (
                    <li key={index}>
                      <label
                        title={fltOpt}
                        className={
                          utilConstants.lableCssClass[
                            index % utilConstants.lableCssClass.length
                          ]
                        }
                      >
                        {fltOpt?.length > 17
                          ? `${fltOpt.substring(0, 17)}...`
                          : fltOpt}
                      </label>
                    </li>
                  ))}
                </ul>
                <div className="d-flex justify-content-between align-items-center filter-sort-row">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-none d-md-block d-lg-none">
                      <Button
                        className="cta-type-3 w-146 h-40 br-16 me-3"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSideMenuOpen(true);
                        }}
                      >
                        Filter
                      </Button>
                    </div>
                    {users?.length ? (
                      <h4 className="mb-0">
                        {candidateCount} candidates available
                      </h4>
                    ) : null}
                  </div>
                  {users.length ? (
                    <div className="d-none justify-content-end custom    align-items-center custom  d-md-flex">
                      <h4 className="m-0  text-black-50">Sorted by:</h4>
                      <span className="best">Best Match</span>
                    </div>
                  ) : null}
                  <div className="d-flex d-md-none desktop-none">
                    <Dropdown className="mobile-menu">
                      <Dropdown.Toggle
                        as="img"
                        id="dropdown-basic"
                        src={"/images/sorting.png"}
                        className="m-r-20"
                        alt="sort"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu className="mobile-menu">
                        <Dropdown.Item>Most recent</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <img
                      src={"/images/filter.png"}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSideMenuOpen(true);
                      }}
                      alt="filter"
                    ></img>
                  </div>
                </div>
              </div>
              {!users.length && <h3>No candidates found</h3>}
              {users.length > 0 &&
                users.map((user, k) => {
                  return (
                    <CandidateCard
                      key={k}
                      user={user}
                      saveCandidate={saveCandidate}
                      scrollvalue={scrollValue}
                    />
                  );
                })}
              {candidateCount - users.length > 0 &&
                searchCriteria.searchText !== undefined && (
                  <div onClick={handleView} className="view-position">
                    View More
                  </div>
                )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    employerActions: bindActionCreators(employerActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
