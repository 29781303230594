import moment from "moment";

const TimeAgo = ({ createdAt }) => {
  const currentDate = moment();
  const postDate = moment(createdAt);

  const diffInDays = currentDate.diff(postDate, 'days');
  const diffInMonths = currentDate.diff(postDate, 'months');
  const diffInYears = currentDate.diff(postDate, 'years');

  let timeAgo;

  if (diffInYears > 0) {
    timeAgo = `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
  } else if (diffInMonths > 0) {
    timeAgo = `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
  } else if (diffInDays > 0) {
    timeAgo = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
  } else {
    timeAgo = 'Today';
  }

  return <p>Posted: {timeAgo}</p>;
};

export default TimeAgo;