import React, { useEffect } from 'react';

const RedirectComponent = () => {

  useEffect(() => {
    if (window.location.pathname === '/python-for-cyber-security' || '/python-for-cyber-security/' ) {
      window.location.href = 'https://myturn.careers/blog/python-for-cyber-security/';
    }
  }, []);

  return (
    <div></div>
  )
};

export default RedirectComponent;
