import React from 'react';
import { Form } from 'react-bootstrap';

export default function InputTextarea({
  label = '',
  placeholder,
  controlId,
  required = false,
  onChange = () => {},
  value,
  error,
  name,
  className,
  defaultValue,
  disabled,
  min,
  maxLength = '12000',
}) {
  return (
    <div className={className || ''}>
      <Form.Group className='form-group' controlId={controlId}>
        <Form.Label>
          {typeof label === 'string' ? label.toLowerCase() : label}
        </Form.Label>
        <Form.Control
          defaultValue={defaultValue}
          as='textarea'
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required={required}
          name={name}
          disabled={disabled}
          min={min}
          maxLength={maxLength}
        />
        <span style={{ color: 'red' }}>{error}</span>
      </Form.Group>
    </div>
  );
}
