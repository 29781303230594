import React from "react";

export default function InputCheckbox({
  listOfOption = [],
  name,
  onChange = () => {},
  value,
  type = "checkbox",
}) {
  return (
    <div className="custom-checkbox-wrapper">
      {listOfOption.map((radioOption, k) => (
        <label htmlFor={radioOption.id} className="custom-checkbox" key={k}>
          <input
            type={type}
            name={name}
            id={radioOption.id}
            onChange={onChange}
            value={radioOption.value}
            checked={value}
          />
          <span className="optionContent">{radioOption.label}</span>
        </label>
      ))}
    </div>
  );
}
