import React from 'react';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import {
  getMediumPasswordStatus,
  getStrongPasswordStatus,
  getWeakPasswordStatus,
} from '../../../utility/constants/utilConstants';
import { passwordStrength } from '../../../utility/helper/helper';
export default function InputPassword({
  label,
  name,
  placeholder,
  controlId,
  onChange = () => {},
  error,
  value,
  required,
  strenghtCheck = false,
  hideShowOption = true,
  maxLength = 255,
}) {
  let [isVisible, setIsVisible] = React.useState(false);
  const displayStrength = () => {
    let strenght = '';
    if (value) {
      strenght = passwordStrength(value);
      if (strenght !== getStrongPasswordStatus) error = strenght;
    }
    switch (strenght) {
      case getStrongPasswordStatus:
        return (
          <div className='password-line'>
            <div className='green'></div>
          </div>
        );
      case getMediumPasswordStatus:
        return (
          <div className='password-line'>
            <div className='yellow'></div>
            <div className='gray wid-20'></div>
          </div>
        );
      case getWeakPasswordStatus:
        return (
          <div className='password-line'>
            <div className='red'></div>
            <div className='gray wid-60'></div>
          </div>
        );
      default:
        return (
          <div className='password-line'>
            <div className='gray wid-60'></div>
          </div>
        );
    }
  };
  return (
    <div>
      <Form.Group className='form-group' controlId={'formBasic' + controlId}>
        <Form.Label>{label}</Form.Label>
        <InputGroup>
          <FormControl
            type={isVisible ? 'text' : 'password'}
            placeholder={placeholder}
            onChange={onChange}
            value={value || ''}
            required={required}
            name={name}
            isInvalid={error}
            maxLength={maxLength}
          />
          <InputGroup.Text
            onClick={(e) => {
              e.preventDefault();
              hideShowOption && setIsVisible(!isVisible);
            }}>
            {hideShowOption && (
              <img
                src={isVisible ? '/images/Eye-close.png' : '/images/Eye.png'}
                alt={isVisible ? 'Eye close' : 'Eye'}
              />
            )}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      {strenghtCheck && displayStrength(value)}
      {
        <Form.Control.Feedback type='invalid' className='password-feedback'>
          {[getWeakPasswordStatus, getMediumPasswordStatus].includes(error) ? (
            <>
              <p>- Must include uppercase and lowercase</p>
              <p>- Must include number</p>
              <p>- Must include special character</p>
              <p>- Must be between 8-255 characters</p>
            </>
          ) : (
            error
          )}
        </Form.Control.Feedback>
      }
    </div>
  );
}
