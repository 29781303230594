import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory, Link } from "react-router-dom";
import InputRadio from "./common/InputRadio";
import CustomButton from "../../common/button/CustomButton";
export default function RemoteWorkOnly() {
  let history = useHistory();
  const [seekingNewJob, setSeekingNewJob] = useState();
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/WillingToTravel");
  };
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/CanRelocate");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            10
          )}
        />

        <h2 className="section-heading-2 text-center mx-auto mw-552">
          Are you looking for remote work only?
        </h2>

        <div className="text-center">
          <InputRadio
            name="seekingJob"
            listOfOption={[
              {
                id: "SeekingJobYes",
                value: "Yes",
                label: <>Yes</>,
              },
              {
                id: "SeekingJobNo",
                value: "No",
                label: <>No</>,
              },
            ]}
            onChange={(e) => {
              setSeekingNewJob(e.target.value);
            }}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          isDisabled={helper.IsEmpty(seekingNewJob)}
          onClick={onSubmit}
          label="Next"
        />
        <div className="other-link">
          <Link to="/Candidate/Onboarding/WillingToTravel" className="ms-0">
            Skip this step
          </Link>
        </div>
      </Container>
    </div>
  );
}
