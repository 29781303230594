import { async } from "./../utility/config/api/index";

const changeAccountEmail = (data, callBack = () => {}) => {
  async("frontend/change-email", "PUT", data).then((response) => {
    callBack(response);
  });
};

const changeAccountPhoneAndPassword = (data, callBack = () => {}) => {
  async("frontend/employer/settings", "PUT", data).then((response) => {
    callBack(response);
  });
};

const verifyOTP = (data, callBack = () => {}) => {
  async(`frontend/verify/change`, "GET", data).then((response) => {
    callBack(response);
  });
};
const updateDetails = (data, callBack = () => {}) => {
  async(`frontend/employer/settings`, "PUT", data).then((response) => {
    callBack(response);
  });
};

const deleteAccount = (data, callBack = () => {}) => {
  async(`frontend/delete-account`, "GET", data).then((response) => {
    callBack(response);
  });
};

const pauseAccount = (data, callBack = () => {}) => {
  async(`frontend/pause-account`, "GET", data).then((response) => {
    callBack(response);
  });
};

const resumeAccount = (data, callBack = () => {}) => {
  async(`frontend/resume-account`, "GET", data).then((response) => {
    callBack(response);
  });
};

const sendSupportRequest = (data, callBack = () => {}) => {
  async(`frontend/support`, "POST", data).then((response) => {
    callBack(response);
  });
};
const updateCompanyDetails = (data, callBack = () => {}) => {
  async(`frontend/employer/company`, "PUT", data).then((response) => {
    callBack(response);
  });
};

const updateNotificationPreferences = (data, callBack = () => {}) => {
  async(`frontend/employer/notification/settings`, "PUT", data).then(
    (response) => {
      callBack(response);
    }
  );
};

const getPackageList = (data, callBack) => {
  async(
    data.role === 3
      ? `frontend/candidate/packages`
      : `frontend/employer/packages`,
    "GET",
    data
  ).then((response) => {
    callBack(response);
  });
};

export {
  changeAccountEmail,
  changeAccountPhoneAndPassword,
  verifyOTP,
  updateDetails,
  sendSupportRequest,
  updateCompanyDetails,
  updateNotificationPreferences,
  getPackageList,
  deleteAccount,
  pauseAccount,
  resumeAccount,
};
