import React, { Suspense } from "react";
import Theme from "../components/theme/Theme";
import PrivateRoute from "./privateRoute";
import { Route, Switch} from "react-router-dom";

import Signup from "../components/landing/signup/Signup";
import Signin from "../components/landing/signin/Signin";
import ForgotPassword from "../components/landing/forgotPassword/ForgotPassword";
import HomeNewOne from "../components/home-new/HomeNewOne";
import AboutUsNew from "../components/about-us-new/about-us-new";
import FAQNew from "../components/faq-new/faq-new";
import PrivacyPolicy from "../components/privacy-policy/privacy-policy";
import TermsandConditions from "../components/terms-and-conditions/TermsandConditions";
import DoNotSellMyPersonalInfo from "../components/do-not-sell-my-personal-info/do-not-sell-my-personal-info";
import Candidate from "./Candidate";
import EmployerRoute from "./EmployerRoute";
import SearchResults from "../components/search-results/search-results";
import EmployerNew from "../components/employer-new/EmployerNew";

import ResetPassword from "../components/landing/resetPassword/ResetPassword";
import { uploadResume } from "components/candidate/onboarding/onboardingActions";
import About from "components/employer/about-page/About";
import NotFoundPage from "components/404_page";
import SearchJob from "components/candidate/searchjob/SearchJob";
import PricingPlan from "components/employer/pricing-plan/PricingPlan";
import RedirectComponent from "components/RedirectionPage";

export default function routFunction() {
  const token = localStorage.getItem("MyTurn-Token");
  const employer = localStorage.getItem("employer_id");
  const isPrivate = token !== null ? true : false;

  const items = [
    {
      name: "Sign-Up",
      path: "/signup",
      exact: true,
      component: Signup,
    },
    {
      name: "Sign-In",
      path: "/signin",
      exact: true,
      component: Signin,
    },
    {
      name: "Forgot Password",
      path: "/forgotPassword",
      exact: true,
      component: ForgotPassword,
    },
    {
      name: "Restore Access",
      path: "/restore-access",
      exact: true,
      component: SearchResults,
    },
    {
      name: "Restore Access",
      path: "/restore-access/:token",
      exact: true,
      component: ResetPassword,
    },
    {
      name: "Candidate",
      path: "/Candidate",
      exact: true,
      private: true,
      component: Candidate,
    },
    {
      name: "Apply For Job",
      path: "/Candidate/applyforjob/:id",
      exact: true,
      private: true,
      component: uploadResume,
    },
    {
      name: "Employer",
      path: "/Employer",
      exact: true,
      component: EmployerNew, 
    },
    {
      name: "Home",
      title: "Cyber Security Jobs and Recruitment | Careers in Cyber Security",
      path: "/",
      exact: true,
      component: employer ? EmployerNew : HomeNewOne, 
    },
    {
      name: "Redirect",
      path: "/python-for-cyber-security",
      exact: true,
      component: RedirectComponent, 
    },
    {
      name: "About Us",
      path: "/about-us",
      exact: true,
      component: AboutUsNew, 
    },
    {
      name: "FAQ",
      path: "/faq",
      exact: true,
      component: FAQNew, 
    },
    {
      name: "Employer",
      path: "/Employer",
      exact: true,
      private: true,
      component: EmployerRoute,
    },
    {
      name: "Privacy Policy",
      path: "/privacy-policy",
      exact: true,
      component: PrivacyPolicy,
    },
    {
      name: "Terms and Conditions",
      path: "/terms-and-conditions",
      exact: true,
      component: TermsandConditions,
    },
    {
      name: "Do Not Sell My Personal Info",
      path: "/do-not-sell-my-personal-info",
      exact: true,
      component: DoNotSellMyPersonalInfo,
    },
    {
      name: "About",
      path: "/company-details/:id",
      exact: true,
      component: About,
    },
    // {
    //   name: "Search Job",
    //   path: "/searchJob",
    //   exact: true,
    //   private: isPrivate,
    //   component: SearchJob,
    // },
    {
      name: "Not Found Page",
      path: "/not-found-page",
      exact: true,
      component: NotFoundPage,
    },
    {
      name: "Pricing Plan",
      path: "/post-a-job",
      exact: false,
      component: PricingPlan,
    }
  ];
  let tempRoute = [];
  async function routingMap(items, parent) {
    items.map((item) => {
      if (Array.isArray(item.component)) {
        if (parent)
          routingMap(item.component, {
            ...item,
            path: parent.path + item.path,
            private: parent.private ? parent.private : false,
          });
        else routingMap(item.component, item);
      } else if (parent)
        tempRoute.push({
          ...item,
          path: parent.path + item.path,
          private: parent.private ? parent.private : false,
        });
      else tempRoute.push(item);
    });
  }
  routingMap(items);
  const Routes = () => {
    return (
      <Switch>
        {tempRoute.map((item, index) => {
          return item.private ? (
            <PrivateRoute {...item} key={index}></PrivateRoute>
          ) : (
            <Route
              key={index}
              path={item.path}
              exact={item.exact}
              name={item.name}
              render={(props) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                document.title = `${item.path === '/'? item.title : item.name}`;
                return <item.component {...props} />;
              }}
            />
          );
        })}
        <Route component={NotFoundPage} />
      </Switch>
    );
  };

  return (
    <Theme>
      <Suspense fallback={<label>Loading...</label>}>
        {Routes()}
      </Suspense>
    </Theme>
  );
}
