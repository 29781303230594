const CustomDateFormat = (datestring) => {
  if (!datestring) {
    return "";
  }

  datestring = datestring.replace("T00:00:00.000Z", "").trim();

  const year = datestring.substring(0, 4);
  const month = datestring.substring(5, 7);
  const day = datestring.substring(8, 10);

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export default CustomDateFormat;
