import React from "react";
import { Col, Row } from "react-bootstrap";


import profileimage from "../../../assets/images/abc.png";
import email from "../../../assets/images/email.png";
import slack from "../../../assets/images/slack.png";
import cross from "../../../assets/images/cross.png";
import linkdin from "../../../assets/images/linkedin.png";
import github from "../../../assets/images/github.png";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function CompanyProfile({ companyDetails }) {
  const {
    company_logo_url,
    company_logo,
    company_name,
    company_website,
    company_industry,
    company_employee_count,
    company_revenue,
    company_address,
    company_ceo,
    company_ciso,
    company_since,
    social_linkedin,
    social_github,
    social_slack,
    social_twitter,
  } = companyDetails?.EmployerProfile || {};

  return (
    <>
      <Col
        xxl={{ span: 4 }}
        xl={{ span: 4 }}
        lg={{ span: 4 }}
        md={6}
        sm={12}
        xs={12}
      >
        <div className="company_wrrpr">
          <div className="company_profile_body">
            <div className="company_profile_title">
              <div className="prodile_image">
                <img
                  src={company_logo !== "" ? company_logo_url : profileimage}
                  alt="profileimage"
                />
              </div>
              <h1>{company_name}</h1>
              <p>
                Since{" "}
                {company_since ? moment(company_since).format("MMMM YYYY") : ""}
              </p>
              <ul className="contact_profile">
                <li>
                  <a target="_blank" href={social_slack ? social_slack : "#"}>
                    <img src={slack} />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={social_twitter ? social_twitter : "#"}
                  >
                    <img src={cross} />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={social_linkedin ? social_linkedin : "#"}
                  >
                    <img src={linkdin} />
                  </a>
                </li>
                <li>
                  <a target="_blank" href={social_github ? social_github : "#"}>
                    <img src={github} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="company_profile_overview">
              <h1>Profile Overview</h1>
              <Row>
                <Col md={4} sm={4} xs={6}>
                  <h4> CEO</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">{company_ceo}</h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4>CISO</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">
                    {company_ciso ? company_ciso : "----"}
                  </h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4> Employee</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">{company_employee_count}</h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4>Industry</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">{company_industry}</h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4>Revenue</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">
                    {company_revenue ? company_revenue : "----"}
                  </h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4>Location</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <h4 className="light_clr">{company_address}</h4>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <h4> Website</h4>
                </Col>
                <Col md={8} sm={8} xs={6}>
                  <a
                    target="_blank"
                    href={company_website}
                    className="light_clr"
                  >
                    {company_website}
                  </a>
                </Col>
              </Row>
            </div>
            <button className="primary-filed-orange w-100">
              <img src={email} /> Mail To
            </button>
          </div>
        </div>
      </Col>
    </>
  );
}
