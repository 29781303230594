import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory, Link } from "react-router-dom";
import InputCheckbox from "./common/InputCheckbox";
import CustomButton from "../../common/button/CustomButton";
export default function EducationLevel() {
  let history = useHistory();
  const [requireVisaSponsorship, setRequireVisaSponsorship] = useState();
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/JoinWithin");
  };
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/HeavyLiftingWork");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            15
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          What is your highest level of education?
        </h2>
        <div className="text-center">
          <InputCheckbox
            name="VisaSponsorship"
            listOfOption={[
              {
                id: "Some school",
                value: "Some school",
                label: "Some school",
              },
              {
                id: "High School Graduate or GED",
                value: "High School Graduate or GED",
                label: "High School Graduate or GED ",
              },
              {
                id: "Associates Degree",
                value: "Associates Degree",
                label: "Associates Degree",
              },
              {
                id: "Bachelor's Degree",
                value: "Bachelor's Degree",
                label: "Bachelor's Degree",
              },
              {
                id: "Masters Degree",
                value: "Masters Degree",
                label: "Masters Degree",
              },
              {
                id: "Doctoral Degree",
                value: "Doctoral Degree",
                label: "Doctoral Degree",
              },
              {
                id: "Other Professional Graduate",
                value: "Other Professional Graduate",
                label: "Other Professional Graduate",
              },
            ]}
            onChange={(e) => {
              setRequireVisaSponsorship(e.target.value);
            }}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          isDisabled={helper.IsEmpty(requireVisaSponsorship)}
          onClick={onSubmit}
          label="Next"
        />
        <div className="other-link">
          <Link to="/Candidate/Onboarding/JoinWithin" className="ms-0">
            Skip this step
          </Link>
        </div>
      </Container>
    </div>
  );
}
