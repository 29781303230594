import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import InputSelect from "../common/select/InputSelect";
import { sendSupportRequest } from "../../actions/account";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import {
  getErrorStatus,
  getSuccessStatus,
} from "../../utility/constants/utilConstants";

export default function Support() {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    description: "",
    subject: "",
  });
  const [isLoading, setLoading] = useState(false);
  const onSubmit = () => {
    let newErrors = {};
    if (!description || !subject) {
      if (!subject) {
        newErrors.subject = "please select an option";
      }
      if (!description) {
        newErrors.description = "please enter description";
      }
    } else {
      setLoading(true);
      sendSupportRequest({ subject, description }, (res) => {
        setLoading(false);
        if (res) {
          setSubject("");
          setDescription("");
          ReactNotifications("Request sent successfully", getSuccessStatus);
        } else {
          ReactNotifications(
            "Something went wrong, Please try again",
            getErrorStatus
          );
        }
      });
    }
    setErrors(newErrors);
  };

  return (
    <div className="account-settings-tab ">
      <div className='edit_heading'>
        <h2>Support</h2>
      </div>
      <form
        className="normal"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <InputSelect
              labelAsValue
              error={errors.subject}
              value={subject}
              label="type of message"
              options={["Feedback", "Question", "Suggestion"]}
              onChange={(e) => setSubject(e.target.value)}
            ></InputSelect>{" "}
          </Col>
        </Row>

        <Form.Group
          className="mb-3 mt-2"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Message</Form.Label>
          <Form.Control
            aria-invalid={errors.description}
            isInvalid={errors.description}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            as="textarea"
            placeholder="Description"
            rows={4}
          />
          <div className="invalid-feedback d-block">{errors.description}</div>
        </Form.Group>
        <button
          type="submit"
          className={`primary-filed-orange mt-40 ${isLoading && " button-loading"
            }`}
          disabled={isLoading}
        >
          Send Message
        </button>
      </form>
    </div>
  );
}
