import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomButton from "../../common/button/CustomButton";
import { bindActionCreators } from "redux";
import {
  Col,
  Row,
  Card,
  Accordion,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import CustomeModel from "../../common/model/CustomeModel";
import CreatableSelect from "react-select/creatable";
import { Link, useHistory } from "react-router-dom";
import * as utilConstants from "./../../../utility/constants/utilConstants";
import * as commonActions from "./../../../actions/commonActions";
import * as employerActions from "../employerActions";
import * as helper from "../../../utility/helper/helper";
import { getAuthentionInfo } from "./../../../utility/helper/authenticationHelper";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import {
  capitalizeFirstLetter2,
  formatPhoneNumber,
  IsEmpty,
} from "../../../utility/helper/helper";
import { Spinner } from "react-bootstrap";
import MultiInputSelect from "components/common/select/MultiInputSelect";
function CandidateCard(props) {
  const history = useHistory();
  const [saveCandidateModal, setSaveCandidateModal] = useState(false);
  const [privteInfoModal, setPrivateInfoModal] = useState({ isOpen: false });
  const [noLimitPopUp, setNoLimitPopUp] = useState(false);
  const [formatedUser, setFormatedUser] = useState({});
  const [continueclick, setcontinueclick] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [value, setValue] = useState([]);
  const [candidate, setCandidate] = useState({});
  const [myTurnPdf, setMyturnPdf] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenWindow, setShouldOpenWindow] = useState(false);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!", eventKey)
    );
    return (
      <button
        type="button"
        style={{ backgroundColor: "transparent", border: "none" }}
        onClick={(e) => {
          e.stopPropagation();
          decoratedOnClick();
        }}
      >
        {children}
      </button>
    );
  }

  useEffect(() => {
    let tempUser = { ...props.user };
    if (props.user.CandidateProfile) {
      delete tempUser.CandidateProfile;
      setFormatedUser({
        ...tempUser,
        ...props.user.CandidateProfile,
        ...props.user.phone,
      });
    }
    if (
      IsEmpty(props.state.commonReducer.employmentType) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.getCommonDropdownList();
  }, []);

  const handleViewResumeMethod = (id) => {
    setIsLoading(true);
    if (
      helper.IsEmpty(props.state.commonReducer.employmentType) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.getCommonDropdownList();
    if (Number.isInteger(id)) {
      commonActions.getUserProfile({ id }, (respose) => {
        if (respose.status === utilConstants.sucessState200) {
          let tempData = { ...respose.data };
          delete tempData.CandidateProfile;
          delete tempData.UserSkill;
          setCandidate({
            ...tempData,
            ...respose.data.CandidateProfile,
            skill:
              respose.data.UserSkill &&
              JSON.parse(respose.data.UserSkill.skill),
          });
        }
      });
      commonActions.downloadCandidateProfilePDF(id, (res) => {
        setMyturnPdf(res);
        setShouldOpenWindow(true);
      });
    }
  }

  useEffect(() => {
    if (shouldOpenWindow && myTurnPdf) {
      setIsLoading(true);

      const newWindow = window.open(
        myTurnPdf,
        '_blank',
        'width=500,height=500,top=100,left=500'
      );

      if (newWindow) {
        newWindow.onload = () => {
           setIsLoading(false);
           setShouldOpenWindow(false);
        };
      } else {
        setIsLoading(false);
        setShouldOpenWindow(false);
      }
    }
  }, [shouldOpenWindow, myTurnPdf]);

  console.log("hii", isLoading)


  useEffect(() => {
    let tempUser = { ...props.user };
    if (!IsEmpty(tempUser.User)) {
      tempUser = { tempUser, ...tempUser.User };
      tempUser = {
        ...tempUser,
        ...tempUser.tempUser,
        ...tempUser.CandidateProfile,
      };
      delete tempUser.tempUser;
      delete tempUser.CandidateProfile;
      setFormatedUser({ ...tempUser });
    } else if (props.user.CandidateProfile) {
      delete tempUser.CandidateProfile;
      setFormatedUser({
        ...tempUser,
        ...props.user.CandidateProfile,
        ...props.user.phone,
      });
    } else if (props.user) {
      delete tempUser.user;
      setFormatedUser({ ...tempUser, ...props.user.User, ...props.user.phone });
    }
  }, [props.user]);

  const employmentArr = [];
  const employmentTypeArr = formatedUser?.desired_employment?.split(",");
  employmentTypeArr?.forEach((employmentType) => {
    if (employmentType !== "") {
      employmentArr.push(utilConstants.employmentType[employmentType]);
    }
  });
  const finalRate = (value) => {
    let num = value;
    let text = num?.toString();
    let arrstr = [];
    if (text !== undefined) arrstr = Array.from(text);
    return arrstr;
  };
  return (
    <div className="search-results">
      <Card className=" border-0 profile-card">
        {formatedUser.best_match && (
          <label className="badge-label">Best Match</label>
        )}
        <div className="highlights">
          <div className="infoBlockDesktop">
            <div
              className="d-flex"
              // onClick={(e) => {
              //   e.preventDefault();
              //   history.push(
              //     `/${getAuthentionInfo().type}/publicprofile/${formatedUser.candidate_id || formatedUser.id
              //     }`
              //   );
              // }}
            >
              <img
                src={
                  formatedUser.profile_image
                    ? process.env.REACT_APP_API_BASE_URL +
                    "/user_profile/" +
                    formatedUser.profile_image
                    : "/images/marcus.png"
                }
                alt="formatedUser"
                className="profile-pic"
              ></img>
              <div>
                <h3 className="text-left">
                  {capitalizeFirstLetter2(formatedUser.first_name)}&nbsp;
                  {formatedUser.seen == "1" ||
                    continueclick === formatedUser.id ? (
                    <>{capitalizeFirstLetter2(formatedUser.last_name)}</>
                  ) : (
                    <>
                      {formatedUser.last_name?.substring(0, 1).toUpperCase()}.
                    </>
                  )}
                  {formatedUser.is_seeking_job !== "1" && (
                    <img
                      src="/images/active-badge.png"
                      title="Active"
                      alt="active"
                    ></img>
                  )}
                  &nbsp;
                  {formatedUser.is_feature && (
                    <img
                      src="/images/star.png"
                      alt="active"
                      className="myturn-wpx-14"
                      title="Feature"
                    ></img>
                  )}
                </h3>
                {console.log("formated", formatedUser)}
                <p>
                  {(formatedUser.seen == "1" ||
                    continueclick === formatedUser.id) &&
                    formatedUser.communication_email}
                </p>
                <p>
                  {(formatedUser.seen == "1" ||
                    continueclick === formatedUser.id) &&
                    formatPhoneNumber(formatedUser.phone)}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="salaryColumnDesktop">
                {formatedUser.minimum_amount !== null &&
                  formatedUser.minimum_amount !== 0 && (
                    <h3>
                      <span>$</span>

                      {finalRate(formatedUser.minimum_amount).map((str, i) => {
                        return (
                          <span key={i}>
                            {str}
                            {/* {i===0 && <>$&nbsp;</>} */}
                            {i === 2 &&
                              finalRate(formatedUser.minimum_amount)?.length >
                              5 &&
                              finalRate(formatedUser.minimum_amount)?.length <
                              7 && <>,</>}
                            {i === 0 &&
                              finalRate(formatedUser.minimum_amount)?.length >
                              6 && <>,</>}
                            {i === 3 &&
                              finalRate(formatedUser.minimum_amount)?.length >
                              6 && <>,</>}
                            {i === 1 &&
                              finalRate(formatedUser.minimum_amount)?.length >
                              4 &&
                              finalRate(formatedUser.minimum_amount)?.length <
                              6 && <>,</>}
                            {i === 0 &&
                              finalRate(formatedUser.minimum_amount)?.length >
                              3 &&
                              finalRate(formatedUser.minimum_amount)?.length <
                              5 && <>,</>}
                          </span>
                        );
                      })}
                      <span>/ {formatedUser.minimum_amount_type}</span>
                    </h3>
                  )}

                <p>
                  {formatedUser.city?.toUpperCase()},
                  {formatedUser.state?.toUpperCase()}
                </p>
                <h4>{employmentArr.join(",")}</h4>
              </div>
              {formatedUser.markAsA ? (
                <Dropdown className="kebabDropDown" align="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      className="kebabIcon"
                      src="/images/kebab.png"
                      alt="menu"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {formatedUser.markAsA.map((opt, k) => {
                      return (
                        <Dropdown.Item
                          key={k}
                          onClick={(e) => {
                            e.preventDefault();
                            props.changeStatus(
                              opt,
                              formatedUser.saved_id,
                              formatedUser.savedCad_id
                            );
                          }}
                        >
                          Mark as a {opt}
                        </Dropdown.Item>
                      );
                    })}
                    {
                      <Dropdown.Item
                        key="3"
                        onClick={(e) => {
                          e.preventDefault();
                          props.changeStatus(
                            "remove",
                            formatedUser.saved_id,
                            formatedUser.savedCad_id
                          );
                        }}
                      >
                        Remove from {props.type} List
                      </Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <img
                  onClick={(e) => {
                    e.preventDefault();

                    !formatedUser.is_saved
                      ? setSaveCandidateModal(true)
                      : commonActions.saveCandidate(
                        {
                          id: formatedUser.candidate_id || formatedUser.id,
                          job_profile_id: null,
                        },
                        (res) => {
                          if (res.status === utilConstants.sucessState200)
                            setFormatedUser({
                              ...formatedUser,
                              is_saved: res.message === "Candidate Saved",
                            });
                          else
                            ReactNotifications(
                              res.message,
                              utilConstants.getErrorStatus
                            );
                        }
                      );
                  }}
                  src={
                    formatedUser.is_saved
                      ? "/images/bookmark-filled.png"
                      : "/images/bookmark-empty.png"
                  }
                  title={formatedUser.is_saved ? "Saved" : "click to save"}
                  className="bookamark"
                  alt="active"
                ></img>
              )}
            </div>
          </div>
          <div className="infoBlockMobile">
            <div className="salaryColumnMobile">
              {formatedUser.minimum_amount !== null && (
                <h3>
                  <span>$</span>
                  {finalRate(formatedUser.minimum_amount).map((str, i) => {
                    return (
                      <span key={i}>
                        {str}
                        {i === 2 &&
                          finalRate(formatedUser.minimum_amount)?.length > 5 &&
                          finalRate(formatedUser.minimum_amount)?.length <
                          7 && <>,</>}
                        {i === 0 &&
                          finalRate(formatedUser.minimum_amount)?.length >
                          6 && <>,</>}
                        {i === 3 &&
                          finalRate(formatedUser.minimum_amount)?.length >
                          6 && <>,</>}
                        {i === 1 &&
                          finalRate(formatedUser.minimum_amount)?.length > 4 &&
                          finalRate(formatedUser.minimum_amount)?.length <
                          6 && <>,</>}
                        {i === 0 &&
                          finalRate(formatedUser.minimum_amount)?.length > 3 &&
                          finalRate(formatedUser.minimum_amount)?.length <
                          5 && <>,</>}
                        {/* } */}
                      </span>
                    );
                  })}
                  <span>/ {formatedUser.minimum_amount_type}</span>
                </h3>
              )}

              <p>
                {formatedUser.city?.toUpperCase()},
                {formatedUser.state?.toUpperCase()}
              </p>
              <h4>{employmentArr.join(",")}</h4>
            </div>
          </div>
        </div>
        <Accordion className="profile-card-content-accordion">
          <Card className="no-style">
            <Card.Header className="no-style justify-content-between">
              <div className="ps-2">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    if (!IsEmpty(formatedUser.phone))
                      setPrivateInfoModal({
                        isOpen: true,
                        res: {
                          data: {
                            communicationemail:
                              formatedUser.communication_email,
                            phone: formatedUser.phone,
                          },
                          status: 200,
                        },
                      });
                    else
                      !formatedUser.mobile &&
                        commonActions.getConatct(
                          { id: formatedUser.candidate_id || formatedUser.id },
                          (res) => {
                            if (res.status === utilConstants.sucessState200)
                              setPrivateInfoModal({
                                isOpen: true,
                                res,
                              });
                            else setNoLimitPopUp(true);
                          }
                        );
                  }}
                  className="tertiary-button"
                >
                  Contact
                </Button>
              </div>
              {candidate.id === getAuthentionInfo().user_id && (
                <div className="uploadBox2">
                  <span className="uploadIcon">
                    <img src="/images/uploadIcon.png" alt="user" />
                  </span>
                  <img
                    className="fileIcon"
                    src="/images/fileText.png"
                    alt="file"
                  />
                  <label className="fileName">
                    Resume - {candidate.cv_path}
                  </label>
                  <div className="cta-panel">
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          process.env.REACT_APP_API_BASE_URL +
                          "/user_resume/" +
                          candidate.cv_path,
                          "_blank",
                          "width=500,height=500, top=100, left=500"
                        );
                      }}
                      className="cursor-pointer"
                    >
                      <img src="/images/Eye.png" alt="preview" />
                    </p>
                    <a
                      href={
                        process.env.REACT_APP_API_BASE_URL +
                        "/user_resume/" +
                        candidate.cv_path
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img src="/images/download.png" alt="download" />
                    </a>
                  </div>
                </div>
              )}
              <div className="">
             <Button
             className="primary-filed-green"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   history.push(
                  //     `/${getAuthentionInfo().type}/publicprofile/${formatedUser.candidate_id || formatedUser.id
                  //     }`
                  //   );
                  //   props.employerActions.getScrolldata(props.scrollvalue);
                  // }}
                  onClick={() => handleViewResumeMethod(formatedUser?.candidate_id || formatedUser?.id) }
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> : "View Resume" } 
                </Button> 
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="other-details">
                  <div className="seperator"></div>
                  <Row>
                    <div>
                      {formatedUser?.CandidateProfile?.cover_letter ?? ""}
                    </div>
                  </Row>
                  <Row>
                    <Col className="text-align-center">
                      <CustomToggle eventKey="0">
                        <img
                          src="/images/arrow-up.png"
                          alt="close"
                          onClick={() => { }}
                        />
                      </CustomToggle>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <CustomeModel
          handleClose={() => {
            setSaveCandidateModal(false);
            setProject([]);
          }}
          onPositive={(e) => {
            e.preventDefault();
            setSaveCandidateModal(false);
            setProject([]);
          }}
          onNegative={(e) => {
            e.preventDefault();
            if (value?.status === 200 && value?.data?.id !== undefined) {
              commonActions.saveCandidate(
                {
                  id: formatedUser.candidate_id || formatedUser.id,
                  job_profile_id: value?.data?.id,
                },
                (res) => {
                  if (res.status === utilConstants.sucessState200)
                    setFormatedUser({
                      ...formatedUser,
                      is_saved: res.message === "Candidate Saved",
                    });
                  else
                    ReactNotifications(
                      res.message,
                      utilConstants.getErrorStatus
                    );
                }
              );
            }

            setSaveCandidateModal(false);
          }}
          modalBody={
            <form>
              <p>Choose job to save the candidate to</p>
              <Form.Label>Job</Form.Label>
              <MultiInputSelect
                type="dropdownlist"
                label=""
                options={[]}
                placeholder=""
                loadOptions={(value, callback = () => { }) => {
                  if (value)
                    candidateActions.getPosition({ st: value }, (response) => {
                      if (response.status === utilConstants.sucessState200) {
                        var options = [];
                        response.data.map((v) => {
                          options.push({
                            label: v,
                            value: v,
                          });
                        });
                        callback(options);
                      }
                    });
                }}
                onSelect={(value) => {
                  if (value?.value?.length > 255) return;
                  commonActions.jobProfileData(
                    {
                      jobTitles: value?.value,
                    },
                    (res) => {
                      if (res.status === utilConstants.sucessState200) {
                        setValue(res);
                      } else {
                        ReactNotifications(
                          res.msg,
                          utilConstants.getErrorStatus
                        );
                      }
                    }
                  );
                }}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={
                  value?.data?.job_profile_name > ""
                    ? [
                      {
                        value: value?.data?.job_profile_name,
                        label: value?.data?.job_profile_name,
                      },
                    ]
                    : []
                }
              />
            </form>
          }
          modalType="Positive/Negative"
          positiveButtonLable="Cancel"
          negativeButtonLable="Save"
          show={saveCandidateModal}
        />
        <CustomeModel
          modalBody={
            <>
              <h3 className="heading-box">Ready to hire !</h3>
              <p className="contant-box">
                The <b>Basic Pack</b> lets you view contact info
              </p>
              <Link
                to="/employer/account-settings/membership"
                className="box-link"
              >
                Upgrade!
              </Link>
            </>
          }
          show={noLimitPopUp}
          handleClose={() => {
            setNoLimitPopUp(false);
          }}
        />
        <CustomeModel
          className={"candidate-info-modal"}
          handleClose={() => {
            setPrivateInfoModal({ isOpen: false });
            setcontinueclick(formatedUser.id);
          }}
          show={privteInfoModal.isOpen}
          headerImage={"/images/wallet.png"}
          modalType="Custome"
          customeButton={
            <CustomButton
              label="Continue"
              className="cta-type-2 h-40 w-50 br-16 btn-cpc custom-btn"
              onClick={(e) => {
                e.preventDefault();
                setPrivateInfoModal(false);
                setcontinueclick(formatedUser.id);
              }}
            />
          }
          modalBody={
            privteInfoModal.res && (
              <p>
                {privteInfoModal.res.status === utilConstants.sucessState200 ? (
                  <ul>
                    <li className="text-start">
                      <b>Email:&nbsp;</b>
                      {privteInfoModal.res.data.communicationemail}
                    </li>
                    {privteInfoModal.res.data.phone !== 0 && (
                      <li className="text-start">
                        <b>Phone:&nbsp;</b>
                        {privteInfoModal.res.data.phone}
                      </li>
                    )}
                  </ul>
                ) : (
                  privteInfoModal.res.message
                )}
              </p>
            )
          }
        />
      </Card>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    employerActions: bindActionCreators(employerActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateCard);
