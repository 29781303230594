import * as typesConstants from "../../utility/constants/typesConstants";
import { updateReducer } from "./../../utility/helper/helper";
import { async } from "./../../utility/config/api/index";

export function flushSignUp() {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.FLUSH_SIGN_UP, null));
  };
}

/**
 *
 * @param {'provide user signup detail'} signUpData
 * @description {'return user sign-up detail'}
 */
export function signUp(data, callBack = () => {}) {
  async("frontend/user/signup", "POST", data).then((response) => {
    callBack(response);
  });
}

export function verifySignup(data, callBack = () => {}) {
  async("frontend/verify/signupOTP", "POST", data).then((response) => {
    callBack(response);
  });
}

export function signIn(data, callBack = () => {}) {
  return async("frontend/user/signin", "POST", data).then((response) => {
    callBack(response);
  });
}

export function signInSignUpWithGoogle(data, callBack = () => {}) {
  return async("frontend/user/signUpSignInWithGoogle", "POST", data).then((response) => {
    callBack(response);
  });
}

export function reSendOTP(data, callBack = () => {}) {
  return async("frontend/resendOTP", "POST", data).then((response) => {
    callBack(response);
  });
}

export function generateOTP(data, callBack = () => {}) {
  return async("frontend/generateOTP", "POST", data).then((response) => {
    callBack(response);
  });
}

export function logout(data, callBack = () => {}) {
  return async("user/logout", "POST", data).then((response) => {
    callBack(response);
  });
}
export function browserSessionLogout(data, callBack = () => {}) {
  return async("user/browserSessionLogout", "POST", data).then((response) => {
    callBack(response);
  });
}

export function sendResetLink(data, callBack = () => {}) {
  return async("frontend/forgot", "POST", data).then((response) => {
    callBack(response);
  });
}
