import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as commonActions from '../../../actions/commonActions';
import { IsEmpty } from '../../../utility/helper/helper';
function Dashboard(props) {
  const [user, setUser] = useState({});
  useEffect(() => {
    props.commonActions.signinUserDetails();
    setUser(props.state.commonReducer.signedUserDetail);
  }, []);
  useEffect(() => {
    if (
      IsEmpty(props.state.commonReducer.signedUserDetail) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.signinUserDetails();
    else {
      let { signedUserDetail } = props.state.commonReducer;
      if (signedUserDetail) {
        let tempDate = { ...signedUserDetail };
        delete tempDate.EmployerProfile;
        tempDate = { ...tempDate, ...signedUserDetail['EmployerProfile'] };
        setUser(tempDate);
      }
    }
  }, [props.state.commonReducer]);
  return (
    <div className='dashboardPageWrapper'>
      {user && (
        <Container>
          <Row>
            <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }}>
              <div className='contentBox'>
                <div className='personInfoHeader'>
                  <div className='column1'>
                    <img
                      className='userpic'
                      src={
                        user.profile_image
                          ? process.env.REACT_APP_API_BASE_URL +
                            '/user_profile/' +
                            user.profile_image
                          : '/images/userpic.png'
                      }
                      alt='user'
                    />
                  </div>
                  <div className='column2'>
                    <label className='row1'>
                      {user.first_name + ` ` + user.last_name}
                    </label>
                    <label className='row2'> {user.phone !==0 && user.phone}</label>
                    <label className='row3'> {user.email}</label>
                    {+user.pause_account === 1 && (
                      <label style={{ color: 'red', fontStyle: 'italic' }}>
                        ACCOUNT PAUSED (to resume, see Account Settings)
                      </label>
                    )}
                  </div>
                  <div className='column3'>
                    <Dropdown className='kebabDropDown' align='end'>
                      <Dropdown.Toggle id='dropdown-basic'>
                        <img
                          className='kebabIcon'
                          src='/images/kebab.png'
                          alt='menu'
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to='/employer/edit_profile'>
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className='innerBlock'>
                  <div className='wrapperRow'>
                    <div className='left'>
                      <p className='row1'>COMPANY NAME</p>
                      <p className='row2'>{user.company_name}</p>
                    </div>
                    <div className='right'>
                      <p className='row1'>COMPANY ADDRESS</p>
                      <p className='row2'>{`${user.company_address}, ${user.company_city}, ${user.company_state}, ${user.company_zip}`}</p>
                    </div>
                  </div>
                  <div className='wrapperRow'>
                    <div className='left'>
                      <p className='row1'>COMPANY WEBSITE</p>
                      <p className='row2'>{user.company_website}</p>
                    </div>
                  </div>
                  <div className='seperator'></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
