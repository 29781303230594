import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div className="top-panel">
        <Container className="h-100">
          <div className="background-image">
          </div>
          <div className="content_privacy">
            <Row className="h-100">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                className="h-100"
              >
                <div className="d-flex align-items-center h-100">
                  <h1>PRIVACY POLICY</h1>
                </div>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="section">
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={6} className="text-center">
              <div className="text-center">{/*<h1>PRIVACY POLICY</h1>*/}</div>
            </Col>
            <Col xs={12}>
              <h2 className="sub-heading-title mt-4">Overview</h2>
              <Card className="infoBoxLandscape mb-3 border-0">
                <Card.Body>
                  <Card.Title>myTurnCareers LLC.</Card.Title>
                  <Card.Text>
                    (&ldquo;myTurnCareers&rdquo;, &ldquo;MyTurn&rdquo;,
                    &ldquo;we&rdquo;, &ldquo;us&rdquo;, and &ldquo;our&rdquo;)
                    respects your privacy and is committed to protecting it
                    through compliance with this{" "}
                    <a href="/privacy-policy">Privacy Policy</a> (&ldquo;Privacy
                    Policy&rdquo;). This{" "}
                    <a href="/privacy-policy">Privacy Policy</a> describes the
                    types of Information we may collect from you when you visit
                    the website at{" "}
                    <a href="https://www.myturn.careers">
                      https://www.myturn.careers
                    </a>{" "}
                    (the &ldquo;Site&rdquo;), and that you may provide through
                    your communications with MyTurn. It also describes our
                    practices for collecting, using, maintaining, protecting,
                    and disclosing that Information.
                    <br></br>
                    <br></br>
                    Please read this{" "}
                    <a href="/privacy-policy">Privacy Policy</a> carefully to
                    understand our policies and practices regarding your
                    Information and how we will handle it. If you do not agree
                    with our policies and practices, do not use the Site. By
                    accessing or using the Site, you agree to this{" "}
                    <a href="/privacy-policy">Privacy Policy</a>.<br></br>
                    <br></br>
                    MyTurn may change this{" "}
                    <a href="/privacy-policy">Privacy Policy</a> at any time, at
                    its discretion. Your continued use of the Site after we make
                    changes is deemed to be acceptance of those changes, so
                    please check the{" "}
                    <a href="/privacy-policy">Privacy Policy</a> periodically
                    for updates.
                    <br></br>
                    <br></br>
                    This <a href="/privacy-policy">Privacy Policy</a> is subject
                    to and governed by the MyTurn Website{" "}
                    <a href="/terms-and-conditions">Terms &amp; Conditions</a>{" "}
                    (&ldquo;Terms and Conditions&rdquo;).
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                The Types of Information That MyTurn Collects About You and How
                MyTurn Collects Information About You.
              </h3>
              <p className="pl-30">
                MyTurn may collect two types of Information from you when you
                visit the Site: Personal Information and Non-Personal
                Information (collectively &ldquo;Information&rdquo;).
              </p>

              <ul className="pl-62 mt-3">
                <li>
                  &ldquo;Personal Information&rdquo; refers to data by which you
                  may be personally identified, such as name, postal address,
                  e-mail address, and telephone number.
                </li>
                <li>
                  &ldquo;Non-Personal Information&rdquo; means data that is
                  about you but does not identify you specifically. If you do
                  nothing during your visit to our Site but browse, read pages,
                  or view content, we will gather and store Information about
                  your visit that does not identify you personally.
                </li>
              </ul>

              <p className="pl-30 mt-3">We collect Information:</p>

              <ul className="pl-62 mt-3">
                <li>Directly from you when you provide it to us;</li>
                <li>
                  Through our business-to-business communications with you;
                </li>
                <li>From other sources or third parties;</li>
                <li>Automatically as you navigate through the Site; and</li>
                <li>Through third-party analytics.</li>
              </ul>

              <Row>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>
                        Information You Provide Directly to MyTurn.
                      </Card.Title>
                      <Card.Text>
                        The Information that you provide us directly through our
                        Site may include:
                        <br></br>
                        <ul className="mt-3">
                          <li>
                            Information that you provide to MyTurn by
                            registering for an account, uploading images,
                            filling in forms or profile data on our Site, or
                            leaving comments on the Site. We may also ask you
                            for Information when you report a problem with our
                            Site. And it may be necessary for you to provide us
                            with Information so we can communicate with you
                            about an inquiry you have.
                          </li>
                          <li>
                            Information that you provide through the submission
                            of your resume;
                          </li>
                          <li>
                            Responses to questionnaires that we might ask you to
                            voluntarily complete;
                          </li>
                          <li>
                            Records and copies of your correspondence (including
                            email addresses), if you contact us; and
                          </li>
                          <li>
                            Details of transactions you carry out through our
                            Site.
                          </li>
                        </ul>
                        <br></br>
                        <br></br>
                        <p>
                          Should you choose to log in or register via a third
                          party (for example, by using Facebook, LinkedIn,
                          Google, etc.), MyTurn will have access to any public
                          information on the associated profile. Any of this
                          public information is considered to be content that
                          you shared with MyTurn.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>
                        Information Automatically Collected from You.
                      </Card.Title>
                      <Card.Text>
                        The Information that MyTurn may automatically collect
                        and store about you when you visit the Site may include:
                        <br></br>
                        <ul className="mt-3">
                          <li>Your location.</li>
                          <li>
                            The Internet Protocol Address and domain name used.
                            The Internet Protocol address is a numerical
                            identifier assigned either to your Internet service
                            provider or directly to your computer. We use the
                            Internet Protocol Address to direct Internet traffic
                            to you.
                          </li>
                          <li>
                            The type of browser and operating system you used;
                          </li>
                          <li>The date and time you visited the Site;</li>
                          <li>
                            The web pages or services you accessed at the Site;
                          </li>
                          <li>
                            The length of time you spent on a web page at the
                            Site;
                          </li>
                          <li>
                            The website you visited prior to coming to the Site;
                            and
                          </li>
                          <li>
                            Cookies, which are small text files that a web
                            server (such as MyTurn’s or its chosen web hosting
                            company server) places on a user’s computer. When
                            accessing a cookie, MyTurn’s server reads a number
                            associated with a browser, but is unable to
                            determine any data about a user. With that number,
                            our web server can tailor its content to the needs
                            of the specific browser, and we can optimize the
                            Site for your use.
                          </li>
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>
                        Google Analytics Advertising Features.
                      </Card.Title>
                      <Card.Text>
                        We also may have the following Google Analytics
                        Advertising Features Implemented: Demographics and
                        Interest reporting, Remarketing, GDN Impression
                        Reporting and Double Click Campaign Manager. These
                        features collect data using Google advertising cookies
                        and anonymous identifiers. MyTurn and third-party
                        vendors use these first-party cookies to access the
                        success of online marketing campaigns, inform future
                        campaigns and improve usability on the Site. To learn
                        how to opt-out of the Google Analytics Advertising
                        Features we use, please see Google Analytics’ currently
                        available opt-outs for the web at{" "}
                        <a href="https://tools.google.com/dlpage/gaoptout">
                          https://tools.google.com/dlpage/gaoptout
                        </a>
                        .
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                How MyTurn Uses Information It Collects About You and the
                Purpose for the Collection and Use.
              </h3>
              <p className="pl-30">
                We use Information that we collect about you or that you provide
                to us, including any Personal Information, for the following
                purposes:
              </p>

              <ul className="pl-62 mt-3">
                <li>
                  To present our Site and the Site Content (as defined in the
                  Terms and Conditions) to you.
                </li>
                <li>To operate, manage, and administer your account.</li>
                <li>
                  To provide you with the information or services that you
                  request from us. For example, when you provide your name and
                  email address to contact us, we are able to use your email
                  address to respond to your comment or question personally.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>
                <li>To personalize our services and advertising to you.</li>
                <li>
                  To create new services and to improve our business, services,
                  and security.
                </li>
                <li>
                  To notify you about changes to our Site or any services we
                  offer or provide though it.
                </li>
                <li>
                  To communicate with you regarding our services, including for
                  marketing purposes.
                </li>
                <li>
                  In any other way we may describe when you provide the
                  Information.
                </li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>For any other purpose with your consent.</li>
              </ul>

              <p className="pl-30 mt-3">
                Site statistics are compiled into traffic reports, which help
                MyTurn understand, anticipate, and respond to user needs. If we
                learn, for example, of heightened interest in certain aspects of
                MyTurn&apos;s projects, we are likely to highlight that
                information on the Site home page. This Information helps us
                create a better overall experience for Site users.
              </p>

              <p className="pl-30 mt-3">
                MyTurn will retain your Information as long as MyTurn believes
                it is appropriate for the purposes of this{" "}
                <a href="/privacy-policy">Privacy Policy.</a>
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                How MyTurn Protects Your Information
              </h3>
              <p className="pl-30">
                We have implemented measures designed to protect your
                Information from accidental loss and from unauthorized access,
                use, alteration, and disclosure. Unfortunately, the internet is
                not completely secure. Although we are working to protect your
                Information, we cannot guarantee the security of your
                Information either in transmission or when stored. We are not
                responsible for circumvention of any privacy settings or
                security measures contained on the Site.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                When MyTurn Sells Your Information
              </h3>
              <p className="pl-30">
                To satisfy our business model, MyTurn &ldquo;sells&rdquo; your
                Personal Contact Information (i.e. phone number and/or email) to
                employers, which includes external human resources personnel and
                recruiters. You understand and agree that MyTurn sells such
                information to maintain its business model as is common industry
                practice. If you no longer agree to have your contact
                information sold, please visit{" "}
                <a href="/do-not-sell-my-personal-info">
                  Do Not Sell My Personal Information.
                </a>
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                When MyTurn Shares Your Information
              </h3>
              <p className="pl-30">
                We may use and disclose Non-Personal Information about our users
                without restriction. We may disclose Personal Information that
                we collect or you provide as described in this Privacy Policy:
              </p>
              <ul className="pl-62 mt-3">
                <li>To service providers, including payment processors.</li>
                <li>
                  To job seekers and employers, including external human
                  resources personnel and recruiters.
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>
                  For any other purpose disclosed by us when you provide the
                  Information.
                </li>
                <li>With your consent.</li>
                <li>
                  When you submit a review of your experience with MyTurn.
                </li>
                <li>To our subsidiaries and affiliates.</li>
                <li>To enforce or apply our Terms and Conditions.</li>
                <li>
                  To a buyer or other successor (potential or actual) in
                  connection with a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of MyTurn’s assets, including any related due
                  diligence.
                </li>
                <li>
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of MyTurn or others.
                </li>
              </ul>

              <p className="pl-30 mt-3">
                Except as described above, MyTurn does not share any of your
                Information.
              </p>
              <p className="pl-30 mt-3">
                <em>Employers:</em> We may inform a Site user when you have
                taken an action with respect to information we have shared with
                you on behalf of that user, such as when you open the user’s
                application, view their resume, and/or make a decision about
                their application. You understand and agree that MyTurn may
                share such information.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Service Providers Providing Services On Our Behalf.
              </h3>
              <p className="pl-30">
                We use Service Providers to host the Site, design and operate
                the Site features, and perform administrative, payment
                processing, analytics, and communication services. Services
                these Service Providers provide include enabling MyTurn to send
                you email newsletters and providing information to MyTurn about
                the use of the Site and viewing of our content. We may disclose
                your Information to these companies so they can carry out these
                services.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Information From Children Under 13.
              </h3>
              <p className="pl-30">
                MyTurn complies with the requirements of the Children&apos;s
                Online Privacy Protection Act and related regulations. The Site
                is not directed at children, and MyTurn does not knowingly
                collect any Information from children under 13 years of age. If
                MyTurn learns that a child under 13 years of age has provided
                Information, MyTurn will delete this Information from our
                databases. If you are under 13, do not use this Site or provide
                any Information about yourself to MyTurn including your name,
                address, telephone number or email address. If you become aware
                that Information of a child under 13 years of age has been
                provided, please contact us at{" "}
                <a href="support@myturn.careers">support@myturn.careers</a>.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Links to Other Websites and Services.
              </h3>
              <p className="pl-30">
                We are not responsible for the practices employed by websites or
                services linked to or from the Site, including the information
                or content contained therein. Our Privacy Policy does not apply
                to our Service Providers or other third-party websites or
                services.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">Do Not Track.</h3>
              <p className="pl-30">
                Some browsers incorporate a &ldquo;Do Not Track&rdquo;
                (&ldquo;DNT&rdquo;) feature that, when turned on, signals to
                websites and online services that you do not want to be tracked.
                At this time, the Site does not respond to DNT signals.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Your Choices About Information.
              </h3>
              <p className="pl-30">
                We respect your privacy and your ability to choose whether to
                share or not share Information with MyTurn or any third party.
                You may choose to &ldquo;opt-out&rdquo; of having us share your
                Personal Information in several ways. You can choose to decline
                to provide Personal Information on the Site; however, if you
                choose not to provide Information, you may be unable to access
                or use all portions of the Site. If you have previously
                submitted Personal Information to the Site, you may
                &ldquo;pause&rdquo; the account from your &ldquo;Account
                Settings&rdquo; while logged in, which prevents MyTurn from
                displaying and selling your personal information. This has the
                effect of “hiding” your profile from employers but does not
                delete your account or your Personal Information.
              </p>
              <p className="pl-30 mt-3">
                You may change or update your Personal Information at any time
                through your profile on MyTurn. This includes the ability to
                modify account settings such as receiving MyTurn emails. We send
                job-related, promotional, and marketing communications only with
                your consent. If you no longer wish to receive these or other
                communications, send us an email at{" "}
                <a href="support@myturn.careers">support@myturn.careers</a> or
                click on the unsubscribe link provided in such communications.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Right to Request Deletion of Personal Information.
              </h3>
              <p className="pl-30">
                Due to the nature of our business model, if you choose to
                opt-out of selling your personal information to an employer,
                MyTurn is then unable to provide you services. As a result, your
                account must be terminated along with your personal information.
              </p>
              <p className="pl-30 mt-3">
                As a registered MyTurn user, you have the right to terminate
                your account at any time. You have the right to request the
                deletion of your Personal Information collected by MyTurn. In
                this event, your Non-Personal Information will still be stored
                in our archives.
              </p>
              <p className="pl-30 mt-3">
                In order for MyTurn to delete your account you may be required
                to provide some minimal Personal Information to us only for
                verification purposes and to locate and delete your account.
                However, if you refuse to provide your Personal Information,
                MyTurn may not be able to comply with your request.
              </p>
              <p className="pl-30 mt-3">
                If you have ultimately chosen to opt out, you must delete your
                account, which will also delete any associated Personal
                Information, by selecting &ldquo;Delete Account&rdquo; from your
                &ldquo;Account Settings&rdquo; while logged in.
              </p>
              <p className="pl-30 mt-3">
                If you are unable to delete your account using this method, you
                may request that we delete your account, along with any
                associated personal information, on your behalf by emailing us
                at <a href="support@myturn.careers">support@myturn.careers</a>{" "}
                from the email address registered with MyTurn for account login.
                This method will provide sufficient verification of your request
                for account deletion.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Right to Opt-Out of the Sale of Personal Information.
              </h3>
              <p className="pl-30">
                You have the right to opt-out of the sale of your Personal
                Information by visiting{" "}
                <a href="/do-not-sell-my-personal-info">
                  Do Not Sell My Personal Information.
                </a>
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Right to Non-Discrimination for the Exercise of a
                Consumer&apos;s Privacy Rights.
              </h3>
              <p className="pl-30">
                MyTurn will never deny you services, charge you a different
                price, or provide a different level or quality of services just
                because you exercised your rights under the CCPA.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">Authorized Agent</h3>
              <p className="pl-30">
                As prescribed within a legally authorized Letter of
                Authorization or Power of Attorney, an authorized agent can make
                a request under the CCPA on the consumer&apos;s behalf.
              </p>
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={10} className="text-center">
              <div className="text-center">
                <h2 className="subheading-small">COOKIES POLICY</h2>
                <p className="font-md">
                  To inform you about the use of cookies on our website we
                  provide this notice (&ldquo;Cookies Policy&rdquo;) on our
                  website as well as other related mobile websites, mobile
                  applications (“mobile apps”), services, tools, and other
                  applications that links to this notice (collectively, the
                  &ldquo;Site&rdquo;). This Cookies Policy governs your consent
                  to our use, as well as use by our third-party partners and
                  vendors, of a variety of cookies.
                </p>
              </div>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title mt-4">
                What are cookies and how do we use them?
              </h3>
              <p className="pl-30">
                A cookie us a small text file that is stored in your device by a
                website that you have visited. Cookies may be placed by the Site
                (first party cookies) or by third parties (third-party cookies)
                to monitor the use of a website. Cookies will be identified
                depending on their purpose and functionality. Some types of
                cookies include, but are not limited to:
              </p>

              <Row>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>Necessary.</Card.Title>
                      <Card.Text>
                        These cookies are essential for the Site’s performance
                        and for you to be able to use its features. Necessary
                        cookies always will be used because these are needed for
                        the Site to function properly. For example, essential
                        cookies include, but are not limited to: cookies to
                        provide the service, maintain your account, provide
                        builder access, payment pages, create IDs for your
                        documents, and store your consents. These cookies record
                        the performance of the Site according to your
                        interactions with it. We may use cookies required for
                        system administration, to prevent fraudulent activity,
                        improve security or allow you to make use of shopping
                        cart functionality.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>Analytics.</Card.Title>
                      <Card.Text>
                        These cookies analyze our visitors’ use of our Site as
                        well offer you a better experience. Analytic related
                        cookies on our Site do not identify who you are nor
                        allow us to send you targeted advertising. For example,
                        we may use cookies for analytics related purposes to:
                        (a) determine the number of visitors to our Site,
                        identify how visitors move around the Site and, in
                        particular, which pages they visit. This allows us to
                        improve our Site and our services; and (b) determine, if
                        data is available, from where in the world our Site is
                        being visited so that we can help provide sufficient
                        coverage. More specifically, and as an example, Provider
                        uses Google Analytics session cookies to provide
                        meaningful reports about site visitors. Google Analytics
                        cookies do not collect personal data about Site
                        visitors. We also have engaged other third-party
                        providers to analyze the activities of visitors to this
                        Site, and its authorized use of cookies enables it to
                        have access to information of visitors to this Site
                        including non-public information about you to determine
                        your user traits and preferences to better understand
                        user conversion rates.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>Performance and Personalization.</Card.Title>
                      <Card.Text>
                        These cookies make it possible for us to give you a
                        customized experience. Personalization cookies are used
                        to deliver content, including ads, relevant to your
                        interests on our Site and third-party sites based on how
                        you interact with the different elements in our site, as
                        well as track the content you access (including video
                        viewing). We may also collect computer and/or connection
                        information to tailor your experience to your needs.
                        During some visits, we may use software tools to measure
                        and collect session information, including page response
                        times, download errors, session information, and page
                        interaction information.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={12}>
                  <Card className="infoBoxLandscape mb-3 border-0">
                    <Card.Body className="pl-80">
                      <Card.Title>Advertising.</Card.Title>
                      <Card.Text>
                        These cookies are placed by third-party companies to
                        deliver targeted content based on relevant topics that
                        interest you (for example, providing you with Facebook
                        ads of interest), and allow you to better interact with
                        social media platforms such as Facebook. We may use
                        advertising tracking technologies to deliver content,
                        including ads, relevant to your interests on our Site
                        and third-party sites based on how you interact with our
                        advertisements or content as well as track the content
                        you access (including video viewing).
                        <br></br>
                        <br></br>
                        Our goal is to collect and use only that information
                        that we think is required for our legitimate business
                        interests, in order to better understand your interests
                        and improve your experience of using the Site.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col>
              <hr></hr>
            </Col>

            <Col xs={12}>
              <h3 className="sub-heading-title">
                Controlling And Deleting Cookies
              </h3>
              <p className="pl-30">
                When you accessed this Site for the first time, or if you have
                not set your cookie preferences yet, depending on your location,
                you may be prompted to accept or decline the use of cookies,
                other than those which are essential. The Help function within
                your browser also will be available to guide you on setting your
                cookie preferences. If cookie preference settings are available
                in your country, you can change them at any time by accessing
                your account and clicking on My Settings - Privacy Settings. If
                you have not created an account with us you can manage your
                cookie preferences through the Settings page of your browser.
                Note also that in some cases, restricting, removing or refusing
                to accept cookies may reduce or prevent the functionality of the
                Site.
              </p>
            </Col>

            <Col>
              <hr></hr>
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={10} className="text-center">
              <div className="text-center">
                <h2 className="subheading-small">CONTACT US</h2>
              </div>
            </Col>

            <Col xs={12}>
              <p className="pl-30">
                For questions or comments about this Privacy Policy and our
                privacy practices, Cookies and Tracking Technology Policy, or to
                report your concerns about the collection or use of Personal
                information, please contact us at{" "}
                <a href="support@myturn.careers">support@myturn.careers</a> or
                by mail at: myTurnCareers LLC, 13800 Coppermine Rd. 1st Floor,
                Herndon, VA 20171
              </p>

              <p className="pl-30 mt-3">EFFECTIVE DATE: March 15, 2022</p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
