import { IsEmpty, IsValidEmail } from "../../../utility/helper/helper";

export default function IsValidSignInData(data) {
  let errors = {};

  if (IsEmpty(data.email)) {
    errors.email = "Email is required";
  } else if (!IsValidEmail(data.email)) errors.email = "Invalid Email!";

  if (IsEmpty(data.password)) {
    errors.password = "Password is required";
  }

  return {
    isValid: IsEmpty(errors),
    errors: errors,
  };
}
