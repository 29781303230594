import React from "react";
import { Col } from "react-bootstrap";
import { getCurrentOpenings } from "../employerActions";
import { sucessState200 } from "utility/constants/utilConstants";
import { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function CurrentOpening() {
  const { id } = useParams();
  const [currentOpenings, setCurrentOpenings] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getOpeningJobs();
  }, [id]);

  const getOpeningJobs = () => {
    const limit = 10;
    const skip = currentOpenings?.length || 0;
    getCurrentOpenings(id, limit, skip, {}, (response) => {
      if (response.status === sucessState200) {
        setCurrentOpenings((prevJobs) => [...prevJobs, ...response.data]);
        setCount(response.count);
      }
    });
  };

  return (
    <Col
      xxl={{ span: 12 }}
      xl={{ span: 12 }}
      lg={{ span: 12 }}
      md={12}
      sm={12}
      xs={12}
    >
      <div className="company_info_body">
        {currentOpenings?.length === 0 ? (
          <div className="current_opening_body">
            <b>No current openings</b>
          </div>
        ) : (
          currentOpenings?.map((opening, index) => (
            <div className="current_opening_body" key={index}>
              <div className="current_opening_title">
                <div className="left_dv">
                  <h1>{opening.job_title}</h1>
                  <p>{opening.job_location}</p>
                </div>
                <div className="right_dv">
                  <h1>
                    ${opening.minimum_pay} - ${opening.maximum_pay}
                  </h1>
                  <p>{opening.job_type}</p>
                </div>
              </div>
              <p className="poster_Date">
                Posted: {moment(opening.created_at).format("L")}
              </p>
            </div>
          ))
        )}
        {currentOpenings.length < count && (
          <div onClick={() => getOpeningJobs()} className="view-position-btn1">
            View More
          </div>
        )}
      </div>
    </Col>
  );
}
