import React from "react";

export default function InputRadio({
  listOfOption = [],
  name,
  onChange = () => {},
  value,
  className,
  selectedValue
}) {
  return (
    <div className={`custom-radio-wrapper ${className || ""}`}>
      {listOfOption.map((radioOption, k) => (
        <label htmlFor={radioOption.id} className={`custom-radio ${selectedValue == radioOption.value ? "box-active" : ""}`} key={k}>
          <input
            type="radio"
            name={name}
            id={radioOption.id}
            onChange={onChange}
            value={radioOption.value}
            checked={value == radioOption.value}
          />
          <span className={"optionContent "}>{radioOption.label}</span>
        </label>
      ))}
    </div>
  );
}
