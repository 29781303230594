import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button, Dropdown } from "react-bootstrap";
import * as candidateActions from "components/candidate/candidateActions";
import { sucessState200 } from "utility/constants/utilConstants";
import Modal from "react-bootstrap/Modal";
import SearchJobListing from "./SearchJobListing";
import SearchJobDetail from "./SearchJobDetail";
import Loader from "components/common/Loader/Loader";
import seacrhimg from "../../../assets/images/magnifier.svg"
import { AuthContext } from "context/authContext";
import CustomDropdown from "./CustomDropdown";

export default function SearchJob() {
  const hasToken = localStorage.getItem("MyTurn-Token");
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [seljob, setSelJob] = useState(null);
  const [showPostForm, setPostFormShow] = useState(false);
  const [formdata, setFormData] = useState({});
  const [jobID, setJobID] = useState("new");
  const [jobDelID, setJobDelID] = useState(null);
  const [showDel, setShowDel] = useState(false);
  const [count, setCount] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [query, setQuery] = useState("");
  const className = hasToken ? "search_dv withToken pt-0" : "search_dv pt-0";
  const [isSearched, setIsSearched] = useState(false);
  const [isPopularSearched, setIsPopularSearched] = useState(false);
  const [active, setActive] = useState(1);

  const [isAdjusted, setIsAdjusted] = useState(false)
  const { fetchIpAddress, ipAddress, } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [remoteFilter, setRemoteFilter] = useState("");
  const itemsPerPage = 15;


  const adjustDetailsForMobileView = () => {
    setIsAdjusted(true)
  }

  const resetDetailsDesign = () => {
    setIsAdjusted(false)
  }

  useEffect(() => {
    loadJobs(false);
    fetchIpAddress();
  }, []);

  useEffect(() => {
    loadJobs(false);
  }, [active]);

  const userId = localStorage.getItem("candidate_id");
  // const loadJobs = (search) => {
  //   let limit
  //   let skip = jobs.length;
  //   if (search) {
  //     skip = 0;
  //     setLoading(true);
  //     setIsSearched(true);
  //     setJobs([]);
  //   }
  //   candidateActions.getMyJobs(limit = 15, skip, query, {}, (response) => {
  //     if (response.status === sucessState200) {
  //       setLoading(false);
  //       const newdata = response?.data.map((job) => {
  //         return {
  //           ...job,
  //           isApplied: job?.applied_job?.some(
  //             (appliedJob) => appliedJob.candidate_id === parseInt(userId)
  //           )
  //             ? true
  //             : false,
  //         };
  //       });
  //       setJobs((prevJobs) => [...prevJobs, ...newdata]);
  //       setCount(response.count);
  //       if ((newdata.length > 0 && seljob === null) || search) {
  //         setSelJob(newdata[0]);
  //       }
  //     }
  //   });
  // };

  const loadJobs = (search) => {
    let limit = itemsPerPage;
    let skip = (active - 1) * itemsPerPage;
    if (search) {
      skip = 0;
      setLoading(true);
      setIsSearched(true);
      setJobs([]);
    }
    candidateActions.getMyJobs(limit, skip, query, selectedOption, remoteFilter, {}, (response) => {
      if (response.status === sucessState200) {
        setLoading(false);
        const newdata = response?.data.map((job) => {
          return {
            ...job,
            isApplied: job?.applied_job?.some(
              (appliedJob) => appliedJob.candidate_id === parseInt(userId)
            )
              ? true
              : false,
          };
        });
        setJobs(newdata);
        setCount(response.count);
        if ((newdata.length > 0 && seljob === null) || search) {
          setSelJob(newdata[0]);
        }
      }
    });
  };

  const selectThisJob = (index, jobId) => {
    setSelJob(jobs[index]);
    setActiveIndex(index);
    const data = {
      ip_address: ipAddress,
      user_id: userId || "",
      user_from: "JOB CLICKS",
      job_id: jobId || "",
    }
    candidateActions.measureUserClicks(data, (response) => { })
  };

  const handleEditFormShow = (job) => {
    setJobID(job.id);
    const initialjobdata = {
      job_title: job.job_title,
      job_location: job.job_location,
      minimum_eduction: job.minimum_eduction,
      hiring_timeframe: job.hiring_timeframe,
      job_shift: job.job_shift,
      job_type: job.job_type,
      required_onsite: job.required_onsite,
      required_travel: job.required_travel,
      required_clearance: job.required_clearance,
      sponsorship: job.sponsorship,
      minimum_pay: job.minimum_pay,
      maximum_pay: job.maximum_pay,
      job_application_url: job.job_application_url,
      job_description: job.job_description,
      job_term_conditions: false,
    };
    setFormData(initialjobdata);
    setPostFormShow(true);
  };
  const handleShowDel = (jobID) => {
    setJobDelID(jobID);
    setShowDel(true);
  };
  const handleShowDelClose = () => setShowDel(false);
  const handleDeleteJob = (jobID) => {
    employerActions.deleteJob(jobID, (response) => {
      if (response.status === sucessState200) {
        setLoading(true);
        setJobDelID(null);
        setShowDel(false);
      }
    });
  };

  const handleSearch = () => {
    if (query !== "") {
      setActive(1)
      loadJobs(true);
      const data = {
        ip_address: ipAddress,
        user_id: "",
        job_id: "",
        user_from: "JOB SEARCH",
        search_keyword: query,
      }
      candidateActions.measureUserClicks(data, (response) => { })
    } else if (!query && isSearched) {
      setActive(1)
      loadJobs(true);
      setIsSearched(false);
    }
  }

  const handlePopularSearch = (value) => {
    setQuery(value);
    setIsPopularSearched(true);
    setActive(1)
  }

  useEffect(() => {
    if (query !== '' && isPopularSearched) {
      handleSearch();

      setIsPopularSearched(false);
    }
  }, [query]);

  if (loading)
    return <Loader />

  return (
    <section className="bg-theme">
      <div className="main-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-banner-section">
                <h1 className="maindiv-left-text1">
                  Secure Your Next <br></br> Cyber Security Jobs with{" "}
                  <span style={{ color: "#278E69" }}>My</span>
                  <span style={{ color: "#FE7650" }}>Turn</span>
                </h1>
                {/* <p className="maindiv-left-text2">
                  Put your security knowledge to work!
                </p> */}
              </div>
            </div>
            <div className="search-dv-wrrpr">
              <div className="search-dv-body">
                <h4>Kickstart your cybersecurity career today!</h4>
                <div className={className}>
                  <Row>
                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <div className="d-flex align-items-center home-react-search-autocomplete">
                        <div className=" w-100">
                          <div className="home_search_wrrpr">
                            <div className="home_search_body">
                              <div className="home_search_input_dv">
                                <img
                                  src={seacrhimg}
                                ></img>
                                <input
                                  placeholder="Search Job..."
                                  id="search-box"
                                  onChange={(e) => setQuery(e.target.value)}
                                  value={query}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleSearch();
                                    }
                                  }}
                                  autoFocus
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <button onClick={handleSearch} className="primary-filed-orange">Search</button>
                      </div>
                    </Col>
                    <Col
                      xxl={{ span: 4 }}
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <div style={{ background: '#fff', padding: '15px', borderRadius: '4px' }} >
                        <Row>
                          <CustomDropdown selectedOption={selectedOption} setSelectedOption={setSelectedOption} loadJobs={loadJobs}
                            remoteFilter={remoteFilter} setRemoteFilter={setRemoteFilter} />
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* <div className="main-banner-image">
              <img
                src="/images/hero-image-updated.webp"
                className=""
                alt="Banner Image"
                width={689}
              />
            </div> */}
            {/* <div className="main-arrow-image">
              <img alt="arrow" src={arrow} width={395} height={106} />
            </div> */}
          </div>
        </div>
      </div>
      <Container>
        <Row>
          <SearchJobListing
            jobs={jobs}
            loadJobs={loadJobs}
            count={count}
            handleDeleteJob={(job) => handleDeleteJob(job)}
            handleEditFormShow={(job) => handleEditFormShow(job)}
            selectThisJob={(index, jobId) => selectThisJob(index, jobId)}
            handleShowDel={(jobID) => handleShowDel(jobID)}
            activeIndex={activeIndex}
            adjustDetailsForMobileView={adjustDetailsForMobileView}
            handlePopularSearch={handlePopularSearch}
            active={active}
            setActive={setActive}
          />
          <SearchJobDetail resetDetailsDesign={resetDetailsDesign} isAdjusted={isAdjusted} job={seljob} />
        </Row>
        <Modal show={showDel}>
          <Modal.Body className="modal_body_wrrpr">
            <div className="delete_modal_body">
              <h1>Are you sure you want to delete this item?</h1>
            </div>
            <div className="post_job_btns_chckbox mt-3">
              <Button
                className="cta-type-2 h-40 btn btn-primaryfalse post_job"
                variant="secondary"
                onClick={handleShowDelClose}
              >
                Close
              </Button>
              <Button
                className="cta-type-1 h-40 btn btn-primary delete-btn post_job"
                variant="primary"
                onClick={() => handleDeleteJob(jobDelID)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
}
