import React, { createContext, useState } from 'react';

import * as lndingActions from "../components/landing/lndingActions";
import * as commonActions from "../actions/commonActions";
import * as utilConstants from "../utility/constants/utilConstants";
import * as helper from "../utility/helper/helper";
import ReactNotifications from 'components/common/reactNotifications/ReactNotifications';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [verifyUserPopUp, setVerifyUserPopUp] = useState(false);
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    sign_in_with_google: false,
    email: "",
    profileImage: "",
    jti_token: "",
    sub: "",
    browser_type: "",
  });
  let history = useHistory();
  const [signupType, setSignupType] = useState("");
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [verifyResumePopUp, setVerifyResumePopUp] = useState(false);
  const [candidate, setCandidate] = useState({
    candidateAgree: false,
  });
  const [otpResponseMessage, setOtpResponseMessage] = useState();
  const [candidateErrors, setCandidateErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isInitialButtonLoading, setIsInitialButtonLoading] = useState(false);
  const [isRecaptcha, setIsRecaptcha] = useState("");
  const [isSubscription, setIsSubscription] = useState(false);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [isSearchTabClicked, setIsSearchTabClicked] = useState(false);
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);


  const handleGenerateOtp = () => {
    setIsInitialButtonLoading(true);
    const requestData = userInfo.sign_in_with_google ? { ...userInfo, type: signupType } : { ...candidate, type: signupType };

    lndingActions.generateOTP(
      requestData,
      (response) => {
        setIsInitialButtonLoading(false);
        if (response.status === utilConstants.notExpectedState409) {
          setVerifyUserPopUp(false);
          setCandidateErrors({ email: response.message });
        } else if (response.status === utilConstants.inCompleteState203) {
          setVerifyUserPopUp(false)
          setOtpPopUp(true);
        }
        else if (response.status === utilConstants.sucessState200) {
          setVerifyUserPopUp(false)
          setOtpPopUp(true);
        }
        else {
          ReactNotifications(response.message, utilConstants.getErrorStatus);
          setVerifyUserPopUp(false)
        }
      }
    );

  }

  const handleApiResponse = (response) => {
    setLoading(false);
    if (response.status === utilConstants.sucessState200 && response.data) {
      setOtpPopUp(false);
      if (response.data.user_type === utilConstants.USER_CANDIDATE) setVerifyResumePopUp(true);
      localStorage.setItem(utilConstants.ACCESS_TOKEN, response.data.auth_token);
      localStorage.setItem(utilConstants.LOGIN_TIME, moment().format());

      if (response.data.user_type === utilConstants.USER_CANDIDATE) {
        localStorage.setItem("candidate_id", response.data.id);
      } else {
        localStorage.setItem("employer_id", response.data.id);
      }
      commonActions.signinUserDetails();

      if (signupType === "Employer" && response.data.checkout_url) {
        window.location.assign(response.data.checkout_url);
      } else if (!response.data.checkout_url && signupType === "Employer") {
        history.push("/employer/account-settings");
      }

    } else {
      ReactNotifications(response.message, utilConstants.getErrorStatus);
      setLoading(false);
    }
  };

  const onOtpConfirm = () => {
    setLoading(true); 0

    const requestData = userInfo.sign_in_with_google ? { ...userInfo, type: signupType } : { ...candidate, type: signupType };
    const isSubscriptionSelected = isSubscription === true;

    const dta = isSubscriptionSelected
      ? { ...candidate, select_subscription: true, selected_plan: subscriptionPlanId, type: signupType }
      : requestData;

    lndingActions.verifySignup(userInfo.sign_in_with_google ? userInfo : candidate, (response) => {
      if (response.status === utilConstants.sucessState200 && response.data) {
        setOtpPopUp(false);

        if (userInfo.sign_in_with_google) {
          lndingActions.signInSignUpWithGoogle(requestData, handleApiResponse);
        } else {
          lndingActions.signUp(dta, handleApiResponse);
        }

        setSignupType("");
        setIsSubscription(false);
        setCandidate({});
      } else {
        ReactNotifications(response.message, utilConstants.getErrorStatus);
      }

      setLoading(false);
    });
  };


  const onChange2 = (e) => {
    e.preventDefault();
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      verify_code: e.target.value || "",
    }));
    let tempCandidate = candidate;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      Object.assign(tempCandidate, helper.setDataAccordingInput(e, true));
    }
    let errors = { ...candidateErrors };
    delete errors[e.target.name];
    setCandidate(tempCandidate);
    setCandidateErrors(errors);
  };

  const googleSignup = () => {
    const data = { ...userInfo, type: signupType }
    lndingActions.signInSignUpWithGoogle(data, handleApiResponse)
  }

  const fetchIpAddress = async () => {
    try {
      const response = await fetch('http://ip-api.com/json/');
      const data = await response.json();
      setIpAddress(data?.query);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  return (
    <AuthContext.Provider value={{
      verifyUserPopUp,
      setVerifyUserPopUp,
      userInfo,
      setUserInfo, signupType, setSignupType,
      otpPopUp, setOtpPopUp, verifyResumePopUp, setVerifyResumePopUp,
      candidate, setCandidate,
      otpResponseMessage, setOtpResponseMessage, candidateErrors, setCandidateErrors,
      isLoading, setLoading, isInitialButtonLoading, setIsInitialButtonLoading,
      isRecaptcha, setIsRecaptcha,
      handleGenerateOtp, onOtpConfirm, onChange2,
      googleSignup, setIsSubscription, isSubscription,
      subscriptionPlanId, setSubscriptionPlanId,
      fetchIpAddress, ipAddress,
      isSearchTabClicked, setIsSearchTabClicked,
      isUpgradePlan, setIsUpgradePlan
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
