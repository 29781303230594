import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import AccountSettingsTab from "./account-settings";
import Billing from "./billing";
import Support from "./support";
import Verification from "./verification";
import Company from "./company";
import Notification from "./notification";
import Membership from "./Membership";
import CPC from "./cpc";
import { useLocation, useHistory } from "react-router-dom";
import { getAuthentionInfo } from "../../utility/helper/authenticationHelper";
import { USER_EMPLOYER } from "../../utility/constants/utilConstants";
import { USER_CANDIDATE } from "../../utility/constants/utilConstants";
import * as commonActions from "../../actions/commonActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dashboard from "components/candidate/dashboard/Dashboard";
import Resume from "components/resume/Resume";
import profileimg from "../../assets/images/candidate_profile.svg";
import resumeimg from "../../assets/images/candidate_resume.svg";
import accountsettingimg from "../../assets/images/account_setting_icon.svg";
import supportimg from "../../assets/images/support_icon.svg";

function AccountSettingsMain(props) {
  const user = props?.state?.commonReducer?.signedUserDetail || {};
  const employer = localStorage.getItem("employer_id");
  const reloadProfile = props.commonActions.signinUserDetails;
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const [currentTab, setTab] = useState("");


  useEffect(() => {
    if (path) {
      if (path.includes("membership") && employer) {
        setTab("membership");
      }
      // else if (path.includes("/employer/account-settings/billing")) {
      //   setTab("account");
      // }
      else if (path.includes("contact-list")) {
        setTab("contact-list");
      } else if (path.includes("support")) {
        setTab("support");
      } else if (path.includes("profile")) {
        setTab("profile");
      } else if (path.includes("resume")) {
        setTab("resume");
      } else if (path.includes("verification")) {
        setTab("verification");
      } else if (path.includes("company")) {
        setTab("company");
      } else if (path.includes("notification")) {
        setTab("notification");
      } else {
        setTab("account");
      }
    }
  }, [path]);

  const changeTab = (tab) => {
    history.push(`/${getAuthentionInfo().type}/account-settings/${tab}`);
  };

  return (
    <div className="account-settings py-md-5">
      <Container>
        <Row>
          <Col
            xs={12}
            sm={10}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="wrapperCol"
          >
            <div className="account-settings-wrapper">

              <div className="content">
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={currentTab}
                  onSelect={(tab) => changeTab(tab)}
                >
                  <Row>
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      className="left-menu"
                    >
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>

                          {getAuthentionInfo().type === USER_CANDIDATE && (
                            <>

                              <Nav.Item>
                                <Nav.Link eventKey="profile">
                                  <span className="menuImg">
                                    <img src={profileimg} alt="" />

                                  </span>
                                  <span className="menu-text">My Candidate Profile</span>
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                          {getAuthentionInfo().type === USER_CANDIDATE && (
                            <>
                              <Nav.Item>
                                <Nav.Link eventKey="resume">
                                  <span className="menuImg">
                                   <img src={resumeimg} alt=""/>
                                  </span>
                                  <span className="menu-text">My Resume</span>
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                          <Nav.Link eventKey="account">
                            <span className="menuImg">
                            <img src={accountsettingimg} alt=""/>
                            </span>
                            <span className="menu-text">Account Settings</span>
                          </Nav.Link>
                        </Nav.Item>
                        {getAuthentionInfo().type === USER_EMPLOYER && (
                          <>
                            <Nav.Item>
                              <Nav.Link eventKey="membership">
                                <span className="menuImg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 512.000000 512.000000"
                                  >
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                      <path d="M330 4139 c-153 -30 -290 -170 -320 -328 -6 -34 -10 -485 -10 -1255 0 -1303 -1 -1268 55 -1368 62 -111 183 -192 313 -209 88 -11 4296 -11 4384 0 130 17 251 98 313 209 57 102 56 63 53 1405 l-3 1232 -21 52 c-46 111 -136 201 -247 247 l-52 21 -2210 1 c-1239 1 -2230 -2 -2255 -7z m1451 -645 c186 -55 319 -188 385 -384 13 -40 18 -83 18 -155 -1 -115 -23 -193 -81 -288 -19 -32 -31 -62 -27 -66 5 -4 43 -30 84 -57 140 -91 292 -273 326 -391 59 -203 -38 -414 -235 -507 l-66 -31 -550 0 -550 0 -58 23 c-161 65 -268 225 -267 401 1 130 59 240 205 386 62 63 126 116 167 140 l68 39 -21 30 c-70 107 -99 201 -100 326 -2 246 168 471 402 534 75 20 232 20 300 0z m2607 -214 c100 -61 96 -204 -8 -264 -34 -21 -48 -21 -610 -21 -562 0 -576 0 -610 21 -104 60 -108 203 -8 264 32 19 52 20 618 20 566 0 586 -1 618 -20z m-38 -570 c20 -5 51 -26 70 -45 29 -30 34 -43 37 -92 5 -68 -16 -111 -69 -143 -32 -19 -52 -20 -618 -20 -566 0 -586 1 -618 20 -109 67 -88 243 34 280 41 12 1120 12 1164 0z m-201 -600 c109 -55 109 -218 1 -272 -32 -16 -77 -18 -493 -18 -403 0 -463 2 -493 16 -49 23 -77 68 -81 128 -5 67 20 113 77 143 43 23 44 23 497 23 432 0 456 -1 492 -20z" />
                                      <path d="M1525 3176 c-60 -28 -87 -56 -114 -116 -25 -55 -27 -145 -5 -198 19 -47 68 -98 116 -123 114 -59 259 -14 320 98 37 68 44 118 24 187 -43 147 -201 218 -341 152z" />
                                      <path d="M1485 2380 c-169 -44 -289 -128 -381 -264 -37 -55 -42 -91 -19 -136 32 -61 27 -61 561 -58 l486 3 29 33 c39 43 39 92 1 153 -45 71 -91 118 -165 170 -152 107 -344 144 -512 99z" />
                                    </g>
                                  </svg>
                                </span>
                                <span className="menu-text">Membership Status</span>
                              </Nav.Link>
                            </Nav.Item>
                          </>
                        )}

                        {/* <Nav.Item>
                          <Nav.Link eventKey="billing">
                            <span className="menuImg">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 6.50727C1 4.02471 2.99 2 5.44 2H14.55C17 2 19 4.02471 19 6.52762C19 6.78851 18.7885 7 18.5276 7H1.49273C1.2206 7 1 6.7794 1 6.50727Z"
                                  fill="#252938"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1.5 8.5C1.22386 8.5 1 8.72386 1 9V13.4724C1 15.9651 2.99 18 5.45 18H14.56C17.01 18 19 15.9753 19 13.4826V9C19 8.72386 18.7761 8.5 18.5 8.5H1.5ZM4 11.5C4 11.2239 4.22386 11 4.5 11H14.5C14.7761 11 15 11.2239 15 11.5C15 11.7761 14.7761 12 14.5 12H4.5C4.22386 12 4 11.7761 4 11.5ZM4.5 14C4.22386 14 4 14.2239 4 14.5C4 14.7761 4.22386 15 4.5 15H9.5C9.77614 15 10 14.7761 10 14.5C10 14.2239 9.77614 14 9.5 14H4.5Z"
                                  fill="#252938"
                                />
                              </svg>
                            </span>
                            <span className="menu-text">Billing</span>
                          </Nav.Link>
                        </Nav.Item> */}
                        {getAuthentionInfo().type === USER_EMPLOYER && (
                          <>

                            <Nav.Item>
                              <Nav.Link eventKey="company">
                                <span className="menuImg">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.83397 2.36707C8.23067 2.36707 7.71741 2.78631 7.57334 3.35137H12.4178C12.2737 2.78631 11.7604 2.36707 11.1571 2.36707H8.83397ZM13.7864 3.35138H15.5693C17.4602 3.35138 19 4.90985 19 6.82377C19 6.82377 18.946 7.63399 18.928 8.76229C18.9262 8.8516 18.8829 8.9391 18.8118 8.99196C18.3787 9.31186 17.9825 9.57616 17.9465 9.59439C16.4517 10.5969 14.7148 11.3023 12.8643 11.6532C12.7437 11.6769 12.6248 11.614 12.5636 11.5065C12.0449 10.6078 11.076 10.0227 9.9955 10.0227C8.92216 10.0227 7.94427 10.6015 7.41031 11.501C7.34817 11.6067 7.23112 11.6678 7.11136 11.645C5.27624 11.2932 3.53927 10.5887 2.05353 9.6035L1.18909 9.00198C1.11706 8.95641 1.07204 8.87439 1.07204 8.78325C1.04502 8.31844 1 6.82377 1 6.82377C1 4.90985 2.53977 3.35138 4.43072 3.35138H6.2046C6.37569 2.02987 7.48324 1 8.83392 1H11.1571C12.5078 1 13.6153 2.02987 13.7864 3.35138ZM18.6937 10.7337L18.6577 10.752C16.8388 11.9732 14.6507 12.7843 12.3545 13.1216C12.0304 13.1671 11.7062 12.9575 11.6162 12.6294C11.4181 11.8821 10.7788 11.3899 10.0134 11.3899H10.0044H9.98636C9.22098 11.3899 8.58166 11.8821 8.38356 12.6294C8.29351 12.9575 7.96935 13.1671 7.64519 13.1216C5.34904 12.7843 3.16095 11.9732 1.34204 10.752C1.33303 10.7428 1.24299 10.6882 1.17095 10.7337C1.08991 10.7793 1.08991 10.8887 1.08991 10.8887L1.15294 15.5367C1.15294 17.4507 2.68371 19 4.57465 19H15.4161C17.307 19 18.8378 17.4507 18.8378 15.5367L18.9098 10.8887C18.9098 10.8887 18.9098 10.7793 18.8288 10.7337C18.7838 10.7064 18.7297 10.7155 18.6937 10.7337ZM10.6709 14.5524C10.6709 14.9352 10.3738 15.236 9.99557 15.236C9.62639 15.236 9.32023 14.9352 9.32023 14.5524V13.3768C9.32023 13.0031 9.62639 12.6932 9.99557 12.6932C10.3738 12.6932 10.6709 13.0031 10.6709 13.3768V14.5524Z"
                                      fill="#252938"
                                    />
                                  </svg>
                                </span>
                                <span className="menu-text">Company Profile</span>
                              </Nav.Link>
                            </Nav.Item>
                          </>
                        )}
                        <Nav.Item>
                          <Nav.Link eventKey="support">
                            <span className="menuImg">
                            <img src={supportimg} alt=""/>
                            </span>
                            <span className="menu-text">Support</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col
                      xxl={9}
                      xl={9}
                      lg={9}
                      md={8}
                      sm={12}
                      xs={12}
                      className="right-content"
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="account">
                          <AccountSettingsTab
                            user={user}
                            reloadProfile={reloadProfile}
                          ></AccountSettingsTab>
                        </Tab.Pane>
                        <Tab.Pane eventKey="membership">
                          <Membership
                            user={user}
                            reloadProfile={reloadProfile}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="billing">
                          <Billing
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Billing>
                        </Tab.Pane>
                        <Tab.Pane eventKey="support">
                          <Support
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Support>
                        </Tab.Pane>
                        <Tab.Pane eventKey="resume">
                          <Resume
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Resume>
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile">
                          <Dashboard
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Dashboard>
                        </Tab.Pane>
                        <Tab.Pane eventKey="verification">
                          <Verification
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Verification>
                        </Tab.Pane>
                        <Tab.Pane eventKey="company">
                          <Company
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Company>
                        </Tab.Pane>
                        <Tab.Pane eventKey="notification">
                          <Notification
                            user={user}
                            reloadProfile={reloadProfile}
                          ></Notification>
                        </Tab.Pane>
                        <Tab.Pane eventKey="cpc">
                          <CPC user={user} reloadProfile={reloadProfile}></CPC>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsMain);
