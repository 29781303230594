//ACCESS_TOKEN
export const ACCESS_TOKEN = 'MyTurn-Token';
export const LOGIN_TIME = 'LOGIN_TIME';
export const REFRESH_TOKEN = 'MyTurn-Refresh-Token';
export const CANDIDATE_PACKAGE_ID = 'CANDIDATE_PACKAGE_ID';
export const EMPLOYER_PACKAGE_ID = 'EMPLOYER_PACKAGE_ID';
export const paginationCount = 50;
export const paginationOption = [10, 20, 30, 40, 50];

export const USER_CANDIDATE = 'Candidate';
export const USER_EMPLOYER = 'Employer';
//Password Strength
export const getStrongPasswordStatus = 'STRONG';
export const getMediumPasswordStatus = 'MEDIUM';
export const getWeakPasswordStatus = 'WEAK';

//REGEX
export const strongPasswordRegex =
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})';
export const mediumPasswordRegex =
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))';
export const youTubeRegex =
  '/^.*(youtu.be/|v/|u/w/|embed/|watch?v=|&v=|?v=)([^#&?]*).*/';
//On-Boarding
export const getOnBoardingTotalSteps = 4;

//Notification config
export const getNotificationTimeOut = 2000;
//Status
export const getFailedText = 'Failed';
export const getErrorStatus = 'Error';
export const getSuccessStatus = 'Success';
export const getWarningStatus = 'Warning';
export const getInfoStatus = 'INFO';

//other
export const objectTypeFormData = '[object FormData]';

// STATUS CODE
export const sucessState200 = 200;
export const notExpectedState409 = 409;
export const inCompleteState203 = 203;
export const serverErrorState500 = 500;

export const educationLevel = {
  1: 'Some School',
  2: 'High School Graduate',
  3: 'Associates Degree',
  4: 'Bachelors Degree',
  5: 'Masters Degree',
  6: 'Doctoral Degree',
  7: 'Vocational, Technical, or Professional Graduate',
  8: 'Others',
};

export const jobShiftType = {
  1: 'Morning',
  2: 'Noon',
  3: 'Evening',
  4: 'Night/Graveyard',
};

export const employmentType = {
  1: 'Full Time',
  2: 'Part Time',
  3: 'Contract',
  4: 'Temporary or Seasonal',
  5: 'Internship or co-op',
};

export const startType = {
  1: 'Immediately',
  2: 'One Month',
  3: '2-3 Months',
  4: '3+ Months',
};

export const contactPreferences = [
  'Email',
  'Phone',
  'Text (SMS)',
  'Phone or Email',
];

export const cmsPageId = {
  home: 1,
  faq: 2,
  aboutus: 3,
  employer: 4,
};

export const lableCssClass = [
  'bg-light-green text-green',
  'bg-light-orange text-orange',
  'bg-light-orange-2 text-light-orange',
  'bg-light-green-2 text-light-green',
  'bg-light-violet text-violet',
  'bg-light-sky text-sky',
  'bg-light-violet text-violet',
  'bg-light-blue text-blue',
  'bg-light-orange text-orange',
  'bg-light-orange-2 text-light-orange',
  'bg-light-sky text-sky',
  'bg-light-green-2 text-light-green',
  'bg-light-gray text-default',
];

export const sortBy = {
  0: 'Most Recent',
  1: 'Newly Registered',
  2: 'Recently Updated',
};
