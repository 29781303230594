import ReactNotifications from "../../../components/common/reactNotifications/ReactNotifications";
import * as utilConstants from "./../../constants/utilConstants";
// import ReactNotifications from "./../common/reactNotifications/ReactNotifications";
const apiPathToCall = process.env.REACT_APP_API_BASE_URL;
/**
 * @description "to call an asynchronous login request"
 * @param {'api/login'} service
 * @param {"POST"} method
 * @param {'anyObject As Data'} data
 * @author Nikunj Patel
 */
export function asyncLogin(service, method, data = {}) {
  switch (method) {
    case "POST":
      return fetch(apiPathToCall + "/" + service, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        body: JSON.stringify(data),
      }).then(function (response) {
        if (!response.ok) {
          if (response.status === 401) {
            // localStorage.clear();
          } else return response.json();
        }
        return response.json();
      });
    default:
      return null;
  }
}
/**
 * @description "to call an asynchronous request"
 * @param {'api/yourServiceToCall'} service
 * @param {"request Method like 'POST','GET'..."} method
 * @param {'anyObject As Data'} data
 * @author Nikunj Patel
 */
export function async(service, method, data = {}) {
  let headers = {
    "Content-Type": "application/json",
  };
  if (localStorage.getItem(utilConstants.ACCESS_TOKEN))
    if (
      Object.prototype.toString.call(data) === utilConstants.objectTypeFormData
    )
      headers = {
        Authorization:
          "Bearer " + localStorage.getItem(utilConstants.ACCESS_TOKEN),
      };
    else
      headers = {
        ...headers,
        Authorization:
          "Bearer " + localStorage.getItem(utilConstants.ACCESS_TOKEN),
      };
  let url = "";
  switch (method) {
    case "GET":
      url = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      return fetch(apiPathToCall + "/" + service + "?" + url, {
        headers: headers,
      })
        .then((res) => {
          if (res.status === 401) {
            // localStorage.clear();
            // window.location = process.env.REACT_APP_BASE_URL;
          } else return res.json();
        })
        .catch(function (error) {
          error &&
            ReactNotifications(
              "Service Unavailable",
              utilConstants.getErrorStatus
            );
        });
    case "POST":
      return fetch(apiPathToCall + "/" + service, {
        method: "POST",
        headers: headers,
        body:
          Object.prototype.toString.call(data) ===
          utilConstants.objectTypeFormData
            ? data
            : JSON.stringify(data),
      })
        .then(function (res) {
          if (res.status === 401) {
            // localStorage.clear();
            // window.location = process.env.REACT_APP_BASE_URL;
            return res.json();
          } else return res.json();
        })
        .catch(function (error) {
          error &&
            ReactNotifications(
              "Service Unavailable",
              utilConstants.getErrorStatus
            );
        });
    case "DELETE":
      url = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      return fetch(apiPathToCall + "/" + service + "?" + url, {
        headers: headers,
        method: "DELETE",
      })
        .then((res) => {
          if (res.status === 401) {
            // localStorage.clear();
            // window.location = process.env.REACT_APP_BASE_URL;
          } else return res.json();
        })
        .catch(function (error) {
          error &&
            ReactNotifications(
              "Service Unavailable",
              utilConstants.getErrorStatus
            );
        });
    case "PUT":
      return fetch(apiPathToCall + "/" + service, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (res) {
          if (res.status === 401) {
            localStorage.clear();
            // window.location = process.env.REACT_APP_BASE_URL;
            return res.json();
          } else return res.json();
        })
        .catch(function (error) {
          error &&
            ReactNotifications(
              "Service Unavailable",
              utilConstants.getErrorStatus
            );
        });
    default:
      return null;
  }
}

export function externalAPIcalling(apiservice, method, data = {}) {
  let url = "";
  switch (method) {
    case "POST":
      return fetch(apiservice, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then(function (res) {
          return res.json();
        })
        .catch(function () {});
    default:
      url = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      return fetch(apiservice + "?" + url, {})
        .then((res) => {
          return res.json();
        })
        .catch(function (error) {
          error &&
            ReactNotifications(
              "Service Unavailable",
              utilConstants.getErrorStatus
            );
        });
  }
}
