import React, { createContext, useState } from 'react';

const CommonContext = createContext();


const CommonProvider = ({ children }) => {
    const [isCandidateEditForm, setIsCandidateEditForm] = useState(false);
    const [showEditDiv, setShowEditDiv] = useState("");

    return (
        <CommonContext.Provider value={{isCandidateEditForm, setIsCandidateEditForm, showEditDiv, setShowEditDiv}}>
            {children}
        </CommonContext.Provider>
    )
}
export {CommonContext, CommonProvider};