import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";

export default function theme({ children }) {
  return (
    <div key={new Date()}>
      <Header />
      <div className={"wrapper"}>
        <div className="theme">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
