import { getStrongPasswordStatus } from "../../../utility/constants/utilConstants";
import {
  IsEmpty,
  IsValidEmail,
  passwordStrength,
} from "../../../utility/helper/helper";
export default function IsValidSignUpData(data) {
  let errors = {};
  if (IsEmpty(data.first_name)) {
    errors.first_name = "First name is required";
  }
  if (IsEmpty(data.last_name)) {
    errors.last_name = "Last name is required";
  }
  if (IsEmpty(data.email)) {
    errors.email = "Email is required";
  } else if (!IsValidEmail(data.email)) errors.email = "Invalid Email!";

  if (IsEmpty(data.password)) {
    errors.password = "Password is required";
  } else {
    let strenght = passwordStrength(data.password);
    if (getStrongPasswordStatus !== strenght) errors.password = strenght;
  }
  if (IsEmpty(data.candidateAgree) || !data.candidateAgree)
    errors.candidateAgree = "You must agree before submitting.";
  return {
    isValid: IsEmpty(errors),
    errors: errors,
  };
}
