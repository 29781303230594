import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function DoNotSellMyPersonalInfo() {
  return (
    <div className="do-not-sell-my-personal-info">
      <div className="top-panel">
        <Container className="h-100">
          <div className="background-image">
            <img src="/images/privacy-banner.png" alt="MyTurn privacy policy" />
          </div>
          <div className="content">
            <Row className="h-100">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                className="h-100"
              >
                <div className="d-flex align-items-center h-100">
                  <h1>DO NOT SELL MY PERSONAL INFORMATION</h1>
                </div>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="section">
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={6} className="text-center">
              <div className="text-center">
              </div>
            </Col>
            <Col xs={12}>
             
              <h2 className="sub-heading-title mt-4">Right To Opt-Out Of The Sale Of Your Personal Information</h2>
              <p>The purpose of this notice is to provide you, the consumer, the right to opt-out of the sale of your personal information.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">What Information Do We &ldquo;Sell&rdquo;?</h3>
              <p>myTurnCareers LLC (&ldquo;myTurnCareers&rdquo;, &ldquo;MyTurn&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, and &ldquo;our&rdquo;) sells no other information except your phone number and/or email to employers, which includes human resources personnel and recruiters.  We do work with service providers that receive and/or process the information you provided to MyTurn as a normal part of our operations.  However, we do not sell any information you provided to MyTurn to any data brokers, nor do we exchange information about you with any other third party for profit.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">How Do I Opt-Out?</h3>
              <p>Due to the nature of our business model, if you choose to opt-out of selling your personal information to an employer, MyTurn is then unable to provide you services.  As a result, your account must be terminated along with your personal information.</p>
              <p className="mt-3">As a registered MyTurn user, you have the right to terminate your account any time. You have the right to request the deletion of your Personal Information collected by MyTurn.  In this event, your Non-Personal Information will still be stored in our archives.</p>
              <p className="mt-3">In order for MyTurn to delete your account, you may be required to provide some minimal personal information to us only for verification purposes and to locate and delete your account. However, if you refuse to provide your personal information, MyTurn may not be able to comply with your request.</p>
              <p className="mt-3">If you have ultimately chosen to opt out, you must delete your account, which will also remove any associated personal information, by selecting &ldquo;Delete Account&rdquo; from your &ldquo;Account Settings&rdquo; while logged in.</p>
              <p className="mt-3">If you are unable to delete your account using this method, you may request that we delete your account, along with any associated personal information, on your behalf by emailing us at <a href="support@myturn.careers"> support@myturn.careers </a> from the email address registered with MyTurn for account login.  This method will provide sufficient verification of your request for account deletion.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">What Happens Once My Account Is Deleted?</h3>
              <p>Once your account is deleted: a) you will no longer receive any email notifications regarding your account, including job notifications, b) you will not be able to participate in any promotions, discounts, and other deals offered to our customers, c) we will no longer retain any of your personal information, including your resume, d) you will no longer be able to “pause” or “transfer” your account, e) <strong><em>your account becomes unrecoverable</em></strong>, and f) you must create a new account to continue using MyTurn.</p>
              <p className="mt-3">Be aware, MyTurn&apos;s service providers are under no obligation to comply with the CCPA.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">Are There Other Ooptions Instead Of Opting-Out?</h3>
              <p>If you do not wish to delete your account, you may &ldquo;pause&rdquo; the account from your &ldquo;Account Settings&rdquo; while logged in, which prevents MyTurn from displaying and selling your personal information.  This has the effect of &ldquo;hiding&rdquo; your profile from employers but does not delete your account or your personal information.</p>
              <p className="mt-3">If instead you wish to &ldquo;transfer&rdquo; your account to another email address, you may make this request by emailing us at <a href="support@myturn.careers">support@myturn.careers</a>.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">How Long Does It Take To Delete My Information?</h3>
              <p>We will respond to your request within 45 calendar days. We may on occasion notify you of our need to extend the deadline by another 45 days (90 days total).</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">I Haven&apos;t Received a Response?</h3>
              <p>If you submitted a request to delete your account and have not received any response within the timeline, review the section above, &ldquo;HOW DO I OPT-OUT?&rdquo; to make sure you submitted your request through the designated way.  If you believe your request was proper, please follow up with us on your request.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">Right To Non-Discrimination</h3>
              <p>MyTurn will never deny you services, charge you a different price, or provide a different level or quality of services just because you exercised your rights under the CCPA.</p>

              <hr className="mt-4"></hr>

              <h3 className="sub-heading-title mt-4">Privacy Policy</h3>
              <p><a href="/privacy-policy">Click here to review our Privacy Policy.</a></p>
              
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default DoNotSellMyPersonalInfo;
