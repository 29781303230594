import { default as Fuse } from "fuse.js";
import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { defaultFuseOptions, defaultTheme } from "../config/config";
import Results from "./Results";
import SearchInput from "./SearchInput";

export const DEFAULT_INPUT_DEBOUNCE = 200;
export const MAX_RESULTS = 10;

export default function ReactSearchAutocomplete({
  items = [],
  fuseOptions = defaultFuseOptions,
  inputDebounce = DEFAULT_INPUT_DEBOUNCE,
  onSearch = () => {},
  onHover = () => {},
  onSelect = () => {},
  onFocus = () => {},
  onClear = () => {},
  showIcon = true,
  showClear = true,
  maxResults = MAX_RESULTS,
  placeholder = "",
  autoFocus = false,
  styling = {},
  resultStringKeyName = "name",
  inputSearchString = "",
  formatResult,
}) {
  const theme = { ...defaultTheme, ...styling };
  const options = { ...defaultFuseOptions, ...fuseOptions };

  const fuse = new Fuse(items, options);
  fuse.setCollection(items);

  const [searchString, setSearchString] = useState(inputSearchString);
  const [results, setResults] = useState([]);
  const [highlightedItem, setHighlightedItem] = useState(0);

  const callOnSearch = (keyword) => {
    console.log("keyword", keyword);
    let newResults = [];

    keyword?.length > 0 && (newResults = fuseResults(keyword));
    if (keyword.includes(",")) {
      setResults([]);
    } else {
      setResults(newResults);
    }

    onSearch(keyword, newResults);
  };
  const handleOnSearch = (keyword) => {
    callOnSearch(keyword);
  };

  useEffect(() => {
    setSearchString(inputSearchString);
  }, [inputSearchString]);

  useEffect(() => {
    searchString?.length > 0 &&
      results &&
      setResults(fuseResults(searchString));
  }, [items]);
  const handleOnClick = (result) => {
    setResults([]);
    onSelect(result);
    setSearchString(result[resultStringKeyName]);
    setHighlightedItem(0);
  };

  const fuseResults = (keyword) =>
    fuse
      .search(keyword, { limit: maxResults })
      .map((result) => ({ ...result.item }))
      .slice(0, maxResults);

  const handleSetSearchString = ({ target }) => {
    const keyword = target.value;
    setSearchString(keyword);
    handleOnSearch(keyword);
  };

  const handleSetHighligthedItem = ({ index, event }) => {
    let itemIndex = 0;

    const setValues = (index) => {
      setHighlightedItem(index);
      onHover(results[index]);
    };

    if (index !== undefined) {
      setHighlightedItem(index);
      onHover(results[index]);
    } else if (event) {
      switch (event.key) {
        case "Enter":
          setResults([]);
          onSelect(results[highlightedItem]);
          setSearchString(results[highlightedItem][resultStringKeyName]);
          setHighlightedItem(0);
          break;
        case "ArrowUp":
          event.preventDefault();
          itemIndex =
            highlightedItem > 0 ? highlightedItem - 1 : results.length - 1;
          setValues(itemIndex);
          break;
        case "ArrowDown":
          event.preventDefault();
          itemIndex =
            highlightedItem < results.length - 1 ? highlightedItem + 1 : 0;
          setValues(itemIndex);
          break;
        default:
          break;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledReactSearchAutocomplete>
        <div className="wrapper">
          <SearchInput
            searchString={searchString}
            setSearchString={handleSetSearchString}
            autoFocus={autoFocus}
            onBlur={() => setResults([])}
            onFocus={onFocus}
            onClear={onClear}
            placeholder={placeholder}
            showIcon={showIcon}
            showClear={showClear}
            setHighlightedItem={handleSetHighligthedItem}
          />
          <Results
            results={results}
            onClick={handleOnClick}
            setSearchString={setSearchString}
            showIcon={showIcon}
            maxResults={maxResults}
            resultStringKeyName={resultStringKeyName}
            formatResult={formatResult}
            highlightedItem={highlightedItem}
            setHighlightedItem={handleSetHighligthedItem}
          />
        </div>
      </StyledReactSearchAutocomplete>
    </ThemeProvider>
  );
}

const StyledReactSearchAutocomplete = styled.div`
  position: relative;
  height: ${(props) => parseInt(props.theme.height) + 2 + "px"};
  > .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    // border: ${(props) => props.theme.border};
    border-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.color};
    font-size: ${(props) => props.theme.fontSize};
    font-family: ${(props) => props.theme.fontFamily};
    z-index: ${(props) => props.theme.zIndex};
    // &:hover {
    //   box-shadow: ${(props) => props.theme.boxShadow};
    // }
    // &:active {
    //   box-shadow: ${(props) => props.theme.boxShadow};
    // }
    // &:focus-within {
    //   box-shadow: ${(props) => props.theme.boxShadow};
    // }
  }
`;
