import React from "react";
import { Col, Row } from "react-bootstrap";
import infoimage from "../../../assets/images/infoimage.png";
import moment from "moment";

export default function CompanyInfo({ companyDetails }) {
  const {
    company_picture_url,
    company_picture,
    company_description,
    monday_opening_time,
    monday_closing_time,
    tuesday_opening_time,
    tuesday_closing_time,
    wednesday_opening_time,
    wednesday_closing_time,
    thursday_opening_time,
    thursday_closing_time,
    friday_opening_time,
    friday_closing_time,
    saturday_opening_time,
    saturday_closing_time,
    sunday_opening_time,
    sunday_closing_time,
  } = companyDetails?.EmployerProfile || {};

  const formatTime = (openingTime, closingTime) => {
    if (openingTime === "-" || closingTime === "-") {
      return "----";
    }

    const momentOpening = moment(openingTime, "HH:mm");
    const momentClosing = moment(closingTime, "HH:mm");
    const formattedOpening = momentOpening.format("h:mm A");
    const formattedClosing = momentClosing.format("h:mm A");
    const displayOpening =
      momentOpening.minutes() > 0
        ? formattedOpening
        : momentOpening.format("h A");
    const displayClosing =
      momentClosing.minutes() > 0
        ? formattedClosing
        : momentClosing.format("h A");

    return `${displayOpening} - ${displayClosing}`;
  };

  return (
    <>
      <Col
        xxl={{ span: 12 }}
        xl={{ span: 12 }}
        lg={{ span: 12 }}
        md={12}
        sm={12}
        xs={12}
      >
        <div className="company_info_body">
          <h1>About Company</h1>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              className="infoimage"
              src={company_picture !== null ? company_picture_url : infoimage}
            />
          </Col>
          <p> {company_description}</p>
        </div>
        <div className="working_location">
          <Row>
            <Col lg={6} md={12} sm={12}>
              <div className="left_clm">
                <h1>Working hours</h1>
                <Row>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Monday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(monday_opening_time, monday_closing_time)}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Tuesday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(tuesday_opening_time, tuesday_closing_time)}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Wednesday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(
                        wednesday_opening_time,
                        wednesday_closing_time
                      )}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Thursday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(thursday_opening_time, thursday_closing_time)}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Friday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(friday_opening_time, friday_closing_time)}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Saturday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(saturday_opening_time, saturday_closing_time)}
                    </h4>
                  </Col>
                  <Col md={4} sm={4} xs={6}>
                    <h4>Sunday</h4>
                  </Col>
                  <Col md={8} sm={8} xs={6}>
                    <h4 className="light_clr">
                      {formatTime(sunday_opening_time, sunday_closing_time)}
                    </h4>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="ryt_clm">
                <h1>Location</h1>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3437.545008378425!2d-97.75581662470744!3d30.50562449657355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644d28d54bcb303%3A0x9d0bcfb39a35d71f!2sIndina%20Hills%20Dr%2C%20Austin%2C%20TX%2078717%2C%20USA!5e0!3m2!1sen!2sin!4v1698869237917!5m2!1sen!2sin"></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}
