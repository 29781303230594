import { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import * as employerActions from "../employer/employerActions";
import { Spinner } from 'react-bootstrap';
import { AuthContext } from 'context/authContext';
import { renderPaginationItems } from 'components/candidate/common/Pagination';
import Pagination from 'react-bootstrap/Pagination';

function UserMembershipDetail() {

    const [userMembershipDetail, setUserMembershipDetail] = useState();
    const { isLoading, setLoading } = useContext(AuthContext);
    const [active, setActive] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(10 / itemsPerPage);

    const fetchMembershipDetails = (skip) => {
        let limit = itemsPerPage;
        let skipsubscriptionId = '';
        if (skip) {
            skipsubscriptionId = '';
        }
        employerActions.viewMemebershipDetail(limit, skipsubscriptionId, {}, (response) => {
            if (response?.data) {
                setLoading(false);
                setUserMembershipDetail(response?.data);
            } else {
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        fetchMembershipDetails(false);
    }, []);

    const handlePageClick = (pageNumber, event) => {
        event.preventDefault();
        setActive(pageNumber);
        window.scrollTo({ top: 100, behavior: 'smooth' });
    };

    const handlePrevClick = (e) => {
        e.preventDefault();
        setActive(prevActive => Math.max(prevActive - 1, 1));
        window.scrollTo({ top: 100, behavior: 'smooth' });
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        setActive(prevActive => Math.min(prevActive + 1, totalPages));
        window.scrollTo({ top: 100, behavior: 'smooth' });
    };

    return (
        <>
            <h2>Membership Details</h2>
            {userMembershipDetail ? <> <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Selected Plan</th>
                        <th>Plan Price</th>
                        <th>Purchasing Date</th>
                    </tr>
                </thead>
                <tbody>
                    {userMembershipDetail?.map((detail) => (
                        <tr>
                            <td>{detail?.job_post_subscription_detail?.plan_title}</td>
                            <td>{detail?.job_post_subscription_detail?.plan_price}</td>
                            <td>{detail?.current_period_start}</td>
                        </tr>

                    ))
                    }
                </tbody>
            </Table>
                {/* <div className="pagination" style={{ float: 'right' }}>

                    <Pagination size="sm">
                        <Pagination.Prev onClick={handlePrevClick} disabled={active === 1} />
                        {renderPaginationItems(active, totalPages, handlePageClick)}
                        <Pagination.Next onClick={handleNextClick} disabled={active === totalPages} />
                    </Pagination>
                </div> */}

            </> : isLoading ?
                <p><Spinner /></p>
                : <p>No Details Found</p>
            }
        </>
    );
}

export default UserMembershipDetail;