import React, { useState } from "react";
import accountIcon from "../../../assets/images/account-icon.svg";
import uploadIcon from "../../../assets/images/upload-icon.svg";
import noticedIcon from "../../../assets/images/noticed-icon.svg";
import engageImage from "../../../assets/images/engage-image.webp";
import recruitersImage from "../../../assets/images/recruiters-icon.svg";
import resume from "../../../assets/images/resume-icon.svg";
import girl from "../../../assets/images/girl-1.webp";
import arrowSecond from "../../../assets/images/arrow-second.webp";
import { Link } from "react-router-dom";

export default function PromoteResume(props) {

  return (
<>
 <div className="cyber-security-section">
        <div className="container">
          <h2 className="section-title">
            MyTurn<br></br> Begin your{" "}
            <span className="orange">cybersecurity journey..</span>
          </h2>
          <div className="row">
            <div className="col-md-4 col-12 cyber_col">
              <div className="cyber-security-column">
                <img
                  alt="cyber security"
                  className="img-fluid cyber-security-icon"
                  width={55}
                  height={55}
                  src={accountIcon}
                />
                <h3>Create Account</h3>
                <p>
                  Embark on Your Adventure and{" "}
                  <Link to="/signup">Create Your Account </Link> Today!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12 cyber_col">
              <div className="cyber-security-column">
                <img
                  alt="upload resume"
                  className="img-fluid cyber-security-icon"
                  width={55}
                  height={55}
                  src={uploadIcon}
                />
                <h3>Upload Resume</h3>
                <p>
                  Unlock Opportunities and Upload Your Cybersecurity Resume!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12 cyber_col">
              <div className="cyber-security-column">
                <img
                  alt="get noticed"
                  width={55}
                  height={55}
                  className="img-fluid cyber-security-icon"
                  src={noticedIcon}
                />
                <h3>Get Noticed</h3>
                <p>Step into the Spotlight and Get Noticed Quickly!</p>
              </div>
            </div>
          </div>
          <a href="/signup">
            <button>Get started for free</button>
          </a>
        </div>
      </div>
      <div className="engage-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 col-12 text-align-center specialize_img">
              <img alt="engage with employers" className="img-fluid" width={478} height={478} src={engageImage} />
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <h3>
                Isn’t it time to engage <br></br> with employers that{" "}
                <span>specialize in cybersecurity...</span>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="platform-designed">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 col-12">
              <h3 className="platform_heading">
                Why choose a platform designed for{" "}
                <span>cyber professionals...</span>
              </h3>
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <div className="row cyber-professionals">
                <div className="col-md-6 col-12 cyber_col">
                  <div className="cyber-security-column text-center">
                    <img
                      alt="recruiters icon"
                      className="img-fluid cyber-security-icon"
                      width={55}
                      height={55}
                      src={recruitersImage}
                    />
                    <h3>
                      Our Recruiters are{" "}
                      <span className="invite_span">Invite Only</span>
                    </h3>
                    <p>
                      We hand select each employer with whom we do business.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-12 cyber_col">
                  <div className="cyber-security-column text-center">
                    <img
                      alt="search icon"
                      className="img-fluid cyber-security-icon"
                      width={55}
                      height={55}
                      src={resume}
                    />
                    <h3>
                      Your Resume is <span>Not for Sale</span>
                    </h3>
                    <p>
                      We will never sell your information to any third party
                      data provide.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="career-change">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-12 text-center">
              <div className="career-content">
                <p>
                  Thinking about a career change? Get started in cyber security
                  <span className="career_sp">...today!</span>
                </p>
                <a href="/signup">
                  <button className="primary-btn">Get started with us</button>
                </a>
              </div>
              <div className="career-image">
                <img alt="career image" width={350} height={490} className="img-fluid" src={girl} />
              </div>
              <div className="arrow-second">
                <img alt="arrow" width={314} height={96} className="img-fluid" src={arrowSecond} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="boost-visibility text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <span>Boost your visibility </span>with a plan that works for
                you...
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="boost-visibility-inner">
                <div className="row">
                  <div className="col-md-6 col-12 boost_col">
                    <div className="cyber-security-column">
                      <span className="badge"></span>
                      <h3>Free</h3>
                      <p>Publish Your Resume</p>
                      <h1>$0</h1>
                      <a href="/signup">
                        <button className="primary-btn">Get started</button>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 premium boost_col">
                    <div className="cyber-security-column">
                      <span className="badge"></span>
                      <h3>Premium</h3>
                      <p>
                        Publish Your Resume{" "}
                        <strong>BOOST Your Visibility</strong>
                      </p>
                      <h1>
                        $15 <sup>/ 3 Months</sup>
                      </h1>
                      <a href="/signup">
                        <button className="primary-btn">Get started</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
</>
  );
}
