import * as typesConstants from "./../../utility/constants/typesConstants";
let initialState = {
  isLoading: false,
  signUpData: [],
  error: "",
};

export default function lndingReducer(state = initialState, action) {
  switch (action.type) {
    case typesConstants.FLUSH_SIGN_UP:
      return initialState;
    case typesConstants.SIGN_UP_REQUEST:
      return { ...state, isLoading: true, signUpData: [], erorr: "" };
    case typesConstants.SIGN_UP_DATA:
      return {
        ...state,
        isLoading: false,
        signUpData: action.response.data,
        error: "",
        sucess: action.response.status,
      };
    case typesConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        isLoading: false,
        signUpData: [],
        error: action.response.message,
      };
    case typesConstants.SIGN_UP_OTP_VERIFICATION:
      return {
        ...state,
        isLoading: false,
        signUpData: action.response.data,
        error: "",
        sucess: action.response.status,
      };
    default:
      return state;
  }
}
