import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import InputText from "../common/inputText/InputText";

export default function Verification() {
  const [isVerified, setVerified] = useState(false);
  return (
    <div className="account-settings-tab ">
      <p className="title">Verification</p>

      {isVerified ? (
        <>
          <div className="d-flex align-items-center">
            <img className="check-img" src="/images/Check.png" alt="yes"></img>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&apos;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book.
            </p>
          </div>
        </>
      ) : (
        <>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book.
          </p>
          <Row>
            <Col xs={12} md={7}>
              <form onSubmit={(e) => e.preventDefault()}>
                <InputText
                  controlId=""
                  label="cardholder's name"
                  name="name"
                  defaultValue="daysdasdv"
                  placeholder="Enter name"
                />
                <InputText
                  controlId=""
                  label="card number"
                  name="number"
                  type="number"
                  defaultValue=""
                  placeholder="Enter card number"
                  isMobileNumber
                />

                <Row>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <InputText
                      controlId=""
                      label="Expiration Date"
                      name="Date"
                      defaultValue=""
                      placeholder="MM/YY"
                    />
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <InputText
                      controlId=""
                      label="Security code"
                      name="number"
                      type="number"
                      defaultValue=""
                      placeholder="CVV/CVC"
                      isMobileNumber
                    />
                  </Col>
                </Row>

                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setVerified(true);
                  }}
                  className="float-left w-48 cta-type-2 h-40 btn btn-primary mx-auto"
                >
                  Validate
                </button>
              </form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
