import React from "react";
export default function InputRange({ onChange = () => {}, value = 50 }) {
  return (
    <>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        id="myRange"
        className="slider"
        onChange={onChange}
      />
      &nbsp;
      {value}
    </>
  );
}
