import * as typesConstants from "../utility/constants/typesConstants";
import { ACCESS_TOKEN } from "../utility/constants/utilConstants";
import { updateReducer } from "../utility/helper/helper";
import { async } from "./../utility/config/api/index";

/**
 *
 * @param { }
 * @description  sign-out
 */
export function signOut() {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.SIGN_OUT, null));
  };
}
export function signinUserDetails() {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.SIGNED_USER_DETAIL_LOADING, {}));
    return async("frontend/user/details", "GET", {}).then((response) => {
      dispatch(updateReducer(typesConstants.SIGNED_USER_DETAIL, response));
    });
  };
}

export function changeEmail(data, callBack = () => {}) {
  async("frontend/request/change", "GET", data).then((response) => {
    callBack(response);
  });
}
export function resetPassword(data, callBack = () => {}) {
  async("frontend/reset", "POST", data).then((response) => {
    callBack(response);
  });
}
export function verifyEmailChangeOtp(data, callBack = () => {}) {
  async("frontend/verify/change", "GET", data).then((response) => {
    callBack(response);
  });
}
export function nameChangeRequest(data, callBack = () => {}) {
  async("frontend/change-name", "PUT", data).then((response) => {
    callBack(response);
  });
}
export function uploadProfile(data, callBack = () => {}) {
  async("upload/user/profile", "POST", data).then((response) => {
    callBack(response);
  });
}
export function uploadResume(data, callBack = () => {}) {
  async("upload/user/resume", "POST", data).then((response) => {
    callBack(response);
  });
}

export function searchKeywords(data, callBack = () => {}) {
  async("frontend/employer/search-filter", "GET", data).then((response) => {
    callBack(response);
  });
}
export function candidateSaveToFilter(data, callBack = () => {}) {
  async("frontend/employer/search-filter", "POST", data).then((response) => {
    callBack(response);
  });
}
export function deleteFilter(data, callBack = () => {}) {
  async("frontend/employer/search-filter", "DELETE", data).then((response) => {
    callBack(response);
  });
}
export function saveCandidate(data, callBack = () => {}) {
  async("frontend/employer/save", "GET", data).then((response) => {
    callBack(response);
  });
}
export function editCandidate(data, callBack = () => {}) {
  async("frontend/employer/search-filter", "PUT", data).then((response) => {
    callBack(response);
  });
}
export function getConatct(data, callBack = () => {}) {
  async("frontend/employer/conatct/purchase", "GET", data).then((response) => {
    callBack(response);
  });
}
export function getProject(data, callBack = () => {}) {
  async("frontend/employer/project", "GET", data).then((response) => {
    callBack(response);
  });
}
export function getDropdownJobList(data, callBack = () => {}) {
  async("job/dropDownListJobPost", "GET", data).then((response) => {
    callBack(response);
  });
}

export function saveProject(data, callBack = () => {}) {
  async("frontend/employer/project", "POST", data).then((response) => {
    callBack(response);
  });
}
export function getCommonDropdownList(data) {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.COMMON_IS_LOADING, {}));
    return async("common", "GET", data).then((response) => {
      dispatch(
        updateReducer(typesConstants.GET_COMMON_DROPDOWN_OPTIONS, response)
      );
    });
  };
}
export function getCityAndStates(data, callBack = () => {}) {
  async("city-state", "GET", data).then((response) => {
    callBack(response);
  });
}
export function getUserProfile(data, callBack = () => {}) {
  async("frontend/user/profile", "GET", data).then((response) => {
    callBack(response);
  });
}
export function downloadCandidateProfilePDF(id, callBack = () => {}) {
  fetch(
    process.env.REACT_APP_API_BASE_URL + "/download-candidate-profile?id=" + id,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
    }
  )
    .then((response) => response.blob())
    .then((response) => {
      var file = window.URL.createObjectURL(response);
      callBack(file);
    });
}
export function uploadVideoCV(data, callBack = () => {}) {
  async("frontend/candidate/video-cv", "PUT", data).then((response) => {
    callBack(response);
  });
}

export function applyForJob(data, callBack = () => {}) {
  async("job/applyforjob", "POST", data).then((response) => {
    callBack(response);
  });
}

export function jobProfileData(data, callBack = () => {}) {
  async("getJobProfilesData", "POST", data).then((response) => {
    callBack(response);
  });
}
