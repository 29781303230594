import React, { useEffect, useContext } from "react";
import jwt_decode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Tab } from "react-bootstrap";
import CandidateSignUP from "../candidate/CandidateSignUP";
import CandidateDashboard from "../../candidate/dashboard/Dashboard";
import EmployerDashboard from "../../employer/dashboard/Dashboard";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as commonActions from "../../../actions/commonActions";
import * as lndingActions from "../lndingActions";
import { useHistory } from "react-router-dom";
import aboutimage from "../../../assets/images/analys.svg";
import aboutshadow from "../../../assets/images/analys2.svg";
import myturnlogo from "../../../assets/images/header-footer-logo-New.svg";
import softwave from "../../../assets/images/softwave.svg";
import amazon from "../../../assets/images/amazon.svg";
import fastcompany from "../../../assets/images/fastcompany.svg";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { AuthContext } from "context/authContext";

const Signup = () => {
  let history = useHistory();
  const clientId = '1038011222422-j588musujaecicpvetgttbpprmsn3op6.apps.googleusercontent.com';
  const { setVerifyUserPopUp, setUserInfo, setSignupType, } = useContext(AuthContext);

  useEffect(() => {
    if (getAuthentionInfo().type === utilConstants.USER_EMPLOYER)
      history.push("/employer/account-settings");

    if (getAuthentionInfo().type === utilConstants.USER_CANDIDATE)
      history.push("/Candidate/account-settings/profile");
  }, []);

  const handleCallbackResponse = (response) => {
    const userAgent = navigator.userAgent
    const userObject = jwt_decode(response.credential);
    setSignupType("");
    const { given_name, family_name, email, picture, jti, sub, } = userObject;
    setUserInfo({
      first_name: given_name || "",
      last_name: family_name || "",
      email: email || "",
      profile_image: picture || "",
      jti_token: jti || "",
      sub: sub || "",
      sign_in_with_google: true,
      browser_type: userAgent || "",
    });
    let usrInfo = {
      first_name: given_name || "",
      last_name: family_name || "",
      profile_image: picture || "",
      email: email,
      sign_in_with_google: true,
      browser_type: userAgent
    }
    lndingActions.signInSignUpWithGoogle(usrInfo, (response) => {
      if (response.status === utilConstants.sucessState200) {
        if (response.data && response.data.auth_token) {
          localStorage.setItem(
            utilConstants.ACCESS_TOKEN,
            response.data.auth_token
          );
          if (response.data.user_type === utilConstants.USER_CANDIDATE) {
            localStorage.setItem("candidate_id", response.data.id);
          } else {
            localStorage.setItem("employer_id", response.data.id);
          }
          localStorage.setItem(utilConstants.LOGIN_TIME, moment().format());
          commonActions.signinUserDetails();
          if (getAuthentionInfo().type === utilConstants.USER_EMPLOYER)
            history.push("/employer/account-settings");

          if (getAuthentionInfo().type === utilConstants.USER_CANDIDATE)
            history.push("/Candidate/account-settings/profile");
        }
      } else {
        setVerifyUserPopUp(true);
      }
    });

  }

  const onFailure = (err) => {
    console.log('failed', err);
  };

  return (
    <>
      {getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? (
        <CandidateDashboard />
      ) : getAuthentionInfo().type === utilConstants.USER_CANDIDATE ? (
        <EmployerDashboard />
      ) : (
        <div className="signupContentWrapper">
          <div className="background">
            <div className="left"></div>
            <div className="right">
            </div>
          </div>

          <div className="content">
            <Container>
              <Row>
                <Col
                  xxl={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={12}
                  xs={12}
                >
                  <Link to="/"><img src={myturnlogo} alt="Site logo" className="signup_logo" /></Link>
                  <h1 className="signup_title">Sign Up</h1>
                  <p className="info-text">
                    Follow the steps below to create your account.
                  </p>
                  <div className="Signup_google_dv">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign Up with Google"
                      onSuccess={handleCallbackResponse}
                      onFailure={onFailure}
                      width="500px"
                      shape="square"
                      logo_alignment="center"
                      prompt="consent"
                    />
                  </div>
                  <h6 className="signup_or">OR</h6>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="candidate"
                  >

                    <Tab.Content>
                      <CandidateSignUP />
                    </Tab.Content>
                  </Tab.Container>
                </Col>
                <Col
                  xxl={{ span: 7, offset: 0 }}
                  xl={{ span: 7, offset: 0 }}
                  lg={{ span: 7, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={12}
                  xs={12}
                >
                  <div className="right_body">
                    <div className="right_title">
                      <h3>The simplest way to apply for a cybersecurity job</h3>
                      <h6>Create your profile and start applying today</h6>
                    </div>
                    <div className="analys_dv">
                      <img
                        src={aboutimage}
                        alt="Site logo"
                        className="about_image"
                      />
                      <img
                        src={aboutshadow}
                        alt="Site logo"
                        className="shadow_image"
                      />
                    </div>
                    <div className="trusty_dv_wrrpr">
                      <h5>Trusted By</h5>
                      <div className="trusty_dv">
                        <img
                          src={softwave}
                          alt="Site logo"
                          className="softwave"
                        />
                        <img src={amazon} alt="Site logo" className="amazon" />
                        <img
                          src={fastcompany}
                          alt="Site logo"
                          className="fastcompany"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
