import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Container, Row, Col, Modal } from "react-bootstrap";
import InputText from "../../common/inputText/InputText";
import CustomeModel from "../../common/model/CustomeModel";
import CustomButton from "../../common/button/CustomButton";
import { IsEmpty } from "../../../utility/helper/helper";
import * as commonActions from "./../../../actions/commonActions";
import * as utilConstants from "../../../utility/constants/utilConstants";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import { updateEmployer } from "../employerActions";
function EditProfile(props) {
  const [profile, setProfile] = useState({});
  const [showRequestModal, setRequestModal] = useState(false);
  const [sendingNameChangeRequest, setSendingNameChangeRequest] =
    useState(false);
  let history = useHistory();
  const [removeImagePopup, setRemoveImagePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleClose = () => {
    setRemoveImagePopup(false);
  };

  useEffect(() => {
    if (
      IsEmpty(props.state.commonReducer.signedUserDetail) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.signinUserDetails();
    else setProfile(props.state.commonReducer.signedUserDetail);
  }, [props.state.commonReducer]);

  const sendNameChangeRequest = () => {
    setSendingNameChangeRequest(true);
    commonActions.nameChangeRequest(profile, (response) => {
      if (response.status === utilConstants.sucessState200) {
        setSendingNameChangeRequest(false);
        ReactNotifications(
          "Your request to change the name has been sent",
          utilConstants.getSuccessStatus
        );
        setRequestModal(false);
      } else {
        setSendingNameChangeRequest(false);
        ReactNotifications(response.message, utilConstants.getErrorStatus);
      }
    });
  };
  const removeImg = () => {
    setProfile({
      ...profile,
      profile_image: "",
      imageURL: "",
      EmployerProfile: {
        ...profile.EmployerProfile,
        profile_image: "",
      },
    });
  };
  const onImageChange = (e) => {
    const file = e.target.files[0];
    const imageURL = URL.createObjectURL(file);
    const formData = new FormData();
    formData.append("profile", file);
    commonActions.uploadProfile(formData, (respoce) => {
      if (respoce.file)
        setProfile({
          ...profile,
          profile_image: respoce.file,
          imageURL: imageURL,
          EmployerProfile: {
            ...profile.EmployerProfile,
            profile_image: respoce.file,
          },
        });
    });
  };

  const profilePicConfirmation = (
    <>
      <p className="title-lg">Remove Profile Picture?</p>
      <p className="mt-3">Are you sure you want to remove this image?</p>
      <div className="justify-content-between d-flex mt-4">
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary deactive-btn mt-0"
          onClick={handleClose}
        >
          No
        </button>
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setRemoveImagePopup(false);
            removeImg();
          }}
        >
          Yes
        </button>
      </div>
    </>
  );
  const onSubmit = () => {
    setIsLoading(true);
    updateEmployer({ profile_image: profile.profile_image }, (response) => {
      if (response.status === utilConstants.sucessState200) {
        setIsLoading(false);
        ReactNotifications(response.message, utilConstants.getSuccessStatus);
        history.push(`/${getAuthentionInfo().type}/Dashboard`);
      }
      setIsLoading(false);
    });
  };
  return (
    !IsEmpty(profile) && (
      <div className="dashboardPageWrapper edit-profile">
        <Modal
          show={removeImagePopup}
          onHide={handleClose}
          className="successModal"
          centered
          size="sm"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="modalbody text-center">
            {removeImagePopup ? profilePicConfirmation : ""}
          </Modal.Body>
        </Modal>
        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={showRequestModal}
          modalBody={
            <form>
              <h2> Name Change Request </h2>
              <p>
                In order to change First name and Last name
                <br /> send email to support team
              </p>
              <Row>
                <Col xs={12}>
                  <InputText
                    label="First name"
                    name="first_name"
                    onChange={(e) => {
                      setProfile((prevState) => {
                        return {
                          ...prevState,
                          first_name: capitalizeFirstLetter(e.target.value),
                        };
                      });
                    }}
                    value={profile.first_name}
                  />
                </Col>
                <Col
                  xs={12}
                  className="d-flex align-items-end justify-content-between "
                >
                  <InputText
                    className="w-100"
                    label="Last name"
                    name="last_name"
                    value={profile.last_name}
                    onChange={(e) => {
                      setProfile((prevState) => {
                        return {
                          ...prevState,
                          last_name: capitalizeFirstLetter(e.target.value),
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </form>
          }
          modalType="Custome"
          customeButton={
            <CustomButton
              label="submit"
              onClick={sendNameChangeRequest}
              className="cta-type-2 w-168 h-40 br-16 mx-auto"
              isLoading={sendingNameChangeRequest}
            />
          }
          handleClose={() => {
            {
              setRequestModal(false);
            }
          }}
        />
        <Container>
          <Row>
            <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }}>
              <Form noValidate>
                <div className="contentBox type-2">
                  <div className="pageInfoHeader">
                    <div className="column1">
                      <h2 className="section-heading-2">Edit profile</h2>
                    </div>
                  </div>
                  <div className="innerBlock px-0">
                    <h3>Personal information settings</h3>
                    <Row>
                      <Col>
                        <div className="ctaRow">
                          <img
                            src="/images/close3.png"
                            alt="remove"
                            className="removePic"
                            onClick={(e) => {
                              e.stopPropagation();
                              setRemoveImagePopup(true);
                            }}
                          />
                          <label className="file-input bold-label">
                            <img
                              className="profile-img"
                              src={
                                profile.EmployerProfile.profile_image
                                  ? process.env.REACT_APP_API_BASE_URL +
                                    "/user_profile/" +
                                    profile.EmployerProfile.profile_image
                                  : profile.imageURL || "/images/camera.png"
                              }
                              alt="upload"
                            ></img>
                            <input
                              accept="image/*"
                              type="file"
                              name="file"
                              onChange={onImageChange}
                            />
                            &nbsp; &nbsp; Upload Photo
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <InputText
                          label="First name"
                          name="new_first_name"
                          value={profile.first_name}
                          onChange={(e) => {
                            e.preventDefault();
                            setRequestModal(true);
                          }}
                        />
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="d-flex align-items-end justify-content-between "
                      >
                        <InputText
                          className="w-100"
                          label="Last name"
                          value={profile.last_name}
                          onChange={(e) => {
                            e.preventDefault();
                            setRequestModal(true);
                          }}
                        />
                        <img
                          onClick={(e) => {
                            e.preventDefault();
                            setRequestModal(true);
                          }}
                          height={35}
                          className="userpic myturn-hpx-20 myturn-ml-4 myturn-mb-10"
                          src="/images/pen.png"
                          alt="user"
                        />
                      </Col>
                    </Row>
                    <div className="seperator"></div>
                  </div>
                  <div className="innerBlock px-0">
                    <div className="cta-panel edit-candidate-cta-panel d-flex justify-content-end mt-24">
                      <CustomButton
                        className="cta-type-3 w-168 h-40 br-16"
                        label="Cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }}
                      />
                      <CustomButton
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit();
                        }}
                        className="cta-type-2 w-168 h-40 br-16 ms-4"
                        label="Save"
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
