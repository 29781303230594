import React, { useState } from "react";
import { Container, Row, Col, Form, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomButton from "../../common/button/CustomButton";
import InputText from "../../common/inputText/InputText";
import * as helper from "./../../../utility/helper/helper";
import * as utilConstants from "./../../../utility/constants/utilConstants";
import * as lndingActions from "./../../../components/landing/lndingActions";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const onChange = (e) => {
    helper.setDataAccordingInput(e);
    setEmail(e.target.value);
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (helper.IsValidEmail(email))
      lndingActions.sendResetLink({ email }, (response) => {
        setEmail("");
        ReactNotifications(
          response.message,
          response.status === utilConstants.sucessState200
            ? utilConstants.getSuccessStatus
            : utilConstants.getWarningStatus
        );
      });
    else setError("Email is required");
  };

  return (
    <div className="signupContentWrapper">
      <div className="background">
        <div className="left"></div>
        <div className="right">
          <img src="/images/signup-bg.png" alt="Site logo" />
        </div>
      </div>

      <div className="content">
        <Container>
          <Row>
            <Col
              xxl={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 6, offset: 3 }}
              md={{ span: 6, offset: 3 }}
              sm={12}
              xs={12}
            >
              <h1>Reset Password</h1>
              <p className="info-text">
                Fill in info to restore access to your account
              </p>

              <Form noValidate onSubmit={handleSubmit}>
                <InputText
                  controlId="formBasicEmail"
                  label={
                    <>
                      Email Address
                      <Badge className="infoBadge">
                        <img src="/images/info.png" alt="info" />
                      </Badge>
                    </>
                  }
                  type="email"
                  placeholder="Name@example.com"
                  name="email"
                  value={email}
                  error={error}
                  onChange={onChange}
                />
                <CustomButton
                  variant="primary"
                  type="submit"
                  className="w-100 primary-filed-orange"
                  isDisabled={!helper.IsEmpty(error)}
                  label="Send Restore Link"
                />

                <div className="other-link">
                  Don&apos;t have an account?{" "}
                  <Link to={"/signup"}>Sign Up</Link>
                </div>
              </Form>
            </Col>
            <Col
              xxl={{ span: 8, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              sm={12}
              xs={12}
            ></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
