import React, { useState, useEffect } from "react";
import { updateNotificationPreferences } from "../../actions/account";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";

export default function Notification({ user, reloadProfile }) {
  const [notification, setNotification] = useState(false);
  useEffect(() => {
    setNotification(user?.EmployerProfile?.get_notification === "1");
  }, [user?.EmployerProfile?.get_notification]);

  const onChange = (e) => {
    setNotification(e.target.checked);
    if (e.target.checked === user.notification) {
      ReactNotifications("Notification references updated successfully");
    } else {
      updateNotificationPreferences(
        { get_notification: e.target.checked ? "1" : "0" },
        (res) => {                                                 
          if (res) {
            reloadProfile();
            ReactNotifications("Notification preferences updated successfully");
          }
        }
      );
    }
  };
  return (
    <div className="account-settings-tab ">
      {" "}
      <p className="title">Notification</p>
      <div className="d-flex justify-content-between">
        <b className="title-xs">
          Get email notifications of candidates matching your Projects
        </b>
        <div className="toggle-switch me-3">
          <label className="switch">
            <input
              defaultChecked={notification}
              type="checkbox"
              checked={notification}
              onChange={onChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </p>
    </div>
  );
}
