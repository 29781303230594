import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import {
  getErrorStatus,
  getSuccessStatus,
} from "../../../utility/constants/utilConstants";
import * as employerActions from "./../employerActions";
import { sucessState200 } from "../../../utility/constants/utilConstants";
import MyJobDetail from "./MyJobDetail";
import MyJobListing from "./MyJobListing";
import PostJob from "./PostJob";
import ReviewJob from "./ReviewJob";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";


export default function MyJobs() {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [seljob, setSelJob] = useState(null);
  const [showReview, setReviewShow] = useState(false);
  const [showPostForm, setPostFormShow] = useState(false);
  const [formdata, setFormData] = useState({});
  const [jobID, setJobID] = useState("new");
  const [jobDelID, setJobDelID] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadJobs(false);
  }, []);

  const loadJobs = (search) => {
    let limit = 10;
    let skip = jobs.length;
    if (search) {
      skip = 0;
      setLoading(true);
      setIsSearched(true);
      setJobs([]);
    }
    employerActions.getMyJobs(limit, skip, query, {}, (response) => {
      if (response.status === sucessState200) {
        setLoading(false);
        setJobs((prevJobs) => [...prevJobs, ...response.data]);
        setCount(response.count);
        if ((response.data.length > 0 && seljob === null) || search) {
          setSelJob(response.data[0]);
        }
      }
    });
  };

  const selectThisJob = (index) => {
    setSelJob(jobs[index]);
    setActiveIndex(index);
  };

  const handleReviewClose = () => setReviewShow(false);
  const handlePostFormClose = () => setPostFormShow(false);
  const handlePostFormShow = () => {
    setJobID("new");
    const newjobdata = {
      job_title: "",
      job_location: "",
      minimum_eduction: "N/A",
      hiring_timeframe: "",
      job_shift: "Morning",
      job_type: "Full Time",
      required_onsite: "Yes",
      required_travel: "N/A",
      required_clearance: "N/A",
      sponsorship: "No",
      minimum_pay: "",
      maximum_pay: "",
      job_application_url: "",
      job_description: "",
      job_term_conditions: "",
    };
    setFormData(newjobdata);
    setPostFormShow(true);
  };
  const handleEditFormShow = (job) => {
    setJobID(job.id);
    console.log(job, "job");
    const initialjobdata = {
      job_title: job.job_title,
      job_location: job.job_location,
      minimum_eduction: job.minimum_eduction,
      hiring_timeframe: job.hiring_timeframe,
      job_shift: job.job_shift,
      job_type: job.job_type,
      required_onsite: job.required_onsite,
      required_travel: job.required_travel,
      required_clearance: job.required_clearance,
      sponsorship: job.sponsorship,
      minimum_pay: job.minimum_pay,
      maximum_pay: job.maximum_pay,
      job_application_url: job.job_application_url,
      job_description: job.job_description,
      job_term_conditions: false,
    };
    setFormData(initialjobdata);
    setPostFormShow(true);
  };
  const handleShowDel = (jobID) => {
    setJobDelID(jobID);
    setShowDel(true);
  };
  const handleShowDelClose = () => setShowDel(false);
  const handleDeleteJob = (jobID) => {
    employerActions.deleteJob(jobID, (response) => {
      if (response.status === sucessState200) {
        // setLoading(true);
        setJobDelID(null);
        setShowDel(false);
        delFromJobListing(jobID);
      }
    });
  };
  const previewFormValues = (e) => {
    console.log(e);
    setFormData(e);
    setPostFormShow(false);
    setReviewShow(true);
  };
  const backtoForm = () => {
    setReviewShow(false);
    setPostFormShow(true);
  };
  const submitForm = () => {
    setSubmitting(true);
    formdata.job_term_conditions = "1";
    employerActions.postJob(jobID, formdata, (response) => {
      if (response.status === sucessState200) {
        setReviewShow(false);
        setSubmitting(false);
        // setLoading(true);
        // loadJobs();
        if (jobID === "new") {
          addIntoJobListing(response.data);
        } else {
          updateIntoJobListing(response.data);
          setSelJob(response.data);
        }
        ReactNotifications("Form successfully submitted", getSuccessStatus);
      } else {
        ReactNotifications(response?.msg, getErrorStatus);
      }
    });
  };

  const addIntoJobListing = (data) => {
    setJobs([data, ...jobs]);
    setCount((prevCount) => prevCount + 1);
    if (seljob === null) {
      setSelJob(data);
    }
  };

  const updateIntoJobListing = (data) => {
    const index = jobs.findIndex((job) => job.id === data.id);
    const newJobs = [...jobs];
    newJobs[index] = data;
    setJobs(newJobs);
  };

  const delFromJobListing = (jobID) => {
    const index = jobs.findIndex((job) => job.id === jobID);
    const newJobs = [...jobs];
    newJobs.splice(index, 1);
    setJobs(newJobs);
    setCount((prevCount) => prevCount - 1);
    if (jobID === seljob.id) {
      if (newJobs.length > 0) {
        setSelJob(newJobs[0]);
      } else {
        setSelJob(null);
      }
    }
  };

  const handleCheckSubscriptionStatus = () => {
    employerActions.checkSubscriptionStatus({}, (response) => {
      if (response.hasSubscription === false) {
        history.push("/post-a-job");
      } else if (response.hasSubscription === true) {
        handlePostFormShow();
      }
    });
  }

  const handleSearch = () => {
    if (query !== "") {
      loadJobs(true);
    } else if (!query && isSearched) {
      loadJobs(true);
      setIsSearched(false);
    }
  }

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {" "}
        <Spinner
          animation="border"
          role="status"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            width: "200px",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <Container>
      <Row>
        <div className="search_dv">
          <Row>
            <Col
              xxl={{ span: 8, offset: 2 }}
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={6}
              sm={12}
              xs={12}
            >
              <div className="d-flex align-items-center react-search-autocomplete">
                <div className=" w-100">
                  <div className="search_wrrpr">
                    <div className="search_body">
                      <div className="search_input_dv">
                        <input
                          placeholder="Enter search keywords here"
                          id="search-box"
                          onChange={(e) => setQuery(e.target.value)}
                          value={query}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearch();
                            }
                          }}
                          autoFocus
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  onClick={handleSearch}
                  role={"button"}
                  itemType="submit"
                  src="/images/search.png"
                ></img>
              </div>
            </Col>
            <Col
              xxl={{ span: 2 }}
              xl={{ span: 2 }}
              lg={{ span: 2 }}
              md={6}
              sm={12}
              xs={12}
            >
              <div className="sorted_dv">
                <p>Found {count} Jobs</p>
              </div>
            </Col>
          </Row>
        </div>
        <Col
          xxl={{ span: 6, offset: 6 }}
          xl={{ span: 6, offset: 6 }}
          lg={{ span: 6, offset: 6 }}
          md={6}
          sm={12}
          xs={12}
        >
          <div className="job_btn_wrrpr">
            <p></p>
            <Button
              onClick={handleCheckSubscriptionStatus}
              className="primary-filed-orange"
            >
              Post a Job
            </Button>
            <PostJob
              handlePostFormClose={handlePostFormClose}
              showPostForm={showPostForm}
              previewFormValues={previewFormValues}
              formdata={formdata}
              setFormData={setFormData}
              validated={validated}
            />
            <ReviewJob
              handleReviewClose={handleReviewClose}
              showReview={showReview}
              backtoForm={backtoForm}
              submitForm={submitForm}
              formdata={formdata}
            />
          </div>
        </Col>

        <Row>
          <MyJobListing
            jobs={jobs}
            loadJobs={loadJobs}
            count={count}
            activeIndex={activeIndex}
            handleDeleteJob={(job) => handleDeleteJob(job)}
            handleEditFormShow={(job) => handleEditFormShow(job)}
            selectThisJob={(index) => selectThisJob(index)}
            handleShowDel={(jobID) => handleShowDel(jobID)}
          />
          <MyJobDetail job={seljob} />
        </Row>
      </Row>
      <Modal show={showDel}>
        <Modal.Body className="modal_body_wrrpr">
          <div className="delete_modal_body">
            <h1>Are you sure you want to delete this item?</h1>
          </div>
          <div className="post_job_btns_chckbox mt-3">
            <Button
              className="cta-type-2 h-40 btn btn-primaryfalse post_job"
              variant="secondary"
              onClick={handleShowDelClose}
            >
              Close
            </Button>
            <Button
              className="cta-type-1 h-40 btn btn-primary delete-btn post_job"
              variant="primary"
              onClick={() => handleDeleteJob(jobDelID)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
