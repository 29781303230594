import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerLogo from "../../../assets/images/header-footer-logo-New.svg";
import { getAuthentionInfo } from "utility/helper/authenticationHelper";
import * as utilConstants from "../../../utility/constants/utilConstants";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              {/* <Navbar className="footerNavbarFirst">
                <Container>
                  <Navbar.Brand className="mx-auto">
                   <Link to={getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? "/employer" :"/"}><img
                      src={footerLogo}
                      width={139}
                      height={50}
                      className="d-inline-block align-top footer-logo"
                      alt="MyTurn footer logo"
                    /></Link> 
                  </Navbar.Brand>
                </Container>
              </Navbar> */}
              <Navbar className="footerNavbarSecond">
                <Container>
                  <Nav className="mx-auto">
                    <Nav.Link
                      as={Link}
                      to="/about-us"
                      href="/about-us"
                      key="/about-us"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link as={Link} to="/faq" href="/faq" key="/faq">
                      FAQ
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/terms-and-conditions"
                      href="/terms-and-conditions"
                      key="/Terms and Conditions"
                    >
                      Terms and Conditions
                    </Nav.Link>
                    <a href="https://myturn.careers/blog/" className="nav-link">
                      Blog
                    </a>
                    <Nav.Link
                      as={Link}
                      to="/privacy-policy"
                      href="/privacy-policy"
                    >
                      Privacy Policy
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/do-not-sell-my-personal-info"
                      href="/do-not-sell-my-personal-info"
                    >
                      Do Not Sell My Personal Information
                    </Nav.Link>
                    <Nav.Link
                      href="mailto:info@myturn.careers"
                    >
                     Contact
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
              {/* <Navbar className="footerNavbarThird">
                <Container>
                  <Nav className="mx-auto">
                    <Nav.Link href="mailto:info@myturn.careers">
                      <img
                        src="/images/email.webp"
                        width={22}
                        height={16}
                        className="d-inline-block align-top"
                        alt="email logo"
                      />
                      info@myturn.careers
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar> */}
              <Navbar className="footerNavbarBottom">
                <Container>
                  <Navbar.Brand>
                    &copy; {new Date().getFullYear()} myTurnCareers LLC. All
                    rights reserved.
                  </Navbar.Brand>
                  <Nav className="ms-auto socialNavs">
                    <Nav.Link
                      target="_blank"
                      href="https://facebook.com/myTurnCareers"
                      aria-label="Facebook"
                    >
                      <svg
                        width="11"
                        height="21"
                        viewBox="0 0 11 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.9051 0.148536V3.46244L8.92706 3.46756C7.37654 3.46756 7.07778 4.2 7.07778 5.27049V7.64195H10.7712L10.2921 11.3502H7.07778V21H3.22518V11.3502H0V7.64195H3.22518V4.90683C3.22518 1.73122 5.17183 0 8.0256 0C9.38604 0 10.56 0.102439 10.9051 0.148536Z"
                          fill="white"
                        />
                      </svg>
                    </Nav.Link>
                    <Nav.Link
                      target="_blank"
                      href="https://twitter.com/myturncareers"
                      aria-label="Twitter"
                    >
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 2.17863C21.1819 2.5375 20.3101 2.77537 19.4012 2.89087C20.3363 2.33262 21.0499 1.45537 21.3854 0.398C20.5136 0.91775 19.5511 1.28488 18.5254 1.48975C17.6976 0.608375 16.5179 0.0625 15.2309 0.0625C12.7339 0.0625 10.7236 2.08925 10.7236 4.57388C10.7236 4.93138 10.7539 5.27512 10.8281 5.60237C7.0785 5.4195 3.76063 3.62238 1.53175 0.88475C1.14262 1.55988 0.914375 2.33262 0.914375 3.1645C0.914375 4.7265 1.71875 6.11112 2.91775 6.91275C2.19313 6.899 1.48225 6.68862 0.88 6.35725C0.88 6.371 0.88 6.38888 0.88 6.40675C0.88 8.5985 2.44337 10.419 4.4935 10.8384C4.12637 10.9388 3.72625 10.9869 3.311 10.9869C3.02225 10.9869 2.73075 10.9704 2.45712 10.9099C3.0415 12.696 4.69975 14.0091 6.6715 14.0518C5.137 15.2521 3.18863 15.9754 1.07938 15.9754C0.7095 15.9754 0.35475 15.9589 0 15.9135C1.99787 17.2019 4.36562 17.9375 6.919 17.9375C15.2185 17.9375 19.756 11.0625 19.756 5.10325C19.756 4.90387 19.7491 4.71138 19.7395 4.52025C20.6346 3.885 21.3867 3.09162 22 2.17863Z"
                          fill="white"
                        />
                      </svg>
                    </Nav.Link>
                    <Nav.Link
                      target="_blank"
                      href="https://www.linkedin.com/company/myturn-careers"
                      aria-label="linkedin"
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43115 21C7.43115 20.9126 7.44864 20.8076 7.44864 20.7202C7.44864 16.2614 7.44864 11.8026 7.44864 7.32637C7.44864 7.01164 7.44864 7.01164 7.78086 7.01164C8.96987 7.01164 10.1414 7.01164 11.3304 7.01164C11.5927 7.01164 11.5927 7.01164 11.5927 7.2914C11.5927 7.76351 11.5927 8.21813 11.5927 8.69024C11.5927 8.76018 11.5927 8.83012 11.6102 8.95252C11.6801 8.90006 11.7151 8.88258 11.7326 8.84761C12.5369 7.51871 13.7259 6.85427 15.2122 6.6969C16.1564 6.59199 17.1006 6.67941 17.9924 6.99415C19.2688 7.43129 20.0556 8.32304 20.4928 9.56451C20.7376 10.2639 20.86 10.9808 20.9124 11.6977C20.9299 12.0649 20.9474 12.4321 20.9649 12.7993C20.9649 12.8693 20.9824 12.9392 20.9998 12.9917C20.9998 15.6494 20.9998 18.3247 20.9998 20.9825C19.5486 20.9825 18.0973 20.9825 16.646 20.9825C16.646 20.93 16.6635 20.8951 16.6635 20.8426C16.6635 18.3247 16.6635 15.8068 16.646 13.2889C16.646 12.8693 16.576 12.4496 16.4711 12.03C16.3138 11.348 15.9466 10.8235 15.2646 10.5612C14.8974 10.4213 14.5128 10.4213 14.1281 10.4213C13.1314 10.4388 12.362 10.9808 12.0123 11.925C11.785 12.537 11.7326 13.149 11.7326 13.796C11.7326 16.1215 11.7326 18.4471 11.7326 20.7552C11.7326 20.8251 11.7501 20.9126 11.7501 20.9825C10.3162 21 8.88244 21 7.43115 21Z"
                          fill="white"
                        />
                        <path
                          d="M0.367188 21C0.367188 20.8951 0.384673 20.7901 0.384673 20.6852C0.384673 16.2264 0.384673 11.7677 0.384673 7.29139C0.384673 6.99414 0.384673 6.99414 0.66444 6.99414C1.9059 6.99414 3.14737 6.99414 4.38884 6.99414C4.65112 6.99414 4.65112 6.99414 4.65112 7.27391C4.65112 11.7502 4.65112 16.209 4.65112 20.6852C4.65112 20.7901 4.65112 20.8951 4.6686 21C3.2348 21 1.80099 21 0.367188 21Z"
                          fill="white"
                        />
                        <path
                          d="M2.65778 0C2.79767 0.0349709 2.93755 0.0524563 3.07744 0.0874271C4.12656 0.332223 4.87843 1.18901 5.00083 2.25562C5.1582 3.54954 4.23147 4.82598 2.83264 5.01832C1.50375 5.21066 0.19234 4.17902 0.0524563 2.83264C0.0524563 2.78018 0.0174854 2.71024 0 2.65779C0 2.57036 0 2.46545 0 2.37802C0.0174854 2.32556 0.0524563 2.25562 0.0524563 2.20316C0.157369 1.18901 0.944213 0.314738 1.94088 0.0874271C2.09825 0.0699417 2.23813 0.0349709 2.37802 0C2.48293 0 2.57036 0 2.65778 0Z"
                          fill="white"
                        />
                      </svg>
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
