import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory, Link } from "react-router-dom";
import InputRadio from "./common/InputRadio";
import CustomButton from "../../common/button/CustomButton";
import InputText from "../../common/inputText/InputText";
export default function MinimumPay() {
  let history = useHistory();
  const [minimumPay, setMinimumPay] = useState({});
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/CurrentlyLive");
  };
  const onChange = (e) => {
    let tempMinimumPay = minimumPay;
    Object.assign(tempMinimumPay, helper.setDataAccordingInput(e));
    setMinimumPay(tempMinimumPay);
  };
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/EmploymentType");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            7
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          What is the minimum pay you’re willing to accept?
        </h2>
        <div className="text-center">
          <div className="tab-type-radio w-360 mx-auto">
            <InputRadio
              name="payType"
              listOfOption={[
                {
                  id: "VisaSponsorshipYes",
                  value: "Per hour",
                  label: "Per hour",
                },
                {
                  id: "VisaSponsorshipNo",
                  value: "Per year",
                  label: "Per year",
                },
              ]}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
        </div>

        <div className="w-360 mx-auto mt-3">
          <InputText
            controlId="formBasicFirstName"
            label="Enter amount"
            type="number"
            min={0}
            name="amount"
            value={minimumPay.amount}
            onChange={onChange}
            placeholder="Enter hourly rate, USD ($)"
            isMobileNumber
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          onClick={onSubmit}
          label="Next"
        />
        <div className="other-link">
          <Link to="/Candidate/Onboarding/CurrentlyLive" className="ms-0">
            Skip this step
          </Link>
        </div>
      </Container>
    </div>
  );
}
