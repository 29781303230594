import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as formik from "formik";
import * as yup from "yup";
import AddressAutocomplete from "../../common/addressAutocomplete/AddressAutocomplete";
import MultiInputSelect from "../../common/select/MultiInputSelect";
import * as candidateActions from "../../candidate/candidateActions";
import { useHistory } from "react-router-dom";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import ReactNotifications from "components/common/reactNotifications/ReactNotifications";
import { getErrorStatus } from "utility/constants/utilConstants";
import TextEditor from "components/TextEditor/TextEditor";

export default function PostJob({
  handlePostFormClose,
  showPostForm,
  previewFormValues,
  formdata,
  setFormData,
  validated,
}) {
  const { Formik } = formik;
  const history = useHistory();
  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
  const schema = yup.object().shape({
    job_title: yup
      .string()
      .matches(/^[\w\s/0-9&]+$/, {
        message:
          "Alphanumeric characters, space, slash, numbers, or & symbol only",
        excludeEmptyString: true,
      })
      .required("job title is required"),
    job_location: yup.string().required("job location is required"),
    minimum_eduction: yup.string().required("minimum education is required"),
    job_shift: yup.string().required("job shift is required"),
    hiring_timeframe: yup.string().required("hiring timeframe is required"),
    required_onsite: yup.string().required("minimum education is required"),
    job_type: yup.string().required("job type is required"),
    required_travel: yup.string().required("required travel is required"),
    required_clearance: yup.string().required("required clearance is required"),
    sponsorship: yup.string().required("sponsorship is required"),
    minimum_pay: yup
      .number()
      .required("Minimum pay is required")
      .typeError("Enter a valid number")
      .positive("Enter only positive number")
      .test("is-integer", "Enter a valid integer", (value) => {
        return /^[0-9]+$/.test(value);
      })
      .min(0, "Minimum pay cannot be negative")
      .max(999999, "Maximum pay is 999,999"),
    maximum_pay: yup
      .number()
      .required("Maximum pay is required")
      .typeError("Enter a valid number")
      .test("is-integer", "Enter a valid integer", (value) => {
        return /^[0-9]+$/.test(value);
      })
      .min(0, "Maximum pay cannot be negative")
      .max(999999, "Maximum pay is 999,999")
      .test(
        "is-greater-than-or-equal",
        "Maximum pay should be greater than or equal to minimum pay",
        function (value) {
          const { minimum_pay } = this.parent;
          if (minimum_pay === undefined || value === undefined) {
            return true;
          }
          return value >= minimum_pay;
        }
      ),
    job_application_url: yup.string().url("URL is not valid"),
    job_description: yup.string().trim().required("job description is required"),
    job_term_conditions: yup
      .bool()
      .required()
      .oneOf([true], "Terms must be accepted"),
  });
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  }
  const formats = ['bold', 'italic', 'underline', 'list', 'bullet']
  const handleCheckboxClick = () => {
    history.push("/terms-and-conditions");
  };
  return (
    <Modal size="lg" show={showPostForm}>
      <Modal.Body className="modal_body_wrrpr">
        <div className="post_job_wrrpr">
          <div className="post_job_body">
            <Formik
              initialValues={formdata}
              onSubmit={previewFormValues}
              validationSchema={schema}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupJobTitle"
                  >
                    <MultiInputSelect
                      customCreate
                      type="position"
                      label="Job Title"
                      options={[]}
                      placeholder=""
                      loadOptions={(value, callback = () => { }) => {
                        if (value)
                          candidateActions.getPosition(
                            { st: value },
                            (response) => {
                              if (
                                response.status === utilConstants.sucessState200
                              ) {
                                var options = [];
                                response.data.map((v) => {
                                  options.push({
                                    label: v,
                                    value: v,
                                  });
                                });
                                callback(options);
                              }
                            }
                          );
                      }}
                      onSelect={(value) => {
                        if (value?.value?.length > 255) return;
                        handleChange({
                          target: { name: "job_title", value: value?.value },
                        });
                      }}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "job_title",
                            value: e.target.value,
                          },
                        });
                      }}
                      value={
                        values.job_title > ""
                          ? [
                            {
                              value: values.job_title,
                              label: values.job_title,
                            },
                          ]
                          : []
                      }
                    />
                    <div className="form-field-error">
                      {touched.job_title && errors.job_title}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupLocation"
                  >


                    <AddressAutocomplete
                      label="location"
                      placeholder={"Enter location"}
                      controlId="job_location"
                      name="job_location"
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: "job_location",
                            value: value.complete,
                          },
                        });
                      }}
                      defaultInputValue={values.job_location}
                    />

                    <div className="form-field-error">
                      {touched.job_location && errors.job_location}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupminimum_eduction"
                  >
                    <Form.Label>Minimum Education</Form.Label>
                    <Form.Select
                      className="form_input"
                      onChange={handleChange}
                      name="minimum_eduction"
                      defaultValue={formdata.minimum_eduction}
                      value={values.minimum_eduction}
                    >
                      <option value="N/A">N/A</option>
                      <option value="High School">High School</option>
                      <option value="Associate's Degree / Trade School">
                        Associate's Degree / Trade School
                      </option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="Doctoral Degree">Doctoral Degree</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupdate"
                  >
                    <Form.Label>Hiring Timeframe</Form.Label>
                    <div className=" form_input">
                      <input
                        type="date"
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="hiring_timeframe"
                        className="form-control"
                        defaultValue={
                          formdata.hiring_timeframe
                            ? formdata.hiring_timeframe.slice(0, 10)
                            : values.hiring_timeframe
                        }
                        min={new Date().toISOString().split("T")[0]}
                        max={
                          new Date(
                            new Date().setFullYear(new Date().getFullYear() + 1)
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                    </div>
                    <div className="form-field-error">
                      {touched.hiring_timeframe && errors.hiring_timeframe}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupjob_shift"
                  >
                    <Form.Label>Shift</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form_input"
                      onChange={handleChange}
                      name="job_shift"
                      defaultValue={formdata.job_shift}
                      value={values.job_shift}
                    >
                      <option value="Morning">Morning</option>
                      <option value="Evening">Evening</option>
                      <option value="Graveyard">Graveyard</option>
                      <option value="On Call">On Call</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupjob_type"
                  >
                    <Form.Label>Job Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form_input"
                      onChange={handleChange}
                      name="job_type"
                      defaultValue={formdata.job_type}
                      value={values.job_type}
                    >
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Contract">Contract</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Internship">Internship</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGrouprequired_onsite"
                  >
                    <Form.Label>Required Onsite</Form.Label>
                    <Form.Select
                      className="form_input"
                      onChange={handleChange}
                      name="required_onsite"
                      defaultValue={formdata.required_onsite}
                      value={values.required_onsite}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Hybrid">Hybrid</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGrouprequired_onsite"
                  >
                    <Form.Label>Required Travel</Form.Label>
                    <Form.Select
                      className="form_input"
                      onChange={handleChange}
                      name="required_travel"
                      defaultValue={formdata.required_travel}
                      value={values.required_travel}
                    >
                      <option value="N/A">N/A</option>
                      <option value="Light">Light</option>
                      <option value="Medium">Medium</option>
                      <option value="Heavy">Heavy</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGrouprequired_clearance"
                  >
                    <Form.Label>Required Clearance</Form.Label>
                    <Form.Select
                      className="form_input"
                      onChange={handleChange}
                      name="required_clearance"
                      defaultValue={formdata.required_clearance}
                      value={values.required_clearance}
                    >
                      <option value="N/A">N/A</option>
                      <option value="Public-Trust">Public Trust</option>
                      <option value="Confidential">Confidential</option>
                      <option value="Secret">Secret</option>
                      <option value="Top-Secret">Top Secret</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupsponsorship"
                  >
                    <Form.Label>Sponsored</Form.Label>
                    <Form.Select
                      className="form_input"
                      onChange={handleChange}
                      name="sponsorship"
                      defaultValue={formdata.sponsorship}
                      value={values.sponsorship}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupminimum_pay"
                  >
                    <Form.Label>Minimum Pay</Form.Label>
                    <Form.Control
                      className="form_input"
                      type="text"
                      onChange={handleChange}
                      onKeyDown={(event) => {
                        const input = event.target;
                        if (event.keyCode === 9) {
                          // Find the next input field and focus on it
                          const nextInput = event.target.parentElement.nextElementSibling.querySelector('.form_input');
                          if (nextInput) {
                            event.preventDefault();
                            nextInput.focus();
                          }
                        } else if (event.keyCode === 8) {
                          return;
                        } else if (event.keyCode === 46 && input.selectionStart !== input.selectionEnd) {
                          const value = input.value;
                          const start = input.selectionStart;
                          const end = input.selectionEnd;
                          const newValue = value.substring(0, start) + value.substring(end);
                          handleChange({ target: { name: 'minimum_pay', value: newValue } });
                          event.preventDefault();
                        } else if (
                          input.selectionStart === 0 &&
                          input.selectionEnd === input.value.length &&
                          /[0-9]/.test(event.key)
                        ) {
                          return;
                        } else if (/^[0-9]$/.test(event.key) && input.value.length < 6) {

                          return;
                        }
                        event.preventDefault();
                      }}
                      name="minimum_pay"
                      defaultValue={formdata.minimum_pay}
                      placeholder=""
                      value={values.minimum_pay}
                      pattern="^(0|[1-9]\d*)$"
                    />


                    <div className="form-field-error">
                      {touched.minimum_pay && errors.minimum_pay}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupmaximum_pay"
                  >
                    <Form.Label>Maximum Pay</Form.Label>
                    <Form.Control
                      className="form_input"
                      type="text"
                      onChange={handleChange}
                      onKeyDown={(event) => {
                        const input = event.target;
                        if (event.keyCode === 9) {
                          const nextInput = event.target.parentElement.nextElementSibling.querySelector('.form_input');
                          if (nextInput) {
                            event.preventDefault();
                            nextInput.focus();
                          }
                        } else if (event.keyCode === 8) {
                          return;
                        } else if (event.keyCode === 46 && input.selectionStart !== input.selectionEnd) {
                          const value = input.value;
                          const start = input.selectionStart;
                          const end = input.selectionEnd;
                          const newValue = value.substring(0, start) + value.substring(end);
                          handleChange({ target: { name: 'maximum_pay', value: newValue } });
                          event.preventDefault();
                        } else if (
                          input.selectionStart === 0 &&
                          input.selectionEnd === input.value.length &&
                          /[0-9]/.test(event.key)
                        ) {

                          return;
                        } else if (/^[0-9]$/.test(event.key) && input.value.length < 6) {
                          return;
                        }
                        event.preventDefault();
                      }}
                      name="maximum_pay"
                      defaultValue={formdata.maximum_pay}
                      placeholder=""
                      value={values.maximum_pay}
                      pattern="^(0|[1-9]\d*)$"
                    />

                    <div className="form-field-error">
                      {touched.maximum_pay && errors.maximum_pay}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupjob_application_url"
                  >
                    <Form.Label>Job URL</Form.Label>
                    <Form.Control
                      className="form_input"
                      type="text"
                      onChange={handleChange}
                      name="job_application_url"
                      defaultValue={formdata.job_application_url}
                      placeholder=""
                      value={values.job_application_url}
                    />
                    <div className="form-field-error">
                      {touched.job_application_url &&
                        errors.job_application_url}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body"
                    controlId="formGroupjob_description"
                  >
                    <Form.Label>Job Description</Form.Label>
                    <ReactQuill
                      className="form_input quill-editor"
                      name="job_description"
                      theme="snow"
                      value={values.job_description}
                      onChange={(value) => {
                        const event = { target: { name: 'job_description', value } };
                        handleChange(event);
                        setFormData({ ...formdata, job_description: value });
                      }}
                      modules={modules}
                      formats={formats}
                      style={{ textAlign: 'left!important' }}
                    />
                    <div className="form-field-error">

                      {touched.job_description && errors.job_description}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form_input_body chckbox_clr"
                    controlId="formGroupjob_description"
                  >
                    <Form.Check
                      required
                      name="job_term_conditions"
                      label={
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={handleCheckboxClick}
                          onKeyPress={handleCheckboxClick}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          By checking this box, you verify this job meets
                          MyTurn's Terms & Conditions and complies with all
                          Federal and Local Employment Laws.
                        </span>
                      }
                      onChange={handleChange}
                      isInvalid={!!errors.job_term_conditions}
                      feedbackType="invalid"
                      id="validationFormik0"
                      checked={values.job_term_conditions}
                      value="true"
                    />
                  </Form.Group>

                  <div className="post_job_btns_chckbox">
                    <Button
                      onClick={handlePostFormClose}
                      className="cta-type-2 h-40 btn btn-primaryfalse post_job"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="cta-type-2 h-40 btn btn-primaryfalse post_job"
                    >
                      Preview
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
