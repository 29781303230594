import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomDropdown = ({ selectedOption, setSelectedOption, loadJobs, remoteFilter, setRemoteFilter }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };


  const handleCrossClick = (e) => {
    e.stopPropagation();
    setSelectedOption("");
  };

  const handleRemoteCrossClick = (e) => {
    e.stopPropagation();
    setRemoteFilter("");
  }

  const toggleDropdown = (isOpen, event, metadata) => {
    setIsDropdownOpen(isOpen);
  };

  const handleRemoteSelect = (eventKey) => {
    setRemoteFilter(eventKey);
  };

  useEffect(() => {
    loadJobs(false);
  }, [selectedOption, remoteFilter])

  return (
    <>
      <Col
        xxl={{ span: 6 }}
        xl={{ span: 6 }}
        lg={{ span: 6 }}
        md={12}
        sm={12}
        xs={12}
      >
        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
          <Dropdown show={isDropdownOpen} onToggle={toggleDropdown} onSelect={handleSelect}>
            <Dropdown.Toggle
              as="div"
              id="dropdown-custom-components"
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0.5rem 1rem', borderRadius: '4px', backgroundColor: '#fe7650', color: '#fff' }}
            >
              {selectedOption ? (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {selectedOption === "entry_level" ? "Entry Level" : "Professional"}
                  <Button
                    variant="link"
                    onClick={handleCrossClick}
                    style={{ marginLeft: 'auto', padding: '0', fontSize: '20px', textDecoration: 'none', background: '#fe7650', color: '#fff', position: 'absolute', right: '-18px', top: '-5px' }}
                  >
                    &times;
                  </Button>
                </div>
              ) : 'Experience'}
            </Dropdown.Toggle>

            {!selectedOption && (
              <Dropdown.Menu show>
                <Dropdown.Item eventKey="entry_level" onSelect={handleSelect}>Entry Level</Dropdown.Item>
                <Dropdown.Item eventKey="professional" onSelect={handleSelect}>Professional</Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </Col>
      <Col
        xxl={{ span: 6 }}
        xl={{ span: 6 }}
        lg={{ span: 6 }}
        md={12}
        sm={12}
        xs={12}
      >
        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
          <Dropdown onSelect={handleRemoteSelect} >
            <Dropdown.Toggle
              as="div"
              id="dropdown-custom-components"
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0.5rem 1rem', borderRadius: '4px', backgroundColor: '#fe7650', color: '#fff' }}
            >
              {remoteFilter ? (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {remoteFilter === "remote" ? "Remote" : "Location"}
                  <Button
                    variant="link"
                    onClick={handleRemoteCrossClick}
                    style={{ marginLeft: 'auto', padding: '0', fontSize: '20px', textDecoration: 'none', background: '#fe7650', color: '#fff', position: 'absolute', right: '-18px', top: '-5px' }}
                  >
                    &times;
                  </Button>
                </div>) : "Location"}
            </Dropdown.Toggle>
            {!remoteFilter && (
              <Dropdown.Menu>
                <Dropdown.Item eventKey="remote" onSelect={handleRemoteSelect} >Remote</Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </Col>
    </>
  );
};

export default CustomDropdown;
