import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory } from "react-router-dom";
import CustomButton from "../../common/button/CustomButton";
import CustomeModel from "../../common/model/CustomeModel";
import InputSelect from "../../common/select/InputSelect";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
export default function SubscriptionPlan() {
  let history = useHistory();
  const [method, setMethod] = useState();
  const [continueModelShow, setContinueModelShow] = useState(false);
  const onSubmit = () => {
    if (method === "Free") history.push("/Candidate/account-settings/profile");
    else history.push("/Candidate/Onboarding/payment-method");
  };
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/UploadResume");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            4
          )}
        />

        <h2 className="section-heading-2 text-center mx-auto mw-552">
          Subscription Plan
        </h2>

        <div className="text-center">
          <InputSelect
            label={"Select your plan"}
            options={["1 Month 10$", "6 Month 50$", "1 Year 80$"]}
            onChange={(e) => {
              setMethod(e.target.value);
            }}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          isDisabled={helper.IsEmpty(method)}
          onClick={onSubmit}
          label="Next"
        />
        <CustomeModel
          modalBody={
            <>
              <h2>
                Would you like to continue setting up your profile or go
                directly to the dashboard?
              </h2>
              <p>
                If you don&apos;t fully complete your profile your resume will
                not be shown to employers
              </p>
            </>
          }
          modalType="Positive/Negative"
          positiveButtonLable="Continue profile"
          negativeButtonLable="Go to Dashboard"
          show={continueModelShow}
          onNegative={() => {
            setContinueModelShow(false);
            history.push(`/${getAuthentionInfo().type}/Dashboard`);
          }}
          onPositive={() => {
            history.push("/Candidate/Onboarding/SeekingNewJob");
            setContinueModelShow(false);
          }}
          handleClose={() => {
            setContinueModelShow(false);
          }}
        />
      </Container>
    </div>
  );
}
