import UploadResume from "../components/candidate/onboarding/UploadResume";
import SeekingNewJob from "../components/candidate/onboarding/SeekingNewJob";
import WorkPermit from "../components/candidate/onboarding/WorkPermit";
import VisaSponsorship from "../components/candidate/onboarding/VisaSponsorship";
import EmploymentType from "../components/candidate/onboarding/EmploymentType";
import MinimumPay from "../components/candidate/onboarding/MinimumPay";
import CurrentlyLive from "../components/candidate/onboarding/CurrentlyLive";
import CanRelocate from "../components/candidate/onboarding/CanRelocate";
import RemoteWorkOnly from "../components/candidate/onboarding/RemoteWorkOnly";
import WillingToTravel from "../components/candidate/onboarding/WillingToTravel";
import ShiftPreference from "../components/candidate/onboarding/ShiftPreference";
import WorkOnHazardous from "../components/candidate/onboarding/WorkOnHazardous";
import HeavyLiftingWork from "../components/candidate/onboarding/HeavyLiftingWork";
import EducationLevel from "../components/candidate/onboarding/EducationLevel";
import JoinWithin from "../components/candidate/onboarding/JoinWithin";
import SecurityClearance from "../components/candidate/onboarding/SecurityClearance";
import Privacy from "../components/candidate/onboarding/Privacy";
import PaymentMethod from "../components/candidate/onboarding/PaymentMethod";
import SignPayment from "../components/candidate/onboarding/SignPayment";
import SubscriptionType from "../components/candidate/onboarding/SubscriptionType";
import SubscriptionPlan from "../components/candidate/onboarding/SubscriptionPlan";

export default [
  {
    name: "Upload Resume",
    path: "/UploadResume",
    exact: true,
    component: UploadResume,
  },
  {
    name: "Seeking A New Job",
    path: "/SeekingNewJob",
    exact: true,
    component: SeekingNewJob,
  },
  {
    name: "Work Permit Of USA",
    path: "/WorkPermit",
    exact: true,
    component: WorkPermit,
  },
  {
    name: "Employment Visa Sponsorship",
    path: "/VisaSponsorship",
    exact: true,
    component: VisaSponsorship,
  },
  {
    name: "Employment Type",
    path: "/EmploymentType",
    exact: true,
    component: EmploymentType,
  },
  {
    name: "Minimum Pay",
    path: "/MinimumPay",
    exact: true,
    component: MinimumPay,
  },
  {
    name: "Currently Live In",
    path: "/CurrentlyLive",
    exact: true,
    component: CurrentlyLive,
  },
  {
    name: "Willing to relocate",
    path: "/CanRelocate",
    exact: true,
    component: CanRelocate,
  },
  {
    name: "Remote work only",
    path: "/RemoteWorkOnly",
    exact: true,
    component: RemoteWorkOnly,
  },
  {
    name: "Willing to travel",
    path: "/WillingToTravel",
    exact: true,
    component: WillingToTravel,
  },
  {
    name: "Shift preference",
    path: "/ShiftPreference",
    exact: true,
    component: ShiftPreference,
  },
  {
    name: "Work on hazardous",
    path: "/WorkOnHazardous",
    exact: true,
    component: WorkOnHazardous,
  },
  {
    name: "Heavy lifting work",
    path: "/HeavyLiftingWork",
    exact: true,
    component: HeavyLiftingWork,
  },
  {
    name: "Education level",
    path: "/EducationLevel",
    exact: true,
    component: EducationLevel,
  },
  {
    name: "Soon you start",
    path: "/JoinWithin",
    exact: true,
    component: JoinWithin,
  },
  {
    name: "Security clearance",
    path: "/SecurityClearance",
    exact: true,
    component: SecurityClearance,
  },
  {
    name: "Privacy",
    path: "/Privacy",
    exact: true,
    component: Privacy,
  },
  {
    name: "Payment Method",
    path: "/payment-method",
    exact: true,
    component: PaymentMethod,
  },
  {
    name: "Sign up payment",
    path: "/sign-up-payment",
    exact: true,
    component: SignPayment,
  },
  {
    name: "Get Subscription",
    path: "/subscription-type",
    exact: true,
    component: SubscriptionType,
  },
  {
    name: "select plan",
    path: "/subscription-plan",
    exact: true,
    component: SubscriptionPlan,
  },
];
