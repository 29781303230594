import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import InputText from "../common/inputText/InputText";
import InputSelect from "../common/select/InputSelect";
import { Tooltip, Overlay } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { bindActionCreators } from "redux";
import CustomeModel from "../common/model/CustomeModel";
import * as commonActions from "./../../actions/commonActions";
import { Card, Accordion, Button } from "react-bootstrap";
import { IsEmpty, removeValueFromArray } from "../../utility/helper/helper";
import * as utilConstants from "./../../utility/constants/utilConstants";
import MultiInputSelect from "../common/select/MultiInputSelect";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import InputRange from "../common/inputRange/InputRange";
function Filters(props) {
  console.log("propskey", props.keywordpress);
  const [createSearchModal, setCreateSearchModal] = useState(false);
  const [worddisance, setWorddistance] = useState(false);
  const [editSearchModal, setEditSearchModal] = useState(false);
  const [manageFilterModal, setManageFilterModal] = useState(false);
  const [filterListModal, setFilterListModal] = useState(false);
  const [resetButtonPressed, setResetButtonPressed] = useState(false);
  const [skillInput] = useState([]);
  const [show1, setshow1] = useState("");
  const [show2, setshow2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [show5, setshow5] = useState(false);
  const [range, setRange] = useState(50);
  const [filter, setFilter] = useState({});
  const [newFilterName, setNewFilterName] = useState("");
  const [salaryError, setsalaryError] = useState("");
  const [negotiable, setNegotiable] = useState("");
  const target = useRef(null); 
  const target1 = useRef(null);
  const target3 = useRef(null); 
  const target4 = useRef(null);
  const target2 = useRef(null);
  const [showDecorator, setShowDecorator] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  function CustomToggle({ props, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setShowDecorator(!showDecorator);
      setFilterChange(true);
      props.filterChange({ localSearch: !showDecorator ? 1 : 0 });
    });

    return (
      <div className="toggle-switch">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={showDecorator}
            onChange={(e) => {
              if (e.target.checked) props.filterChange({ localSearch: 1 });
              else props.filterChange({ localSearch: 0 }, true);
            }}
          />
          <span
            className="slider round"
            onClick={(e) => {
              e.stopPropagation();
              decoratedOnClick();
            }}
          ></span>
        </label>
      </div>
    );
  }

  useEffect(() => {
    if (editSearchModal) {
      setFilterListModal(false);
    }
  }, [editSearchModal]);
  useEffect(() => {
    if (!IsEmpty(skillInput))
      props.filterChange({
        skill: skillInput.map((ski) => ski.label).toString(),
      });
    else
      props.filterChange(
        {
          skill: null,
        },
        true
      );
  }, [skillInput]);
  useEffect(() => {
    if (range !== 0) props.filterChange({ range });
    else props.filterChange({ range: null }, true);
  }, [range]);
  useEffect(() => {
    if (
      IsEmpty(props.state.commonReducer.employmentType) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.getCommonDropdownList();
  }, []);
  const saveMyFilter = () => {
    let filterOption = {
      ...props.activeFilter,
      title: newFilterName,
      id: props.selectedSuggestion,
    };
    delete filterOption.limit;
    delete filterOption.skip;
    if (editSearchModal)
      commonActions.editCandidate(filterOption, (response) => {
        if (response.status === utilConstants.sucessState200) {
          props.updateSugesstion();
          setEditSearchModal(false);
          setCreateSearchModal(false);
        } else seterrorMessage(response.message);
      });
    else
      commonActions.candidateSaveToFilter(filterOption, (response) => {
        if (response.status === utilConstants.sucessState200) {
          props.updateSugesstion();
          console.log("response", response.data);
          props.selectedFilter(response.data.id);
          setEditSearchModal(false);
          setCreateSearchModal(false);
        } else seterrorMessage(response.message);
      });
  };
  console.log("error", errorMessage);
  useEffect(() => {
    props.suggestion &&
      props.suggestion.map((sug) => {
        if (sug.id === parseInt(props.selectedSuggestion))
          setNewFilterName(sug.title);
      });
  }, [props.selectedSuggestion]);
  let { employmentType, highestEducation, workShift } =
    props.state.commonReducer;

  const deleteSavedFilter = (id) => {
    commonActions.deleteFilter({ id }, (response) => {
      if (response.status === utilConstants.sucessState200)
        props.updateSugesstion(response.data.rows);
    });
  };
  const handleSalaryChange = (e) => {
    const re = /^\d+(\.\d{0,2})?$/;
    console.log("e", e.target.value, filter.salary);
    if (
      (filter.minimum_amount === undefined && e.target.value == "0") ||
      filter.salary?.length === 6
    ) {
      console.log("hii");
    }
    if (filter.salary?.length === 6 && e.target.value.slice(-1) === ".") {
      console.log("hii2");
      setFilter({
        ...filter,
        salary: e.target.value,
      });
    } else if (
      filter.minimum_amount !== undefined ||
      (e.target.value !== "0" &&
        (e.target.value === "" ||
          (re.test(e.target.value) && filter.salary?.length !== 6)))
    ) {
      setFilter({
        ...filter,
        salary: e.target.value,
      });
    }
  };
  const clearRate = () => {
    setFilter({
      ...filter,
      salary: "",
    });
  };
  
  useEffect(() => {
    if (props.keywordpress) {
      setFilterChange(false);

    }
  }, [props.keywordpress]);
  useEffect(() => {
    if (filterChange) {
      props.propskeywordpress();
    }
  }, [filterChange]);
  useEffect(() => {
    if (!filterChange && resetButtonPressed) {
      props.filterChange({
        need_visa_sponsorship: 0,
        localSearch: 0,
        willing_relocate: 0,
        willing_remote_work: 0,
        willing_travel: 0,
      });
      setShowDecorator(false);
      setFilter({
        ...filter,
        salary: "",
      });
    }
  }, [filterChange, resetButtonPressed]);
  const handlekeyPress = () => {
    if (filter.salary === undefined || filter.salary === "") {
      setsalaryError("Please correct this field");
    }
    if (filter.salary !== undefined && filter.salary !== "") {
      props.filterChange({ minimum_amount: filter.salary });
      setsalaryError("");
    }
  };
  const handleShow2 = () => {
    if (show2) {
      setshow1("yes");
      setshow2(!show2);
    } else {
      setshow1("no");
      setshow2(!show2);
    }
  };
  useEffect(() => {
    if (show1 !== "yes") {
      setshow2(false);
    }
  }, [show1]);
  console.log("shjo", show2, show1);
  return (
    <form>
      <CustomeModel
        show={manageFilterModal}
        handleClose={() => setManageFilterModal(false)}
        title={"Manage Filter"}
        className="p-sm searchFilterModal"
        modalBody={
          <table className="delete-position">
            {props.suggestion &&
              props.suggestion.map((sug, index) => (
                <tr key={index}>
                  <td>{sug.title}</td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        deleteSavedFilter(sug.id);
                      }}
                      height={30}
                      className="userpic pb-2 "
                      src="/images/delete.png"
                      alt="user"
                    />
                  </td>
                </tr>
              ))}
          </table>
        }
      />
      <CustomeModel
        className="p-sm searchFilterModal"
        noImage
        modalBody={
          <form>
            <InputText
              label="Filter Search title"
              placeholder="Filter name"
              name="filterName"
              value={newFilterName}
              onChange={(e) => {
                e.preventDefault();
                setNewFilterName(e.target.value);
                seterrorMessage("");
              }}
              maxLength={50}
            ></InputText>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </form>
        }
        modalType="Positive/Negative"
        positiveButtonLable="Cancel"
        negativeButtonLable="Save"
        show={createSearchModal || editSearchModal}
        handleClose={() => {
          setEditSearchModal(false);
          setCreateSearchModal(false);
          seterrorMessage("");
        }}
        onPositive={(e) => {
          e.preventDefault();
          setEditSearchModal(false);
          setCreateSearchModal(false);
          seterrorMessage("");
        }}
        onNegative={(e) => {
          e.preventDefault();
          if (!newFilterName) {
            ReactNotifications(
              "Please enter filter name",
              utilConstants.getErrorStatus
            );
            return;
          }

          saveMyFilter(newFilterName);
        }}
      />
      <CustomeModel
        handleClose={(e) => {
          e.preventDefault();
          setFilterListModal(false);
        }}
        onPositive={(e) => {
          e.preventDefault();
          setFilterListModal(false);
        }}
        onNegative={(e) => {
          e.preventDefault();
          setFilterListModal(false);
        }}
        title="Filter search list"
        className="p-sm searchFilterModal"
        noImage
        modalBody={
          <div>
            <ul className="filter-list">
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
              <li>
                Custom Search #3
                <div className="actionBox">
                  <img
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditSearchModal(true);
                    }}
                    src={"/images/pen-outline.png"}
                  ></img>
                  <img
                    alt="delete"
                    className="mx-1"
                    src={"/images/delete-outline.png"}
                  ></img>
                </div>
              </li>
            </ul>
          </div>
        }
        modalType="Positive/Negative"
        positiveButtonLable="Cancel"
        negativeButtonLable="Create Filter"
        show={filterListModal}
      />
      {(Object.keys(props.activeFilter).length > 4 || props.suggestion) && (
        <Card className=" border-0 w-100">
          <div className="flex-align-center align-reset justify-content-between">
            {(props.selectedSuggestion || filterChange) && (
              <label
                className="clear-btn flex-align-center"
                onClick={(e) => {
                  e.preventDefault();
                  props.clearFilter();
                  setFilterChange(false);
                  setResetButtonPressed(true);
                }}
              >
                Reset Filter
                <img
                  src="/images/close.png"
                  alt="close"
                  onClick={(e) => {
                    e.preventDefault();
                    props.clearFilter();
                    setFilterChange(false);
                    setResetButtonPressed(true);
                  }}
                ></img>
              </label>
            )}
          </div>
          <InputSelect
            controlId="formBasicLastName"
            className={"search-input"}
            label={
              <div className="flex-align-center  justify-content-between">
                <span> Filter Search</span>
                <b
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setManageFilterModal(true);
                  }}
                >
                  <img src="/images/pen-outline.png"></img>
                </b>
              </div>
            }
            type="text"
            name="email"
            placeholder="Enter your current email"
            optionLable="title"
            optionValue="id"
            options={props.suggestion}
            onChange={(e) => {
              e.preventDefault();
              setFilterChange(true);
              commonActions.searchKeywords(
                { S: 0, l: 10, id: e.target.value },
                (response) => {
                  if (response)
                    props.filterChange(
                      response.data.rows[0].search_condition,
                      "filter-change"
                    );
                }
              );
              props.selectedFilter(e.target.value);
            }}
            value={
              props.selectedSuggestion !== null && props.selectedSuggestion
            }
          />
          <div className="flex-align-center  justify-content-between mt-3">
            <Button
              className="tertiary-button"
              onClick={(e) => {
                e.preventDefault();
                if (props.selectedSuggestion) setEditSearchModal(true);
                else setCreateSearchModal(true);
              }}
            >
              {props.selectedSuggestion ? `Edit Search` : `Create Search`}
            </Button>
          </div>
        </Card>
      )}
      <Accordion defaultActiveKey="0" className="mt-3  d-md-block d-lg-none">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p className="title">You choosed:</p>
          </Accordion.Header>
          <Accordion.Body>
            <ul className=" d-lg-block indicatorLabels ">
              {[...props.filterOptionFormate()].map((fltOpt, index) => (
                <li key={index}>
                  <label
                    className={
                      utilConstants.lableCssClass[
                        index % utilConstants.lableCssClass.length
                      ]
                    }
                  >
                    {fltOpt}
                  </label>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="pay">
          <Accordion.Header>
            <p className="title">SALARY</p>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex align-item-center">
              <label
                htmlFor="perhour"
                className="d-flex custom-label align-items-center cursor-pointer"
              >
                <input
                  type="radio"
                  name="payType"
                  value="hour"
                  id="perhour"
                  defaultChecked
                  checked={
                    props.activeFilter.minimum_amount_type === "Per hour"
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setNegotiable(false);
                    setsalaryError("");
                    props.filterChange({
                      minimum_amount_type: "Per hour",
                      minimum_amount: "",
                    });
                    setFilterChange(true);
                    setFilter({ ...filter, salary: "" });
                  }}
                ></input>
                <span className="checkmark">
                  <span className="mark"></span>
                </span>
                Per hour
              </label>
              <label
                htmlFor="peryear"
                className="d-flex custom-label mt-2 align-items-center cursor-pointer"
              >
                <input
                  type="radio"
                  name="payType"
                  value="year"
                  id="peryear"
                  checked={
                    props.activeFilter.minimum_amount_type === "Per year"
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setsalaryError("");
                    setNegotiable(false);
                    props.filterChange({
                      minimum_amount_type: "Per year",
                      minimum_amount: "",
                    });
                    setFilterChange(true);
                    setFilter({ ...filter, salary: "" });
                  }}
                ></input>
                <span className="checkmark">
                  <span className="mark"></span>
                </span>
                Per year
              </label>
              <label
                htmlFor="negotiable"
                className="d-flex custom-label mt-2 align-items-center cursor-pointer"
              >
                <input
                  type="radio"
                  name="payType"
                  value="Negotiable"
                  id="negotiable"
                  checked={
                    props.activeFilter.minimum_amount_type === "Negotiable"
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setNegotiable(true);
                    setsalaryError("");
                    setFilter({ ...filter, salary: "" });
                    setFilterChange(true);
                    props.filterChange({
                      minimum_amount_type: "Negotiable",
                      minimum_amount: "",
                    });
                  }}
                ></input>
                <span className="checkmark">
                  <span className="mark"></span>
                </span>
                Negotiable
              </label>
            </div>
            <div className="pos-div">
              <InputText
                label="Upper Salary Limit"
                value={filter.salary}
                name="salary"
                onChange={(e) => {
                  handleSalaryChange(e);
                  setFilterChange(true);
                }}
                onBlur={(e) => {
                  if (e.target.value)
                    props.filterChange({ minimum_amount: e.target.value });
                  else
                    props.filterChange(
                      { minimum_amount: e.target.value },
                      true
                    );
                }}
                onKeypress={handlekeyPress}
                className="mt-3 pos-div"
                placeholder="$"
                disabled={negotiable}
                maxLength={filter.salary?.includes(".") ? "9" : "7"}
              />
            </div>
            {salaryError && <div style={{ color: "red" }}>{salaryError}</div>}
            {filter.salary !== "" && (
              <span className="clear-filter" onClick={() => clearRate()}>
                Clear Rate
              </span>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p className="title">TITLE</p>
          </Accordion.Header>
          <Accordion.Body>
            <MultiInputSelect
              customCreate
              type="jobTitle"
              label="Job Title"
              options={[]}
              placeholder="Job Title"
              value={
                props?.activeFilter?.job_title > ""
                  ? [
                      {
                        value: props?.activeFilter?.job_title,
                        label: props?.activeFilter?.job_title,
                      },
                    ]
                  : []
              }
              onSelect={(value) => {
                if (value?.value?.length > 255) return;
                props.filterChange({ job_title: value?.value });
                setFilterChange(true);
              }}
              onClear={() => props.filterChange({ job_title: "" })}
              showClearFilter
              defaultInputValue={props?.activeFilter?.job_title}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <p className="title">JOB SEARCHING</p>
          </Accordion.Header>
          <Accordion.Body className="radios">
            <label
              htmlFor="all"
              className="d-flex custom-label align-items-center cursor-pointer"
            >
              <input
                type="radio"
                name="type"
                value="1"
                id="all"
                defaultChecked
                checked={!props.activeFilter.is_seeking_job}
                onChange={(e) => {
                  e.preventDefault();
                  setFilterChange(true);
                  props.filterChange(
                    {
                      is_seeking_job: "ALL",
                    },
                    true
                  );
                }}
              ></input>
              <span className="checkmark">
                <span className="mark"></span>
              </span>
              All
            </label>
            <label
              htmlFor="active"
              className="d-flex custom-label mt-2 align-items-center cursor-pointer"
            >
              <input
                type="radio"
                name="type"
                value="2"
                id="active"
                checked={props.activeFilter.is_seeking_job === "active"}
                onChange={(e) => {
                  e.preventDefault();
                  setFilterChange(true);
                  props.filterChange({
                    is_seeking_job: "active",
                  });
                }}
              />
              <span className="checkmark">
                <span className="mark"></span>
              </span>
              Active
            </label>
            <label
              htmlFor="passive"
              className="d-flex custom-label mt-2 align-items-center cursor-pointer"
            >
              <input
                type="radio"
                name="type"
                value="3"
                id="passive"
                checked={props.activeFilter.is_seeking_job === "passive"}
                onChange={(e) => {
                  e.preventDefault();
                  setFilterChange(true);
                  props.filterChange({
                    is_seeking_job: "passive",
                  });
                }}
              />
              <span className="checkmark">
                <span className="mark"></span>
              </span>
              Passive
            </label>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <p className="title">EDUCATION LEVEL</p>
          </Accordion.Header>
          <Accordion.Body className="radios">
            {highestEducation &&
              highestEducation.map((hedu, k) => (
                <label
                  htmlFor={hedu.title}
                  key={k}
                  className="custom-check-box"
                >
                  <input
                    type="checkbox"
                    name={hedu.title}
                    id={hedu.title}
                    checked={
                      !props?.keywordpress &&
                      props?.activeFilter?.degree !== undefined &&
                      props?.activeFilter?.degree
                        ?.split(",")
                        .includes(hedu.id.toString())
                    }
                    onChange={(e) => {
                      let higEdu = props.activeFilter.degree
                        ? props.activeFilter.degree.split(",")
                        : [];
                      if (e.target.checked) {
                        higEdu.push(+hedu.id);
                      } else {
                        higEdu = removeValueFromArray(
                          hedu.id.toString(),
                          higEdu
                        );
                      }
                      setFilterChange(true);
                      if (IsEmpty(higEdu))
                        props.filterChange({ degree: "" }, true);
                      else
                        props.filterChange({
                          degree: higEdu.toString(),
                        });
                    }}
                  ></input>
                  <span className="checkmark"></span>
                  <span className="check-text">{hedu.title}</span>
                </label>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <p className="title">EMPLOYMENT TYPE</p>
          </Accordion.Header>
          <Accordion.Body className="radios">
            {employmentType &&
              employmentType.map((empt, k) => (
                <label
                  htmlFor={empt.title}
                  key={k}
                  className="custom-check-box"
                >
                  <input
                    type="checkbox"
                    name="desired_employment"
                    id={empt.title}
                    checked={
                      !props?.keywordpress &&
                      props?.activeFilter?.desired_employment !== undefined &&
                      props?.activeFilter?.desired_employment
                        ?.split(",")
                        .includes(empt.id.toString())
                    }
                    onChange={(e) => {
                      let desEmp = props.activeFilter.desired_employment
                        ? props.activeFilter.desired_employment.split(",")
                        : [];
                      if (e.target.checked) {
                        desEmp.push(+empt.id);
                      } else {
                        desEmp = removeValueFromArray(
                          empt.id.toString(),
                          desEmp
                        );
                      }
                      setFilterChange(true);
                      if (IsEmpty(desEmp))
                        props.filterChange({ desired_employment: "" }, true);
                      else
                        props.filterChange({
                          desired_employment: desEmp.toString(),
                        });
                    }}
                  ></input>
                  <span className="checkmark"></span>
                  <span className="check-text">{empt.title}</span>
                </label>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <p className="title">SHIFT PREFERENCE</p>
          </Accordion.Header>
          <Accordion.Body className="radios">
            {workShift &&
              workShift.map((wshf, k) => (
                <label
                  htmlFor={wshf.title}
                  key={k}
                  className="custom-check-box"
                >
                  <input
                    type="checkbox"
                    name="type"
                    value="1"
                    id={wshf.title}
                    checked={
                      !props?.keywordpress &&
                      props?.activeFilter?.job_shift_type !== undefined &&
                      props?.activeFilter?.job_shift_type
                        ?.split(",")
                        .includes(wshf.id.toString())
                    }
                    onChange={(e) => {
                      let shiftType = props.activeFilter.job_shift_type
                        ? props.activeFilter.job_shift_type.split(",")
                        : [];
                      if (e.target.checked) {
                        shiftType.push(+wshf.id);
                      } else {
                        shiftType = removeValueFromArray(
                          wshf.id.toString(),
                          shiftType
                        );
                      }
                      setFilterChange(true);
                      if (IsEmpty(shiftType))
                        props.filterChange({ job_shift_type: "" }, true);
                      else
                        props.filterChange({
                          job_shift_type: shiftType.toString(),
                        });
                    }}
                  ></input>
                  <span
                    className="checkmark"
                    onChange={(e) => e.target.checked}
                  ></span>
                  <span className="check-text">{wshf.title}</span>
                </label>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <div className="flex-align-center  justify-content-between mt-3">
          <p className="title">LOCAL CANDIDATE RESULTS</p>
          <div className="toggle-switch">
            <CustomToggle eventKey="local" props={props} />{" "}
          </div>
        </div>
        {showDecorator && (
          <>
            <InputText
              label="zip"
              onBlur={(e) => {
                if (e.target.value) props.filterChange({ zip: e.target.value });
                else props.filterChange({ zip: e.target.value }, true);
              }}
              value={filter.zip}
              onChange={(e) => {
                setFilter({ ...filter, zip: e.target.value });
                setFilterChange(true);
              }}
              name="zip"
            />
            <p className=" form-label mt-3">
              Range<span>(miles)</span>
            </p>
            <InputRange
              onChange={(e) => {
                setRange(e.target.value);
                setFilterChange(true);
              }}
              value={range}
            />
          </>
        )}
      </Accordion>
      <div className="flex-align-center  justify-content-between mt-3">
        <div className="tooltip-container" style={{ alignItems: "center" }}>
          <p className="title pos-rel">NO VISA SPONSORSHIP?</p>
          <button
            ref={target1}
            onClick={(e) => {
              e.preventDefault();
              handleShow2();
            }}
            className="btn-infotool"
          >
            <img
              style={{ margin: "0 8px", width: "10px", height: "10px" }}
              src="/images/tooltip.png"
              alt="Question mark"
            />
          </button>
          <Overlay target={target1.current} show={show2} placement="right">
            {(props) => (
              <Tooltip
                id="overlay-example-3"
                {...props}
                className="umh-ca-tooltip"
              >
                <p>Will the position provide VISA Sponsorship </p>
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="toggle-switch" style={{ padding: "7.5px" }}>
          <input
            type="checkbox"
            id="color-checkbox"
            checked={
              !props?.keywordpress &&
              props.activeFilter.need_visa_sponsorship !== undefined &&
              props.activeFilter.need_visa_sponsorship
            }
            onChange={(e) => {
              props.filterChange({
                need_visa_sponsorship: e.target.checked ? 1 : 0,
              });
              setFilterChange(true);
            }}
          />
        </div>
      </div>
      <div className="flex-align-center  justify-content-between mt-3">
        <div className="tooltip-container" style={{ alignItems: "center" }}>
          <p className="title pos-rel">RELOCATION REQUIRED?</p>
          <button
            ref={target2}
            onClick={(e) => {
              e.preventDefault();
              setshow3(!show3);
            }}
            className="btn-infotool"
          >
            <img
              style={{ margin: "0 8px", width: "10px", height: "10px" }}
              src="/images/tooltip.png"
              alt="Question mark"
            />
          </button>
          <Overlay target={target2.current} show={show3} placement="right">
            {(props) => (
              <Tooltip
                id="overlay-example-3"
                {...props}
                className="umh-ca-tooltip"
              >
                <p>Will the position require the candidate to relocate </p>
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="toggle-switch" style={{ padding: "7.5px" }}>
          <input
            type="checkbox"
            id="color-checkbox"
            checked={
              !props?.keywordpress &&
              props.activeFilter.willing_relocate !== undefined &&
              props.activeFilter.willing_relocate
            }
            onChange={(e) => {
              props.filterChange({
                willing_relocate: e.target.checked ? 1 : 0,
              });
              setFilterChange(true);
            }}
          />
        </div>
      </div>
      <div className="flex-align-center  justify-content-between mt-3">
        <div className="tooltip-container" style={{ alignItems: "center" }}>
          <p className="title pos-rel">ONSITE REQUIRED?</p>

          <button
            ref={target3}
            onClick={(e) => {
              e.preventDefault();
              setshow4(!show4);
            }}
            className="btn-infotool"
          >
            <img
              style={{ margin: "0 8px", width: "10px", height: "10px" }}
              src="/images/tooltip.png"
              alt="Question mark"
            />
          </button>
          <Overlay target={target3.current} show={show4} placement="right">
            {(props) => (
              <Tooltip
                id="overlay-example-3"
                {...props}
                className="umh-ca-tooltip"
              >
                <p>
                  Will the position require the worker to be onsite, even
                  occasionally?
                </p>
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="toggle-switch" style={{ padding: "7.5px" }}>
          <input
            type="checkbox"
            id="color-checkbox"
            checked={
              !props?.keywordpress &&
              props.activeFilter.willing_remote_work !== undefined &&
              props.activeFilter.willing_remote_work
            }
            onChange={(e) => {
              props.filterChange({
                willing_remote_work: e.target.checked ? 1 : 0,
              });
              setFilterChange(true);
            }}
          />
        </div>
      </div>
      <div className="flex-align-center  justify-content-between mt-3">
        <div className="tooltip-container" style={{ alignItems: "center" }}>
          <p className="title pos-rel">TRAVEL REQUIRED?</p>

          <button
            ref={target4}
            onClick={(e) => {
              e.preventDefault();
              setshow5(!show5);
            }}
            className="btn-infotool"
          >
            <img
              style={{ margin: "0 8px", width: "10px", height: "10px" }}
              src="/images/tooltip.png"
              alt="Question mark"
            />
          </button>
          <Overlay target={target4.current} show={show5} placement="right">
            {(props) => (
              <Tooltip
                id="overlay-example-3"
                {...props}
                className="umh-ca-tooltip"
              >
                <p>
                  Will the position require the worker to travel? Local travel
                  with an expected work region is generally not considered
                  travel, but will leave that to you
                </p>
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="toggle-switch" style={{ padding: "7.5px" }}>
          {console.log("filter", props.activeFilter)}
          <input
            type="checkbox"
            id="color-checkbox"
            checked={
              !props?.keywordpress &&
              props.activeFilter.willing_travel !== undefined &&
              props.activeFilter.willing_travel
            }
            onChange={(e) => {
              props.filterChange({ willing_travel: e.target.checked ? 1 : 0 });
              setFilterChange(true);
            }}
          />
        </div>
      </div>
      <div className="d-sm-block d-md-block d-lg-none  d-xl-none w-100">
        <div className="flex-align-center  justify-content-between mt-3 mb-5 ">
          <Button
            className="cta-type-3 w-146 h-40 br-16"
            onClick={() => {
              props.setIsSideMenuOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button className="cta-type-2 w-146 h-40 br-16 disabled">Show</Button>
        </div>
      </div>
    </form>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
