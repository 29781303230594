import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  FormSelect,
  Table,
  Pagination,
} from "react-bootstrap";
import InputText from "../common/inputText/InputText";
import InputPassword from "../common/inputPassword/InputPassword";
import { getPaytmentHistory } from "../../actions/payment";

export default function Billing() {
  const [show, setShow] = useState(false);
  const [payments, setPayments] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");

  const perPage = 10;
  const handleClose = () => {
    setShow(false);
  };
  const getPayments = () => {
    getPaytmentHistory(
      {
        s: (currentPage - 1) * perPage,
        l: perPage,
        filter,
      },
      (res) => {
        if (res?.data) {
          setPayments(res.data.rows);
          setTotalPages(Math.ceil(res.data.count / perPage));
        }
      }
    );
  };
  useEffect(() => {
    getPayments();
  }, [filter, currentPage]);
  return (
    <div className="account-settings-tab ">
      <Modal
        show={show}
        onHide={handleClose}
        className="successModal addPaymenyMethodModal"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="modalbody text-center">
          <h2>Add Payment Method</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <InputText
              controlId=""
              label="cardholder's name"
              name="name"
              defaultValue="daysdasdv"
              placeholder="Enter name"
            />
            <InputText
              controlId=""
              label="card number"
              name="number"
              type="number"
              defaultValue=""
              placeholder="Enter card number"
              isMobileNumber
            />

            <Row>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <InputText
                  controlId=""
                  label="Expiration Date"
                  name="Date"
                  defaultValue=""
                  placeholder="MM/YY"
                />
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <InputPassword
                  controlId=""
                  label="Security code"
                  name="number"
                  type="number"
                  defaultValue=""
                  placeholder="CVV/CVC"
                />
              </Col>
            </Row>

            <button
              type="submit"
              onClick={handleClose}
              className="w-100 cta-type-2 h-40 btn btn-primary mx-auto"
            >
              Add payment method
            </button>
            <br></br>
          </form>
        </Modal.Body>
      </Modal>

      <form>
        <Row>
          <Row className="justify-content-between align-items-center p-0 ">
            <Col>
              <p className="title">Transactions history</p>
            </Col>
            <Col className="d-flex justify-content-md-end align-items-center custom justify-content-sm-start sort-by-row">
              Sort by:
              <FormSelect
                className="w-73"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value={""}>Most Recent</option>
                <option value={"lw"}>Last week</option>
                <option value={"lm"}>This month</option>
              </FormSelect>
            </Col>
            {!payments.length && (
              <p className="text-center">
                No records found! try changing filters.
              </p>
            )}
            <Col xs={12} className="d-block  d-sm-none">
              <Row className="table ">
                {payments.map((payment) => (
                  <Col xs={12} className="p-0 p-3" key={payment.transaction_id}>
                    <p className="strong">{payment.transaction_id}</p>
                    <div className="d-flex justify-content-around align-items-center">
                      <div className="px-0">
                        <p className="th">Date</p>
                        <p className="td">
                          {new Date(payment.createdAt).toDateString()}
                        </p>
                      </div>
                      <div className="px-0">
                        <p className="th">Card</p>
                        <p className="td">* {payment.card_number}</p>
                      </div>
                      <div className="px-0">
                        <p className="th">Amount</p>
                        <p className="td">{payment.amount}</p>
                      </div>
                      <div className="px-0">
                        <p className="th">Package</p>
                        <p className="td">
                          {payment.package_name}-{payment.package_type}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} className="d-none   d-sm-block">
              <div className="border table-container table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>Transaction</th> */}
                      <th>Package</th>
                      <th>Amount</th>
                      <th>Date</th>
                      {/* <th>Card</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment) => (
                      <tr key={payment.transaction_id}>
                        {/* <td className="strong">{payment.transaction_id}</td> */}
                        <td>
                          {payment.package_name}-{payment.package_type}
                        </td>
                        <td>{payment.amount}</td>
                        <td>{new Date(payment.createdAt).toDateString()}</td>
                        {/* <td>*{payment.card_number}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Pagination>
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <img src="/images/arrow-prev.png" alt="prev" />
              </Pagination.Prev>
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentPage(page + 1);
                  }}
                  active={currentPage === page + 1}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <img src="/images/arrow-next.png" alt="doc" />
              </Pagination.Next>
            </Pagination>
          </Row>
        </Row>
      </form>
    </div>
  );
}
