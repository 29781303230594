import React, { useState, useEffect, useContext } from "react";
import { bindActionCreators } from "redux";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import InputText from "../../common/inputText/InputText";
import InputPassword from "../../common/inputPassword/InputPassword";
import IsValidSignInData from "./validation";
import CustomButton from "../../common/button/CustomButton";
import * as helper from "../../../utility/helper/helper";
import * as utilConstants from "../../../utility/constants/utilConstants";
import { Container, Row, Col, Form, } from "react-bootstrap";
import CandidateDashboard from "../../candidate/dashboard/Dashboard";
import EmployerDashboard from "../../employer/dashboard/Dashboard";
import { useHistory, Link } from "react-router-dom";
import * as candidateActions from "../../candidate/candidateActions";
import * as employerActions from "../../employer/employerActions";
import * as lndingActions from "../lndingActions";
import * as commonActions from "../../../actions/commonActions";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
import aboutimage from "../../../assets/images/analys.svg";
import aboutshadow from "../../../assets/images/analys2.svg";
import softwave from "../../../assets/images/softwave.svg";
import amazon from "../../../assets/images/amazon.svg";
import fastcompany from "../../../assets/images/fastcompany.svg";
import logo from "../../../assets/images/header-footer-logo-New.svg";
import VerifyOtp from "../VerifyOtp";
import { GoogleLogin } from "@react-oauth/google";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "context/authContext";
import VerifyUser from "../VerifyUser";
import UploadResume from "components/candidate/onboarding/UploadResume";
import verifyemail from "../../../assets/images/verifyemail.png";
import SearchResults from "components/search-results/search-results";
export function Signin(props) {
  const { userInfo, setUserInfo,
    setVerifyUserPopUp,
    setSignupType, otpPopUp, setOtpPopUp, setVerifyResumePopUp,
    candidate, setCandidate, otpResponseMessage, setOtpResponseMessage,
    isLoading, setLoading, setIsInitialButtonLoading,
    onOtpConfirm, onChange2, subscriptionPlanId, setSubscriptionPlanId } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({});
  const [loginDataError, setLoginDataError] = useState({});
  let history = useHistory();
  const clientId = '1038011222422-j588musujaecicpvetgttbpprmsn3op6.apps.googleusercontent.com';

  const onChange = (e) => {
    let tempLoginData = { ...loginData };
    Object.assign(tempLoginData, helper.setDataAccordingInput(e));
    let errors = { ...loginDataError };
    delete errors[e.target.name];
    setLoginData(tempLoginData);
    setLoginDataError(errors);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let { isValid, errors } = IsValidSignInData(loginData);
    if (isValid) {
      setLoading(true);
      const data = subscriptionPlanId !== null ? { ...loginData, select_subscription: true, selected_plan: subscriptionPlanId } : loginData;
      lndingActions.signIn(data, (response) => {
        if (response.status === utilConstants.sucessState200) {
          if (response.data && response.data.auth_token) {
            localStorage.setItem(
              utilConstants.ACCESS_TOKEN,
              response.data.auth_token
            );
            if (response.data.user_type === utilConstants.USER_CANDIDATE) {
              localStorage.setItem("candidate_id", response.data.id);
            } else {
              localStorage.setItem("employer_id", response.data.id);
            }
            localStorage.setItem(utilConstants.LOGIN_TIME, moment().format());
            if (response.data.user_type === "Employer" && response.data.checkout_url) {
              setSubscriptionPlanId(null);
              window.location.assign(response.data.checkout_url);
            }
            props.commonActions.signinUserDetails();
            setLoading(false);
          }
          setLoading(false);
        } else if (response.status === utilConstants.inCompleteState203) {
          setLoading(false);
          setOtpPopUp(true);
        } else {
          if (response.errorType === "email") {
            setLoading(false);
            setLoginDataError((errors) => {
              return {
                ...errors,
                email: response.message,
              };
            });
          } else {
            setLoading(false);
            setLoginDataError({
              password: response.message,
            });
          }
        }
      });
    } else {
      setLoginDataError(errors);
      setLoading(false);
    }
  };

  const handleSignInWithGoogle = (userObject) => {
    const userAgent = navigator.userAgent
    setSignupType("");
    const { given_name, family_name, email, picture, jti, sub, } = userObject;
    setUserInfo({
      first_name: given_name || "",
      last_name: family_name || "",
      email: email || "",
      profile_image: picture || "",
      jti_token: jti || "",
      sub: sub || "",
      sign_in_with_google: true,
      browser_type: userAgent || "",
    });
    let usrInfo = {
      first_name: given_name || "",
      last_name: family_name || "",
      profile_image: picture || "",
      email: email,
      sign_in_with_google: true,
      browser_type: userAgent
    }
    setLoading(true);
    lndingActions.signInSignUpWithGoogle(usrInfo, (response) => {
      if (response.status === utilConstants.sucessState200) {
        if (response.data && response.data.auth_token) {
          localStorage.setItem(
            utilConstants.ACCESS_TOKEN,
            response.data.auth_token
          );
          if (response.data.user_type === utilConstants.USER_CANDIDATE) {
            localStorage.setItem("candidate_id", response.data.id);
          } else {
            localStorage.setItem("employer_id", response.data.id);
          }
          localStorage.setItem(utilConstants.LOGIN_TIME, moment().format());
          props.commonActions.signinUserDetails();
          setLoading(false);
        }
        setLoading(false);
      } else {
        setVerifyUserPopUp(true);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    let { signedUserDetail } = props.state.commonReducer;
    if (signedUserDetail) {
      setLoading(false);
      if (otpPopUp) {
        if (getAuthentionInfo().type === utilConstants.USER_EMPLOYER)
          history.push("/employer/SearchResults");
        else if (getAuthentionInfo().type === utilConstants.USER_CANDIDATE)
          history.push("/Candidate/Onboarding/WorkPermit");
        else history.push("/");
      } else if (getAuthentionInfo().type === utilConstants.USER_EMPLOYER)
        history.push("/employer/SearchResults");
      else history.push("/");
    }
  }, [props.state.commonReducer.signedUserDetail]);

  const handleCallbackResponse = (response) => {
    const userObject = jwt_decode(response.credential);
    const { email } = userObject;
    if (email) {
      handleSignInWithGoogle(userObject);
    }
  }
  const onFailure = (err) => {
    console.log('failed', err);
  };

  React.useEffect(() => {
    setOtpResponseMessage("");
  }, [otpPopUp]);

  useEffect(() => {
    const userAgent = navigator.userAgent
    setLoginData({ ...loginData, browser_type: userAgent })
  }, [])

  useEffect(() => {
    setVerifyUserPopUp(false);
    setOtpPopUp(false);
    setOtpResponseMessage();
    setVerifyResumePopUp(false);
    setIsInitialButtonLoading(false);
  }, [])

  return (
    <>
      {getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? (
        <SearchResults />
      ) : getAuthentionInfo().type === utilConstants.USER_CANDIDATE ? (
        <EmployerDashboard />
      ) : (
        <div className="signupContentWrapper">
          <div className="background">
            <div className="left"></div>
            <div className="right">
            </div>
          </div>
          <div className="content">
            <Container>
              <Row>
                <Col
                  xxl={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={12}
                  xs={12}
                >
                  <Link to="/"><img src={logo} alt="Site logo" className="signup_logo" /></Link>
                  <h1>Sign In</h1>
                  <p className="info-text">
                    Fill in the information below to sign into your account
                  </p>
                  <div className="Signup_google_dv">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign Up with Google"
                      onSuccess={handleCallbackResponse}
                      onFailure={onFailure}
                      width="500px"
                      shape="square"
                      logo_alignment="center"
                      prompt="consent"
                    />
                  </div>
                  <h6 className="signup_or">OR</h6>
                  <Form noValidate onSubmit={handleSubmit}>
                    <InputText
                      controlId="formBasicEmail"
                      label="Email Address"
                      type="email"
                      placeholder="Name@example.com"
                      name="email"
                      value={loginData.email}
                      error={loginDataError.email}
                      onChange={onChange}
                      maxLength="255"
                    />
                    <InputPassword
                      isVisible={false}
                      label="Password"
                      type="password"
                      placeholder="At least 8 characters"
                      controlId="Password"
                      name="password"
                      value={loginData.password}
                      error={loginDataError.password}
                      onChange={onChange}
                    />
                    <div
                      className="other-link right-aligned cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/forgotPassword");
                      }}
                    >
                      Forgot password?
                    </div>
                    <CustomButton
                      variant="primary"
                      type="submit"
                      className="w-100 primary-filed-orange"
                      isDisabled={!helper.IsEmpty(loginDataError)}
                      label="Sign In"
                      isLoading={isLoading}
                    />
                    <div className="other-link left_align">
                      Don&apos;t have an account?{" "}
                      <Link to={"/signup"}>Sign Up</Link>
                    </div>
                  </Form>
                </Col>
                <Col
                  xxl={{ span: 7, offset: 0 }}
                  xl={{ span: 7, offset: 0 }}
                  lg={{ span: 7, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={12}
                  xs={12}
                >
                  <div className="right_body">
                    <div className="right_title">
                      <h3>The simplest way to apply for a cybersecurity job</h3>
                      <h6>Create your profile and start applying today</h6>
                    </div>
                    <div className="analys_dv">
                      <img
                        src={aboutimage}
                        alt="Site logo"
                        className="about_image"
                      />
                      <img
                        src={aboutshadow}
                        alt="Site logo"
                        className="shadow_image"
                      />
                    </div>
                    <div className="trusty_dv_wrrpr">
                      <h5>Trusted By</h5>
                      <div className="trusty_dv">
                        <img
                          src={softwave}
                          alt="Site logo"
                          className="softwave"
                        />
                        <img src={amazon} alt="Site logo" className="amazon" />
                        <img
                          src={fastcompany}
                          alt="Site logo"
                          className="fastcompany"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <ToastContainer />
            </Container>
          </div>
          <VerifyOtp
            show={otpPopUp}
            handleClose={() => {
              setOtpPopUp(false);
              setCandidate((candidate) => {
                return {
                  ...candidate,
                  verify_code: "",
                };
              });
            }}
            modalBody={
              <>
                <img src={verifyemail} alt="Site logo" className="verify_email" />
                <p>{`An email verification code has been sent to ${candidate?.email || userInfo?.email}`}</p>
                {otpResponseMessage && (
                  <p
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {otpResponseMessage}
                  </p>
                )}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onOtpConfirm();
                  }}
                  className="signup-form"
                >
                  <InputText
                    controlId=""
                    name="verify_code"
                    onChange={(e) => {
                      if (e.target.value.length > 4) return false;
                      onChange2(e);
                    }}
                    placeholder="X-X-X-X"
                    value={candidate.verify_code}
                  />
                  <span
                    className="b-link resend_email cursor-pointer"
                    onClick={() => {
                      const requestData = userInfo.sign_in_with_google ? { ...userInfo, type: "signup" } : { ...candidate, type: "signup" };
                      lndingActions.reSendOTP(
                        requestData,
                        (response) => {
                          setOtpResponseMessage(response.message);
                        }
                      );
                    }}
                  >
                    Did not get an email? Resend
                  </span>
                  <CustomButton
                    label="Verify"
                    type="button"
                    className="w-48 cta-type-2 h-40 btn btn-primary mx-auto"
                    isDisabled={helper.IsEmpty(candidate.verify_code)}
                    onClick={(e) => {
                      e.preventDefault();
                      onOtpConfirm();
                    }}
                    isLoading={isLoading}
                  />
                </form>
              </>
            }
          />
          <VerifyUser />
          <UploadResume />
        </div>
      )}
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    lndingActions: bindActionCreators(lndingActions, dispatch),
    candidateActions: bindActionCreators(candidateActions, dispatch),
    employerActions: bindActionCreators(employerActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
