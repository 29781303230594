import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  FormControl,
  InputGroup,
  Accordion,
} from "react-bootstrap";
import { getFAQs } from "../../actions/faqs";
import { cmsPageId } from "../../utility/constants/utilConstants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContentByPageId } from "../../actions/cms";

function FAQNew({ getPageContent, state }) {
  const pageId = cmsPageId.faq;
  const content = state[pageId] || {};
  const [faqs, setFaqs] = useState([]);
  const [tab, setTab] = useState("candidate");
  const [searchText, setSearchText] = useState("");

  const loadFaqs = () => {
    getFAQs((res) => {
      setFaqs(res.data);
    });
  };
  useEffect(() => {
    loadFaqs();
  }, []);
  useEffect(() => {
    if (pageId && !Object.keys(content).length) {
      getPageContent({ page_id: pageId });
    }
  }, [pageId]);
  return (
    <div className="faq-new">
      <div className="top-panel">
        <Container className="h-100">
          <div className="background-image">
            <img src="/images/faq-banner.png" alt="FAQ banner" />
          </div>
          <div className="content">
            <Row className="h-100">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                className="h-100"
              >
                <div className="d-flex justify-content-center align-items-center h-100">
                  <h1>FREQUENTLY ASKED QUESTIONS</h1>
                </div>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="section">
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={6} className="text-center">
              <div className="text-center">
                
                <h2 className="subheading-inner">FAQs</h2>
                <p className="font-md">Find answers for all questions related MyTurn</p>

                <Tab.Container
                  id="tabs"
                  onSelect={(key) => setTab(key)}
                  activeKey={tab}
                >
                  <Nav variant="pills" className="flex-row w-75 mx-auto">
                    <Nav.Item>
                      <Nav.Link eventKey="candidate">
                        Candidate
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="employer">
                        Employer
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="candidate"></Tab.Pane>
                    <Tab.Pane eventKey="employer"></Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                <div className="d-flex mt-2 align-items-center search-panel">
                  <div className="w-100 pr-2 mt-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img alt="search" src="/images/search-dark.png"></img>
                      </InputGroup.Text>
                      <FormControl
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={content.SEARCH_INPUT_PLACEHOLDER}
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </div>
                  <a
                    onClick={(e) => e.preventDefault()}
                    href="/#"
                    className="primary-filed-orange ml-35 text-decoration-none"
                  >
                    {content.SEARCH_BUTTON_TEXT}
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              {!faqs.length ? (
                content.NO_RECORDS
              ) : (
                <>
                  {" "}
                  {!faqs.filter(
                    (f) => f.type === tab && f.question.includes(searchText)
                  ).length && content.NO_FILTERED_RECORDS}
                  <Accordion>
                    {faqs
                      .filter(
                        (f) => f.type === tab && f.question.includes(searchText)
                      )
                      .map((faq, index) => (
                        <Accordion.Item eventKey={faq.id} key={faq.id}>
                          <Accordion.Header>
                            
                            <b>
                              {index + 1}.&nbsp;&nbsp;&nbsp;&nbsp;{faq.question}
                            </b>
                            
                          </Accordion.Header>
                          <Accordion.Body>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    getPageContent: bindActionCreators(getContentByPageId, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state.cmsReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FAQNew);
