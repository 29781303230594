import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import InputSelect from "./../../common/select/InputSelect";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CandidateCard from "./CandidateCard";
import * as employerActions from "./../employerActions";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import * as utilConstants from "./../../../utility/constants/utilConstants";
import * as commonActions from "./../../../actions/commonActions";
import Pagination from "./../../common/pagination/Pagination";
import { IsEmpty } from "../../../utility/helper/helper";
import { getAuthentionInfo } from "./../../../utility/helper/authenticationHelper";
import CustomeModel from "../../common/model/CustomeModel";
import EditModal from "../../common/model/EditModal";
import MultiInputSelect from "components/common/select/MultiInputSelect";
export default function SavedCandidate() {
  const [projects, setProjects] = useState([]);
  const [deleteid, setdeleteid] = useState(""); 
  const [editedid, seteditedid] = useState(""); 
  const [project, setProject] = useState([]);
  const [projectname, setProjectname] = useState(""); 
  const [edit, setEdit] = useState(false);
  const [deletebutton, setDeleteButton] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [edited, setEdited] = useState(false); 
  const [count, setCount] = useState({
    savedCandidate: 0,
    ShortedCandidate: 0,
    InterviewedCandidate: 0,
    JobAppliedCandidate: 0,
  });
  const [savedCandidate, setSavedCandidate] = useState([]);
  const [shortCandidate, setShortCandidate] = useState([]);
  const [shortType, setShortType] = useState(0);
  const [tab, setTab] = useState(4);
  const [interviewedCandidate, setInterviewedCandidate] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [value, setValue] = useState("");

  const handledelete = () => {
    employerActions.removeProject(
      {
        id: deleteid,
      },
      (response) => {
        if (response.status === utilConstants.sucessState200)
          ReactNotifications(
            "Project Delete successfully",
            utilConstants.getSuccessStatus
          );
        setDeleted(true);

      }
    );
  };
  useEffect(() => {
    if (deleted || edited) {
      commonActions.getProject({}, (res) => {
        if (res.status === utilConstants.sucessState200) {
          setProjects(res.data.rows);
        } else ReactNotifications(res.message, utilConstants.getErrorStatus);
      });
      setDeleteButton(false);
      setProject([]);
      setSavedCandidate([]);
    }
    return () => {
      setDeleted(false);
      setEdited(false);
    };
  }, [deleted, edited]);
  const getCandidate = (job_id = project, type = tab, sort_by = shortType) => {
    if (!IsEmpty(job_id))
      employerActions.savedCandidate(
        { S: 0, l: 5, job_profile_id: job_id, type, sort_by },
        (response) => {
          if (response.status === utilConstants.sucessState200) {
            setCount({
              SavedCandidate: response.counts.SavedCandidate,
              ShortedCandidate: response.counts.ShortedCandidate,
              InterviewedCandidate: response.counts.InterviewedCandidate,
              JobAppliedCandidate: response.counts.JobAppliedCandidate,
            });
            let tempSaved = [],
              tempShorted = [],
              tempInterviewd = [],
              tempJobAppliedCandidate = [];
            response.data.map((candidate) => {
              switch (type) {
                case 1:
                  tempSaved.push({ ...candidate, saved_id: candidate.id });
                  break;
                case 2:
                  tempShorted.push({
                    ...candidate,
                    saved_id: candidate.id,
                  });
                  break;
                case 3:
                  tempInterviewd.push({
                    ...candidate,
                    saved_id: candidate.id,
                  });
                  break;
                default:
                  tempJobAppliedCandidate.push({
                    ...candidate,
                    saved_id: candidate.id,
                  });
                  break;
              }
            });
            setAppliedJobs(tempJobAppliedCandidate);
            setSavedCandidate(tempSaved);
            setShortCandidate(tempShorted);
            setInterviewedCandidate(tempInterviewd);
          } else
            ReactNotifications(response.message, utilConstants.getErrorStatus);
        }
      );
  };
  const changeStatus = (opt, saved_id, savedCad_id) => {
    switch (opt) {
      case "Short Listed":
        employerActions.candidateChangeStatus(
          {
            savedCad_id,
            saved_id,
            status: "shorted",
          },
          (response) => {
            if (response.status === utilConstants.sucessState200)
              getCandidate(project);
          }
        );
        break;
      case "Interviewed":
        employerActions.candidateChangeStatus(
          {
            savedCad_id,
            saved_id,
            status: "interviewed",
          },
          (response) => {
            if (response.status === utilConstants.sucessState200)
              getCandidate(project);
          }
        );
        break;
      case "Saved":
        employerActions.candidateChangeStatus(
          {
            savedCad_id,
            saved_id,
            status: "saved",
          },
          (response) => {
            if (response.status === utilConstants.sucessState200)
              getCandidate(project);
          }
        );
        break;
      case "Applied Jobs":
        employerActions.candidateChangeStatus(
          {
            savedCad_id,
            saved_id,
            status: "candidateJobApplied",
          },
          (response) => {
            if (response.status === utilConstants.sucessState200)
              getCandidate(project);
          }
        );
        break;
      case "remove":
        employerActions.removeCandidate(
          {
            savedCad_id: savedCad_id,
            id: saved_id,
            job_profile_id: project,
            status: "removed",
          },
          (response) => {
            if (response.status === utilConstants.sucessState200)
              getCandidate(project);
          }
        );
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getCandidate(project);
  }, [shortType, project]);
  const onTabChange = (tab) => {
    switch (tab) {
      case "Applied Jobs":
        getCandidate(project, 4);
        setTab(4);
        break;
      case "Saved candidates":
        getCandidate(project, 1);
        setTab(1);
        break;
      case "Short Listed Candidates":
        getCandidate(project, 2);
        setTab(2);
        break;
      default:
        getCandidate(project, 3);
        setTab(3);
        break;
    }
  };
  const handleEditButton = () => {
    setEdit(false);
    // setProjectname('')
  };
  const handleUpdate = () => {
    console.log("update");
    ReactNotifications(
      "Project Update successfully",
      utilConstants.getSuccessStatus
    );
    setEdit(false);
    setEdited(true);
  };
  useEffect(() => {
    if (project.length > 0) {
      let chooseproject = projects.filter((pro) => pro.id == project);
      setProjectname(chooseproject[0]?.name);
      console.log("projectedi5t", project, chooseproject);
    }
  }, [project]);

  return (
    <div className="dashboardPageWrapper">
      <Container>
        <Row>
          <Col>
            <div className="contentBox">
              <div className="personInfoHeader publicProfileHeaderDesktop align-items-center">
                <div className="column2">
                  <h4 className="headerText font-bold m-0">Candidates List</h4>
                </div>
              </div>
              <div className="personInfoHeader publicProfileHeaderMobile">
                <div className="topRow">
                  <div className="column2">
                    <h4 className="headerText font-bold m-0">
                      Candidates List
                    </h4>
                  </div>
                </div>
              </div>
              <div className="innerBlock">
                <form>
                  <Row className="align-items-center">
                    <Col xxl={4} xl={3} lg={3} md={3} sm={12} xs={12}>
                      <MultiInputSelect
                        type="dropdownlist"
                        label="Job"
                        options={[]}
                        placeholder=""
                        loadOptions={(value, callback = () => {}) => {
                          if (value)
                            candidateActions.getPosition(
                              { st: value },
                              (response) => {
                                if (
                                  response.status ===
                                  utilConstants.sucessState200
                                ) {
                                  let newOptions = response.data.map((v) => {
                                    options.push({
                                      label: v,
                                      value: v,
                                    });
                                  });
                                  setOptions(newOptions);
                                  callback(newOptions);
                                }
                              }
                            );
                        }}
                        onSelect={(value) => {
                          if (value?.value?.length > 255) return;
                          commonActions.jobProfileData(
                            {
                              jobTitles: value?.value,
                            },
                            (res) => {
                              if (res.status === utilConstants.sucessState200) {
                                getCandidate(res?.data?.id);
                                setProject(res?.data?.id);
                                setValue(value?.value);
                              } else {
                                ReactNotifications(
                                  res.msg,
                                  utilConstants.getErrorStatus
                                );
                              }
                            }
                          );
                        }}
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                        value={
                          value > ""
                            ? [
                                {
                                  value: value,
                                  label: value,
                                },
                              ]
                            : []
                        }
                      />
                    </Col>
                    {project?.length > 0 && (
                      <Col xxl={1} xl={1} lg={3} md={3} sm={12} xs={12}>
                        <div
                          className="margin-equal"
                          onClick={() => setEdit(true)}
                        >
                          <img src="/images/pen-outline.png"></img>
                        </div>
                        <div
                          className="margin-equal"
                          onClick={() => {
                            setDeleteButton(true), setdeleteid(project);
                          }}
                        >
                          <img src="/images/delete-outline.png"></img>
                        </div>
                      </Col>
                    )}

                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      className="align-right"
                    >
                      <InputSelect
                        label="Sorting"
                        options={[
                          { key: "Highest salary", value: 0 },
                          { key: "Low salary", value: 1 },
                          { key: "Available soon", value: 2 },
                        ]}
                        optionLable="key"
                        optionValue="value"
                        onChange={(e) => {
                          setShortType(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </form>
              </div>

              <div className="innerBlock">
                <Tabs
                  defaultActiveKey="Applied Jobs"
                  id="uncontrolled-tab-example"
                  className="mb-4"
                  onSelect={(e) => onTabChange(e)}
                >
                  <Tab eventKey="Applied Jobs" title="Applied Jobs">

                    {appliedJobs?.length > 0
                      ? appliedJobs.map((candidate, k) => {
                          return (
                            <CandidateCard
                              key={k}
                              user={{
                                ...candidate,
                                markAsA: [
                                  "Saved",
                                  "Interviewed",
                                  "Short Listed",
                                ],
                              }}
                              changeStatus={changeStatus}
                              type="Applied Jobs"
                            />
                          );
                        })
                      : "No Applied candidate found"}
                  </Tab>
                  <Tab eventKey="Saved candidates" title="Saved candidates">
                    <>
                      {savedCandidate?.length > 0
                        ? savedCandidate.map((candidate, k) => {
                            return (
                              <CandidateCard
                                key={k}
                                user={{
                                  ...candidate,
                                  markAsA: [
                                    "Short Listed",
                                    "Interviewed",
                                    "Applied Jobs",
                                  ],
                                }}
                                changeStatus={changeStatus}
                                type="Saved"
                              />
                            );
                          })
                        : "No candidate found"}
                      <Pagination totalItems={count.savedCandidate} />
                    </>
                  </Tab>
                  <Tab
                    eventKey="Short Listed Candidates"
                    title="Short Listed Candidates"
                  >
                    {shortCandidate?.length > 0
                      ? shortCandidate.map((candidate, k) => {
                          return (
                            <CandidateCard
                              key={k}
                              user={{
                                ...candidate,
                                markAsA: [
                                  "Saved",
                                  "Interviewed",
                                  "Applied Jobs",
                                ],
                              }}
                              changeStatus={changeStatus}
                              type="Short Listed"
                            />
                          );
                        })
                      : "No candidate found"}
                  </Tab>
                  <Tab
                    eventKey="Interviewed Candidates"
                    title="Interviewed Candidates"
                  >
                    {interviewedCandidate?.length > 0
                      ? interviewedCandidate.map((candidate, k) => {
                          return (
                            <CandidateCard
                              key={k}
                              user={{
                                ...candidate,
                                markAsA: [
                                  "Saved",
                                  "Short Listed",
                                  "Applied Jobs",
                                ],
                              }}
                              changeStatus={changeStatus}
                              type="Interviewed"
                            />
                          );
                        })
                      : "No candidate found"}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
        {edit && (
          <EditModal
            show={edit}
            onHide={() => handleEditButton()}
            id={project}
            name={projectname}
            onupdate={() => handleUpdate()}
          />
        )}
        {deletebutton && (
          <CustomeModel
            negativeButtonLable="No"
            positiveButtonLable="Yes"
            show={deletebutton}
            onNegative={(e) => {
              e.preventDefault();
              setDeleteButton(false);
            }}
            modalBody={
              <>
                <p>
                  <b>Confirmation !</b>
                </p>
                <p>Are you sure you want to delete this project?</p>
              </>
            }
            modalType="Positive/Negative"
            headerImage={"/images/membership.png"}
            handleClose={() => setDeleteButton(false)}
            onPositive={handledelete}
          ></CustomeModel>
        )}
      </Container>
    </div>
  );
}
