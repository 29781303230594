import React, { useEffect, useState } from "react";
import InputRadio from "./../candidate/onboarding/common/InputRadio";
import CustomButton from "../common/button/CustomButton";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import Stripe from "../common/stripe/stripe";

export default function Subscription({
  onSubmit = () => { },
  packages = [],
  role_id,
  onSucess,
  selectedPackage,
  designtype = () => { }
}) {
  const [subscriptionPackage, setSubscriptionPackage] = useState(null);
  console.log("subscription", subscriptionPackage);
  var selectedPackageDetails = packages.find((pkg) => pkg.id == subscriptionPackage) || {};
  console.log("package", packages,selectedPackageDetails,subscriptionPackage);
  useEffect(() => {
    if (selectedPackage) {
      setSubscriptionPackage(selectedPackage);
    }
  }, [selectedPackage]);
  useEffect(() => {
    if(packages.length =="1"){
    setSubscriptionPackage(packages[0].id)
    designtype("")
    }
  }, [packages]);
  return (
    <div className="subscription">

      <Container>
        {subscriptionPackage === null &&
          <Row className='pricingRow '>
            {packages.map((packageDetails) => (
              <Col
                xxl={4}
                xl={4}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                key={packageDetails.id}
                className="pricing-col"
              >
               
                <Card className="pricingCard">
                  <Card.Body>
                    <Card.Title>{packageDetails.title}</Card.Title>
                    <Card.Text>
                      {" "}
                      {packageDetails.view_requests} View requests
                    </Card.Text>
                    <label className="price-label">
                      <strong>${packageDetails.price}</strong>
                    </label>
                  </Card.Body>

                  <Button
                    onClick={() => {
                      setSubscriptionPackage(packageDetails.id)
                      designtype("")
                    }}
                    className="primary-filed-orange text-decoration-none mt-40"
                  >
                    Get Started
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        }
        {subscriptionPackage !== null && <>
          <InputRadio
            value={subscriptionPackage}
            name="VisaSponsorship"
            listOfOption={[
              ...packages.filter((item) => item.id === subscriptionPackage).map((p) => ({
                ...p,
                value: p.id,
                label: `${p.title} ${role_id !== 3
                  ?
                  `(${p.view_requests} Contact Credits)` :  `(${p.validity} months)`
                  }  `,
              })),
            ]}
            onChange={(e) => {
              setSubscriptionPackage(e.target.value);
            }}
          />
          <h2>Total:<em>${selectedPackageDetails.price}.00</em> </h2>
          {subscriptionPackage && (
            <>
              <Stripe
                selectedPackageDetails={selectedPackageDetails}
                packageId={subscriptionPackage}
                onSucess={onSucess}
              ></Stripe>
            </>
          )}
          {subscriptionPackage === "Free" && (
            <CustomButton
              type="button"
              className="cta-type-2 w-360 h-40 br-16 mx-auto mt-3"
              onClick={(e) => {
                e.preventDefault();
                onSubmit(subscriptionPackage);
              }}
              label="Finish"
            />
          )}
        </>}

      </Container>
    </div>
  );
}
