import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Container,
  Row,
  Col,
  Carousel,
  Card,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import {
  cmsPageId,
} from '../../utility/constants/utilConstants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContentByPageId } from '../../actions/cms';
import { getPackageList } from '../../actions/account';
import { Link } from 'react-router-dom';

function EmployerNew({ getPageContent, state }) {
  const pageId = cmsPageId.employer;
  const content = state[pageId] || {};
  const [index, setIndex] = useState(0);
  const [packages, setPackages] = useState([]);
  console.log("packages", packages);
  useEffect(() => {
    getEmployerPackages();
  }, []);
  const getEmployerPackages = () => {
    getPackageList(
      {
        role: 4,
      },
      (res) => {
        if (res?.data) {
          setPackages(res.data.rows.filter((item) => item.title !== "Free"));
        }
      }
    );
  };
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    if (pageId && !Object.keys(content).length) {
      getPageContent({ page_id: pageId });
    }
  }, [pageId]);
  return (
    <div className='mainPageWrapper'>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className='mainPageBanner'
        controls='false'>
        <Carousel.Item>
          <img
            className='d-block w-100 banner-img'
            src='/images/baner-img-employer.png'
            alt='cybersecurity experts for hire'
          />
          <Carousel.Caption>
            <Container>

              <h1>
                Cybersecurity Recruiting... <br></br>Simplified.
              </h1>
              <h2 className='description mb-40'>
                Join our network of Cyber Recruiters to source the right kind of talent!</h2>

              <Link
                to='/signup?type=employer'
                className='primary-filed-orange text-decoration-none'>
                Get started
              </Link>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className='section howItWorks'>
        <Container>
          <h2 className='section-heading text-center'>
            Gain access to an excellent talent pool of <br></br>
            <span className='color-orange'>
              cyber security professionals...
            </span>
          </h2>
          <p className='description text-center mx-auto'>
            {content.SECTION1_DESCRIPTION}
          </p>

          <Row className='guidelineRow justify-content-center mt-40'>
            <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
              <Card className='infoBoxPortrait'>
                <Card.Img variant='top' src='/images/create-account.png' />
                <Card.Body className='text-center'>
                  <Card.Title>Create account</Card.Title>
                  <Card.Text>Sign up to get the ball rolling.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
              <Card className='infoBoxPortrait'>
                <Card.Img variant='top' src='/images/search.png' />
                <Card.Body className='text-center'>
                  <Card.Title>Search profiles</Card.Title>
                  <Card.Text>Find and select your perfect hire.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
              <Card className='infoBoxPortrait'>
                <Card.Img variant='top' src='/images/get-selected.png' />
                <Card.Body className='text-center'>
                  <Card.Title>Select candidate</Card.Title>
                  <Card.Text>Start interviewing.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className='mt-40 centered'>
            <a
              href='/signup?type=employer'
              className='primary-filed-orange text-decoration-none margin_auto'>
              Sign up now
            </a>
          </div>
        </Container>
      </div>

      <div className='connect-with-companies'>
        <div className='background'>
          <img src='/images/bg-3.png' />
        </div>
        <Container>
          <Row>
            <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={12} className='left'>
              <img src='/images/connect-img-2.png' alt="connect with MyTurn" />
            </Col>
            <Col
              xxl={7}
              xl={7}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className='right'>

              <h3 className='section-heading'>
                Use our advanced search features to find the{' '}
                <span className='color-green'>
                  right cybersecurity profiles...
                </span>
              </h3>
              <p className='description'>
                <span className='color-orange'>Discover the power</span> of our
                database for your search needs!
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section whyChooseUs'>
        <Container>

          <h3 className='section-heading text-center'>
            MyTurn brings you a step closer to the <br></br>
            <span className='color-orange'>right candidate...</span>
          </h3>
          <Row className='guideLineRow mt-40'>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card className='infoBoxLandscape'>
                <Card.Img variant='top' src='/images/automated.png' />
                <Card.Body>
                  <Card.Title>
                    {content.SECTION3_SUBSECTION1_HEADING}
                  </Card.Title>
                  <Card.Text>
                    {content.SECTION3_SUBSECTION1_DESCRIPTION}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card className='infoBoxLandscape'>
                <Card.Img variant='top' src='/images/search.png' />
                <Card.Body>
                  <Card.Title>
                    {content.SECTION3_SUBSECTION2_HEADING}
                  </Card.Title>
                  <Card.Text>
                    {content.SECTION3_SUBSECTION2_DESCRIPTION}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className='getStartedBanner'>
            <div className='content'>
              <div className='background'>
                <img src='/images/bg-4.png' alt='background' />
              </div>

              <h3 className='section-heading'>
                Need help with you cyber security job requirements?
              </h3>
              <div className='w-760 centered'>
                <a href='#' className='primary-filed-orange text-decoration-none margin_auto'>
                  {content.SECTION4_BUTTON_TEXT}
                </a>
              </div>
              <div className='content-right'>
                <img src='/images/candidate-2.png' alt='cybersecurity candidates' />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className='section our-pricing'>
        <Container>

          <h3 className='section-heading text-center'>
            Join MyTurn to take the next step in meeting <br></br>your cyber
            security staffing needs...
            <span className='color-green'>today!</span>
          </h3>
          <p className='description text-center mx-auto'>
            {content.SECTION5_DESCRIPTION}
          </p>

          <Row className='pricingRow mt-40'>
            {packages.map((packageDetails) => (
              <Col
                xxl={4}
                xl={4}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                key={packageDetails.id}
                className="pricing-col"
              >

                <Card className="pricingCard">
                  <Card.Body>
                    <Card.Title>{packageDetails.title}</Card.Title>
                    <Card.Text>
                      {" "}
                      {packageDetails.view_requests} View requests
                    </Card.Text>
                    <label className="price-label">
                      <strong>${packageDetails.price}</strong>
                    </label>
                  </Card.Body>
                  <div className='centered'>
                  <Link
                    to='/employer/account-settings/membership'
                    className="primary-filed-orange text-decoration-none mt-40"
                  >
                    {content.PACKAGE_BUTTON_TEXT}
                  </Link>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    getPageContent: bindActionCreators(getContentByPageId, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state.cmsReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployerNew);
