import { combineReducers } from "redux";

import commonReducer from "./commonReducer";
import lndingReducer from "./../components/landing/lndingReducer";
import employerReducer from "./../components/employer/employerReducer";
import onBoardingReducer from "./../components/candidate/onboarding/onBoardingReducer";
import cmsReducer from "./cms";

const rootReducer = combineReducers({
  commonReducer,
  lndingReducer,
  onBoardingReducer,
  employerReducer,
  cmsReducer,
});
export default rootReducer;
