import * as typesConstants from "../../../utility/constants/typesConstants";
import { updateReducer } from "./../../../utility/helper/helper";
import { async } from "./../../../utility/config/api/index";

/**
 *
 * @param {'provide user id'} userId
 * @description {'return user detail'}
 */
export function uploadResume(data) {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.ONBOARDING_UPLOAD_RESUME, data));
  };
}
export function candidateBoarding(data, callBack = () => {}) {
  async("frontend/candidate/boarding", "PUT", data).then((response) => {
    callBack(response);
  });
}
