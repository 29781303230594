import React from "react";
import { Col } from "react-bootstrap";
import DOMPurify from 'dompurify';
import CustomDateFormat from "utility/CustomDateFormat";
import location from '../../../assets/images/locatuion.svg';
import jobtype from '../../../assets/images/fulltime.svg';
import shift from '../../../assets/images/shift.svg';
import onsite from '../../../assets/images/onsite.svg';
import travel from '../../../assets/images/travel.svg';
import status from "../../../assets/images/ClockCountdown.svg";
import government from '../../../assets/images/travel.svg';
import hiring from '../../../assets/images/hiring.svg';
import TimeAgo from "components/common/postedDateFunctionality/TimeAgo";
export default function MyJobDetail({ job }) {
  return (
    <>
      <Col
        xxl={{ span: 8 }}
        xl={{ span: 8 }}
        lg={{ span: 8 }}
        md={8}
        sm={12}
        xs={12}
      >
        {job && (
          <div className="right-job-dv">
            <div className="job_detail_data_emp">
              <div className="right_dv_wrrpr emp_job_detail">
                <div className="left_dv_data_wrrpr_emp">
                  <h3 className="left_dv_title"> {job.job_title}{" "}
                  </h3>
                  <div className="left_dv_data">
                    {job.company && (
                      <div className="company_logo2">
                        {job?.company_logo_url ? <img src={job?.company_logo_url} className="company-avatar-detail" />
                          : <div className="company-avatar-detail">{job.company.charAt(0)}</div>}
                        <h4 className="company">{job.company} </h4>{" "}
                        {job.job_status && (
                          <div>
                            <img height={25} width={25} src={status} />
                            <strong>Job Status :</strong>{" "}{" "}
                            <span>
                              {job.job_status === "1"
                                ? "Active"
                                : job.job_status === "0"
                                  ? "Suspended"
                                  : job.job_status === "2"
                                    ? "Expired"
                                    : ""
                              }
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="left_dv_innr_wrrpr">
                      {job.job_type && (
                        <div className="right_dv_ineer_data">
                          <img src={jobtype} />
                          <p>{job.job_type.replace("-", " ")}{" "}
                          </p>
                        </div>
                      )}
                      {job.job_location && (
                        <div className="right_dv_ineer_data">
                          <img src={location} />
                          <p>{job.job_location}{" "}
                          </p>
                        </div>
                      )}
                      {job.job_shift && (
                        <div className="right_dv_ineer_data">
                          <img src={shift} />
                          <p> {job.job_shift.replace("-", " ")}{" "}
                          </p>
                        </div >
                      )}
                      {job.required_onsite && (
                        <div className="right_dv_ineer_data"><img src={onsite} />
                          <p>
                            Required Onsite: {job.required_onsite}{" "}
                          </p>
                        </div>
                      )}
                      {job.required_travel && (
                        <div className="right_dv_ineer_data"><img src={travel} />
                          <p>
                            Required Travel: {job.required_travel}
                          </p>
                        </div>
                      )}
                      {job.required_clearance && (
                        <div className="right_dv_ineer_data"><img src={government} />
                          <p>
                            Government Clearance:{" "}
                            {job.required_clearance.replace("-", " ")}{" "}
                          </p>
                        </div>
                      )}
                      {job.hiring_timeframe && (
                        <div className="right_dv_ineer_data"><img src={hiring} />
                          <p>
                            Hiring Timeframe:{" "}
                            {CustomDateFormat(job.hiring_timeframe)}{" "}
                          </p>
                        </div>
                      )}

                    </div>
                  </div>
                  <div className="pay_range_btn_wrrp">
                    <p className="posted_dv"><TimeAgo createdAt={job.created_at} /></p>
                    {(job?.minimum_pay || job?.maximum_pay) && (
                      <h3 className="pay_range"> {" "}

                        ${job?.minimum_pay.toLocaleString()} - ${job?.maximum_pay.toLocaleString()}{" "}
                        {" "}
                      </h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="left_dv_data_wrrpr_emp">
                <div className="description">
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.job_description) }} />


                </div>
              </div>
            </div>
          </div>
        )}
      </Col>
    </>
  );
}
