import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

export default function PaginatedItems({
  itemsPerPage = 4,
  onChange = () => {},
  totalItems = 0,
}) {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalItems;
    onChange(event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        nextLabel={<img src="/images/arrow-next.png" alt="doc" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<img src="/images/arrow-prev.png" alt="prev" />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
