import React from 'react';

const ProfilePercentageCalculation = ({ candidateDetails }) => {
    const completionCriteria = {
        "Personal Info": ["first_name", "last_name", "zip", "current_address", "contact_preference", "degree"],
        "Authorization": ["is_us_authorized_emp", "need_visa_sponsorship"],
        "Preferences": ["job_shift_type", "desired_employment"],
        "Job Histories": ["JobHistories"],
        "User Skill": ["UserSkill"],
        "Education": ["UserEducations"]
    };

    const isFieldFilled = (field) => {
        const value = candidateDetails[field];
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return !!value;
    };

    const calculateSectionCompletion = (criteria) => {
        return criteria.every(isFieldFilled) ? 100 / Object.keys(completionCriteria).length : 0;
    };

    const calculateOverallCompletion = () => {
        let totalCompletionPercentage = 0;
        const totalSections = Object.keys(completionCriteria).length;

        for (const section in completionCriteria) {
            const sectionCompletion = calculateSectionCompletion(completionCriteria[section]);
            totalCompletionPercentage += sectionCompletion;
        }

        return totalCompletionPercentage;
    };

    const overallCompletion = calculateOverallCompletion();

    console.log(overallCompletion.toFixed(0));

    return (
        <>
            {overallCompletion.toFixed(0) !== "100" && <div>
                <h4 className="text-end">Complete The Profile</h4>
                {/* <p className="text-end">To unlock the opportunity<br />
                    to apply new jobs</p> */}
            </div>}
            {overallCompletion.toFixed(0) !== "100" && <div role="progressbar" aria-valuenow={overallCompletion.toFixed(0)} aria-valuemin="0" aria-valuemax="100" style={{ '--value': overallCompletion.toFixed(0) }}>
            </div>}
        </>
    );
};

export default ProfilePercentageCalculation;
