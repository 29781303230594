import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Pagination } from "react-bootstrap";
import CandidateList from "./CandidateList";
import InputSelect from "../../common/select/InputSelect";
import CustomButton from "../../common/button/CustomButton";
import { useHistory } from "react-router-dom";
import * as employerActions from "./../employerActions";
export default function SavedCandidate() {
  const [actiePage, setActivePage] = useState(1);
  const history = useHistory();
  useEffect(() => {
    employerActions({}, (response) => {
      console.log(response);
    });
  }, []);
  return (
    <div className="dashboardPageWrapper saved-candidate">
      <Container>
        <Row>
          <Col>
            <h2 className="section-heading-2">
              <div
                className="d-flex align-items-center"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                <img src="/images/back.png" alt="back" />
                Saved candidates
              </div>
            </h2>
            <div className="contentBox">
              <div className="personInfoHeader publicProfileHeaderDesktop align-items-center d-block">
                <div className="infoBlock w-100">
                  <div className="d-flex">
                    <div>
                      <h3>Part time design assistant </h3>
                      <p>New York City, New York, 10201</p>
                      <h4>Local candidates only</h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="salaryColumnDesktop">
                      <h3>
                        $120,000.00 <span>/ year</span>
                      </h3>
                      <p>Start in 2-3 months</p>
                      <h4>Part time employment</h4>
                    </div>

                    <img
                      src={"/images/kebab.png"}
                      className="options"
                      alt="active"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="innerBlock pb-4">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&apos;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen
                </p>
              </div>

              <div className="seperator"></div>
              <div className="innerBlock">
                <h3>Personal information</h3>

                <Row>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/degree.png" alt="telephone" />
                      </div>
                      <div className="data-row">
                        Bachelor&apos;s Degree graduated
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/shift.png" alt="email" />
                      </div>
                      <div className="data-row">
                        Morning, Noon, Evening, Night shifts
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/sponsor.png" alt="link" />
                      </div>
                      <div className="data-row ">Sponsorship Required</div>
                    </div>
                  </Col>

                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/agent.png" alt="telephone" />
                      </div>
                      <div className="data-row">FBI Agent</div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/desktop.png" alt="email" />
                      </div>
                      <div className="data-row">Remote work preference</div>
                    </div>
                  </Col>

                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/house.png" alt="telephone" />
                      </div>
                      <div className="data-row">Willing to travel</div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/prize.png" alt="email" />
                      </div>
                      <div className="data-row">
                        Able to work in hazardous conditions
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="info-row-with-icon">
                      <div className="icon-row">
                        <img src="/images/leaderboard.png" alt="link" />
                      </div>
                      <div className="data-row">
                        Able to work lifting heavy items
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="seperator"></div>

              <div className="innerBlock">
                <h3>Skills</h3>

                <ul className="indicatorLabels">
                  <li>
                    <label className="bg-light-green text-green">
                      Capacity for improvement
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-orange text-orange">
                      Critical thinking
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-orange-2 text-light-orange">
                      Cultural competence
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-green-2 text-light-green">
                      Ethical responsibility to self and others
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-violet text-violet">
                      Human behavior
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-sky text-sky">
                      Living systems
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-violet text-violet">
                      Oral communication
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-blue text-blue">
                      Quantitative reasoning
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-orange text-orange">
                      Reliability and dependability
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-orange-2 text-light-orange">
                      2-3, 3+ months
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-sky text-sky">
                      Remote work preference
                    </label>
                  </li>
                  <li>
                    <label className="bg-light-green-2 text-light-green">
                      Willingness to travel
                    </label>
                  </li>
                </ul>

                <div className="seperator"></div>
              </div>
              <div className="personInfoHeader publicProfileHeaderMobile">
                <div className="topRow">
                  <div className="column1">
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/employer/PublicProfile");
                      }}
                      className="userpic"
                      src="/images/userpic2.png"
                      alt="user"
                    />
                  </div>
                  <div className="column2">
                    <label className="row1 d-flex align-items-center">
                      Mary P.
                      <span className="status active">
                        <img src="/images/rocket-active.png" alt="active" />
                        Active
                      </span>
                    </label>
                    <label className="row2">(542) 710-6018</label>
                    <label className="row3">pittman@gmail.com</label>
                  </div>
                  <div className="column3"></div>
                </div>
                <div className="bottomRow">
                  <div className="column3">
                    <div>
                      <label className="row1">
                        $120,000.00 / <span className="font-regular">year</span>
                      </label>
                      <label className="row2">Available in 1 month</label>
                      <label className="row3">Part time employment</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innerBlock">
                <form>
                  <Row className="align-items-end">
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                      <InputSelect
                        label="Labels"
                        options={["All (10)"]}
                        value={0}
                      />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                      <InputSelect
                        label="Sorting"
                        options={["Highest salary"]}
                        value={0}
                      />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                      <InputSelect
                        label="salary"
                        options={["Per year"]}
                        value={0}
                      />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                      <CustomButton
                        className="cta-type-3 w-100 h-40 br-16 mx-auto mt-3"
                        label="View"
                      />
                    </Col>
                  </Row>
                </form>
              </div>

              <div className="innerBlock">
                <Tabs
                  defaultActiveKey="Saved candidates"
                  id="uncontrolled-tab-example"
                  className="mb-4"
                >
                  <Tab eventKey="Saved candidates" title="Saved candidates">
                    {[1, 2, 3].map((candidate, k) => {
                      return (
                        <CandidateList
                          key={k}
                          candidate={candidate}
                          markAsA={["Archived", "Selected"]}
                        />
                      );
                    })}

                    <Pagination
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                      onSelect={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Pagination.Prev>
                        <img src="/images/arrow-prev.png" alt="prev" />
                      </Pagination.Prev>
                      <Pagination.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(1);
                        }}
                        active={actiePage === 1}
                      >
                        {1}
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(2);
                        }}
                        active={actiePage === 2}
                      >
                        {2}
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(3);
                        }}
                        active={actiePage === 3}
                      >
                        {3}
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(4);
                        }}
                        active={actiePage === 4}
                      >
                        {4}
                      </Pagination.Item>
                      <Pagination.Ellipsis />
                      <Pagination.Next>
                        <img src="/images/arrow-next.png" alt="doc" />
                      </Pagination.Next>
                    </Pagination>
                  </Tab>
                  <Tab
                    eventKey="Archived candidates"
                    title="Archived candidates"
                  >
                    {[{ isArchived: true }, { isArchived: true }].map(
                      (candidate, k) => {
                        return (
                          <CandidateList
                            key={k}
                            candidate={candidate}
                            markAsA={["Saved", "Selected"]}
                          />
                        );
                      }
                    )}
                  </Tab>
                  <Tab
                    eventKey="Selected candidates"
                    title="Selected candidates"
                  >
                    {[1, 2].map((candidate, k) => {
                      return (
                        <CandidateList
                          key={k}
                          candidate={candidate}
                          markAsA={["Saved", "Archived"]}
                        />
                      );
                    })}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
