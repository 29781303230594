import React, { useState } from 'react';
import * as helper from '../../../utility/helper/helper';
import { useHistory } from 'react-router-dom';
import InputRadio from './common/InputRadio';
import CustomButton from '../../common/button/CustomButton';
import OnBoarding from './OnBoarding';
export default function VisaSponsorship(props) {
  let history = useHistory();
  const [requireVisaSponsorship, setRequireVisaSponsorship] = useState();
  const onSubmit = () => {
    if (props.location.state)
      history.push({
        pathname: '/Candidate/Onboarding/UploadResume',
        state: {
          is_us_authorized_emp: props.location.state.is_us_authorized_emp,
          need_visa_sponsorship: requireVisaSponsorship,
        },
      });
  };
  return (
    <OnBoarding cureentStep={2} previousStep='WorkPermit'>
      <h2 className='section-heading-2 text-center mx-auto mw-552'>
        Will you now or in the future require the hiring company to commence
        (&quot;sponsor&quot;) an immigration case in order to employ you ?
      </h2>
      <p className='text-center mx-auto mw-552 mb-4'>e.g., H-1B visa status</p>
      <div className='text-center'>
        <InputRadio
          name='VisaSponsorship'
          listOfOption={[
            {
              id: 'VisaSponsorshipYes',
              value: 1,
              label: 'Yes',
            },
            {
              id: 'VisaSponsorshipNo',
              value: 0,
              label: 'No',
            },
          ]}
          onChange={(e) => {
            setRequireVisaSponsorship(e.target.value);
          }}
          selectedValue={requireVisaSponsorship}
        />
      </div>
      <CustomButton
        type='button'
        className='cta-type-2 w-360 h-40 br-16 mx-auto mt-64'
        isDisabled={helper.IsEmpty(requireVisaSponsorship)}
        onClick={onSubmit}
        label='Next'
      />
    </OnBoarding>
  );
}
