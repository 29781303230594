import { sucessState200 } from "../utility/constants/utilConstants";
import * as typesConstants from "./../utility/constants/typesConstants";
let initialState = {
  isLoading: false,
  signUpData: [],
  error: "",
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case typesConstants.SIGNED_USER_DETAIL_LOADING:
      return { ...state, isLoading: true };
    case typesConstants.SIGNED_USER_DETAIL:
      return {
        ...state,
        signedUserDetail: action?.response?.data,
        isLoading: false,
      };
    case typesConstants.COMMON_IS_LOADING:
      return { ...state, isLoading: true };
    case typesConstants.GET_COMMON_DROPDOWN_OPTIONS:
      if (action?.response?.status === sucessState200)
        return {
          ...state,
          isLoading: false,
          highestEducation: action?.response?.data?.highestEducation?.rows,
          SalaryType: action?.response?.data?.salaryType?.rows,
          contactPreference: action?.response?.data?.contactPreference?.rows,
          noticePeriod: action?.response?.data?.noticePeriod?.rows,
          workShift: action?.response?.data?.workShift?.rows,
          employmentType: action?.response?.data?.employmentType?.rows,
          sortBy: action?.response?.data?.sortBy,
        };
      else return { ...state, error: action?.response?.status };
    default:
      return state;
  }
}
