import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory, Link } from "react-router-dom";
import InputCheckbox from "./common/InputCheckbox";
import CustomButton from "../../common/button/CustomButton";
export default function ShiftPreference() {
  let history = useHistory();
  const [requireVisaSponsorship, setRequireVisaSponsorship] = useState();
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/WorkOnHazardous");
  };
  return (
    <div className="onboardingContentWrapper">
      <Container className="onboarding">
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/WillingToTravel");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            12
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          What shifts do you prefer?
        </h2>
        <p className="text-center mx-auto mw-552 mb-4">
          You can select multiple options
        </p>
        <div className="text-center">
          <InputCheckbox
            name="VisaSponsorship"
            listOfOption={[
              {
                id: "Morning",
                value: "Morning",
                label: "Morning",
              },
              {
                id: "Noon",
                value: "Noon",
                label: "Noon",
              },
              {
                id: "Evening",
                value: "Evening",
                label: "Evening",
              },
              {
                id: "Night (graveyard)",
                value: "Night (graveyard)",
                label: "Night (graveyard)",
              },
              {
                id: "Any time / Not necessary",
                value: "Any time / Not necessary",
                label: "Any time / Not necessary",
              },
            ]}
            onChange={(e) => {
              setRequireVisaSponsorship(e.target.value);
            }}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          isDisabled={helper.IsEmpty(requireVisaSponsorship)}
          onClick={onSubmit}
          label="Next"
        />
        <div className="other-link">
          <Link to="/Candidate/Onboarding/WorkOnHazardous" className="ms-0">
            Skip this step
          </Link>
        </div>
      </Container>
    </div>
  );
}
