import React, { useContext, useEffect, useRef } from "react";
import { Button, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as candidateActions from "components/candidate/candidateActions";
import ReactNotifications from "components/common/reactNotifications/ReactNotifications";
import {
  getErrorStatus,
  getSuccessStatus,
} from "utility/constants/utilConstants";
import CustomDateFormat from "utility/CustomDateFormat";
import DOMPurify from "dompurify";
import location from '../../../assets/images/locatuion.svg';
import jobtype from '../../../assets/images/fulltime.svg';
import shift from '../../../assets/images/shift.svg';
import onsite from '../../../assets/images/onsite.svg';
import travel from '../../../assets/images/travel.svg';
import government from '../../../assets/images/government.svg';
import hiring from '../../../assets/images/hiring.svg';
import TimeAgo from "components/common/postedDateFunctionality/TimeAgo";
import CloseModal from "../../../assets/images/cross2.svg";
import { AuthContext } from "context/authContext";

export default function SearchJobDetail({ job, resetDetailsDesign, isAdjusted }) {
  const token = localStorage.getItem("MyTurn-Token");
  const [isApplied, setIsApplied] = React.useState(false);
  const { ipAddress, } = useContext(AuthContext);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.scrollTop = 0;
    }
  }, [job]);

  const userId = localStorage.getItem("candidate_id");
  const history = useHistory();
  const handleApply = () => {
    candidateActions.checkToUploadCandidateResume(
      { job_id: job?.id },
      (response) => {

        if (response.success === true) {
          job?.job_application_url.trim() === '' && job?.job_source_type === "0" && ReactNotifications(response.message, getSuccessStatus);
          job.isApplied = true;
          setIsApplied(true);
          const userFrom = job?.job_application_url.trim() === '' && job?.job_source_type === "0" ? "JOB APPLY" : "JOB VIEW";
          const data = {
            ip_address: ipAddress,
            user_id: userId || "",
            user_from: userFrom,
            job_id: job?.id || "",
          }
          candidateActions.measureUserClicks(data, (response) => { });

          if (response.jobSource) {
            window.open(response.jobURL, "_blank");
            job.isApplied = false;
            setIsApplied(false);
          }
        } else if (response.success === false && response.status === 200) {
          history.push({
            pathname: `/Candidate/applyforjob/${job.id}`,
            state: { data: job.applied_job },
          });
          ReactNotifications(response.message, getSuccessStatus);
        } else {
          ReactNotifications(response.msg, getErrorStatus);
        }
      }
    );
  };

  useEffect(() => {
    if (job?.isApplied && job?.job_source !== "Imported") {
      setIsApplied(true);
    } else {
      setIsApplied(false);
    }
  }, [job]);

  const handleGuestApplyButtonClick = () => {
    if (job?.job_application_url.trim() !== '' && job?.job_source_type === "1" || job?.job_application_url.trim() !== '' && job?.job_source_type === "0") {
      const data = {
        ip_address: ipAddress,
        user_id: userId || "",
        user_from: "JOB VIEW",
        job_id: job?.id || "",
      }
      candidateActions.measureUserClicks(data, (response) => { })
      window.open(job.job_application_url, "_blank");
      job.isApplied = true;
      setIsApplied(false);
    } else {
      history.push('/signin')
    }
  }

  function updateJobDescriptionHtmlContent(html) {
    if (!html.startsWith('<p>')) {
      html = '<p>' + html;
    }
    if (!html.endsWith('</p>')) {
      html = html + '</p>';
    }

    html = html.replace(/(\s*<br\s*\/?>\s*){3,}/gi, '<br><br>');

    html = html.replace(/(<br\s*\/?>)(?![\s\S]*<br\s*\/?>)/gi, '$1<br>');

    return html;
  }

  // Function to handle body overflow style
  // Function to handle body overflow style
  const handleBodyOverflow = () => {
    document.body.style.overflow = isAdjusted ? "hidden" : "auto";
  };

  useEffect(() => {
    if (window.innerWidth <= 450) {
      handleBodyOverflow();
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isAdjusted]);
  return (
    <>
      <Col
        xxl={{ span: 8 }}
        xl={{ span: 8 }}
        lg={{ span: 8 }}
        md={7}
        sm={12}
        xs={12}
      >
        {job && (
          <div className={`${isAdjusted ? "right-job-dv-active" : "right-job-dv"}`}>
            <button className={`${isAdjusted ? "close_btn_show" : "close_btn_hide  "}`} onClick={resetDetailsDesign} ><img src={CloseModal} /></button>
            <div className="job_detail_data">
              <div className="right_dv_wrrpr">
                <div>
                  <h3 className="left_dv_title"> {job.job_title}{" "}
                  </h3>
                  <div className="left_dv_data border-b">
                    {job.company && (
                      <div className="company_logo2">
                        {job?.company_logo_url !== null ? <img src={job?.company_logo_url} className="company-avatar-detail" />
                          : <div className="company-avatar-detail">{job.company.charAt(0)}</div>}
                        <h4 className="company">{job.company} </h4>
                      </div>
                    )}
                    <div className="left_dv_innr_wrrpr">
                      {job.job_type && (
                        <div className="right_dv_ineer_data">
                          <img src={jobtype} />
                          <p>{job.job_type.replace("-", " ")}{" "}
                          </p>
                        </div>
                      )}
                      {job.job_location && (
                        <div className="right_dv_ineer_data">
                          <img src={location} />
                          <p>{job.job_location}{" "}
                          </p>
                        </div>
                      )}
                      {job.job_shift && (
                        <div className="right_dv_ineer_data">
                          <img src={shift} />
                          <p> {job.job_shift.replace("-", " ")}{" "}
                          </p>
                        </div >
                      )}
                      {job.required_onsite && (
                        <div className="right_dv_ineer_data"><img src={onsite} />
                          <p>
                            Required Onsite: {job.required_onsite}{" "}
                          </p>
                        </div>
                      )}
                      {job.required_travel && (
                        <div className="right_dv_ineer_data"><img src={travel} />
                          <p>
                            Required Travel: {job.required_travel}
                          </p>
                        </div>
                      )}
                      {job.required_clearance && (
                        <div className="right_dv_ineer_data"><img src={government} />
                          <p>
                            Government Clearance:{" "}
                            {job.required_clearance.replace("-", " ")}{" "}
                          </p>
                        </div>
                      )}
                      {job.hiring_timeframe && (
                        <div className="right_dv_ineer_data"><img src={hiring} />
                          <p>
                            Hiring Timeframe:{" "}
                            {CustomDateFormat(job.hiring_timeframe)}{" "}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="pay_range_btn_wrrp">

                      <div className="apply_btn">
                        {token ?
                          (
                            <Button
                              className="primary-filed-orange"
                              onClick={handleApply}
                            >
                              {isApplied ? "Applied" : <>{job?.job_application_url.trim() === '' && job?.job_source_type === "0" ? "Apply" : "View Job"}</>}
                            </Button>
                          ) : (
                            <Button
                              className="primary-filed-orange"
                              onClick={() => handleGuestApplyButtonClick()}
                            >
                              {job?.job_application_url.trim() === '' && job?.job_source_type === "0" ? "Apply" : "View Job"}
                            </Button>
                          )}
                        <div className="posted_dv"><TimeAgo createdAt={job?.created_at} /></div>
                      </div>

                      {(job?.minimum_pay || job?.maximum_pay) && (
                        <h3 className="pay_range"> {" "}

                          ${job.minimum_pay.toLocaleString()} - ${job.maximum_pay.toLocaleString()}{" "}
                          {" "}
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="left_dv_data_wrrpr" ref={descriptionRef}>
                <div className="description">
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(updateJobDescriptionHtmlContent(job.job_description)) }} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Col>
    </>
  );
}
