import * as utilConstants from "./../constants/utilConstants";
import moment from "moment";


export function updateReducer(TYPE, response) {
  return {
    type: TYPE,
    response: response,
  };
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function removeValueFromArray(value, array) {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}
export function formatDate(data, format = "YYYY-MM-DD") {
  return moment(data).format(format);
}
export const getFormattedDate = (dt) => {
  if (dt && dt !== undefined && dt !== "") {
    let date = new Date(dt);
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year;
  } else {
    return "--";
  }
};
/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Check value is empty or not
 * @returns Boolean
 */
export function IsEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}
/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Check password strength
 * @returns String
 */
export function passwordStrength(password) {
  /*eslint-disable */
  let strongPassword = new RegExp(utilConstants.strongPasswordRegex); // eslint-disable-line no-use-before-define
  let mediumPassword = new RegExp(utilConstants.mediumPasswordRegex); // eslint-disable-line no-use-before-define

  if (strongPassword.test(password))
    return utilConstants.getStrongPasswordStatus;
  else if (mediumPassword.test(password))
    return utilConstants.getMediumPasswordStatus;
  else return utilConstants.getWeakPasswordStatus;
}

/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Check Email validation
 * @returns String
 */
export function IsValidEmail(email) {
  var myRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-use-before-define
  var isemail = myRegEx.test(email);
  if (isemail) {
    return true;
  } else {
    return false;
  }
}
/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Check web validation
 * @returns String
 */
export function IsValidWebUrl(webUrl) {
  var regex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return regex.test(webUrl);
} /*eslint-enable */
/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Check Email validation
 * @returns String
 */

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const capitalizeFirstLetter2 = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function setDataAccordingInput(e, capitalize = false) {
  switch (e.target.type) {
    case "checkbox":
      return { [e.target.name]: e.target.checked };
    case "select-one":
      return { [e.target.name]: e.target.value };
    case "radio":
      return { [e.target.name]: e.target.value };
    case "time":
      return { [e.target.name]: e.target.value };
    default:
      return { [e.target.name]: capitalize && (e.target.name === 'first_name' || e.target.name === 'last_name') ? capitalizeFirstLetter(e.target.value) : e.target.value};
  }
}

/**
 * @author Nikunj Patel
 * @param {*} any
 * @description Fill Progressbar Parentage from input
 * @returns number
 */
export function isFutureDate(date) {
  return moment().diff(date) < 0;
}
export function fillProgressBarParentage(
  totalSetp,
  curentStep,
  outOfParentage = 100
) {
  return (outOfParentage * curentStep) / totalSetp;
}
export const isValidPhoneNumber = (phone = "") => {
  var phoneno = /^\d{10}$/;
  return phone.match(phoneno);
};
export const getdaysbetween2=(date1,date2)=>{
  const oneDay = 24 * 60 * 60 * 1000;
  var start = moment(date1).format("YYYY/MM/DD"),
   end = moment(date2).format("YYYY/MM/DD");
   const firstDate = new Date(start?.split('/')[0], start?.split('/')[1]-1, start?.split('/')[2]);

const secondDate = new Date(end?.split('/')[0], end?.split('/')[1]-1, end?.split('/')[2]);
const diffDays = (firstDate - secondDate) / oneDay;
   return diffDays
}
export const getCookie = (sKey) => {
  if (!sKey) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp( //eslint-disable-line
          "(?:(?:^|.*;)\\s*" +
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + //eslint-disable-line
            "\\s*\\=\\s*([^;]*).*$)|^.*$"
        ),
        "$1"
      )
    ) || null
  );
};
export const setCookie = (sKey, data) => {
  document.cookie = `${sKey}=${data}; max-age=600048000; secure;Priority=High`;
};