import { async } from "../utility/config/api/index";
import * as typesConstants from "../utility/constants/typesConstants";
import { updateReducer } from "../utility/helper/helper";

export function getContentByPageId(params) {
  return (dispatch) => {
    return async("frontend/cms/by-page", "GET", params).then((response) => {
      dispatch(
        updateReducer(typesConstants.GET_PAGE_CONTENT, {
          key: params.page_id,
          content: response.data,
        })
      );
    });
  };
}
