import React from 'react';

function NotFoundPage() {
  return (
    <div className="section_nt-found">
    <h1 className="error">404</h1>
    <div className="page">Ooops!!! The page you are looking for is not found</div>
    <a className="back-home" href="/">Back to home</a>
  </div>
  )
}

export default NotFoundPage;