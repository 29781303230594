import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { cmsPageId } from "../../utility/constants/utilConstants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContentByPageId } from "../../actions/cms";
import { Link } from "react-router-dom";

function AboutUsNew({ getPageContent, state }) {
  const pageId = cmsPageId.aboutus;
  const content = state[pageId] || {};

  useEffect(() => {
    if (pageId && !Object.keys(content).length) {
      getPageContent({ page_id: pageId });
    }
  }, [pageId]);
  return (
    <div className="aboutusnew">
      <div className="top-panel">
        <Container className="h-100">
          <div className="background-image">
            <img src="/images/about-us-banner.png" alt="about us banner" />
          </div>
          <div className="content">
            <Row className="h-100">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                className="h-100"
              >
                <div className="d-flex align-items-center h-100">
                  <h1>Bridging the gap between you and your dream cyber job <br></br><span className="color-orange">MyTurn</span></h1>
                </div>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="content-panel">
        <Container>
          <div className="section about-section-content">
            <Row>
              <Col xs={12} md={6}>
                <img alt="about MyTurn" src="/images/abousus-main.png"></img>
              </Col>
              <Col xs={12} md={6}>
                <div className="d-flex align-items-center h-100">
                  <div>
                
                    <h2 class="subheading-inner">About Us</h2>
                    <p className="font-lg">Here at <span className="color-orange">MyTurn</span>, we&apos;re dedicated to the advancement of the cyber security industry...</p>  
                    <p className="font-lg mt-3"><span className="color-orange">MyTurn</span> is a Recruitment Portal dedicated to job seeking cyber security professionals and recruiters.</p> 
                    <p className="font-lg mt-3">We help cyber security professional and recruiters make the candidate hiring process easy and effective.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section founding-section-content">
           
            <h3 className="section-heading">
              <em>Since our <span className="color-orange">founding...</span></em>
            </h3>
            <p className="description">We pride ourselves on being a different kind of company. At <span className="color-orange">MyTurn</span>, we set ourselves apart by ensuring that the professionals on our platform are passionate about their careers and have a forum to provide those services.</p>
            <p className="description mt-3">In a world where so many professionals have trouble finding jobs, we decided to scoop them up for ourselves.</p>
            <p className="description mt-3">Once we saw that talent shine though, we asked ourselves if there was a better way to get their cyber experience across the table.</p>
            <p className="description mt-3">That&apos;s where we come in... Welcome to <span className="color-orange">MyTurn!</span></p>
            <p className="description mt-3 text-end"><em>- Amit Doshi, Founder &amp; CEO of <span className="color-orange">MyTurn</span></em></p>
          </div>
          <div className="section startcareer-section-content">
            <Row>
              <Col xs={12} md={6}>
                <div className="d-flex justify-content-end align-items-end">
                  <img src="/images/about-ppl.png" alt="about cybersecurity career"></img>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="d-flex align-items-center h-100">
                  <div className="w-100">
                   
                    <h3 class="subheading-small">Ready to secure your <br></br><span className="color-green">cyber career...</span></h3>
                    <Link
                      to="/signup"
                      className="primary-filed-green text-decoration-none"
                    >
                      Get started
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    getPageContent: bindActionCreators(getContentByPageId, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state.cmsReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutUsNew);
