import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CompanyProfile from "./CompanyProfile";
import CompanyInfo from "./CompanyInfo";
import { useParams } from "react-router-dom";
import CurrentOpening from "./CurrentOpening";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import {
  getErrorStatus,
  sucessState200,
} from "utility/constants/utilConstants";
import { getCompanyDetails } from "../employerActions";
import ReactNotifications from "components/common/reactNotifications/ReactNotifications";
import { useHistory } from "react-router-dom";

export default function About() {
  const { id } = useParams();
  const history = useHistory();
  const hasToken = localStorage.getItem("MyTurn-Token");
  const [companyDetails, setCompanyDetails] = useState([]);
  const className = hasToken ? "about_banner withToken" : "about_banner";

  useEffect(() => {
    getCompanyDetails(id, (response) => {
      if (response.status === sucessState200) {
        setCompanyDetails(response.data);
      } else {
        ReactNotifications(response.msg, getErrorStatus);
        history.push("/");
      }
    });
  }, []);

  return (
    <div className={className}>
      <Container>
        <Row>
          <CompanyProfile companyDetails={companyDetails} />
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={6}
            sm={12}
            xs={12}
          >
            <div className="company_wrrpr">
              <Tabs>
                <TabList>
                  <Tab>Company Info</Tab>
                  <Tab>Current Opening</Tab>
                </TabList>
                <TabPanel>
                  <CompanyInfo companyDetails={companyDetails} />
                </TabPanel>
                <TabPanel>
                  <CurrentOpening />
                </TabPanel>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
