import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Container, Row, Col, Button, Modal, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import InputText from "../../common/inputText/InputText";
import InputTextarea from "../../common/inputTextarea/InputTextarea";
import InputSelect from "../../common/select/InputSelect";
import InputCheckbox from "../../common/inputCheckbox/InputCheckbox";
import CustomButton from "../../common/button/CustomButton";
import { useHistory } from "react-router-dom";
import CustomeModel from "./../../../components/common/model/CustomeModel";
import * as commonActions from "../../../actions/commonActions";
import * as candidateActions from "../candidateActions";
import * as helper from "../../../utility/helper/helper";
import { getFormattedDate } from "../../../utility/helper/helper";
import Calendar from "react-calendar";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import * as utilConstants from "../../../utility/constants/utilConstants";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
import MultiInputSelect from "../../common/select/MultiInputSelect";
import { IsValidEmail } from "../../../utility/helper/helper";
import CalendarImg from "../../../assets/images/calendar.svg";
import moment from "moment";
import { CommonContext } from "context/commonContext";
import tick from '../../../assets/images/tick.png'

function CandidateEditProfile(props) {
  const [changeNameModel, setChangeNameModel] = useState(false);
  const [isNameChangeRequestSuccess, setNameChangeRequestSuccess] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [startCalendarvalue, setstartCalendarvalue] = useState("");
  const [endCalendarvalue, setendCalendarvalue] = useState("");
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] =
    useState(false);
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const [errors, setErros] = useState({});
  const [errors2, setErros2] = useState({}); //eslint-disable-line
  const [changeNameErrors, setChangeNameErrors] = useState({});
  const [update, setUpdate] = useState(false);
  const [skillInput, setSkill] = useState("");
  const [skillList] = useState([]);
  const [commonDropdown, setCommonDropdon] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [edus, setEdus] = useState([]);
  const [changeEmailPopup, setEmailPopup] = useState(false);
  const [emailOtpPopup, setEmailOtpPopup] = useState(false);
  const [isEmailChanged, setEmailChanged] = useState(false);
  const [removeImagePopup, setRemoveImagePopup] = useState(false);


  const [profile, setProfile] = useState({});
  const [changeNameData, setChangeNameData] = useState({});
  let inputEl = React.useRef(null);
  const { setIsCandidateEditForm, showEditDiv, setShowEditDiv } = useContext(CommonContext);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    edus?.map((field) => {
      if (field?.start_date && field?.end_date) {
        setErros({});
      }
    })

    if (skillInput) {
      setErros({});
    }
  }, [edus, skillInput])

  const onCurrentCompanyChange = (index, checked) => {
    setJobs(
      jobs?.map((job, idx) => {
        if (idx === index) {
          return {
            ...job,
            end_date: moment().format(),
            currently_working: checked ? 1 : 0,
          };
        } else {
          return { ...job, currently_working: 0 };
        }
      })
    );
  };
  useEffect(() => {
    if (profile.minimum_amount_type === "negotiable") {
      setProfile({ ...profile, minimum_amount: "", maximum_amount: "" })
    }
  }, [profile?.minimum_amount_type])

  const handleClose = () => {
    setRemoveImagePopup(false);
  };
  const onSubmit = () => {
    if (!helper.IsEmpty(errors)) {
      return
    }
    const startDateArr = [];
    const endDateArr = [];
    const shiftPreference = Object.keys(shiftType)
      .filter((key) => shiftType[key])
      .join(",");
    const employmentPref = Object.keys(employmentType)
      .filter((key) => employmentType[key])
      .join(",");

    const keys = Object.keys(profile);
    let newErrors = {};
    let newErrors2 = {};

    if (showEditDiv === "preferences") {
      if (shiftPreference === "") {
        newErrors["shift_type"] = "Shift type is required";
      }
      if (employmentPref === "") {
        newErrors["employment_type"] = "Employment type is required";
      }
      // if (
      //   profile.minimum_amount_type !== "negotiable" &&
      //   !profile["minimum_amount"]
      // ) {
      //   newErrors["minimum_amount"] = "This Field is required";
      // }
    }

    if (showEditDiv === "personal_information") {
      keys.map((key) => {
        if (
          !profile[key] &&
          ["degree", "contact_preference", "current_address", "zip"].includes(key)
        ) {
          newErrors[key] = "This field is required";
        }
      });
    }

    if (showEditDiv === "skills") {
      if (helper.IsEmpty(skillInput)) {
        newErrors.UserSkill = "This field is required";
      }
    }

    if (showEditDiv === "job_history") {
      jobs.map((job, index) => {
        Object.keys(job).map((key) => {
          if (!job[key] && key !== "currently_working" && key !== "end_date") {
            if (key === "position") {
              newErrors[key + index] = "Please select the job position";
            } else if (key !== "summary") {
              newErrors[key + index] = "Please fill the empty fields";
            }
          }

          if (
            job["start_date"] === job["end_date"] &&
            job["currently_working"] == "0"
          ) {
            if (job["end_date"] === "") {
              newErrors["end_date" + index] = "Please fill the empty fields";
            } else {
              newErrors["end_date" + index] =
                "start date and end date cannot be same";
            }
          }
          if (helper.getdaysbetween2(job["start_date"], moment().format()) > 0) {
            newErrors["start_date" + index] =
              "Start date cannot be greater than today";
          }
          if (helper.getdaysbetween2(job["end_date"], moment().format()) > 0) {
            newErrors["end_date" + index] =
              "End date cannot be greater than today";
          }
          if (
            helper.getdaysbetween2(job["start_date"], job["end_date"]) > 0 &&
            job["currently_working"] === 0
          ) {
            newErrors["start_date" + index] =
              "Start date cannot be after end date";
          }

          if (key === "start_date") {
            if (startDateArr.includes(job["start_date"])) {
              newErrors["start_date" + index] = "Duplicate start date";
            }
            startDateArr.push(job["start_date"]);
          }

          if (key === "end_date") {
            if (endDateArr.includes(job["end_date"])) {
              newErrors["end_date" + index] = "Duplicate end date";
            }
            endDateArr.push(job["end_date"]);
          }
          return job[key];
        });
        return job;
      });
    }

    // if (showEditDiv === "education") {
    //   edus?.forEach((field, index) => {
    //     let fieldErrors = {};
    //     if (field?.start_date === "") {
    //       fieldErrors.start_date = "Start date is required";
    //     }
    //     if (field?.end_date === "") {
    //       fieldErrors.end_date = "End date is required";
    //     }
    //     if (Object.keys(fieldErrors).length > 0) {
    //       newErrors[index] = fieldErrors;
    //     }
    //   });
    // }
    if (showEditDiv === "education") {
      let datePairs = new Set();

      edus?.forEach((field, index) => {
        let fieldErrors = {};
        if (field?.start_date === "") {
          fieldErrors.start_date = "Start date is required";
        }

        if (field?.end_date === "") {
          fieldErrors.end_date = "End date is required";
        }

        if (!fieldErrors.start_date && !fieldErrors.end_date) {
          let normalizedStartDate = new Date(field?.start_date).toISOString().split('T')[0];
          let normalizedEndDate = new Date(field?.end_date).toISOString().split('T')[0];

          let startEndDatePair = `${normalizedStartDate}-${normalizedEndDate}`;
          let startDatePair = `${normalizedStartDate}`;
          let endDatePair = `${normalizedEndDate}`;

          if (datePairs.has(startEndDatePair)) {
            fieldErrors.start_date = "Duplicate start date ";
            fieldErrors.end_date = "Duplicate end date ";
          } else {
            datePairs.add(startEndDatePair);
          }

          if (datePairs.has(startDatePair)) {
            fieldErrors.start_date = "Duplicate start date ";
          } else {
            datePairs.add(startDatePair);
          }

          if (datePairs.has(endDatePair)) {
            fieldErrors.end_date = "Duplicate end date ";
          } else {
            datePairs.add(endDatePair);
          }
        }


        if (Object.keys(fieldErrors).length > 0) {
          newErrors[index] = fieldErrors;
        }
      });
    }







    let UserSkill = "";
    if (!helper.IsEmpty(skillInput)) {
      UserSkill = JSON.stringify(skillInput.map((k) => k.value));
    }
    if (helper.IsEmpty(newErrors2)) {
      setErros2({});
    } else setErros2(newErrors2);

    if (helper.IsEmpty(newErrors)) {
      setErros({});
      setLoading(true);
      candidateActions.updateCandidateProfile(
        {
          ...profile,
          UserSkill,
          jobHistory: jobs,
          UserEducations: edus,
          desired_employment: employmentPref,
          job_shift_type: shiftPreference,
          minimum_amount:
            profile.minimum_amount_type === "negotiable"
              ? ""
              : profile.minimum_amount,
          section_validation: showEditDiv,
        },
        (response) => {
          setLoading(false);
          if (response.status === utilConstants.sucessState200) {
            props.commonActions.signinUserDetails();
            ReactNotifications(response.message, utilConstants.sucessState200);
            setIsCandidateEditForm(false);
            setShowEditDiv("");
            // history.push(`/${getAuthentionInfo().type}/Dashboard`);
          } else
            ReactNotifications(response.message, utilConstants.getErrorStatus);
        }
      );
    }
    else setErros(newErrors);
  };
  const onChange2 = (name, e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {


      if (name == "minimum_amount") {

        if (e.target.value?.length > 3) {
          return
        }
        if (profile.minimum_amount_type !== "negotiable" && !e.target.value?.length) {
          setErros((prevState) => {
            return {
              ...prevState,
              maximum_amount: "This field is required ",
            };
          });


        } else if (e.target.value > profile.maximum_amount) {
          setErros((prevState) => {
            return {
              ...prevState,
              maximum_amount: "Minimum value can not be greater than Maximum",
            };
          });
        } else {
          setErros(prevState => {
            const { maximum_amount, ...rest } = prevState;
            return rest;
          });
        }
      }
      if (name == "maximum_amount") {
        if (e.target.value?.length > 3) {
          return
        }
        if (profile.minimum_amount_type !== "negotiable" && !e.target.value?.length) {
          setErros((prevState) => {
            return {
              ...prevState,
              maximum_amount: "This field is required ",
            };
          });
        } else if (e.target.value < profile.minimum_amount) {
          setErros((prevState) => {
            return {
              ...prevState,
              maximum_amount: "Maximunm value can not be less than Minimum",
            };
          });
        } else {
          setErros(prevState => {
            const { maximum_amount, ...rest } = prevState;
            return rest;
          });
        }
      }
      setProfile({
        ...profile,
        [name || e.target.name]: e.target.value,
      });
    }

    if (e.target.value !== "") {
      const objectName = name || e.target.name;
      setErros(prevState => {
        const rest = Object.keys(prevState).filter(objKey =>
          objKey !== objectName).reduce((newObj, key) => {
            newObj[key] = prevState[key];
            return newObj;
          }, {}
          );
        return rest;
      });
    }
  };

  const onChange = (name, e) => {
    if (name === "new_email") {
      if (e.target.value === (profile.email || profile.communication_email)) {
        setErros((prevState) => {
          return {
            ...prevState,
            new_email: "New email cannot be your current email",
          };
        });
      } else {
        setErros((prevState) => {
          return {
            ...prevState,
            new_email: "",
          };
        });
      }
    }

    if (name === "otp" && e.target.value) {
      setErros((prevState) => {
        return {
          ...prevState,
          otp: "",
        };
      });
    }
    setProfile({
      ...profile,
      [name || e.target.name]: e.target.value,
    });

    if (e.target.value !== "") {
      const objectName = name || e.target.name;
      setErros(prevState => {
        const rest = Object.keys(prevState).filter(objKey =>
          objKey !== objectName).reduce((newObj, key) => {
            newObj[key] = prevState[key];
            return newObj;
          }, {}
          );
        return rest;
      });
    }
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    const imageURL = URL.createObjectURL(file);
    const formData = new FormData();
    formData.append("profile", file);
    commonActions.uploadProfile(formData, (respoce) => {
      if (respoce.file)
        setProfile({
          ...profile,
          profile_image: respoce.file,
          imageURL: imageURL,
        });
    });
  };

  const uploadResume = (file) => {
    file = file[0];
    const formData = new FormData();
    formData.append("resume", file);
    commonActions.uploadResume(formData, (response) => {
      if (response.file) setProfile({ ...profile, cv_path: response.file });
      else ReactNotifications(response.msg, utilConstants.getErrorStatus);
    });
  };

  const removeImg = () => {
    inputEl.value = "";
    setProfile({
      ...profile,
      profile_image: "",
      imageURL: "",
      profile_image_url: "",
    })
  };

  const sendNameChangeRequest = () => {
    let newChangeNameErrors = {};
    if (!changeNameData.first_name) {
      newChangeNameErrors.first_name = "Enter first name";
    }
    if (!changeNameData.last_name) {
      newChangeNameErrors.last_name = "Enter last name";
    }
    setChangeNameErrors(newChangeNameErrors);
    setUpdate(!update);

    if (!Object.keys(newChangeNameErrors).length) {
      setLoading(true);
      commonActions.nameChangeRequest(changeNameData, (response) => {
        if (response.status === utilConstants.sucessState200) {
          setLoading(false);
          setNameChangeRequestSuccess(true);
          setChangeNameModel(false);
        } else {
          setLoading(false);
          ReactNotifications(response.message, utilConstants.getErrorStatus);
        }
      });
    }
  };

  const onJobFieldChange = (index, name, value) => {

    let error = { ...errors };
    delete error[name + index];
    setErros(error);

    let element2 = jobs[index];

    element2 = {
      ...element2,
      [name]: new Date(value).toISOString(),
    };
    setJobs(
      jobs.map((job, idx) => {
        if (idx === index) {
          return element2;
        } else {
          return job;
        }
      })
    );
  };

  const onJobFieldChange2 = (index, name, value) => {

    let error = { ...errors };
    delete error[name + index];
    setErros(error);
    let element = jobs[index];
    element = {
      ...element,
      [name]: value,
    };
    setJobs(
      jobs.map((job, idx) => {
        if (idx === index) {
          return element;
        } else {
          return job;
        }
      })
    );
  };

  const addNewJob = () => {
    setJobs(
      jobs.concat({
        company: "",
        position: "",
        start_date: "",
        end_date: "",
        summary: "",
        currently_working: 0,
      })
    );
  };

  const onEduFieldChange = (index, name, value) => {
    let error = { ...errors };
    delete error[name + index];
    setErros(error);

    let element2 = edus[index];

    if (name === "start_date" || name === "end_date") {
      let date = new Date(value);

      let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      element2 = {
        ...element2,
        [name]: utcDate.toISOString(),
      };
    } else {
      element2 = {
        ...element2,
        [name]: value,
      };
    }

    setEdus(
      edus.map((edu, idx) => {
        if (idx === index) {
          return element2;
        } else {
          return edu;
        }
      })
    );
  };


  const addNewEdu = () => {
    setEdus(
      edus.concat({
        course: "",
        collage: "",
        start_date: "",
        end_date: "",
        currently_present: '0',
      })
    );
  };
  const onPresentEduChange = (index, checked) => {
    setEdus(
      edus.map((edu, idx) => {
        if (idx === index) {
          return {
            ...edu,
            end_date: moment().format(),
            currently_present: checked ? '1' : '0',
          };
        } else {
          return { ...edu, currently_present: '0' };
        }
      })
    );
  };

  const removeEduFromlist = (index) => {
    setEdus(edus.filter((item, idx) => index !== idx));
  };

  const downloadResume = (e) => {
    e.preventDefault();
    commonActions.downloadCandidateProfilePDF(profile.id, (response) => {
      window.open(
        response,
        "_blank",
        "width=500,height=500, top=100, left=500, noopener, noreferrer"
      );
    });
  };

  const removeJobFromHistory = (index) => {
    setJobs(jobs.filter((item, idx) => index !== idx));
  };

  const [employmentType, setEmploymentType] = React.useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [shiftType, setShiftType] = React.useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    console.log("checked>>>", checked);
    setEmploymentType((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
    if (checked) {
      setErros(prevState => {
        const { employment_type, ...rest } = prevState;
        return rest;
      });
    }

  };

  const handleShiftChange = (event) => {
    const { name, checked } = event.target;
    setShiftType((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });

    if (checked) {
      setErros(prevState => {
        const { shift_type, ...rest } = prevState;
        return rest;
      });
    }
  };

  useEffect(() => {
    if (!helper.IsEmpty(props.state.commonReducer.signedUserDetail)) {
      const { signedUserDetail } = props.state.commonReducer;
      let tempDate = { ...signedUserDetail };
      delete tempDate.CandidateProfile;
      tempDate = { ...tempDate, ...signedUserDetail["CandidateProfile"] };
      if (!helper.IsEmpty(tempDate.UserSkill)) {
        let tempSkillInput = [],
          skills = JSON.parse(tempDate.UserSkill.skill);
        if (typeof skills === "string") skills = JSON.parse(skills);
        skills.map((v) => {
          tempSkillInput.push({ label: v, value: v });
        });
        setSkill(tempSkillInput);
      }
      if (tempDate.JobHistories) setJobs(tempDate.JobHistories);
      if (tempDate.UserEducations) setEdus(tempDate.UserEducations);

      setProfile(tempDate);
      setChangeNameData({
        first_name: signedUserDetail.first_name,
        last_name: signedUserDetail.last_name,
      });

      const desiredEmploymentArr = tempDate?.desired_employment?.split(",");
      const shiftTypeArr = tempDate?.job_shift_type?.split(",");

      setEmploymentType({
        1: desiredEmploymentArr?.includes("1"),
        2: desiredEmploymentArr?.includes("2"),
        3: desiredEmploymentArr?.includes("3"),
        4: desiredEmploymentArr?.includes("4"),
        5: desiredEmploymentArr?.includes("5"),
      });

      setShiftType({
        1: shiftTypeArr?.includes("1"),
        2: shiftTypeArr?.includes("2"),
        3: shiftTypeArr?.includes("3"),
        4: shiftTypeArr?.includes("4"),
      });
    }
    if (
      helper.IsEmpty(props.state.commonReducer.contactPreference) ||
      helper.IsEmpty(props.state.commonReducer.employmentType)
    ) {
      if (
        !props.state.commonReducer.isLoading &&
        helper.IsEmpty(props.state.commonReducer.workShift)
      )
        props.commonActions.getCommonDropdownList();
    } else {
      let {
        employmentType,
        contactPreference,
        highestEducation,
        noticePeriod,
        workShift,
        SalaryType,
      } = props.state.commonReducer;
      setCommonDropdon({
        employmentType,
        contactPreference,
        highestEducation,
        noticePeriod,
        workShift,
        SalaryType,
      });
    }
  }, [props.state.commonReducer]);

  const handleNameChangeModal = () => {
    const { signedUserDetail } = props.state.commonReducer;
    setChangeNameData({
      first_name: signedUserDetail.first_name,
      last_name: signedUserDetail.last_name,
    });
    setChangeNameModel(true);
  };

  const profilePicConfirmation = (
    <>
      <p className="title-lg">Remove Profile Picture?</p>
      <p className="mt-3">Are you sure you want to remove this image?</p>
      <div className="justify-content-between d-flex mt-4">
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary deactive-btn mt-0"
          onClick={handleClose}
        >
          No
        </button>
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setRemoveImagePopup(false);
            removeImg();
          }}
        >
          Yes
        </button>
      </div>
    </>
  );
  const handleStartCalendar = (index) => {
    if (startCalendarvalue === index) {
      setstartCalendarvalue("");
    } else {
      setstartCalendarvalue(index);
    }
  };
  const handleEndCalendar = (index) => {
    if (endCalendarvalue === index) {
      setendCalendarvalue("");
    } else {
      setendCalendarvalue(index);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" >
      {props}
    </Tooltip>
  );

  return (
    <div className=" edit-profile edit_profile_labels">  {/*className="dashboardPageWrapper edit-profile" */}
      <>
        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={changeNameModel}
          modalBody={
            <form>
              <h2> Name Change Request </h2>
              <p>
                In order to change First name and Last name
                <br /> send email to support team
              </p>
              <Row>
                <Col xs={12}>
                  <InputText
                    label="First name"
                    onChange={(e) => {
                      e.preventDefault();
                      setChangeNameData({
                        ...changeNameData,
                        first_name: capitalizeFirstLetter(e.target.value),
                      });
                    }}
                    value={changeNameData.first_name}
                    error={changeNameErrors.first_name}
                  />
                </Col>
                <Col
                  xs={12}
                  className="d-flex align-items-end justify-content-between "
                >
                  <InputText
                    className="w-100"
                    label="Last name"
                    value={changeNameData.last_name}
                    onChange={(e) => {
                      e.preventDefault();
                      setChangeNameData({
                        ...changeNameData,
                        last_name: capitalizeFirstLetter(e.target.value),
                      });
                    }}
                    error={changeNameErrors.last_name}
                  />
                </Col>
              </Row>
            </form>
          }
          modalType="Custome"
          customeButton={
            <CustomButton
              label="submit"
              onClick={sendNameChangeRequest}
              className="cta-type-2 w-168 h-40 br-16 mx-auto"
              isLoading={isLoading}
            />
          }
          handleClose={() => {
            {
              setChangeNameModel(false);
            }
          }}
        />
        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={isNameChangeRequestSuccess}
          modalBody={
            <>
              <p className="title-lg">Thank You!</p>
              <p className="">
                Your request to change name is recieved, our team will review it
                shortly
              </p>
            </>
          }
          defaultButtonLabel={
            <span
              className="text-white"
            >
              Got it
            </span>
          }
          onPositive={(e) => {
            e.preventDefault();
            setNameChangeRequestSuccess(false);
          }}
          handleClose={() => {
            {
              setNameChangeRequestSuccess(false);
            }
          }}
        />
        <Modal
          show={removeImagePopup}
          onHide={handleClose}
          className="successModal"
          centered
          size="sm"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="modalbody text-center">
            {removeImagePopup ? profilePicConfirmation : ""}
          </Modal.Body>
        </Modal>
        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={changeEmailPopup}
          modalBody={
            <>
              <h2>Change email</h2>
              <p>Enter your new email</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <InputText
                  label="New email"
                  type="email"
                  name="new_email"
                  onChange={(e) => onChange(e.target.name, e)}
                  placeholder="example@mail.com"
                  value={profile.new_email}
                  error={errors.new_email}
                />
              </form>
            </>
          }
          modalType="Custome"
          customeButton={
            <CustomButton
              label="submit"
              onClick={(e) => {
                e.preventDefault();
                setIsSendingVerificationEmail(true);
                if (!profile.new_email) {
                  setErros((prevState) => {
                    return {
                      ...prevState,
                      new_email: "Please enter an Email Id",
                    };
                  });
                  setIsSendingVerificationEmail(false);
                  return;
                }
                if (!IsValidEmail(profile.new_email)) {
                  setErros((prevState) => {
                    return {
                      ...prevState,
                      new_email: "Invalid Email!",
                    };
                  });
                  setIsSendingVerificationEmail(false);
                  return;
                }
                if (profile.new_email) {
                  if (
                    profile.new_email !==
                    (profile.communication_email || profile.email)
                  ) {
                    commonActions.changeEmail(
                      { email: profile.new_email, type: 1 },
                      (response) => {
                        setIsSendingVerificationEmail(false);
                        if (response.status === utilConstants.sucessState200) {
                          setEmailOtpPopup(true);
                          setEmailPopup(false);
                          ReactNotifications(
                            response.message,
                            utilConstants.sucessState200
                          );
                        } else
                          ReactNotifications(
                            response.message,
                            utilConstants.getErrorStatus
                          );
                      }
                    );
                  } else {
                    setIsSendingVerificationEmail(false);
                  }
                } else {
                  setErros((prevState) => {
                    return {
                      ...prevState,
                      new_email: "Email is required",
                    };
                  });
                  setIsSendingVerificationEmail(false);
                }
              }}
              className="cta-type-2 w-168 h-40 br-16 mx-auto"
              isLoading={isSendingVerificationEmail}
            />
          }
          handleClose={() => {
            setProfile({
              ...profile,
              new_email: "",
              otp: "",
            });
            setEmailPopup(false);
            setErros((prevState) => {
              return {
                ...prevState,
                new_email: "",
              };
            });
          }}
        />

        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={emailOtpPopup}
          modalBody={
            <>
              <h2>Change email</h2>
              <p className="opt-code">
                An authorization code has been sent to your email
              </p>
              <form onSubmit={(e) => e.preventDefault()} className="otp-code">
                <InputText
                  controlId="otp"
                  label="authorization code"
                  type="number"
                  name="otp"
                  onChange={(e) => {
                    if (e.target.value.length > 4) return false;
                    onChange("otp", e);
                  }}
                  placeholder="X-X-X-X"
                  value={profile.otp}
                  isMobileNumber
                  error={errors.otp}
                />
              </form>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <span
                  className="b-link cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    commonActions.changeEmail(
                      { email: profile.new_email, type: 1 },
                      (response) => {
                        if (response.status === utilConstants.sucessState200) {
                          ReactNotifications(
                            response.message,
                            utilConstants.sucessState200
                          );
                        } else
                          ReactNotifications(
                            response.message,
                            utilConstants.getErrorStatus
                          );
                      }
                    );
                  }}
                >
                  Send Code Again
                </span>
              </div>
            </>
          }
          modalType="Custome"
          customeButton={
            <CustomButton
              label="submit"
              onClick={(e) => {
                e.preventDefault();
                setIsOTPSubmitting(true);
                if (!profile.otp) {
                  setErros((prevState) => {
                    return {
                      ...prevState,
                      otp: "Please enter valid OTP",
                    };
                  });
                  setIsOTPSubmitting(false);
                  return;
                }
                commonActions.verifyEmailChangeOtp(
                  { code: profile.otp, type: 1, new_email: profile.new_email },
                  (response) => {
                    setIsOTPSubmitting(false);
                    if (response.status === utilConstants.sucessState200) {
                      setEmailChanged(true);
                      setEmailOtpPopup(false);
                    } else
                      ReactNotifications(
                        response.message,
                        utilConstants.getErrorStatus
                      );
                  }
                );
              }}
              className="cta-type-2 w-168 h-40 br-16 mx-auto"
              isLoading={isOTPSubmitting}
            />
          }
          handleClose={() => {
            setProfile({
              ...profile,
              otp: "",
              new_email: "",
            });
            setEmailChanged(false);
            setEmailOtpPopup(false);
            setErros((prevState) => {
              return {
                ...prevState,
                otp: "",
              };
            });
          }}
        />
        <CustomeModel
          className="change-request-modal"
          headerImage="/images/like.png"
          show={isEmailChanged}
          modalBody={
            <>
              <p className="title-lg">
                Congratulations! Your email has changed
              </p>
            </>
          }
          modalType="Custome"
          customeButton={
            <CustomButton
              label="Got it"
              onClick={(e) => {
                e.preventDefault();
                setProfile({
                  ...profile,
                  new_email: "",
                  otp: "",
                });
                setEmailChanged(false);
                setEmailPopup(false);
              }}
              className="cta-type-2 w-168 h-40 br-16 mx-auto"
            />
          }
          handleClose={(e) => {
            e.preventDefault();
            setProfile({
              ...profile,
              new_email: "",
              otp: "",
            });
            setEmailPopup(false);
            setEmailChanged(false);
          }}
        />
      </>
      <Container>
        {/* <h2 className="section-heading-2">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <img src="/images/back.png" alt="back" />
            Back to previous page
          </div>
        </h2> */}
        <Row>
          <Col>
            <Form noValidate onSubmit={() => { }}>
              <div className=" type-2">  {/* className="contentBox type-2 */}
                {!helper.IsEmpty(profile) && (
                  <>
                    {/* <div className="pageInfoHeader">
                      <div className="column1">
                        <h2 className="section-heading-2">Edit profiles</h2>
                      </div>v
                    </div> */}
                    {showEditDiv === "personal_information" && <div className="innerBlock">
                      <h3>Personal information settings</h3>
                      <Row>
                        <Col>
                          <div className="ctaRow" style={{ marginTop: 18 }}>
                            <img
                              src="/images/close3.png"
                              alt="remove"
                              className="removePic"
                              onClick={(e) => {
                                e.stopPropagation();
                                setRemoveImagePopup(true);
                              }}
                            />
                            <label className="file-input bold-label" style={{ textAlign: "left" }}>
                              <img
                                className="profile-img"
                                // src={
                                //   profile.profile_image && profile?.sign_in_with_google === "0"
                                //     ? process.env.REACT_APP_API_BASE_URL +
                                //     "/user_profile/" +
                                //     profile.profile_image
                                //     : profile.profile_image && profile?.sign_in_with_google === "1"
                                //       ? profile.profile_image
                                //       : profile.imageURL || "/images/camera.png"
                                // }
                                src={profile?.profile_image ? profile.imageURL || profile.profile_image_url : "/images/camera.png" }
                                alt="upload"
                              ></img>
                              <input
                                accept="image/*"
                                type="file"
                                name="file"
                                onChange={onImageChange}
                                ref={(ref) => (inputEl = ref)}
                              />
                              &nbsp; &nbsp; Upload Photo
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            label="First name"
                            value={profile.first_name}
                            onChange={(e) => {
                              e.preventDefault();
                              handleNameChangeModal();
                            }}
                          />
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-end justify-content-between "
                        >
                          <InputText
                            className="w-90"
                            label="Last name"
                            value={profile.last_name}
                            onChange={(e) => {
                              e.preventDefault();
                              handleNameChangeModal();
                            }}
                          />
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              handleNameChangeModal();
                            }}
                            height={30}
                            className="userpic pb-3 "
                            src="/images/pen.png"
                            alt="user"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            autoComplete="off"
                            label="current address"
                            value={profile.current_address}
                            onChange={(e) => onChange("current_address", e)}
                            error={errors.current_address}
                            maxLength="255"
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            label="address 2 (optional)"
                            value={profile.country}
                            onChange={(e) => onChange("country", e)}
                            maxLength="255"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            autoComplete="off"
                            className="otp-code"
                            label="zip"
                            value={profile.zip}
                            error={errors.zip}
                            onChange={(event) => {
                              if (event.target.value.length > 5) return false;
                              onChange2("zip", event);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            className="otp-code"
                            label="phone (optional)"
                            value={profile.phone}
                            error={errors.phone}
                            onChange={(event) => {
                              if (event.target.value.length > 10) return false;
                              onChange2("phone", event);
                            }}
                          />
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-end justify-content-between "
                        >
                          <InputText
                            className="w-90"
                            label="display email"
                            type="email"
                            value={profile.communication_email || profile.email}
                            onChange={(e) => {
                              e.preventDefault();
                              setEmailPopup(true);
                            }}
                            displayToolTip
                            toolTipText="Enter the email displayed to recruiters. This does not change your login email."
                          />
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              setEmailPopup(true);
                            }}
                            height={30}
                            className="userpic pb-3"
                            src="/images/pen.png"
                            alt="user"
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="job Searching"
                            options={[
                              { value: 0, label: "Active" },
                              { value: 1, label: "Exploring" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.is_seeking_job}
                            error={errors.is_seeking_job}
                            onChange={(e) => onChange("is_seeking_job", e)}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Contact preference"
                            options={
                              !helper.IsEmpty(commonDropdown)
                                ? commonDropdown.contactPreference
                                : []
                            }
                            optionLable="title"
                            optionValue="id"
                            error={errors.contact_preference}
                            value={profile.contact_preference}
                            labelAsValue
                            onChange={(e) => onChange("contact_preference", e)}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Highest level of education"
                            options={
                              !helper.IsEmpty(commonDropdown)
                                ? commonDropdown.highestEducation
                                : []
                            }
                            optionLable="title"
                            optionValue="id"
                            error={errors.degree}
                            value={profile.degree}
                            labelAsValue
                            onChange={(e) => onChange("degree", e)}
                          />
                        </Col>
                      </Row>
                    </div>}
                    {showEditDiv === "authorization" && <div className="innerBlock">
                      <h3 className="mb-4">Authorization</h3>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Legally authorized to work in the USA"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            error={errors.is_us_authorized_emp}
                            value={profile.is_us_authorized_emp}
                            labelAsValue
                            onChange={(e) =>
                              onChange("is_us_authorized_emp", e)
                            }
                            displayToolTip
                            toolTipText="Are you legally authorized to work in the United States?"
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Sponsorship Required"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            error={errors.need_visa_sponsorship}
                            value={profile.need_visa_sponsorship}
                            labelAsValue
                            onChange={(e) =>
                              onChange("need_visa_sponsorship", e)
                            }
                            displayToolTip
                            toolTipText="Will you now or in the future require sponsorship for employment visa status (e.g., H-1B visa status)?"
                          />
                        </Col>
                      </Row>

                    </div>}
                    {showEditDiv === "preferences" && <div className="innerBlock ">
                      <h3 className="pb-0 mb-4">Preferences</h3>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Willing to relocate"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.willing_relocate}
                            labelAsValue
                            error={errors.willing_relocate}
                            onChange={(e) => onChange("willing_relocate", e)}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Willing to work onsite"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.willing_remote_work}
                            labelAsValue
                            error={errors.willing_remote_work}
                            onChange={(e) => onChange("willing_remote_work", e)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Willing to work hazardous conditions"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.willing_hazardous}
                            labelAsValue
                            error={errors.willing_hazardous}
                            onChange={(e) => onChange("willing_hazardous", e)}
                          />
                        </Col> */}

                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="What security clearance do you possess?"
                            options={[
                              { value: "N/A", label: "N/A" },
                              { value: "Public Trust", label: "Public Trust" },
                              { value: "Confidential", label: "Confidential" },
                              { value: "Secret", label: "Secret" },
                              { value: "L Clearance", label: "L Clearance" },
                              { value: "Top Secret", label: "Top Secret" },
                              { value: "Q Clearance", label: "Q Clearance" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.security_clearance_possess}
                            labelAsValue
                            error={errors.security_clearance_possess}
                            onChange={(e) => onChange("security_clearance_possess", e)}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Start Date"
                            options={
                              !helper.IsEmpty(commonDropdown)
                                ? commonDropdown.noticePeriod
                                : []
                            }
                            optionLable="title"
                            optionValue="id"
                            value={profile.notice_period}
                            error={errors.notice_period}
                            onChange={(e) => onChange("notice_period", e)}
                          />
                        </Col>
                        {/* <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Willing to travel"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.willing_travel}
                            labelAsValue
                            error={errors.willing_travel}
                            onChange={(e) => onChange("willing_travel", e)}
                          />
                        </Col> */}
                      </Row>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Do you qualify as a diversity candidate?"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.qualify_diversity_candidate}
                            labelAsValue
                            error={errors.qualify_diversity_candidate}
                            onChange={(e) => onChange("qualify_diversity_candidate", e)}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Do you qualify as a service-disabled veteran?"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.qualify_service_disabled_veteran}
                            labelAsValue
                            error={errors.qualify_service_disabled_veteran}
                            onChange={(e) => onChange("qualify_service_disabled_veteran", e)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="salary_range_wrrpr">
                            <p className="range-title">Salary Range</p>
                            {profile.minimum_amount_type === "per year" ?
                              <Form.Label>
                                <div className='tooltip-container'>
                                  <img
                                    className="range-tooltip"
                                    style={{ margin: '0 8px' }}
                                    src='/images/tooltip.png'
                                    alt='Question mark'
                                    title={"e.g. for $80,000 enter ‘$80’"}
                                  />
                                </div>
                              </Form.Label>
                              : ""}
                            <div className="salary_range_body">
                              <div className="salary_range_data">
                                <span className="salary_span">From</span>
                                {/* <OverlayTrigger
                                  placement="bottom"
                                  overlay={renderTooltip(profile.minimum_amount * 1000)}
                                  show={profile?.minimum_amount_type === "per year"}
                                > */}
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    value={profile.minimum_amount}
                                    onChange={(event) => {

                                      onChange2("minimum_amount", event);
                                    }}
                                    displayToolTip
                                    toolTipText="cvbfg"

                                    disabled={
                                      profile.minimum_amount_type === "negotiable"
                                    }

                                  />
                                </InputGroup>
                                {/* </OverlayTrigger> */}

                              </div>
                              <div className="salary_range_data">
                                <span className="salary_span">To</span>
                                {/* <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip(profile.maximum_amount * 1000)}
                                  show={profile?.minimum_amount_type === "per year"}
                                > */}
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    value={profile.maximum_amount}
                                    onChange={(event) => {
                                      const value = event.target.value.slice(0, 3);
                                      event.target.value = value;
                                      onChange2("maximum_amount", event);
                                    }}
                                    disabled={
                                      profile.minimum_amount_type === "negotiable"
                                    }
                                  />
                                </InputGroup>
                                {/* </OverlayTrigger> */}
                              </div>
                            </div>
                            {errors.maximum_amount && (
                              <span style={{ color: "red" }}>
                                {errors.maximum_amount}
                              </span>
                            )}
                          </div>

                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Salary Type"
                            className="salaryTypField"
                            options={
                              !helper.IsEmpty(commonDropdown)
                                ? commonDropdown.SalaryType
                                : []
                            }
                            optionLable="title"
                            optionValue="value"
                            error={errors.minimum_amount_type}
                            value={profile.minimum_amount_type}
                            labelAsValue
                            onChange={(e) => onChange("minimum_amount_type", e)}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputText
                            className="otp-code"
                            label="Salary Type"
                            placeholder="Example: $ 250,000"
                            error={errors.minimum_amount}
                            value={
                              profile.minimum_amount_type === "negotiable"
                                ? ""
                                : profile.minimum_amount
                            }
                            disabled={
                              profile.minimum_amount_type === "Negotiable"
                            }
                            onChange={(event) => {
                              if (
                                profile.minimum_amount_type === "Per Year" &&
                                event.target.value.length > 7
                              )
                                return false;
                              if (
                                profile.minimum_amount_type === "Per Hour" &&
                                event.target.value.length > 4
                              )
                                return false;
                              onChange2("minimum_amount", event);
                            }}
                          />
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="pb-1"
                        >
                          <div className="mt-3">
                            <div className="customRadioWrap">
                              <InputRadio
                                label="Per hour"
                                name="minimum_amount_type"
                                checked={
                                  profile.minimum_amount_type === "per hour"
                                }
                                onChange={() =>
                                  setProfile((prevState) => {
                                    return {
                                      ...prevState,
                                      minimum_amount_type: "per hour",
                                      minimum_amount: "",
                                    };
                                  })
                                }
                                value="per hour"
                              />
                            </div>
                            <div className="customRadioWrap">
                              <InputRadio
                                label="Per year"
                                name="minimum_amount_type"
                                checked={
                                  profile.minimum_amount_type === "per year"
                                }
                                onChange={() =>
                                  setProfile((prevState) => {
                                    return {
                                      ...prevState,
                                      minimum_amount_type: "per year",
                                      minimum_amount: "",
                                    };
                                  })
                                }
                                value="per year"
                              />
                            </div>
                            <div className="customRadioWrap">
                              <InputRadio
                                label="Negotiable"
                                name="minimum_amount_type"
                                checked={
                                  profile.minimum_amount_type === "negotiable"
                                }
                                onChange={() =>
                                  setProfile((prevState) => {
                                    return {
                                      ...prevState,
                                      minimum_amount_type: "negotiable",
                                      minimum_amount: "",
                                    };
                                  })
                                }
                                value="negotiable"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row> */}
                      <Row>
                        {/* <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <InputSelect
                            label="Willing to work heavy lifting jobs (at least 50lbs)"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                            optionLable="label"
                            optionValue="value"
                            value={profile.willing_heavy_lift}
                            labelAsValue
                            error={errors.willing_heavy_lift}
                            onChange={(e) => onChange("willing_heavy_lift", e)}
                          />
                        </Col> */}

                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Form.Group
                            className={`form-group top-10`}
                            controlId={"formBasicCheckbox" + 1}
                          >
                            <Form.Label>
                              <div>
                                What shifts do you prefer?
                              </div>
                              {errors.shift_type && (
                                <span style={{ color: "red" }}>
                                  {errors.shift_type}
                                </span>
                              )}
                            </Form.Label>
                            <div>
                              <label
                                htmlFor="shift1"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="shift1"
                                  onChange={handleShiftChange}
                                  name="1"
                                  checked={shiftType["1"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Morning</span>
                              </label>
                              <label
                                htmlFor="shift2"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="shift2"
                                  onChange={handleShiftChange}
                                  name="2"
                                  checked={shiftType["2"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Noon</span>
                              </label>
                              <label
                                htmlFor="shift3"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="shift3"
                                  onChange={handleShiftChange}
                                  name="3"
                                  checked={shiftType["3"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Evening</span>
                              </label>
                              <label
                                htmlFor="shift4"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="shift4"
                                  onChange={handleShiftChange}
                                  name="4"
                                  checked={shiftType["4"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">
                                  Night / Graveyard
                                </span>
                              </label>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Form.Group
                            className={`form-group top-10`}
                            controlId={"formBasicCheckbox" + 1}
                          >
                            <Form.Label>
                              <div>
                                What is your desired type of employment?
                              </div>
                              {errors.employment_type && (
                                <span style={{ color: "red" }}>
                                  {errors.employment_type}
                                </span>
                              )}
                            </Form.Label>
                            <div>
                              <label
                                htmlFor="1"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="1"
                                  onChange={handleCheckBoxChange}
                                  name="1"
                                  checked={employmentType["1"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Full Time</span>
                              </label>
                              <label
                                htmlFor="2"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="2"
                                  onChange={handleCheckBoxChange}
                                  name="2"
                                  checked={employmentType["2"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Part Time</span>
                              </label>
                              <label
                                htmlFor="3"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="3"
                                  onChange={handleCheckBoxChange}
                                  name="3"
                                  checked={employmentType["3"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">Contract</span>
                              </label>
                              <label
                                htmlFor="4"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="4"
                                  onChange={handleCheckBoxChange}
                                  name="4"
                                  checked={employmentType["4"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">
                                  Temporary or Seasonal
                                </span>
                              </label>
                              <label
                                htmlFor="5"
                                className="custom-check-box d-flex "
                              >
                                <input
                                  type="checkbox"
                                  id="5"
                                  onChange={handleCheckBoxChange}
                                  name="5"
                                  checked={employmentType["5"]}
                                />
                                <span className={"checkmark"}></span>
                                <span className="check-text">
                                  Internship or co-op
                                </span>
                              </label>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>{" "}
                    </div>}
                    {showEditDiv === "skills" && <div className="innerBlock ">
                      <h3 className="h3-margin-remove mb-4">Skills</h3>
                      <Row className="align-items-end">
                        <Col xxl={9} xl={9} lg={8} md={7} sm={7} xs={9}>
                          <MultiInputSelect
                            label=""
                            isMulti
                            type="skills"
                            placeholder="Enter your skills"
                            value={skillInput}
                            customCreate
                            options={skillList}
                            optionLable="key"
                            optionValue="value"
                            onSelect={(value) => {
                              if (value[value?.length - 1]?.value.length > 255)
                                return;
                              setSkill(value);
                            }}
                            error={errors.UserSkill}
                          />
                        </Col>
                      </Row>
                    </div>}
                    {showEditDiv === "education" && (
                      <div className="innerBlock">
                        <h3 className="h3-margin-remove mb-4">Education</h3>
                        {edus.map((edu, index) => (
                          <div key={index}>
                            {console.log(edu.currently_present, "edu")}
                            <Row>
                              <Col md={6}>
                                <InputText
                                  autoComplete="off"
                                  label="Institution"
                                  placeholder="Institution"
                                  value={edu.collage}
                                  onChange={(e) => onEduFieldChange(index, "collage", e.target.value)}
                                  error={errors[index]?.collage}
                                />
                                {errors[index]?.collage && <span style={{ color: "red" }}>{errors[index].collage}</span>}
                              </Col>
                              <Col md={6}>
                                <InputText
                                  autoComplete="off"
                                  label="Degree"
                                  placeholder="Degree"
                                  value={edu.course}
                                  onChange={(e) => onEduFieldChange(index, "course", e.target.value)}
                                  error={errors[index]?.course}
                                />
                                {errors[index]?.course && <span style={{ color: "red" }}>{errors[index].course}</span>}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} className="education">
                                <Form.Group className="form-group">
                                  <Form.Label className="form-label">Start</Form.Label>
                                  <InputText
                                    onKeyPress={(e) => e.preventDefault()}
                                    placeholder="DD / YYYY"
                                    className="form-control col-4"
                                    value={getFormattedDate(edu.start_date)}
                                  />
                                  <div id="inputGroup-sizing-default" className="calendar-icon">
                                    <img
                                      src={CalendarImg}
                                      alt="calendar"
                                      width="30"
                                      onClick={() => handleStartCalendar(index)}
                                    />
                                  </div>
                                  {startCalendarvalue === index && (
                                    <Calendar
                                      name="start_at"
                                      onChange={(e) => {
                                        onEduFieldChange(index, "start_date", e);
                                        handleStartCalendar(index);
                                      }}
                                    />
                                  )}
                                  {errors[index]?.start_date && <span style={{ color: "red" }}>{errors[index].start_date}</span>}
                                </Form.Group>
                              </Col>
                              <Col md={4} className="education">
                                <Form.Group className="form-group">
                                  <Form.Label className="form-label">End</Form.Label>
                                  <InputText
                                    onKeyPress={(e) => e.preventDefault()}
                                    placeholder="DD / YYYY"
                                    className="form-control col-4"
                                    value={edu.currently_present === '1' ? getFormattedDate(moment().format()) : getFormattedDate(edu.end_date)}
                                    disabled={edu.currently_present}
                                  />
                                  {edu.currently_present === '0' && (
                                    <>
                                      <div id="inputGroup-sizing-default" className="calendar-icon">
                                        <img
                                          src={CalendarImg}
                                          alt=""
                                          width="30"
                                          onClick={() => handleEndCalendar(index)}
                                        />
                                      </div>
                                      {endCalendarvalue === index && (
                                        <Calendar
                                          name="end_at"
                                          onChange={(e) => {
                                            onEduFieldChange(index, "end_date", e);
                                            handleEndCalendar(index);
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                  {errors[index]?.end_date && <span style={{ color: "red" }}>{errors[index].end_date}</span>}
                                </Form.Group>
                              </Col>
                              <Col md={4} style={{ marginTop: "30px" }}>
                                <InputCheckbox
                                  label="Present"
                                  checked={parseInt(edu.currently_present)}
                                  controlId={`edu-${index}`}
                                  onChange={(e) => onPresentEduChange(index, e.target.checked)}
                                ></InputCheckbox>
                                <img
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeEduFromlist(index);
                                  }}
                                  height={25}
                                  src="/images/minus-button.png"
                                  alt="remove"
                                  className="remove-bob-btn"
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button className="add-btn mt-4" onClick={addNewEdu}>
                          <img src="/images/plus.png" alt="add" className="addImg" />
                          Add Education
                        </Button>
                      </div>
                    )}
                    {showEditDiv === "job_history" && <div className="innerBlock">
                      <h3 className="mb-0">Job history</h3>
                      {jobs.map((job, index) => (
                        <>
                          <Row>
                            <Col md={6}>
                              <InputText
                                autoComplete="off"
                                label="Company"
                                placeholder="Company"
                                value={job.company}
                                onChange={(e) =>
                                  onJobFieldChange2(
                                    index,
                                    "company",
                                    e.target.value
                                  )
                                }
                                error={errors["company" + index]}
                              />
                            </Col>
                            <Col md={6} className="d-flex align-items-end">
                              <div className="w-90 mr-5">
                                <MultiInputSelect
                                  customCreate
                                  type="position"
                                  label="Position"
                                  options={[]}
                                  placeholder="Position"
                                  loadOptions={(value, callback = () => { }) => {
                                    if (value)
                                      candidateActions.getPosition(
                                        { st: value },
                                        (response) => {
                                          if (
                                            response.status ===
                                            utilConstants.sucessState200
                                          ) {
                                            var options = [];
                                            response.data.map((v) => {
                                              options.push({
                                                label: v,
                                                value: v,
                                              });
                                            });
                                            callback(options);
                                          }
                                        }
                                      );
                                  }}
                                  value={
                                    job.position > ""
                                      ? [
                                        {
                                          value: job.position,
                                          label: job.position,
                                        },
                                      ]
                                      : []
                                  }
                                  error={errors["position" + index]}
                                  onSelect={(value) => {
                                    if (value?.value?.length > 255) return;
                                    onJobFieldChange2(
                                      index,
                                      "position",
                                      value?.value
                                    );
                                  }}
                                  onChange={(e) =>
                                    onJobFieldChange2(
                                      index,
                                      "position",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <img
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeJobFromHistory(index);
                                }}
                                height={25}
                                src="/images/minus-button.png"
                                alt="remove"
                                className="remove-bob-btn"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="edit_textarea">
                              <InputTextarea
                                className={"myturn-mt-0 "}
                                placeholder="summary"
                                label="summary"
                                defaultValue={job.summary}
                                onChange={(e) =>
                                  onJobFieldChange2(
                                    index,
                                    "summary",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Form.Group className="form-group">
                                <Form.Label className="form-label">
                                  Start
                                </Form.Label>
                                <input
                                  onKeyPress={(e) => e.preventDefault()}
                                  placeholder="DD / YYYY"
                                  className="form-control col-4"
                                  value={getFormattedDate(job.start_date)}
                                />
                                <div
                                  id="inputGroup-sizing-default"
                                  className="calendar-icon"
                                >
                                  <img
                                    src={CalendarImg}
                                    alt="calendar"
                                    width="30"
                                    onClick={() => handleStartCalendar(index)}
                                  />
                                </div>
                                {startCalendarvalue === index && (
                                  <Calendar
                                    name="start_at"
                                    onChange={(e) => {
                                      onJobFieldChange(index, "start_date", e);
                                      handleStartCalendar(index);
                                    }}
                                  />
                                )}
                                {errors["start_date" + index] && (
                                  <span style={{ color: "red" }}>
                                    {errors["start_date" + index]}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="form-group ">
                                <Form.Label className="form-label">
                                  End
                                </Form.Label>
                                <input
                                  onKeyPress={(e) => e.preventDefault()}
                                  placeholder="DD / YYYY"
                                  className="form-control col-4"
                                  value={
                                    +job.currently_working
                                      ? getFormattedDate(moment().format())
                                      : getFormattedDate(job.end_date)
                                  }
                                  disabled={+job.currently_working}
                                />

                                {!+job.currently_working && (
                                  <>
                                    <div
                                      id="inputGroup-sizing-default"
                                      className="calendar-icon"
                                    >
                                      <img
                                        src={CalendarImg}
                                        alt=""
                                        width="30"
                                        onClick={() => handleEndCalendar(index)}
                                      />
                                    </div>
                                    {endCalendarvalue === index && (
                                      <Calendar
                                        name="end_at"
                                        onChange={(e) => {
                                          onJobFieldChange(
                                            index,
                                            "end_date",
                                            e
                                          );
                                          handleEndCalendar(index);
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                {errors["end_date" + index] && (
                                  <span style={{ color: "red" }}>
                                    {errors["end_date" + index]}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={4} style={{ marginTop: "30px" }}>
                              <InputCheckbox
                                label="Current Company"
                                checked={+job.currently_working}
                                controlId={`job-${index}`}
                                onChange={(e) => {
                                  onCurrentCompanyChange(
                                    index,
                                    e.target.checked
                                  );
                                }}
                              ></InputCheckbox>
                            </Col>
                          </Row>

                        </>
                      ))}
                      {errors.jobs && (
                        <div className="invalid-feedback d-block">
                          {errors.jobs}
                        </div>
                      )}
                      <Button className="add-btn mt-4 " onClick={addNewJob}>
                        <img
                          src="/images/plus.png"
                          alt="add"
                          className="addImg"
                        />
                        Add job
                      </Button>
                    </div>}
                    {/* <div className="innerBlock">
                      {showEditDiv === "job_history" && <div>
                        <h3>Attachments</h3>
                        <div className="custom-upload-box">
                          <InputFile
                            onDrop={(files) => {
                              uploadResume(files);
                            }}
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            label="resume"
                          />
                        </div>
                      </div>
                      } */}
                    {/* {showEditDiv === "job_history" && <Row className="align-items-center">
                        {!helper.IsEmpty(profile.cv_path) && (
                          <Col xs="auto">
                            <div className="wrapperRow flex-column mt-3">
                              <p className="row1">Resume</p>
                              <p className="row2">
                                <div className="uploadBox2">
                                  <span className="uploadIcon">
                                    <img
                                      className="userpic"
                                      src="/images/uploadIcon.png"
                                      alt="user"
                                    />
                                  </span>
                                  <img
                                    className="fileIcon"
                                    src="/images/fileText.png"
                                    alt="file"
                                  />
                                  <label className="fileName">
                                    {`${profile.first_name} ${profile.last_name} - resume`}
                                  </label>
                                  <div className="cta-panel">
                                    <button
                                      onClick={downloadResume}
                                      className="button-design cursor-pointer"
                                    >
                                      <p className="btn-text-design">
                                        View Resume
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </p>
                            </div>
                          </Col>
                        )}
                      </Row>} */}
                    {showEditDiv && <div className="cta-panel edit-candidate-cta-panel d-flex justify-content-end mt-16" style={{ marginBottom: "16px" }}>
                      <CustomButton
                        className="tertiary-button"
                        // label="Cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCandidateEditForm(false);
                          setShowEditDiv("");
                        }}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit();
                        }}
                        isLoading={isLoading}
                        className="primary-filed-orange"
                      // label="Save"
                      >
                        <img className="crct-img" src={tick} alt="" />
                        Save
                      </CustomButton>
                    </div>}
                    {/* </div> */}
                  </>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateEditProfile);
