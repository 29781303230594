import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { SIGN_OUT } from "./utility/constants/typesConstants";
import { GoogleOAuthProvider } from "@react-oauth/google";
if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}
const clearReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    localStorage.clear();
    state = undefined;
  }
  return rootReducer(state, action);
};

const store = createStore(clearReducer, applyMiddleware(thunkMiddleware));
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="1038011222422-j588musujaecicpvetgttbpprmsn3op6.apps.googleusercontent.com">
          <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
