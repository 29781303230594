import SavedCandidateFullVersion from "./../components/employer/saved-candidate/SavedCandidateFullVersionWithJob";
import SavedCandidate from "./../components/employer/saved-candidate/SavedCandidate";

import Dashboard from "../components/employer/dashboard/Dashboard";
import PublicProfile from "./../components/profile/PublicProfile";
import EditProfile from "./../components/employer/profile/EditProfile";
import AccountSettings from "../components/account-settings";
import ContactList from "../components/employer/contactList/ContactList";
import searchResults from "../components/search-results/search-results";
import MyJobs from "../components/employer/my-jobs/MyJobs";
import PostJob from "../components/employer/my-jobs/PostJob";
import ReviewJob from "../components/employer/my-jobs/ReviewJob";
import PricingPlan from "components/employer/pricing-plan/PricingPlan";

export default [
  {
    name: "Saved Candidate",
    path: "/SavedCandidate",
    exact: true,
    component: SavedCandidate,
  },
  {
    name: "Saved Candidate",
    path: "/SavedCandidateFullVersion",
    exact: true,
    component: SavedCandidateFullVersion,
  },
  // {
  //   name: "My Dashboard",
  //   path: "/Dashboard",
  //   exact: true,
  //   component: Dashboard,
  // },
  {
    name: "profile",
    path: "/PublicProfile/:id",
    exact: true,
    component: PublicProfile,
  },
  {
    name: "Edit Profile",
    path: "/edit_profile",
    exact: true,
    component: EditProfile,
  },
  {
    name: "Contact List",
    path: "/contact-list",
    exact: true,
    component: ContactList,
  },
  {
    name: "Search Results",
    path: "/SearchResults",
    exact: true,
    component: searchResults,
  },
  {
    name: "Account Settings",
    path: "/account-settings",
    exact: false,
    component: AccountSettings,
  },
  {
    name: "My Jobs",
    path: "/my-jobs",
    exact: false,
    component: MyJobs,
  },
  {
    name: "Post Jobs",
    path: "/post-jobs",
    exact: false,
    component: PostJob,
  },
  {
    name: "Review Job",
    path: "/review-job",
    exact: false,
    component: ReviewJob,
  },
  {
    name: "Pricing Plan",
    path: "/post-a-job",
    exact: false,
    component: PricingPlan,
  },
];
