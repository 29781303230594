import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditImage from "../../../assets/images/edit.svg"
import * as commonActions from "../../../actions/commonActions";
import {
  formatDate,
  IsEmpty,
} from "../../../utility/helper/helper";
import EditProfile from "../profile/EditProfile";
import CandidateEditProfile from "../profile/CandidateEditProfile";
import { CommonContext } from "context/commonContext";
import ProfilePercentageCalculation from "../common/ProfilePercentageCalculation";
function Dashboard(props) {
  const [user, setUser] = useState();
  const [commonDropdown, setCommonDropdon] = useState([]);
  const { isCandidateEditForm, setIsCandidateEditForm, showEditDiv, setShowEditDiv } = useContext(CommonContext);



  useEffect(() => {
    if (props.state.commonReducer) props.commonActions.signinUserDetails();
    setIsCandidateEditForm(false);
  }, []);
  useEffect(() => {
    if (
      IsEmpty(props.state.commonReducer.signedUserDetail) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.signinUserDetails();
    else {
      let { signedUserDetail } = props.state.commonReducer;
      if (signedUserDetail) {
        let tempDate = { ...signedUserDetail };
        delete tempDate.CandidateProfile;
        tempDate = { ...tempDate, ...signedUserDetail["CandidateProfile"] };
        setUser(tempDate);
      }
    }
    if (
      IsEmpty(props.state.commonReducer.contactPreference) ||
      IsEmpty(props.state.commonReducer.employmentType)
    ) {
      if (
        !props.state.commonReducer.isLoading &&
        IsEmpty(props.state.commonReducer.workShift)
      )
        props.commonActions.getCommonDropdownList();
    } else {
      let {
        employmentType,
        contactPreference,
        highestEducation,
        noticePeriod,
        workShift,
        SalaryType,
      } = props.state.commonReducer;
      setCommonDropdon({
        employmentType,
        contactPreference,
        highestEducation,
        noticePeriod,
        workShift,
        SalaryType,
      });
    }
  }, [props.state.commonReducer]);

  const formateSkills = (skills) => {
    if (typeof skills === "string") skills = JSON.parse(skills);
    return skills?.map((v, k) => (
      <li key={k}>
        <label
          title={v}
          className={
            k % 3 === 0
              ? "bg-light-green text-green"
              : k % 2 === 0
                ? "bg-light-orange text-orange"
                : "bg-light-blue text-blue"
          }
        >
          {v?.length > 20 ? `${v.substring(0, 20)}...` : v}
        </label>
      </li>
    ));
  };

  const jobShiftType = {
    1: "Morning",
    2: "Noon",
    3: "Evening",
    4: "Night/Graveyard",
  };

  const employmentTypeMap = {
    1: "Full Time",
    2: "Part Time",
    3: "Contract",
    4: "Temporary or Seasonal",
    5: "Internship or co-op",
  };

  const shiftArr = [];
  const employmentArr = [];

  const jobShiftTypeArr = user?.job_shift_type?.split(",");
  jobShiftTypeArr?.forEach((shiftType) =>
    shiftArr.push(jobShiftType[shiftType])
  );

  const employmentTypeArr = user?.desired_employment?.split(",");
  employmentTypeArr?.forEach((employmentType) =>
    employmentArr.push(employmentTypeMap[employmentType])
  );

  const downloadResume = (e) => {
    e.preventDefault();
    commonActions.downloadCandidateProfilePDF(user.id, (response) => {
      window.open(
        response,
        "_blank",
        "width=500,height=500, top=100, left=500, noopener, noreferrer"
      );
    });
  };
  const handleEditButtonClick = (formType) => {
    setIsCandidateEditForm(true);
    setShowEditDiv(formType);
  }

  const salryamt = user?.minimum_amount_type === "per year" ? "K" : ""

  return (
    <div>
      {user && (
        <div>
          <div className="edit_heading">
            <h2>My Profile</h2>
          </div>
          <div className="personInfoHeader">
            <div className="complete_profile_wrrpr">
              <div className="column1">
                <img
                  className="userpic"
                  // src={
                  //   user.sign_in_with_google === "1"
                  //     ? user.profile_image
                  //       ? user.profile_image
                  //       : "/images/userpic.png"
                  //     : user.profile_image && user.sign_in_with_google === "0"
                  //       ? process.env.REACT_APP_API_BASE_URL + "/user_profile/" + user.profile_image
                  //       : "/images/userpic.png"
                  // }
                  src={user?.profile_image ? user.profile_image_url : "/images/userpic.png"}
                  alt="user"
                />
              </div>
              <div className="column2">
                <label className="edit_profile_title">
                  {user?.first_name + " " + user?.last_name.charAt(0) + "."}
                </label>
                {/* <label className="row3">{user.email}</label> */}
                {+user.pause_account === 1 && (
                  <label style={{ color: "red", fontStyle: "italic" }}>
                    ACCOUNT PAUSED (to resume, see Account Settings)
                  </label>
                )}
              </div>
              <div className="column3">
                <div className="complete_profile">
                  <ProfilePercentageCalculation candidateDetails={user} />
                </div>
              </div>
            </div>

            {isCandidateEditForm && showEditDiv === "personal_information" ? <CandidateEditProfile />
              : <div className="inner_block_wrrpr">
                <div className="innerBlock">
                  <div className="personal_information_setting">
                    <h3>Personal information settings</h3>
                    <Button onClick={() => handleEditButtonClick("personal_information")} className="tertiary-button-small centered">Edit<img className="ml-10" src={EditImage} /></Button>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">First Name</p>
                      <p className="row2">{user.first_name}</p>
                    </div>
                    <div className="right">
                      <p className="row1">Last Name</p>
                      <p className="row2">{user.last_name} </p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Address</p>
                      <p className="row2">
                        {user.current_address && user.current_address}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">Zipcode</p>
                      <p className="row2">{user.zip !== 0 && user.zip}</p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Address 2</p>
                      <p className="row2">{user.country && user.country}</p>
                    </div>
                    <div className="right">
                      <p className="row1">Contact Preference</p>
                      <p className="row2">
                        {user.contact_preference &&
                          commonDropdown.contactPreference &&
                          commonDropdown.contactPreference.filter(
                            (obj) =>
                              obj.id === parseInt(user.contact_preference)
                          )[0]?.title}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Contact Email</p>
                      <p className="row2 not_capitalized">{user.communication_email}</p>
                    </div>
                    <div className="right">
                      <p className="row1">Phone</p>
                      <p className="row2">
                        {user.phone !== 0 && user.phone}
                      </p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Highest Level Of Education</p>
                      <p className="row2">
                        {user.degree &&
                          commonDropdown.highestEducation &&
                          commonDropdown.highestEducation.filter(
                            (obj) => obj.id === parseInt(user.degree)
                          )[0]?.title}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">Job Searching</p>
                      <p className="row2">
                        {parseInt(user.is_seeking_job) === 0
                          ? "Active"
                          : "Exploring"}
                      </p>
                    </div>
                  </div>

                </div>
              </div>}

            {isCandidateEditForm && showEditDiv === "authorization" ? <CandidateEditProfile />
              : <div className="inner_block_wrrpr">
                <div className="innerBlock">
                  <div className="personal_information_setting">
                    <h3>Authorization</h3>
                    <Button onClick={() => handleEditButtonClick("authorization")} className="tertiary-button-small centered">Edit<img className="ml-10" src={EditImage} /></Button>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">
                        Legally authorized to work in USA?
                      </p>
                      <p className="row2">
                        {parseInt(user.is_us_authorized_emp) === 0
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">Do you require sponsorship?</p>
                      <p className="row2">
                        {parseInt(user.need_visa_sponsorship) === 0
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                  </div>

                </div>
              </div>}
            {isCandidateEditForm && showEditDiv === "preferences" ? <CandidateEditProfile />
              : <div className="inner_block_wrrpr">
                <div className="innerBlock">
                  <div className="personal_information_setting">
                    <h3>Preferences</h3>
                    <Button onClick={() => handleEditButtonClick("preferences")} className="tertiary-button-small centered">Edit<img className="ml-10" src={EditImage} /></Button>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Willing to relocate?</p>
                      <p className="row2">
                        {parseInt(user.willing_relocate) === 0 ? "No" : "Yes"}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">Willing to work onsite?</p>
                      <p className="row2">
                        {parseInt(user.willing_remote_work) === 0
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                  </div>

                  <div className="wrapperRow">
                    {/* <div className="left">
                  <p className="row1">
                    Willing to work hazardous conditions
                  </p>
                  <p className="row2">
                    {parseInt(user.willing_hazardous) === 0 ? "No" : "Yes"}
                  </p>
                </div> */}
                    {/* <div className="left">
                  <p className="row1">Willing to travel</p>
                  <p className="row2">
                    {parseInt(user.willing_travel) === 0 ? "No" : "Yes"}
                  </p>
                </div> */}
                    <div className="left">
                      <p className="row1">What security clearance do you possess?</p>
                      <p className="row2">
                        {user.security_clearance_possess}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">Start Date</p>
                      <p className="row2">
                        {user.notice_period &&
                          commonDropdown.noticePeriod &&
                          commonDropdown.noticePeriod.filter(
                            (obj) => obj.id === parseInt(user.notice_period)
                          )[0].title}
                      </p>
                    </div>
                  </div>

                  <div className="wrapperRow">
                    {/* <div className="left">
                      <p className="row1">Salary Type</p>
                      <p className="row2">
                       { user?.minimum_amount_type}
                      </p>
                    </div> */}
                    <div className="right">
                      <p className="row1">
                        Salary Range
                      </p>
                      <p className="row2">
                        {user?.minimum_amount_type !== "negotiable" && user?.minimum_amount && user?.maximum_amount && ` $${user.minimum_amount}${salryamt} - $${user.maximum_amount}${salryamt} ${user.minimum_amount_type}`}
                        {user?.minimum_amount_type === "negotiable" && <>Negotiable</>}
                      </p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    <div className="left">
                      <p className="row1">Do you qualify as a diversity candidate?</p>
                      <p className="row2">
                        {parseInt(user.qualify_diversity_candidate) === 0
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                    <div className="right">
                      <p className="row1">
                        Do you qualify as a service-disabled veteran?
                      </p>
                      <p className="row2">
                        {parseInt(user.qualify_service_disabled_veteran) === 0
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                  </div>
                  <div className="wrapperRow">
                    {user.job_shift_type && (
                      <div className="left">
                        <p className="row1">What shift do you prefer?</p>
                        <p className="row2">{shiftArr.join(",")}</p>
                      </div>
                    )}

                    <div className="right">
                      <p className="row1">What is your desired type of employment?</p>
                      <p className="row2">{employmentArr.join(",")}</p>
                    </div>
                  </div>
                </div>
              </div>}
            {/* {isCandidateEditForm && showEditDiv === "skills" ? <CandidateEditProfile />
              :<div className="inner_block_wrrpr">
                <div className="innerBlock">
                  <div className="personal_information_setting">
                    <h3>Skills</h3>
                    <Button onClick={() => handleEditButtonClick("skills")} className="tertiary-button-small centered">Edit<img className="ml-10" src={EditImage} /></Button>
                  </div>
                  <ul className="indicatorLabels">
                    {formateSkills(user?.UserSkill?.skill)}
                  </ul>

               
                </div>
            </div> } */}

            {/* {isCandidateEditForm && showEditDiv === "job_history" ? <CandidateEditProfile />
              :  <div className="inner_block_wrrpr">
                <div className="innerBlock">
                  <div className="personal_information_setting">
                    <h3>Job history</h3>
                    <Button onClick={() => handleEditButtonClick("job_history")} className="tertiary-button-small centered">Edit<img className="ml-10" src={EditImage} /></Button>
                  </div>
                  {user?.JobHistories?.map((job) => (
                    <>
                      <div className="wrapperRow">
                        <div className="left d-flex align-items-center job-history-row myturn-w-100">
                          <p className="row1 w-auto">{job.company}</p>
                          <p className="row1 w-auto  ms-4">{job.position}</p>
                          <p className="row2 w-auto ms-4">
                            {formatDate(job.start_date, "MM/DD/YYYY")} -
                            &nbsp;{formatDate(job.end_date, "MM/DD/YYYY")}
                          </p>
                          <p
                            className="row2 w-auto  ms-4"
                            title={job?.summary}
                          >
                            {job?.summary?.length > 20
                              ? `${job.summary.substring(0, 20)}...`
                              : job.summary}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p
                          className="row2 w-auto text-ellipsis"
                          title="A job description summarizes the essential responsibilities, activities, qualifications and skills for a role. Also known as a JD, this document describes the type of work performed. ... It may also specify to whom the position reports and salary range."
                        >
                          {job?.description}
                        </p>
                      </div>
                    </>
                  ))}
               
                </div>
            </div> } */}
            {/* {!IsEmpty(user.cv_path) && (
              <div className="innerBlock">
                <h3>Attachments</h3>
                <div className="wrapperRow flex-column">
                  <p className="row1">Resume</p>
                  <p className="">
                    <div className="uploadBox2">
                      <span className="uploadIcon">
                        <img
                          className="userpic"
                          src="/images/uploadIcon.png"
                          alt="user"
                        />
                      </span>
                      <img
                        className="fileIcon"
                        src="/images/fileText.png"
                        alt="file"
                      />
                      <label className="fileName">
                        {`${user.first_name} ${user.last_name} - resume`}
                      </label>
                      <div>
                        <button
                          onClick={downloadResume}
                          className="button-design cursor-pointer"
                        >
                          <p className="btn-text-design">View Resume</p>
                        </button>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
