import React, { useState, useRef } from "react";
import { FileDrop } from "react-file-drop";
import { IsEmpty } from "../../../utility/helper/helper";
export default function InputFile({
  multiple = false,
  accept = ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  onFrameDragEnter = () => {},
  onFrameDragLeave = () => {},
  onFrameDrop = () => {},
  onDragOver = () => {},
  onDragLeave = () => {},
  onDrop = () => {},
  onCancel = () => {},
  label,
  setProfile,
}) {
  let inputEl = useRef(null);
  const [logoSrc, setLogoSrc] = useState("/images/upload3.png");
  const [selectedFilesName, setSelectedFilesName] = useState([]);
  const preOnDrop = (files, event) => {
    if (!IsEmpty(files)) {
      switch (files[0].type) {
        case "image/jpeg":
          setLogoSrc("");
          break;
        case "application/pdf":
          setLogoSrc("/images/doc.png");
          break;
        default:
          break;
      }
      setSelectedFilesName(Array.from(files));
      onDrop(files, event);
    }
  };
  const clearSelection = () => {
    setLogoSrc("/images/upload2.png");
    setSelectedFilesName([]);
    onCancel();
  };
  return (
    <FileDrop
      onFrameDragEnter={onFrameDragEnter} //event
      onFrameDragLeave={onFrameDragLeave} //event
      onFrameDrop={onFrameDrop} //event
      onDragOver={onDragOver} //event
      onDragLeave={onDragLeave} //event
      onDrop={preOnDrop} //files, event
    >
        <img src={logoSrc} alt="upload" className="uploadImg mx-auto" />
        <input
          type="file"
          multiple={multiple}
          accept={accept}
          ref={(ref) => (inputEl = ref)}
          style={{ display: "none" }}
          onChange={(event) => {
            preOnDrop(event.target.files, event);
          }}
        />
        {IsEmpty(selectedFilesName) ? (
          <div className="instructionText">
            Drag your {label || "files"} here <a
              className="cursor-pointer" onClick={(e) => {
                e.preventDefault();
                inputEl.click();
              }}> </a> 
              <br /> or  &nbsp;
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                inputEl.click();
              }}
            >
              browse
            </a>
          </div>
        ) : (
          <div className="instructionText">
            {selectedFilesName.map((file) => {
              return (
                <>
                  {file.name}
                  <br />
                </>
              );
            })}
          </div>
        )}
    </FileDrop>
  );
}
