import React, { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from "../../search-autocomplete";
import { IsEmpty } from "../../../utility/helper/helper";
import useDebounce from "../../hooks/usedebounce";
export default function InputSearch({
  callBackOnSearch,
  onSuggestionSelect,
  defaultValue,
}) {
  const [inputValue, setInputValue] = useState("");
  const [beforedebounce, setbeforedebounce] = useState(false);
  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const [items, setItems] = useState([]);
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  useEffect(() => {
    if (debouncedSearchTerm !== "" && beforedebounce) {
      if (!IsEmpty(debouncedSearchTerm)) {
        callBackOnSearch(debouncedSearchTerm, (results) => {
          if (!IsEmpty(results)) setItems(results);
        });
      }
    }
  }, [debouncedSearchTerm, beforedebounce]);
  const handleOnSearch = (string) => {
    setInputValue(string);
    setbeforedebounce(true);
  };
  const handleOnSelect = (item) => {
    setbeforedebounce(false);
    setInputValue(item.name);
    onSuggestionSelect(item.name);
  };

  const onSubmit = (e) => {
    setbeforedebounce(false);
    e.preventDefault();
    if (inputValue.length > 13) {
      clearTimeout(inputValue);
      setTimeout(function () {
        onSuggestionSelect(inputValue);
      }, 3000);
    } else onSuggestionSelect(inputValue);
  };
  const handleOnClear = () => {
    setInputValue("");
    onSuggestionSelect("");
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex align-items-center react-search-autocomplete">
        <div className=" w-100">
          <ReactSearchAutocomplete
            placeholder="Enter search keywords here"
            showIcon={false}
            items={items}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            onClear={handleOnClear}
            styling={{ zIndex: 2 }}
            inputSearchString={defaultValue}
            autoFocus
            showClear
          />
        </div>
        <img
          role={"button"}
          itemType="submit"
          onClick={onSubmit}
          src="/images/search.png"
        ></img>
      </div>
    </form>
  );
}
