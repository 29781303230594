import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import InputText from "../common/inputText/InputText";
import * as commonActions from "../../actions/commonActions";
import { updateCompanyDetails } from "../../actions/account";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import { IsValidWebUrl } from "../../utility/helper/helper";
import AddressAutocomplete from "../common/addressAutocomplete/AddressAutocomplete";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import {
  getErrorStatus,
  getSuccessStatus,
} from "../../utility/constants/utilConstants";
import { Link } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function Company({ user, reloadProfile }) {


  const employerId = localStorage.getItem("employer_id");
  let initialWorkingHours = {
    Monday: { start: "9 AM", end: "5 Pm" },
    Tuesday: { start: "9 AM", end: "5 PM" },
    Wednesday: { start: "9 AM", end: "5 PM" },
    Thursday: { start: "9 AM", end: "5 PM" },
    Friday: { start: "9 AM", end: "5 PM" },
    Saturday: { start: "-", end: "-" },
    Sunday: { start: "-", end: "-" },
  };
  const [workingHours, setWorkingHours] = useState(initialWorkingHours);
  const [showContent, setShowContent] = useState(false);
  const [data, setData] = useState({
    company_logo: "",
    company_picture: "",
    company_address: "",
    company_city: "",
    company_name: "",
    company_contact: "",
    company_state: "",
    company_website: "",
    company_zip: "",
    company_since: "",
    social_slack: "",
    social_twitter: "",
    social_linkedin: "",
    social_github: "",
    company_ceo: "",
    company_ciso: "",
    company_employee_count: null,
    company_industry: "",
    company_description: "",
  });

  let companyTimingHours = {
    monday_opening_time: workingHours.Monday.start,
    tuesday_opening_time: workingHours.Tuesday.start,
    wednesday_opening_time: workingHours.Wednesday.start,
    thursday_opening_time: workingHours.Thursday.start,
    friday_opening_time: workingHours.Friday.start,
    saturday_opening_time: workingHours.Saturday.start,
    sunday_opening_time: workingHours.Sunday.start,
    monday_closing_time: workingHours.Monday.end,
    tuesday_closing_time: workingHours.Tuesday.end,
    wednesday_closing_time: workingHours.Wednesday.end,
    thursday_closing_time: workingHours.Thursday.end,
    friday_closing_time: workingHours.Friday.end,
    saturday_closing_time: workingHours.Saturday.end,
    sunday_closing_time: workingHours.Sunday.end,
  };

  const [errors, setErrors] = useState({
    company_address: "",
    company_logo: "",
    company_picture: "",
    company_city: "",
    company_name: "",
    company_state: "",
    company_website: "",
    company_zip: "",
    company_ceo: "",
    company_employee_count: "",
    company_industry: "",
    company_contact: "",
    company_description: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [unit, setUnit] = useState("Thousand");
  const [combinedValue, setCombinedValue] = useState("");

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onChange2 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onImageChange = (e, vlu) => {
    const file = e.target.files[0];
    URL.createObjectURL(file);
    const formData = new FormData();
    formData.append("profile", file);
    commonActions.uploadProfile(formData, (response) => {
      if (response.file) {
        const updatedData = {
          ...data,
        };
        if (vlu) {
          updatedData.company_logo = response.file;
        } else {
          updatedData.company_picture = response.file;
        }
        setData(updatedData);
      }
    });
  };

  useEffect(() => {
    if (user?.EmployerProfile) {
      setData({
        company_address: user?.EmployerProfile?.company_address || "",
        company_logo: user.EmployerProfile.company_logo,
        company_picture: user.EmployerProfile.company_picture,
        company_ceo: user.EmployerProfile.company_ceo,
        company_ciso: user.EmployerProfile.company_ciso,
        company_industry: user.EmployerProfile.company_industry,
        company_contact: user.EmployerProfile.company_contact,
        company_employee_count: user.EmployerProfile.company_employee_count,
        company_description: user.EmployerProfile.company_description,
        company_since: user.EmployerProfile.company_since,
        company_city: user.EmployerProfile.company_city,
        company_name: user.EmployerProfile.company_name,
        company_state: user.EmployerProfile.company_state,
        company_website: user.EmployerProfile.company_website,
        company_zip: user.EmployerProfile.company_zip,
        social_slack: user.EmployerProfile.social_slack,
        social_twitter: user.EmployerProfile.social_twitter,
        social_linkedin: user.EmployerProfile.social_linkedin,
        social_github: user.EmployerProfile.social_github,
      });
      const parts = user.EmployerProfile.company_revenue
        .replace("$", "")
        .split(" ");
      setNumber(parts[0]);
      setUnit(parts.slice(1).join(" "));
      initialWorkingHours = {
        Monday: {
          start: user.EmployerProfile.monday_opening_time,
          end: user.EmployerProfile.monday_closing_time,
        },
        Tuesday: {
          start: user.EmployerProfile.tuesday_opening_time,
          end: user.EmployerProfile.tuesday_closing_time,
        },
        Wednesday: {
          start: user.EmployerProfile.wednesday_opening_time,
          end: user.EmployerProfile.wednesday_closing_time,
        },
        Thursday: {
          start: user.EmployerProfile.thursday_opening_time,
          end: user.EmployerProfile.thursday_closing_time,
        },
        Friday: {
          start: user.EmployerProfile.friday_opening_time,
          end: user.EmployerProfile.friday_closing_time,
        },
        Saturday: {
          start: user.EmployerProfile.saturday_opening_time,
          end: user.EmployerProfile.saturday_closing_time,
        },
        Sunday: {
          start: user.EmployerProfile.sunday_opening_time,
          end: user.EmployerProfile.sunday_closing_time,
        },
      };
      setWorkingHours(initialWorkingHours);
      setCombinedValue(user.EmployerProfile.company_revenue);
    }
  }, [user?.EmployerProfile?.company_address]);

  const validateForm = () => {
    let newErrors = {};
    if (!data.company_address) {
      newErrors.company_address = "Address is required";
    }
    if (!data.company_logo) {
      newErrors.company_logo = "Logo is required";
    } else {
      const allowedExtensions = [".jpg", ".jpeg", ".png"];
      const fileExtension = data.company_logo
        .toLowerCase()
        .slice(((data.company_logo.lastIndexOf(".") - 1) >>> 0) + 2);
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        newErrors.company_logo =
          "Invalid file format. Allowed extensions: jpg, jpeg, png";
      }
    }
    if (data.company_picture) {
      const allowedExtensions = [".jpg", ".jpeg", ".png"];
      const fileExtension = data.company_picture
        .toLowerCase()
        .slice(((data.company_picture.lastIndexOf(".") - 1) >>> 0) + 2);
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        newErrors.company_picture =
          "Invalid file format. Allowed extensions: jpg, jpeg, png";
      }
    }
    if (!data.company_city) {
      newErrors.company_city = "City is required";
    } else if (/\d/.test(data.company_city)) {
      newErrors.company_city = "City should not contain numbers";
    }
    if (!data.company_state) {
      newErrors.company_state = "State is required";
    } else if (/\d/.test(data.company_state)) {
      newErrors.company_state = "State should not contain numbers";
    }
    if (!data.company_name) {
      newErrors.company_name = "Name is required";
    }
    if (!data.company_ceo) {
      newErrors.company_ceo = "CEO Name is required";
    }
    if (!data.company_employee_count || data.company_employee_count < 1) {
      newErrors.company_employee_count = "Employee is required";
    }
    if (!data.company_industry) {
      newErrors.company_industry = "Industry is required";
    }
    if (!data.company_contact) {
      newErrors.company_contact = "Contact is required";
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(data.company_contact)) {
        newErrors.company_contact = "Please enter a valid email address";
      }
    }
    if (!data.company_description) {
      newErrors.company_description = "Description is required";
    }
    if (!data.company_website) {
      newErrors.company_website = "Website is required";
    } else if (!IsValidWebUrl(data.company_website)) {
      newErrors.company_website = "Please enter valid url";
    }
    if (!data.company_zip) {
      newErrors.company_zip = "Zip is required";
    } else {
      const zipCodePattern = /^\d{5}$/;
      if (!zipCodePattern.test(data.company_zip)) {
        newErrors.company_zip = "Please enter a valid zip code";
      }
    }

    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };
  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      const updatedData = {
        ...data,
        ...companyTimingHours,
        company_revenue: combinedValue,
      };
      updateCompanyDetails(updatedData, (res) => {
        setLoading(false);

        if (res) {
          reloadProfile();
          ReactNotifications(res.message, getSuccessStatus);
          setData({
            company_address: res.data.company_address || "",
            company_logo: res.data.company_logo,
            company_picture: res.data.company_picture,
            company_ceo: res.data.company_ceo,
            company_ciso: res.data.company_ciso,
            company_contact: res.data.company_contact,
            company_employee_count: res.data.company_employee_count,
            company_industry: res.data.company_industry,
            company_since: res.data.company_since,
            company_description: res.data.company_description,
            company_city: res.data.company_city,
            company_name: res.data.company_name,
            company_state: res.data.company_state,
            company_website: res.data.company_website,
            company_zip: res.data.company_zip,
            social_slack: res.data.social_slack,
            social_twitter: res.data.social_twitter,
            social_linkedin: res.data.social_linkedin,
            social_github: res.data.social_github,
          });
          const parts = res.data.company_revenue.replace("$", "").split(" ");
          setNumber(parts[0]);
          setUnit(parts.slice(1).join(" "));
          initialWorkingHours = {
            Monday: {
              start: res.data.monday_opening_time,
              end: res.data.monday_closing_time,
            },
            Tuesday: {
              start: res.data.tuesday_opening_time,
              end: res.data.tuesday_closing_time,
            },
            Wednesday: {
              start: res.data.wednesday_opening_time,
              end: res.data.wednesday_closing_time,
            },
            Thursday: {
              start: res.data.thursday_opening_time,
              end: res.data.thursday_closing_time,
            },
            Friday: {
              start: res.data.friday_opening_time,
              end: res.data.friday_closing_time,
            },
            Saturday: {
              start: res.data.saturday_opening_time,
              end: res.data.saturday_closing_time,
            },
            Sunday: {
              start: res.data.sunday_opening_time,
              end: res.data.sunday_closing_time,
            },
          };
          setWorkingHours(initialWorkingHours);
          setCombinedValue(res.data.company_revenue);
        } else {
          ReactNotifications(res.message, getErrorStatus);
        }
      });
    }
  };

  const handleTimeChange = (day, field, value) => {
    setWorkingHours((prevWorkingHours) => ({
      ...prevWorkingHours,
      [day]: {
        ...prevWorkingHours[day],
        [field]: value,
      },
    }));
  };
  const resetTime = (day) => {
    setWorkingHours((prevWorkingHours) => ({
      ...prevWorkingHours,
      [day]: { start: "-", end: "-" },
    }));
  };

  const toggleContent = (e) => {
    e.preventDefault();
    setShowContent(!showContent);
  };

  const handleNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 3) {
      setNumber(value);
      setCombinedValue("$" + value + " " + unit);
    }
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
    setCombinedValue("$" + number + " " + event.target.value);
  };

  const handleSelectPackage = async () => {
    console.log("Calling handleSelectPackage")
    const selectedPackage = {
      id: 'your_package_id',
      name: 'Premium Subscription',
      price: '$9.99/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
    };

    const stripe = await stripePromise;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        { price: 'your_stripe_price_id', quantity: 1 },
      ],
      mode: 'payment',
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cancel`,
      clientReferenceId: selectedPackage.id,
    });

    if (error) {
      console.error('Error redirecting to Checkout:', error);
    }
  };

  useEffect(() => {

    let plan = localStorage.getItem('plan');
    let planDetails = JSON.parse(plan)
    if (planDetails?.plan != null && planDetails?.plan != undefined) {
      handleSelectPackage()
    }
  }, [])


  return (
    <div className="account-settings-tab ">
      <p className="title mb-2">Company Setting</p>

      <Row>
        <Col xs={12} md={7}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Form.Group className="form_input_body" controlId="formGroupdate">
              <InputText
                label="Company Name"
                placeholder="Company Name"
                name="company_name"
                value={data.company_name}
                onChange={onChange}
                error={errors.company_name}
              />
              <div className="ctaRow">
                <label className="file-input">
                  <img
                    className="logo-img"
                    src={
                      data.company_logo
                        ? process.env.REACT_APP_API_BASE_URL +
                        "/user_profile/" +
                        data.company_logo
                        : "/images/camera.png"
                    }
                    alt="upload"
                  ></img>
                  <input
                    accept="image/*"
                    type="file"
                    name="company_logo"
                    onChange={(e) => onImageChange(e, "companyLogo")}
                  />
                  &nbsp; &nbsp; Upload Logo
                </label>
              </div>
              <div className="form-field-error">{errors.company_logo}</div>
            </Form.Group>
            <Form.Group className="form_input_body" controlId="formGroupdate">
              <Form.Label>Since</Form.Label>
              <div>
                <input
                  type="date"
                  onChange={onChange}
                  value={data.company_since}
                  onKeyDown={(e) => {
                    if (/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  name="company_since"
                  className="form-control"
                />
              </div>
            </Form.Group>
            <Form.Group
              className="form_input_body mt-3"
              controlId="formGroupdate"
            >
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Social Media</Accordion.Header>
                  <Accordion.Body>
                    <InputText
                      label="Slack"
                      placeholder="Slack"
                      name="social_slack"
                      value={data.social_slack}
                      onChange={onChange}
                      error={errors.social_slack}
                    />
                    <InputText
                      label="Twitter"
                      placeholder="Twitter"
                      name="social_twitter"
                      value={data.social_twitter}
                      onChange={onChange}
                      error={errors.social_twitter}
                    />
                    <InputText
                      label="Linkedin"
                      placeholder="Linkedin"
                      name="social_linkedin"
                      value={data.social_linkedin}
                      onChange={onChange}
                      error={errors.social_linkedin}
                    />
                    <InputText
                      label="Github"
                      placeholder="Github"
                      name="social_github"
                      value={data.social_github}
                      onChange={onChange}
                      error={errors.social_github}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form.Group>
            <InputText
              label="CEO"
              placeholder="CEO"
              name="company_ceo"
              value={data.company_ceo}
              onChange={onChange}
              error={errors.company_ceo}
            />
            <InputText
              label="CISO"
              placeholder="CISO"
              name="company_ciso"
              value={data.company_ciso}
              onChange={onChange}
            />
            <Form.Group>
              <Form.Label>Employee</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                onKeyDown={(event) => {
                  if (event.keyCode === 8) {
                    return;
                  }
                  if (
                    /^[0-9]$/.test(event.key) &&
                    event.target.value.length < 6
                  ) {
                    return;
                  }
                  event.preventDefault();
                }}
                name="company_employee_count"
                placeholder=""
                value={data.company_employee_count}
                pattern="^(0|[1-9]\d*)$"
              />
              <div className="form-field-error">
                {errors.company_employee_count}
              </div>
              <InputText
                label="Industry"
                placeholder="Industry"
                name="company_industry"
                value={data.company_industry}
                onChange={onChange}
                error={errors.company_industry}
              />

              <div className="revenue-div">
                <InputText
                  className="company-revenue-input"
                  label="Revenue"
                  placeholder="Revenue"
                  type="text"
                  value={number}
                  onChange={handleNumberChange}
                />
                <select
                  className="company-revenue-select"
                  value={unit}
                  onChange={handleUnitChange}
                >
                  <option value="Thousand">Thousand</option>
                  <option value="Million">Million</option>
                  <option value="Billion">Billion</option>
                  <option value="Trillion">Trillion</option>
                </select>
              </div>
            </Form.Group>
            <AddressAutocomplete
              label="Company Address"
              defaultInputValue={user?.EmployerProfile?.company_address}
              placeholder={"Enter adress"}
              controlId="company_address"
              name="company_address"
              onSelect={(value) => {
                let tempProfile = { ...data };
                Object.assign(tempProfile, {
                  company_address: value.complete,
                  company_zip: value.postcode,
                  company_state: value.region,
                  company_city: value.locality
                    ? value.locality
                    : value.region,
                });
                setData(tempProfile);
              }}
            />
            {/* )} */}
            <InputText
              label="COMPANY City"
              placeholder="Company City"
              name="company_city"
              value={data.company_city}
              onChange={onChange}
              error={errors.company_city}
            />
            <InputText
              label="COMPANY State"
              placeholder="Company State"
              name="company_state"
              value={data.company_state}
              onChange={onChange}
              error={errors.company_state}
            />
            <InputText
              label="COMPANY ZIP"
              placeholder="Company zip"
              name="company_zip"
              value={data.company_zip}
              onChange={onChange2}
              error={errors.company_zip}
            />
            <InputText
              label="Company Website."
              placeholder="Company URL"
              name="company_website"
              value={data.company_website}
              onChange={onChange}
              error={errors.company_website}
            />
            <InputText
              label="Company Contact"
              placeholder="Company Contact"
              name="company_contact"
              value={data.company_contact}
              onChange={onChange}
              error={errors.company_contact}
            />
            <Form.Group className="form_input_body" controlId="formGroupdate">
              <div className="ctaRow">
                <label className="file-input">
                  <img
                    className="logo-img"
                    src={
                      data.company_picture
                        ? process.env.REACT_APP_API_BASE_URL +
                        "/user_profile/" +
                        data.company_picture
                        : "/images/camera.png"
                    }
                    alt="upload"
                  ></img>
                  <input
                    accept="image/*"
                    type="file"
                    name="company_picture"
                    onChange={(e) => onImageChange(e)}
                  />
                  &nbsp; &nbsp; Upload Picture
                </label>
              </div>
              <div className="form-field-error">{errors.company_picture}</div>
            </Form.Group>
            <Form.Group
              className="form_input_body"
              controlId="formGroupjob_description"
            >
              <Form.Label>Company Description</Form.Label>
              <Form.Control
                className="form_input"
                as="textarea"
                onChange={onChange}
                name="company_description"
                placeholder="company description"
                value={data.company_description}
                maxLength={5000}
              />
              <div className="form-field-error">
                {errors.company_description}
              </div>
            </Form.Group>
            <Form.Group
              className="form_input_body mt-3"
              controlId="formGroupdate"
            >
              
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={toggleContent}>
                    Working Hours
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table bordered striped hover size="sm">
                      <thead>
                        <tr>
                          <th style={{ fontSize: "15px" }}>Day</th>
                          <th style={{ fontSize: "15px" }}>Start Time</th>
                          <th style={{ fontSize: "15px" }}>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(workingHours).map((day) => (
                          <tr key={day}>
                            <td style={{ fontSize: "14px" }}>{day}</td>
                            <td>
                              <Form.Control
                                type="time"
                                value={workingHours[day].start}
                                onChange={(e) =>
                                  handleTimeChange(day, "start", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="time"
                                value={workingHours[day].end}
                                onChange={(e) =>
                                  handleTimeChange(day, "end", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              {workingHours[day].start !== "-" ||
                                workingHours[day].end !== "-" ? (
                                <button
                                  type="button"
                                  className="working-hours-reset-btn"
                                  onClick={() => resetTime(day)}
                                >
                                  x
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form.Group>
            <div className="btn_wrrpr">
              <button
                type="submit"
                className={`primary-filed-orange mt-40 w-150 ${isLoading && " button-loading"
                  }`}
                disabled={isLoading}
              >
                Submit
              </button>
              <Link
                to={`/company-details/${employerId}`}
                className="primary-filed-orange text-decoration-none mt-40 w-150 centered"
                style={{ marginTop: 32 }}
              >
                Preview
              </Link>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
}
