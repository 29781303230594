//--------------------Module Wise Constants Start -----------------------//
//COMMON
export const GET_COMMON_DROPDOWN_OPTIONS = "GET_COMMON_DROPDOWN_OPTIONS";
export const COMMON_IS_LOADING = "COMMON_IS_LOADING";

//LOGIN
export const SIGN_IN = "SIGN_IN";
export const SIGNED_USER_DETAIL = "SIGNED_USER_DETAIL";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNED_USER_DETAIL_LOADING = "SIGNED_USER_DETAIL_LOADING";

//SIGN-UP
export const FLUSH_SIGN_UP = "FLUSH_SIGN_UP";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_UP_DATA = "SIGN_UP_DATA";
export const SIGN_UP_OTP_VERIFICATION = "SIGN_UP_OTP_VERIFICATION";

//ON-BOARDING
export const ONBOARDING_UPLOAD_RESUME = "ONBOARDING_UPLOAD_RESUME";

//Employer
export const FLUSH_EMPLOYER_REDUCER = "FLUSH_EMPLOYER_REDUCER";
export const EMPLOYER_SEARCH_CANDIDATE = "EMPLOYER_SEARCH_CANDIDATE";
export const SAVE_SEARCH_CRITERIA_LOCAL = "SAVE_SEARCH_CRITERIA_LOCAL";
export const CANDIDATE_REMOVER = "CANDIDATE_REMOVER";
//CMS
export const GET_PAGE_CONTENT = "GET_PAGE_CONTENT";
export const GET_SCROLL_VALUE='GET_SCROLL_VALUE';
