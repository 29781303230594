import React, { useContext } from 'react'
import accountIcon from "../../../assets/images/JobPosting.svg";
import uploadIcon from "../../../assets/images/Tracking.svg";
import noticedIcon from "../../../assets/images/DedicatedAccount.svg";
import checkimage from "../../../assets/images/checkimage.svg";
import * as employerActions from "../employerActions";
import filledcheckimage from "../../../assets/images/filledcheckimage.svg";
import {
    IsAuthenticated,
} from "../../../utility/helper/authenticationHelper";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AuthContext } from 'context/authContext';
import { sucessState200 } from 'utility/constants/utilConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Accordion,
    Col,
    Form,
    Row,
} from "react-bootstrap";
import Industry1 from '../../../assets/images/Industry1.svg';
import Industry2 from '../../../assets/images/Industry2.svg';
import Industry3 from '../../../assets/images/Industry3.svg';
import Industry4 from '../../../assets/images/Industry4.svg';
import Industry5 from '../../../assets/images/Industry5.svg';
import qoutes from '../../../assets/images/qoutes.svg';
import InputText from 'components/common/inputText/InputText';
import CustomButton from 'components/common/button/CustomButton';
import PricingPlanCarousel from './PricingPlanCarousel';
const PricingPlan = () => {

    const { setIsSubscription, setSubscriptionPlanId, isUpgradePlan, setIsUpgradePlan } = useContext(AuthContext);
    const history = useHistory();

    const handlePricingPlan = (plan_id) => {
        setIsSubscription(true);
        setSubscriptionPlanId(plan_id);
        history.push('/signup')
    }

    const handleToBuySubscriptionPlan = (plan_id) => {
        setIsUpgradePlan(false);
        employerActions.buySubscriptionPlan({ selected_plan: plan_id }, (response) => {
            if (response.status === sucessState200) {
                if (response.data.select_subscription === true && response.data.checkout_url) {
                    window.location.assign(response.data.checkout_url);

                }
            }
        });
    }

    const handleUpgradeSubscriptionPlan = (plan_id) => {
        employerActions.upgradeSubscriptionPlan({ selected_plan: plan_id }, (response) => {
            if (response?.status === sucessState200) {
                if (response?.data?.status === "active") {
                    setIsUpgradePlan(false);
                    toast.success(response.message, {
                        hideProgressBar: true,
                    })
                    setTimeout(() => {
                        history.push("/employer/account-settings/membership");
                    }, 2000)
                } else {
                    toast.error(response.message, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,

                    });
                }
            }
        });
    }

    return (
        <section>
            <div className="start-posting">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-11 col-md-11 col-12 mb-5 start-posting-title">
                            <h1 className={IsAuthenticated() ? "hide_dv" : "show_dv"}></h1>
                            <h3>Start Posting<br /> <span className='green-pricing-pg'>Cyber</span><span className='orange'>Security </span>Jobs</h3>
                            <p>Access our powerful platform to showcase your cybersecurity job opportunities to a vast and qualified pool of candidates. Explore how our tailored job posting solutions can connect you with the perfect cybersecurity candidates to drive your organization's success.</p>
                        </div>
                    </div>
                </div>
            </div>
            <PricingPlanCarousel />
            <div className='industries-dv' style={{ textAlign: 'center' }}>
                <div className="container">
                    <h2 className="section-title">
                        <span class="green-pricing-pg">
                            Trusted</span> by Industry Leaders
                    </h2>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry1} alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry2} alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry3} alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry4} alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry1} alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <img src={Industry5} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='our-clients-dv'>
                <div className="container">
                    <h2 className="section-title" style={{ textAlign: 'center' }}>
                        Hear What Our Clients<br />
                        <span class="green-pricing-pg">
                            Have to Say!</span>
                    </h2>
                    <div className="row">
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column" style={{ borderRadius: '8px', padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <img
                                        alt=""
                                        width={55}
                                        height={55}
                                        src={qoutes}
                                    />
                                    <p style={{ padding: '30px 0', fontSize: '16px' }}>"Within weeks my company found two new hires on MyTurn allowing us to quickly bid on new contracts."</p>
                                </div>
                                <div style={{ borderTop: '1px solid #E5EBFE' }}>
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <img src="/images/userpic.png" alt="user" class="userPic"></img>
                                        <div className='our-client-profile-dv'>
                                            <h6>Kevin S.</h6>
                                            <p>Chief Executive Officer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column" style={{ borderRadius: '8px', padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <img
                                        alt=""
                                        width={55}
                                        height={55}
                                        src={qoutes}
                                    />
                                    <p style={{ padding: '30px 0', fontSize: '16px' }}>"After recommending MyTurn to our HR team, many of our cybersecurity vacancies were filled within 60 days of posting!”</p>
                                </div>
                                <div style={{ borderTop: '1px solid #E5EBFE' }}>
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <img src="/images/userpic.png" alt="user" class="userPic"></img>
                                        <div className='our-client-profile-dv'>
                                            <h6>Sarah T.</h6>
                                            <p>Chief Executive Officer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column" style={{ borderRadius: '8px', padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <img
                                        alt=""
                                        width={55}
                                        height={55}
                                        src={qoutes}
                                    />
                                    <p style={{ padding: '30px 0', fontSize: '16px' }}>"Using MyTurn, we were able to find top candidates quickly, decreasing our time to hire. It's been a game-changer for our recruiting!”</p>
                                </div>
                                <div style={{ borderTop: '1px solid #E5EBFE' }}>
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <img src="/images/userpic.png" alt="user" class="userPic"></img>
                                        <div className='our-client-profile-dv'>
                                            <h6>Alex P.</h6>
                                            <p>Talent Acquisition Lead</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pricing-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center procing_plan">
                        <div className="col-lg-9 col-md-9 col-12 mb-5">
                            <h3>Pricing<span className="orange">Plans</span></h3>
                            <p>Each package is tailored to suit different hiring timelines and budgets, ensuring that you can choose the option that best fits your specific recruitment needs.</p>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12 cyber_col'>
                            <div className='monthly_yearly_dv_wrrpr'>
                                <div className='monthly_yearly_body'>
                                    <div className='monthly_yearly_upper_body'>
                                        <div className='monthly_yearly_upper_dv'>
                                            <h4 className='monthly_yearly_text'>Monthly</h4>
                                            <p>Great for immediate hiring</p>
                                            <h5>$99/<span>month</span></h5>
                                        </div>
                                        <div className='monthly_yearly_lower_dv'>
                                            <ul>
                                                <li><img src={checkimage} />Unlimited Job Postings</li>
                                                <li><img src={checkimage} />30-day Job Postings</li>
                                                <li><img src={checkimage} />Applicant Tracking System</li>
                                                <li><img src={checkimage} />Cancel Anytime</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='centered  mt-auto'>
                                        <button className='primary-filed-orange w-100' onClick={() => {
                                            IsAuthenticated() && isUpgradePlan === false ? handleToBuySubscriptionPlan(1)
                                                : IsAuthenticated() && isUpgradePlan === true ? handleUpgradeSubscriptionPlan("1")
                                                    : handlePricingPlan(1)
                                        }} type='button'>Start Hiring</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12 cyber_col'>
                            <div className='quarterely_wrrpr'>
                                <div className='monthly_yearly_body'>
                                    <div className='monthly_yearly_upper_body'>
                                        <div className='monthly_yearly_upper_dv'>
                                            <h4 className='quarterly_text'>QUARTERLY</h4>
                                            <p>Looking for the right candidate</p>
                                            <h5>$89/<span>month</span></h5>
                                        </div>
                                        <div className='monthly_yearly_lower_dv'>
                                            <ul>
                                                <li><img src={filledcheckimage} />10% Discount from Monthly</li>
                                                <li><img src={filledcheckimage} />Unlimited Job Postings</li>
                                                <li><img src={filledcheckimage} />30-day Job Postings</li>
                                                <li><img src={filledcheckimage} />Applicant Tracking System</li>
                                                <li><img src={filledcheckimage} />Cancel Anytime</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='centered  mt-auto'>
                                        <button className='primary-filed-orange w-100' onClick={() => {
                                            IsAuthenticated() && isUpgradePlan === false ? handleToBuySubscriptionPlan(2)
                                                : IsAuthenticated() && isUpgradePlan === true ? handleUpgradeSubscriptionPlan("2")
                                                    : handlePricingPlan(2)
                                        }} type='button'>Start Hiring</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12 cyber_col'>
                            <div className='monthly_yearly_dv_wrrpr'>
                                <div className='monthly_yearly_body'>
                                    <div className='monthly_yearly_upper_body'>
                                        <div className='monthly_yearly_upper_dv'>
                                            <h4 className='monthly_yearly_text'>Annual</h4>
                                            <p>Time for the best candidate</p>
                                            <h5>$79/<span>month</span></h5>
                                        </div>
                                        <div className='monthly_yearly_lower_dv'>
                                            <ul>
                                                <li><img src={checkimage} />20% Discount from Monthly</li>
                                                <li><img src={checkimage} />Unlimited Job Postings</li>
                                                <li><img src={checkimage} />30-day Job Postings</li>
                                                <li><img src={checkimage} />Applicant Tracking System</li>
                                                <li><img src={checkimage} />Cancel Anytime</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='centered  mt-auto'>
                                        <button className='primary-filed-orange w-100' onClick={() => {
                                            IsAuthenticated() && isUpgradePlan === false ? handleToBuySubscriptionPlan(3)
                                                : IsAuthenticated() && isUpgradePlan === true ? handleUpgradeSubscriptionPlan("3")
                                                    : handlePricingPlan(3)
                                        }} type='button'>Start Hiring</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pricing_note_dv'>
                        <p>Note: *All prices in USD / Quarterly package billed $267 every 3 months / Annual package billed $948 every 12 months / All packages renew automatically.</p>
                    </div>
                </div>
            </div>
            <div className='pricing-plan-contact-us'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 " style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='contact-right-dv'>
                                <h1 style={{ color: '#FFFFFF' }}>Not Ready to Post?<br />
                                    <span class="orange">No Problem!</span>
                                </h1>
                                <p>
                                    Join our mailing list today and receive <br />a one-month trial absolutely free.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 ">
                            <div className='contact-left-dv'>
                                <h1>Contact Us</h1>
                                <Form noValidate>
                                    <Row>
                                        <Col
                                            xxl={{ span: 6, offset: 0 }}
                                            xl={{ span: 6, offset: 0 }}
                                            lg={{ span: 6, offset: 0 }}
                                            md={{ span: 12, offset: 0 }}
                                            sm={12}
                                            xs={12}
                                        >
                                            <InputText
                                                controlId="formBasicfirst_name"
                                                type="text"
                                                name="first_name"
                                                // onChange={onChange}
                                                placeholder="First Name"
                                            // value={candidate.first_name}
                                            // error={candidateErrors.first_name}
                                            // maxLength="255"
                                            />
                                        </Col>
                                        <Col
                                            xxl={{ span: 6, offset: 0 }}
                                            xl={{ span: 6, offset: 0 }}
                                            lg={{ span: 6, offset: 0 }}
                                            md={{ span: 12, offset: 0 }}
                                            sm={12}
                                            xs={12}
                                        >
                                            <InputText
                                                controlId="formBasiclast_name"
                                                type="text"
                                                name="last_name"
                                                // onChange={onChange}
                                                placeholder="Last Name"
                                            // value={candidate.last_name}
                                            // error={candidateErrors.last_name}
                                            // maxLength="255"
                                            />
                                        </Col>
                                    </Row>
                                    <InputText
                                        controlId="formBasicEmail"
                                        type="email"
                                        placeholder="Email Address"
                                        name="email"
                                        // value={candidate.email}
                                        // error={candidateErrors.email}
                                        // onChange={onChange}
                                        maxLength="255"
                                    />
                                    <CustomButton
                                        variant="primary"
                                        type="submit"
                                        className="w-100 primary-filed-orange"
                                        label="Send"
                                    // isLoading={isInitialButtonLoading}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cyber-security-section">
                <div className="container">
                    <h2 className="section-title">
                        Each Pricing Plan Includes…
                    </h2>
                    <div className="row">
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column">
                                <img
                                    alt="cyber security"
                                    className="img-fluid cyber-security-icon"
                                    width={55}
                                    height={55}
                                    src={accountIcon}
                                />
                                <h3>Unlimited Job Postings</h3>
                                <p>
                                    Offers the freedom to post as many cybersecurity job listings as needed, ensuring maximum reach and exposure to potential candidates.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column">
                                <img
                                    alt="upload resume"
                                    className="img-fluid cyber-security-icon"
                                    width={55}
                                    height={55}
                                    src={uploadIcon}
                                />
                                <h3>Applicant Tracking System (ATS)</h3>
                                <p>
                                    A sophisticated system that streamlines the hiring process by efficiently organizing and managing candidate applications.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 cyber_col">
                            <div className="cyber-security-column">
                                <img
                                    alt="get noticed"
                                    width={55}
                                    height={55}
                                    className="img-fluid cyber-security-icon"
                                    src={noticedIcon}
                                />
                                <h3>Dedicated Account Support</h3>
                                <p>Provides personalized, ongoing support from a dedicated team member to assist with the recruitment process.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='job-insight-section'>
                <div className="container">
                    <h2 className="section-title">
                        Let's Answer Your Ouestions
                    </h2>
                    <div className='pricing-plan-question-wrrpr'>
                        <div className='popular-search-tags'>
                            <Accordion defaultActiveKey="">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Can you provide insights into the company's approach to cyber security and its importance within the organization?</Accordion.Header>
                                    <Accordion.Body>
                                        Our platform emphasizes the critical role of cyber security in safeguarding organizational assets and maintaining trust with stakeholders. We encourage companies to highlight their cyber security strategies and culture in job listings, showcasing their commitment to security as a core value.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Do you offer discounts for job posts?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, we offer a 10% discount for employers who subscribe for a 3-month period and a 20% discount for annual subscribers. These subscription options are ideal for employers looking to post unlimited cyber security job listings over time, ensuring broad exposure to a pool of qualified cyber security professionals.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How can I track the performance of my job posting?</Accordion.Header>
                                    <Accordion.Body>
                                        Although analytical tools will be available on our platform soon, recruiters can effectively track the performance of their job postings by using a tracking code in their URL. This method allows for monitoring traffic sources and gauging interest levels in specific listings.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How do I make my job posting more appealing?</Accordion.Header>
                                    <Accordion.Body>
                                        To make your cyber security job posting more appealing, include detailed salary information and ensure that entry-level positions are accessible by keeping job qualifications realistic and minimal. Clearly outlining the role, its benefits, and its impact within the company can attract more qualified and interested candidates.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How can I ensure my job listing stands out among others on the site?</Accordion.Header>
                                    <Accordion.Body>
                                        Crafting a clear and engaging job title and description is key to making your listing stand out. Highlighting unique company benefits, career growth opportunities, and emphasizing the supportive work environment can also draw attention to your listing without the need for featured listings or promotional spots.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How do you handle candidate complaints or disputes?</Accordion.Header>
                                    <Accordion.Body>
                                        Candidate complaints or disputes are directly addressed via email support at support@myturn.careers. We strive to provide timely and fair resolutions, maintaining a high standard of professionalism and support for both candidates and employers.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section >
    )
}

export default PricingPlan