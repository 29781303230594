import React from "react";
import { Form } from "react-bootstrap";
export default function InputSelect({
  controlId,
  label,
  selectOptionLabel = "Select",
  name,
  value,
  options = [],
  onChange = () => {},
  placeholder,
  required,
  error,
  className,
  labelAsValue,
  optionLable,
  optionValue,
  displayToolTip = false,
  toolTipText = "",
}) {
  const getOptions = () => {
    return options.map((v, k) => {
      if (optionLable)
        return (
          <option
            value={optionValue ? v[optionValue] : v}
            key={v[optionLable] + v[optionValue]}
          >
            {v[optionLable]}
          </option>
        );
      else
        return (
          <option value={labelAsValue ? v : k} key={k}>
            {v}
          </option>
        );
    });
  };
  return (
    <div className={className || ""}>
      <Form.Group className="form-group" controlId={controlId}>
        <Form.Label>
          <div className="tooltip-container">
            {label}
            {displayToolTip && (
              <img
                style={{ margin: "0 8px" }}
                src="/images/tooltip.png"
                alt="tooltip"
                title={toolTipText}
              />
            )}
          </div>
        </Form.Label>
        <Form.Select
          as="select"
          type="select"
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required={required}
          isInvalid={error}
        >
          <option hidden>{selectOptionLabel}</option>
          {getOptions()}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
