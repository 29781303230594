
import React, { useContext, useEffect, useState } from 'react';
import InputFile from "../common/inputFile/InputFile";
import { Button, Col, Spinner } from "react-bootstrap";
import EditImage from "../../assets/images/edit.svg";
import { CommonContext } from "context/commonContext";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import * as utilConstants from "../../utility/constants/utilConstants";
import * as candidateActions from "../../components/candidate/candidateActions";
import {
    formatDate,
    IsEmpty,
} from "../../utility/helper/helper";
import CandidateEditProfile from 'components/candidate/profile/CandidateEditProfile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Resume = (props) => {
    const [user, setUser] = useState();
    const [commonDropdown, setCommonDropdon] = useState([]);
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { isCandidateEditForm, setIsCandidateEditForm, showEditDiv, setShowEditDiv } = useContext(CommonContext);

    const history = useHistory()

    useEffect(() => {
        if (props.state.commonReducer) props.commonActions.signinUserDetails();
        setIsCandidateEditForm(false);
    }, []);
    useEffect(() => {
        if (
            IsEmpty(props.state.commonReducer.signedUserDetail) &&
            !props.state.commonReducer.isLoading
        )
            props.commonActions.signinUserDetails();
        else {
            let { signedUserDetail } = props.state.commonReducer;
            if (signedUserDetail) {
                let tempDate = { ...signedUserDetail };
                delete tempDate.CandidateProfile;
                tempDate = { ...tempDate, ...signedUserDetail["CandidateProfile"] };
                setUser(tempDate);
            }
        }
        if (
            IsEmpty(props.state.commonReducer.contactPreference) ||
            IsEmpty(props.state.commonReducer.employmentType)
        ) {
            if (
                !props.state.commonReducer.isLoading &&
                IsEmpty(props.state.commonReducer.workShift)
            )
                props.commonActions.getCommonDropdownList();
        } else {
            let {
                employmentType,
                contactPreference,
                highestEducation,
                noticePeriod,
                workShift,
            } = props.state.commonReducer;
            setCommonDropdon({
                employmentType,
                contactPreference,
                highestEducation,
                noticePeriod,
                workShift,
            });
        }
    }, [props.state.commonReducer]);

    const formateSkills = (skills) => {
        if (typeof skills === "string") skills = JSON.parse(skills);
        return skills?.map((v, k) => (
            <li key={k}>
                <label
                    title={v}
                    className={
                        k % 3 === 0
                            ? "bg-light-green text-green"
                            : k % 2 === 0
                                ? "bg-light-orange text-orange"
                                : "bg-light-blue text-blue"
                    }
                >
                    {v?.length > 20 ? `${v.substring(0, 20)}...` : v}
                </label>
            </li>
        ));
    };

    const jobShiftType = {
        1: "Morning",
        2: "Noon",
        3: "Evening",
        4: "Night/Graveyard",
    };

    const employmentTypeMap = {
        1: "Full Time",
        2: "Part Time",
        3: "Contract",
        4: "Temporary or Seasonal",
        5: "Internship or co-op",
    };

    const shiftArr = [];
    const employmentArr = [];

    const jobShiftTypeArr = user?.job_shift_type?.split(",");
    jobShiftTypeArr?.forEach((shiftType) =>
        shiftArr.push(jobShiftType[shiftType])
    );

    const employmentTypeArr = user?.desired_employment?.split(",");
    employmentTypeArr?.forEach((employmentType) =>
        employmentArr.push(employmentTypeMap[employmentType])
    );

    const downloadResume = (e) => {
        e.preventDefault();
        commonActions.downloadCandidateProfilePDF(user.id, (response) => {
            window.open(
                response,
                "_blank",
                "width=500,height=500, top=100, left=500, noopener, noreferrer"
            );
        });
    };

    const handleEditButtonClick = (formType) => {
        setIsCandidateEditForm(true);
        setShowEditDiv(formType);
    }

    const updateCandidateProfile = (file) => {
        setIsLoading(true);
        if (file) {
            candidateActions.updateCandidateProfile(
                {
                    communication_email: user?.communication_email,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user?.email,
                    id: user?.id,
                    profile_image_url: user?.profile_image_url,
                    cv_path: file,
                    section_validation: "cv_attachment"
                },
                (response) => {
                    if (response.status === utilConstants.sucessState200) {
                        props.commonActions.signinUserDetails();
                        ReactNotifications(response.message, utilConstants.sucessState200);
                        setIsCandidateEditForm(false);
                        setShowEditDiv("");
                        setIsLoading(false);
                        history.push("/Candidate/account-settings/resume");
                    } else
                        ReactNotifications(response.message, utilConstants.getErrorStatus);
                }
            );
        }
    }

    const uploadResume = (file) => {
        setIsLoading(true);
        file = file[0];
        const formData = new FormData();
        formData.append("resume", file);
        commonActions.uploadResume(formData, (response) => {
            if (response.file) {
                setProfile({ ...profile, cv_path: response.file });
                updateCandidateProfile(response?.file)
            } else ReactNotifications(response.msg, utilConstants.getErrorStatus);
        });
    };

    return (
        <>
            <div className='candidate_resume_wrrpr'>
                <div className='resume_title'>
                    <h4>Upload or Create Your Resume...</h4>
                </div>
                <div class="inner_block_wrrpr">
                    <div className="innerBlock candidate_uppload_resume">
                        <h3 className='mb-2'>Attachments</h3>
                        <div className="custom-upload-box">
                            <InputFile
                                onDrop={(files) => {
                                    uploadResume(files);
                                }}
                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                label="resume"
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            {isLoading && <Spinner animation="border" variant="success" />}
                        </div>
                    </div>
                </div>
                {isCandidateEditForm && showEditDiv === "job_history" ? <CandidateEditProfile />
                    : <div className="inner_block_wrrpr">
                        <div className="innerBlock">
                            <div className="personal_information_setting">
                                <h3>Job or Academic History</h3>
                                <Button onClick={() => handleEditButtonClick("job_history")} className="tertiary-button-small centered">Edit<img className='ml-10' src={EditImage} /></Button>
                            </div>
                            {user?.JobHistories?.map((job) => (
                                <>
                                    <div className="wrapperRow">
                                        <div className='education_dv job_dv_wrrpr'>
                                            <div className='education_title '>
                                                <div className='d-flex gp-10 align-center mb-1'>
                                                    <h5 className=" w-auto">{job.company}</h5>
                                                    <div>
                                                        {job?.currently_working === "1" ? (
                                                            <button className='tertiary-button-small'>Present</button>
                                                        ) : (
                                                            " "
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <Col xs={6}>
                                                    <p className="row1 w-auto ">Position: {job.position}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <p className="row1 w-auto">
                                                        Duration: <span>{formatDate(job.start_date, "MM/DD/YYYY")}</span>
                                                        <span>-{formatDate(job.end_date, "MM/DD/YYYY")}</span>
                                                    </p>
                                                </Col>
                                            </div>
                                            <h6
                                                className=" w-auto"
                                                title={job?.summary}
                                            >
                                                {/* {job?.summary?.length > 20
                                                    ? `${job.summary}`
                                                    : job.summary} */}
                                                {job?.summary}
                                            </h6>
                                        </div>
                                    </div>
                                    <div>
                                        <p
                                            className="row2 w-auto text-ellipsis"
                                            title="A job description summarizes the essential responsibilities, activities, qualifications and skills for a role. Also known as a JD, this document describes the type of work performed. ... It may also specify to whom the position reports and salary range."
                                        >
                                            {job?.description}
                                        </p>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>}
                {isCandidateEditForm && showEditDiv === "skills" ? <CandidateEditProfile />
                    : <div className="inner_block_wrrpr">
                        {/* {!IsEmpty(user.UserSkill) && ( */}
                        <div className="innerBlock">
                            <div className="personal_information_setting">
                                <h3>Skills</h3>
                                <Button onClick={() => handleEditButtonClick("skills")} className="tertiary-button-small centered">Edit<img className='ml-10' src={EditImage} /></Button>
                            </div>
                            <ul className="indicatorLabels">
                                {formateSkills(user?.UserSkill?.skill)}
                            </ul>
                        </div>
                        {/* )} */}
                    </div>}
                {isCandidateEditForm && showEditDiv === "education" ? <CandidateEditProfile />
                    :
                    <div class="inner_block_wrrpr">
                        <div className="innerBlock">
                            <div className="personal_information_setting">
                                <h3>Education</h3>
                                <Button onClick={() => handleEditButtonClick("education")} className="tertiary-button-small centered">Edit<img className='ml-10' src={EditImage} /></Button>
                            </div>
                            {user?.UserEducations?.map((edu) => (
                                <div className='education_dv'>
                                    <div className='education_title'>
                                        <h5>{edu.collage}{" "}
                                            (<span>{formatDate(edu.start_date, "MM/DD/YYYY")}</span>{" "}
                                            {"-"} <span>{edu?.currently_present === "1" ? "Present" : formatDate(edu.end_date, "MM/DD/YYYY")}</span>)
                                        </h5>
                                    </div>
                                    <p>Degree: {edu.course}</p>
                                </div>
                            ))}
                        </div>
                    </div>}
                <Button onClick={downloadResume} className='primary-filed-orange false'>View Resume</Button>
            </div>
        </>
    )
}
function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}
function mapStateToProps(state) {
    return {
        state: state,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume)

