import React from "react";
import { Row, Col } from "react-bootstrap";
import InputText from "../common/inputText/InputText";
import MultiRangeSlider from "multi-range-slider-react";

export default function CPC() {
  return (
    <div className="account-settings-tab ">
      <div className="d-flex justify-content-between align-items-center">
        <p className="title">CPC Thresholds</p>
        <div className="d-flex  align-items-center">
          <b className="title-xs">Pause my Profilec</b>
          <div className="toggle-switch d-flex  align-items-center ms-2 me-3">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <b className="title-xs"> $0.32 CPC </b> <div className="dot"></div>
        <b className="title-xs"> $13.42 avg. per day</b>
        <div className="dot"></div>
        <b className="title-xs"> $64.37 avg. per week </b>
      </div>
      <img src="/images/cpc-chart.png" className="mt-3" alt="chart"></img>
      <form>
        <Row>
          <Col xs={12} md={6}>
            <InputText
              label={
                <>
                  Daily budget{" "}
                  <img
                    src="/images/info-dark.png"
                    className="ms-1"
                    alt="info"
                  ></img>{" "}
                </>
              }
              name="budget"
              value="$10"
            ></InputText>
          </Col>
          <Col xs={12} md={6}>
            <InputText
              label={
                <>
                  cpd{" "}
                  <img
                    src="/images/info-dark.png"
                    className="ms-1"
                    alt="info"
                  ></img>{" "}
                </>
              }
              name="clicks"
              value="30 clicks"
            ></InputText>
          </Col>
          <Col xs={12} md={6} className="pt-3 lbl-0-un-dollar">
            <MultiRangeSlider
              min={0}
              max={100}
              step={5}
              ruler={false}
              label={true}
              preventWheel={false}
              minValue={10}
              maxValue={25}
            />
          </Col>
          <Col xs={12} md={6} className="pt-3 lbl-0-un">
            <MultiRangeSlider
              min={0}
              max={100}
              step={5}
              ruler={false}
              label={true}
              preventWheel={false}
              minValue={10}
              maxValue={25}
            />
          </Col>
        </Row>
      </form>
      <button
        disabled
        type="submit"
        onClick={(e) => e.preventDefault()}
        className="cta-type-2 w-200 h-40 btn btn-primary mt-5"
      >
        Save changes
      </button>
    </div>
  );
}
