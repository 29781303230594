import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as employerActions from "./../employerActions";
import CandidateCard from "../saved-candidate/CandidateCard";
import Pagination from "./../../common/pagination/Pagination";
import { sucessState200 } from "../../../utility/constants/utilConstants";
import { useHistory } from "react-router-dom";
export default function ContactList() {
  const [contactListCandidate, setcontactList] = useState([]);
  const [count, setCount] = useState(0);
  const history = useHistory();
  useEffect(() => {
    employerActions.getConatctsList({}, (response) => {
      if (response.status === sucessState200) {
        setcontactList(response.data.rows);
        setCount(response.data.count);
      }
    });
  }, []);
  return (
    <div className="dashboardPageWrapper">
      <Container>
        <Row>
          <Col>
            <h2 className="section-heading-2">
              <div
                className="d-flex align-items-center"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/employer/SavedCandidate");
                }}
              >
                <img src="/images/back.png" alt="back" />
                Saved candidates
              </div>
            </h2>
            <div className="contentBox">
              <div className="personInfoHeader publicProfileHeaderDesktop align-items-center">
                <div className="column2">
                  <h4 className="headerText font-bold m-0">Candidates List</h4>
                </div>
              </div>
              <div className="innerBlock">
                {contactListCandidate &&
                  contactListCandidate.map((candidate, k) => {
                    return (
                      <CandidateCard
                        key={k}
                        user={{
                          ...candidate,
                        }}
                        changeStatus={() => {}}
                      />
                    );
                  })}
                <Pagination totalItems={count} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
