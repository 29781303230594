import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ReactNotifications from "../reactNotifications/ReactNotifications";
import { acceptPayment } from "../../../actions/payment";
import CustomeModel from "../model/CustomeModel";
import { connect } from "react-redux";
import InputText from "../inputText/InputText";
import LockImage from "../../../assets/images/lock.png";
const CheckoutForm = ({
  packageId,
  onSucess = () => { },
  onError = () => { },
  selectedPackageDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isValidCard, setIsValidCard] = useState(false);
  const [card_name, setCard_name] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const onChange = (event) => {
    if (event.complete && !event.empty) {
      setIsValidCard(true);
    } else {
      setIsValidCard(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowConfirmationPopup(false);
    if (selectedPackageDetails.price == 0) {
      setLoading(true);

      acceptPayment(
        {
          package_id: packageId,
        },
        (apiRes) => {
          setLoading(false);
          if (apiRes.success) {
            onSucess();
            ReactNotifications("Membership updated successfully!");
          } else {
            setLoading(false);
            onError();
            ReactNotifications("Error occurred while processing", "Error");
          }
        }
      );
    } else {
      if (elements == null || !isValidCard) {
        return;
      }
      setLoading(true);
      stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        })
        .then((res) => {
          acceptPayment(
            {
              package_id: packageId,
              payment_method_id: res.paymentMethod.id,
              card_name
            },
            (apiRes) => {
              setLoading(false);
              if (apiRes.success) {
                onSucess();
                ReactNotifications("Membership updated successfully!");
              } else {
                onError();
                ReactNotifications("Error occurred while processing", "Error");
              }
            }
          );
        })
        .catch((e) => {
          onError();
          setLoading(false);
          ReactNotifications(
            e.message || "Error occurred while processing",
            "Error"
          );
        });
    }
  };

  return (
    <>
      <CustomeModel
        negativeButtonLable="No"
        positiveButtonLable="Yes"
        show={showConfirmationPopup}
        onNegative={(e) => {
          e.preventDefault();
          setShowConfirmationPopup(false);
        }}
        onPositive={handleSubmit}
        modalBody={
          <>
            <p>
              <b>Confirmation !</b>
            </p>
            <p>Are you sure you want to buy this package?</p>
          </>
        }
        modalType="Positive/Negative"
        headerImage={"/images/membership.png"}
        handleClose={() => setShowConfirmationPopup(false)}
      ></CustomeModel>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {selectedPackageDetails.price != 0 && (
          <>
            <p className="mt-4 mb-4">
              Please fill the below details to procced
            </p>
            <InputText
              controlId='formBasiclast_name'
              label='Card Holder Name'
              type='text'
              placeholder='Jane Smith'
              name='Full name'
              value={card_name}
              // error={candidateErrors.email}
              onChange={(e) => setCard_name(e.target.value)}
              maxLength='255'
            />

            <br></br>
            <CardElement onChange={onChange} />
          </>
        )}
        {isValidCard && card_name !== "" || selectedPackageDetails.price == 0 ? (
          <>
            <button
              type="submit"
              className={`cta-type-2 w-360 h-40 br-16 mx-auto mt-64 btn btn-primary color-change ${isLoading && " button-loading"
                }`}
              disabled={!stripe || !elements || isLoading}
            >
              Confirm & Pay
            </button>
            <div className="d-flex">

              <p className="s-txt"> <img src={LockImage} alt="lock" /> SSL Secure Payment</p>
            </div>
          </>
        ) : null}
      </form>
    </>
  );
};

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const Stripe = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps)(Stripe);
