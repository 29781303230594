import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";

const OptionsOutsideSelect = (props) => {
  const { onChange, value, isMulti, customCreate = false } = props;

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        console.log(props.onInputChange(inputValue));
        resolve(props.onInputChange(inputValue));
      }, 1000);
    });

  return (
    <div>
      {customCreate ? (
        <AsyncCreatableSelect
          isMulti={isMulti}
          onChange={onChange}
          defaultOptions={props.type === "dropdownlist" ? true : false}
          value={value}
          loadOptions={promiseOptions}
          cacheOptions
        />
      ) : (
        <AsyncSelect
          isMulti={isMulti}
          onChange={onChange}
          defaultOptions={props.type === "dropdownlist" ? true : false}
          value={value}
          loadOptions={promiseOptions}
          cacheOptions
        />
      )}
    </div>
  );
};

export default OptionsOutsideSelect;
