import React from "react";
export default function InputRadio({
  value,
  name,
  label,
  id,
  checked,
  onChange,
}) {
  return (
    <>
      <div className="form-check radio-input  cursor-pointer">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        ></input>
        <label className="form-check-label  cursor-pointer"> {label}</label>
      </div>
    </>
  );
}
