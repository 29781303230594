import React from 'react';
import { Container, ProgressBar } from 'react-bootstrap';
import * as utilConstants from '../../../utility/constants/utilConstants';
import * as helper from '../../../utility/helper/helper';
import { useHistory } from 'react-router-dom';

export default function OnBoarding(props) {
  let history = useHistory();
  return (
    <div className='onboardingContentWrapper'>
      <Container>
        <div className='backLink'>
          {parseInt(props.cureentStep) === 1 ? (
            <>&nbsp;</>
          ) : (
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/Candidate/Onboarding/' + props.previousStep,
                  state: { ...props?.location?.state },
                });
              }}>
              <img src='/images/back.png' alt='upload' />
              Previous step
            </a>
          )}
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            props.cureentStep
          )}
        />
        {props.children}
      </Container>
    </div>
  );
}
