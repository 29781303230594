import React, { useContext, useEffect,} from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import candidateImg from "../../assets/images/candidate.png";
import employer from "../../assets/images/employer.png";
import { AuthContext } from "context/authContext";

export default function VerifyUser() {

  const { verifyUserPopUp, setVerifyUserPopUp, signupType,
    setSignupType, setCandidate, setIsInitialButtonLoading, handleGenerateOtp, googleSignup, userInfo } = useContext(AuthContext);

  const handleClose = () => {
    setIsInitialButtonLoading(false);
    setVerifyUserPopUp(false);
    setCandidate({candidateAgree: false});
  }

  const handleRedirect = () => {
    if (userInfo.sign_in_with_google === false) {
      handleGenerateOtp();
    } else {
      googleSignup();
      setSignupType("");
    } 
  };

  useEffect(() => {
    if (signupType) {
      handleRedirect();
    }
  }, [signupType]);


  return (
    <>
      <Modal
        show={verifyUserPopUp}
        onHide={handleClose}
        className="successModal"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="modalbody text-center">
          <h4 className="verify_user_title">Are you?</h4>

          <Row>
            <Col
              xxl={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={6}
              xs={12}
            >
              <div className="verify_user_input_wrrpr">
                <Button onClick={() => setSignupType("Candidate")} className="verify_user_input_body">
                  <img src={candidateImg} alt="candidate" />
                  <p>Candidate</p>
                </Button>
              </div>
            </Col>
            <Col
              xxl={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={6}
              xs={12}
            >
              <div className="verify_user_input_wrrpr">
                <Button onClick={() => setSignupType("Employer")} className="verify_user_input_body">
                  <img src={employer} alt="employer" />
                  <p>Employer</p>
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
