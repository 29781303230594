import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import CustomeModel from "../common/model/CustomeModel";
import Subscription from "./../subscription/Subscription";
import { getPackageList } from "../../actions/account";
import * as employerActions from "../employer/employerActions";

import {
  CANDIDATE_PACKAGE_ID,
  EMPLOYER_PACKAGE_ID,
} from "../../utility/constants/utilConstants";
import { Link } from "react-router-dom";
import Billing from "./billing";
import UserMembershipDetail from "./userMembershipDetail";
import { AuthContext } from "context/authContext";
import { useHistory } from 'react-router-dom';

export default function Membership({ user, reloadProfile }) {
  const profileDetails = user.EmployerProfile || user.CandidateProfile || {};
  const [type, settype] = useState("subscription");
  const [packages, setPackages] = useState([]);
  const [upgradeMembershipPopup, setUpgradeMembershipPopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [subscriptionValidityDate, setSubscriptionValidityDate] = useState("");
  const [subscriptionData, setSubcriptionData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const { setLoading, setIsUpgradePlan } = useContext(AuthContext);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isChangePlanPopupOpen, setIsChangePlanPopupOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (user.role_id) {
      getPackages(user.role_id);
      let packageId;
      if (user.role_id === 3) {
        packageId = localStorage.getItem(CANDIDATE_PACKAGE_ID);
      } else if (user.role_id === 4) {
        packageId = localStorage.getItem(EMPLOYER_PACKAGE_ID);
      }
      if (packageId) {
        localStorage.removeItem(EMPLOYER_PACKAGE_ID);
        localStorage.removeItem(CANDIDATE_PACKAGE_ID);
        setSelectedPackage(packageId);
        setUpgradeMembershipPopup(true);
      }
    }
  }, [user.role_id]);
  const getPackages = (role) => {
    getPackageList({ role }, (res) => {
      setPackages(res?.data?.rows);
    });
  };
  const onSucess = () => {
    setUpgradeMembershipPopup(false);
    reloadProfile();
  };
  const designtype = () => {
    settype("");
  };
  const result = packages.filter((res) => res.title !== "Free");

  const checkSubscription = () => {
    employerActions.checkSubscriptionStatus({}, (response) => {
      if (response && response?.status === 200) {
        setSubcriptionData(response);
        setSubscriptionValidityDate(response?.data?.current_period_end)
      } else {
        setSubscriptionValidityDate("");
      }
    });
  }

  useEffect(() => {
    checkSubscription();
    setIsUpgradePlan(false);
  }, []);

  const handleCancelClick = (event) => {
    event.preventDefault();
    setIsConfirmed(true);
  };

  const handleCancelSubscription = () => {
    employerActions.cancelMembership({}, (response) => {
      if (response?.status === 200) {
        setIsConfirmed(false);
        checkSubscription();
      }
    });
  }

  const handleUpgradePlan = () => {
    setIsUpgradePlan(true);
    history.push("/post-a-job")
  }

  const handleBuySubscription = () => {
    setIsUpgradePlan(false);
    history.push("/post-a-job");
  }

  return (
    <div className="account-settings-tab ">
      {/* <>
        <Row>
          <Col xs={12} md={12}>
            <span>
              <div>
                {user.role_id === 4 && (
                  <>
                    <div
                      className="d-flex justify-content-between"
                      style={{ maxWidth: "90%" }}
                    >
                      {profileDetails.current_package_name !== "Free" && (
                        <p className="title text-black mb-5">
                          <strong>{profileDetails.contact_wallet}</strong>&nbsp;
                          Contacts Remaining
                        </p>
                      )}
                    </div>
                    {profileDetails.current_package_name === "Free" && (
                      <h2 className="text-orange text-bold">
                        Limited Time Offer...
                      </h2>
                    )}

                    <div className="subscription-details-box">
                      {profileDetails.current_package_name === "Free" && (
                        <h3>
                          <em>View Contact info for 5$ per profile</em>
                        </h3>
                      )}
                      {profileDetails.current_package_name !== "Free" && (
                        <h3>
                          <em>
                            Thanks for being a{" "}
                            <strong>
                              {profileDetails.current_package_name.slice(0, -4)}
                            </strong>{" "}
                            member!
                          </em>
                        </h3>
                      )}

                      {profileDetails.current_package_name === "Free" && (
                        <p>
                          As a Basic Member,you get{" "}
                          <strong>full profile access</strong> which include
                          downloading resumes and viewing contact information
                        </p>
                      )}
                      {profileDetails.current_package_name !== "Free" && (
                        <p>
                          As a{" "}
                          {profileDetails.current_package_name.slice(0, -4)}{" "}
                          Member,you get <strong>full profile access</strong>{" "}
                          which include downloading resumes and viewing contact
                          information
                        </p>
                      )}

                      <label className="section-text w-100">
                        <em>What&apos;s more...</em>
                      </label>
                      <p>As a thank you, you&apos;ll get to:</p>
                      <ul>
                        <li>
                          Be invited to try new features as they become
                          available
                        </li>
                        <li>
                          Keep the contact information of each profile you
                          purchased for a year{" "}
                        </li>
                      </ul>

                      <button
                        className="cta-type-2 h-40 btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setUpgradeMembershipPopup(true);
                          settype("subscription");
                        }}
                      >
                        {profileDetails.current_package_name === "Free"
                          ? "Upgrade to Basic"
                          : profileDetails.current_package_name === "Basic Pack"
                            ? "Upgrade to Power Pack!"
                            : profileDetails.current_package_name === "Power Pack"
                              ? "Upgrade to Premium Pack!"
                              : profileDetails.current_package_name ===
                              "Premium Pack" && "Add more contacts"}
                      </button>
                    </div>
                  </>
                )}
                {user.role_id === 3 && (
                  <>
                    <p className="title text-black mb-5">
                      Current Subscription:&nbsp;&nbsp;
                      <strong>{profileDetails.current_package_name}</strong>
                    </p>
                    {profileDetails.current_package_name === "Free" && (
                      <h2 className="text-orange text-bold">
                        Limited Time Offer...
                      </h2>
                    )}

                    <div className="subscription-details-box">
                      {profileDetails.current_package_name === "Free" && (
                        <h3>
                          <em>
                            Increase your visibility by boosting your profile!
                          </em>
                        </h3>
                      )}

                      <p>
                        With a Premier membership your profile stays promoted,
                        making it easier to catch the recruiter&apos;s
                        attention.
                      </p>
                      <label className="section-text w-100">
                        <em>What&apos;s more...</em>
                      </label>
                      <p>As a thank you, you&apos;ll get to:</p>
                      <ul>
                        <li>
                          Be invited to try new features as they become
                          available
                        </li>
                      </ul>
                      {profileDetails?.current_package_name === "Free" ? (
                        <button
                          className="cta-type-2 h-40 btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpgradeMembershipPopup(true);
                            settype("subscription");
                          }}
                        >
                          Upgrade my Plan
                        </button>
                      ) : (
                        <button
                          className="cta-type-2 h-40 btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          style={{ cursor: "default" }}
                        >
                          Thank you
                        </button>
                      )}
                    </div>
                    <div className="bottom-links-others">
                      <Link className="text-red" to="/terms-and-conditions">
                        Payment Terms
                      </Link>
                    </div>
                  </>
                )}
              </div>
              {user.role_id === 4 && (
                <div className="bottom-links-others">
                  <Link className="text-red" to="/terms-and-conditions">
                    Payment Terms
                  </Link>
                </div>
              )}
            </span>
          </Col>
        </Row>
      </> */}
      <div className="subscription-details-box">
        <Row className="membership_row">
          <Col xs={12} md={8}>
            <h3>MemberShip Details:</h3>
            {subscriptionValidityDate &&
              <p>{subscriptionData?.data?.job_post_subscription_detail?.plan_title}: {subscriptionValidityDate && subscriptionValidityDate}</p>}
            <button onClick={() => {
              setIsDetailModalOpen(true);
              setLoading(true);
            }}
              className="primary-filed-orange w-200">View Transactions</button>
          </Col>
          <Col xs={12} md={4}>
            {subscriptionData?.hasSubscription === true
              ? <button  onClick={() => setIsChangePlanPopupOpen(true)} className="primary-filed-orange w-250">Upgrade Membership</button>
              : <button  onClick={handleBuySubscription} className="primary-filed-orange w-250">Buy Membership</button>
            }
          </Col>
          {subscriptionData?.data?.status === "active" && <div className="bottom-links-others">
            <Link onClick={handleCancelClick} className="text-red" to="#" >
              Cancel Membership
            </Link>
          </div>}
        </Row>
        <div className="mbrship-row-divider"></div>
        <Row>
          <Col xs={12} md={8}>
            <h3>Resume Database:</h3>
            {profileDetails.current_package_name !== "Free" && (
              <p>
                Available :
                &nbsp;<strong>{profileDetails.contact_wallet}</strong>&nbsp;
                Contacts
              </p>
            )}
            <button onClick={() => setModalShow(true)} className="primary-filed-orange w-200">View Transactions</button>
          </Col>
          <Col xs={12} md={4}>
            <button onClick={(e) => {
              e.preventDefault();
              setUpgradeMembershipPopup(true);
              settype("subscription");
            }} className="primary-filed-orange w-250">Add more contacts</button>
          </Col>
        </Row>
        <div className="bottom-links-others">
          <Link className="text-red" to="/terms-and-conditions">
            Payment Terms
          </Link>
        </div>
      </div>
      <CustomeModel
        className="membership_modal"
        show={modalShow}
        modalBody={
          <Billing />
        }
        handleClose={() => setModalShow(false)}
      />

      <CustomeModel
        className="membership_modal"
        show={isDetailModalOpen}
        modalBody={
          <UserMembershipDetail />
        }
        handleClose={() => setIsDetailModalOpen(false)}
      />

      <CustomeModel
        show={isConfirmed}
        modalType="Positive/Negative"
        modalBody={
          <h3>Are you sure ? You want to cancel the membership !!</h3>
        }
        positiveButtonLable="Confirm"
        negativeButtonLable="Cancel"
        onPositive={() => handleCancelSubscription()}
        onNegative={() => setIsConfirmed(false)}
        handleClose={() => setIsConfirmed(false)}
      />

      <CustomeModel
        show={isChangePlanPopupOpen}
        modalType="Positive/Negative"
        modalBody={
          <h3>Are you sure ? You want to change your plan !!</h3>
        }
        positiveButtonLable="Yes"
        negativeButtonLable="No"
        onPositive={handleUpgradePlan}
        onNegative={() => setIsChangePlanPopupOpen(false)}
        handleClose={() => setIsChangePlanPopupOpen(false)}
      />

      <CustomeModel
        className="membership_modal"
        show={upgradeMembershipPopup}
        type={type}
        modalBody={
          <Subscription
            selectedPackage={selectedPackage}
            onSucess={onSucess}
            role_id={user.role_id}
            packages={result}
            designtype={designtype}
          />
        }
        headerImage={"/images/membership.png"}
        handleClose={() => setUpgradeMembershipPopup(false)}
      />
    </div>
  );
}
