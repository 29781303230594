import React, { useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory } from "react-router-dom";
import InputCheckbox from "./common/InputCheckbox";
import CustomButton from "../../common/button/CustomButton";
export default function EmploymentType() {
  let history = useHistory();
  const [requireVisaSponsorship, setRequireVisaSponsorship] = useState();
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/MinimumPay");
  };
  return (
    <div className="onboardingContentWrapper">
      <Container className="onboarding">
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/SeekingNewJob");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            6
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          What is your desired type of employment?
        </h2>
        <p className="text-center mx-auto mw-552 mb-4">
          You can select multiple options
        </p>
        <div className="text-center">
          <InputCheckbox
            name="VisaSponsorship"
            listOfOption={[
              {
                id: "Full Time",
                value: "Full Time",
                label: "Full Time",
              },
              {
                id: "Part Time",
                value: "Part Time",
                label: "Part Time",
              },
              {
                id: "Contract",
                value: "Contract",
                label: "Contract",
              },
              {
                id: "Temporary or seasonal",
                value: "Temporary or seasonal",
                label: "Temporary or seasonal",
              },
              {
                id: "Internship or co-op",
                value: "Internship or co-op",
                label: "Internship or co-op",
              },
            ]}
            onChange={(e) => {
              setRequireVisaSponsorship(e.target.value);
            }}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          isDisabled={helper.IsEmpty(requireVisaSponsorship)}
          onClick={onSubmit}
          label="Next"
        />
      </Container>
    </div>
  );
}
