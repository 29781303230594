import React from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory, Link } from "react-router-dom";
import CustomButton from "../../common/button/CustomButton";
import InputText from "../../common/inputText/InputText";
import InputSelect from "../../common/select/InputSelect";
import AddressAutocomplete from "../../common/addressAutocomplete/AddressAutocomplete";
export default function CurrentlyLive() {
  let history = useHistory();
  const onSubmit = () => {
    history.push("/Candidate/Onboarding/CanRelocate");
  };
  const onChange = () => {};
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/MinimumPay");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            8
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          What city and state do you currently live in?
        </h2>
        <div className="w-360 mx-auto mt-3">
          <AddressAutocomplete
            placeholder={"Enter adress"}
            label="Address"
            controlId="formBasicAddress"
            name="address"
          />
        </div>
        <div className="w-360 mx-auto mt-3">
          <InputText
            controlId="formBasicFirstName"
            label="City"
            type="text"
            name="firstName"
            onChange={onChange}
            placeholder="Enter city"
          />
        </div>
        <div className="w-360 mx-auto mt-3">
          <InputSelect
            controlId="formBasicCompanyState"
            label="STATE"
            options={["Mumbai", "Surat", "Ahemdabad"]}
            name="state"
            selectOptionLabel="Select state"
            placeholder="Select state"
            onChange={onChange}
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          onClick={onSubmit}
          label="Next"
        />
        <div className="other-link">
          <Link to="/Candidate/Onboarding/CanRelocate" className="ms-0">
            Skip this step
          </Link>
        </div>
      </Container>
    </div>
  );
}
