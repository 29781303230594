import Onboarding from "./Onboarding";
import PublicProfile from "./../components/profile/PublicProfile";
import EditProfile from "./../components/candidate/profile/EditProfile";
import MyResume from "../components/candidate/dashboard/Dashboard";
import AccountSettings from "../components/account-settings";
import SearchJob from "../components/candidate/searchjob/SearchJob";
import UploadResume from "../components/candidate/searchjob/UploadResume";
import PromoteResume from "components/candidate/Promote/PromoteResume";
import Resume from "components/resume/Resume";
import JobInsights from "components/candidate/JobInsights/JobInsights";
import PopularSearch from "components/candidate/PopolarSearch/PopularSearch";
export default [
  {
    name: "Onboarding",
    path: "/Onboarding",
    exact: true,
    component: Onboarding,
  },
  {
    name: "Public profile",
    path: "/PublicProfile/:id",
    exact: true,
    component: PublicProfile,
  },
  // {
  //   name: "Edit profile",
  //   path: "/EditProfile",
  //   exact: true,
  //   component: EditProfile,
  // },
  {
    name: "My Resume",
    path: "/Dashboard",
    exact: true,
    component: MyResume,
  },
  {
    name: "New Reume Page",
    path: "/Resume",
    exact: true,
    component: Resume,
  },
  // {
  //   name: "Search Job",
  //   path: "/SearchJob",
  //   exact: true,
  //   component: SearchJob,
  // },
  {
    name: "Promotr Resume",
    path: "/PromoteResume",
    exact: true,
    component: PromoteResume,
  },
  {
    name: "Insights Jobs",
    path: "/JobInsights",
    exact: true,
    component: JobInsights,
  },
  {
    name: "Popular Search",
    path: "/Popular Search",
    exact: true,
    component: PopularSearch,
  },
  {
    name: "Upload Resume",
    path: "/UploadResume",
    exact: true,
    component: UploadResume,
  },
  {
    name: "Apply Job",
    path: "/applyforjob/:id",
    exact: true,
    component: UploadResume,
  },
  {
    name: "Account Settings",
    path: "/account-settings",
    exact: false,
    component: AccountSettings,
  },
];
