import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Form,
  Button,
} from "react-bootstrap";
import * as commonActions from "./../../actions/commonActions";
import * as utilConstants from "../../utility/constants/utilConstants";
import * as helper from "../../utility/helper/helper";
import { getAuthentionInfo } from "../../utility/helper/authenticationHelper";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import CustomeModel from "./../common/model/CustomeModel";
import InputText from "../../components/common/inputText/InputText";
import CustomButton from "../common/button/CustomButton";
import { Link } from "react-router-dom";
function PublicProfile(props) {
  const [candidate, setCandidate] = useState({});
  const [myTurnPdf, setMyturnPdf] = useState({});
  const [showMyturn, setShowMyturn] = useState(false);
  const [noLimitPopUp, setNoLimitPopUp] = useState(false);
  const [youtubeLink, setYouTubeLink] = useState("");
  const [privteInfoModal, setPrivateInfoModal] = useState({ isOpen: false });

  useEffect(() => {
    if (
      helper.IsEmpty(props.state.commonReducer.employmentType) &&
      !props.state.commonReducer.isLoading
    )
      props.commonActions.getCommonDropdownList();

    let id = parseInt(props.match.params.id);
    if (Number.isInteger(id)) {
      commonActions.getUserProfile({ id }, (respose) => {
        if (respose.status === utilConstants.sucessState200) {
          let tempData = { ...respose.data };
          delete tempData.CandidateProfile;
          delete tempData.UserSkill;
          setCandidate({
            ...tempData,
            ...respose.data.CandidateProfile,
            skill:
              respose.data.UserSkill &&
              JSON.parse(respose.data.UserSkill.skill),
          });
        }
      });
      commonActions.downloadCandidateProfilePDF(id, (res) => {
        setMyturnPdf(res);
      });
    } else props.history.goBack();
  }, []);
  let commonDropdown = props.state.commonReducer;
  const getDropValue = (array, key) => {
    if (commonDropdown[array]) {
      let temp = [];
      temp = commonDropdown[array].filter(
        (obj) => obj.id === parseInt(candidate[key])
      )[0];
      return temp ? temp.title : null;
    }
  };
  const finalRate = (value) => {
    let num = value;
    let text = num?.toString();
    let arrstr = [];
    if (text !== undefined) arrstr = Array.from(text);
    return arrstr;
  };
 
  return (
    <div className="dashboardPageWrapper">
      <CustomeModel
        modalBody={
          <Form>
            <InputText
              label="youtube Link"
              placeholder=""
              value={youtubeLink}
              onChange={(e) => {
                setYouTubeLink(e.target.value);
              }}
            />
          </Form>
        }
        className="p-sm searchFilterModal"
        show={showMyturn}
        positiveButtonLable="Cancel"
        negativeButtonLable="Save"
        onPositive={() => {
          setShowMyturn(false);
        }}
        onNegative={() => {
          commonActions.uploadVideoCV(
            { video_resume: youtubeLink },
            (response) => {
              if (response.status === utilConstants.sucessState200) {
                setCandidate({ ...candidate, video_resume: youtubeLink });
                setShowMyturn(false);
              } else
                ReactNotifications(
                  response.message,
                  utilConstants.getErrorStatus
                );
            }
          );
        }}
        title={"Add your youtube video resume link"}
        modalType="Positive/Negative"
        handleClose={() => setShowMyturn(false)}
      />
      <Container>
        <Row>
          <Col>
            <h2 className="section-heading-2">
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
              >
                <img src="/images/back.png" alt="back" />
                Back to previous page
              </div>
            </h2>
            {candidate && (
              <div className="contentBox">
                <div className="personInfoHeader publicProfileHeaderDesktop">
                  <div className="column1">
                    <img
                      className="userpic"
                      src={
                        process.env.REACT_APP_API_BASE_URL +
                          "/user_profile/" +
                          candidate.profile_image || "/images/marcus.png"
                      }
                      onError={(e) => (e.target.src = "/images/marcus.png")}
                      alt="user"
                    />
                  </div>
                  <div className="column2">
                    <label className="row1 d-flex align-items-center mb-remove">
                      {helper.capitalizeFirstLetter2(candidate.first_name)}
                      &nbsp;
                      {candidate.seen == "1" ? (
                        helper.capitalizeFirstLetter2(candidate.last_name)
                      ) : (
                        <>
                          {candidate.last_name?.substring(0, 1).toUpperCase()}.
                        </>
                      )}
                      {candidate.is_seeking_job !== "1" && (
                        <span className="status active">
                          <img src="/images/rocket-active.png" alt="active" />
                          Active
                        </span>
                      )}
                    </label>
                    {candidate.seen == "1" && (
                      <>
                        <label className="row3">{candidate.email}</label>
                        <label className="row3 ">
                          {helper.formatPhoneNumber(candidate.phone)}
                        </label>
                      </>
                    )}
                  </div>
                  <div className="column3">
                    <div>
                      <label className="row1 test-end">
                        {candidate.minimum_amount !== 0 &&
                          candidate.minimum_amount !== null && (
                            <>
                              $
                              {finalRate(candidate.minimum_amount).map(
                                (str, i) => {
                                  return (
                                    <span key={i}>
                                      {str}
                                      {i === 2 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length > 5 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length < 7 && <>,</>}
                                      {i === 0 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length > 6 && <>,</>}
                                      {i === 3 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length > 6 && <>,</>}
                                      {i === 1 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length > 4 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length < 6 && <>,</>}
                                      {i === 0 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length > 3 &&
                                        finalRate(candidate.minimum_amount)
                                          ?.length < 5 && <>,</>}
                                    </span>
                                  );
                                }
                              )}
                              <span className="font-regular">
                                /{candidate.minimum_amount_type}
                              </span>
                            </>
                          )}
                      </label>
                      <label className="row2 text-end">
                        {candidate.city?.toUpperCase()},
                        {candidate.state?.toUpperCase()}
                      </label>
                      <label className="row3 text-end">
                        {getDropValue("employmentType", "desired_employment")}
                      </label>
                    </div>
                    {props.bookmarkOption && (
                      <img
                        src={
                          "/images/bookmark-filled.png"
                        }
                        className="bookamark"
                        alt="active"
                      />
                    )}
                  </div>
                </div>

                <div className="personInfoHeader publicProfileHeaderMobile">
                  <div className="topRow">
                    <div className="column1">
                      <img
                        className="userpic"
                        src={
                          process.env.REACT_APP_API_BASE_URL +
                            "/user_profile/" +
                            candidate.profile_image || "/images/marcus.png"
                        }
                        onError={(e) => (e.target.src = "/images/marcus.png")}
                        alt="user"
                      />
                    </div>
                    <div className="column2">
                      <label className="row1 d-flex align-items-center">
                        {helper.capitalizeFirstLetter2(candidate.first_name)}
                        &nbsp;
                        {candidate.seen == "1" ? (
                          helper.capitalizeFirstLetter2(candidate.last_name)
                        ) : (
                          <>
                            {candidate.last_name?.substring(0, 1).toUpperCase()}
                            .
                          </>
                        )}
                        {candidate.is_seeking_job !== "1" && (
                          <span className="status active">
                            <img src="/images/rocket-active.png" alt="active" />
                            Active
                          </span>
                        )}
                      </label>
                      {candidate.seen == "1" && (
                        <>
                          <label className="row3">{candidate.email}</label>
                          <label className="row3 ">
                            {helper.formatPhoneNumber(candidate.phone)}
                          </label>
                        </>
                      )}
                    </div>
                    <div className="column3">
                      <Dropdown className="kebabDropDown" align="end">
                        <Dropdown.Toggle id="dropdown-basic">
                          <img
                            className="kebabIcon"
                            src="/images/kebab.png"
                            alt="menu"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            View profile as employer
                          </Dropdown.Item>
                          <Dropdown.Item href="#">Edit</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="bottomRow">
                    <div className="column3">
                      <div>
                        <label className="row1">
                          {candidate.minimum_amount !== 0 &&
                            candidate.minimum_amount !== null && (
                              <>
                                $
                                {finalRate(candidate.minimum_amount).map(
                                  (str, i) => {
                                    return (
                                      <span key={i}>
                                        {str}
                                        {i === 2 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length > 5 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length < 7 && <>,</>}
                                        {i === 0 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length > 6 && <>,</>}
                                        {i === 3 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length > 6 && <>,</>}
                                        {i === 1 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length > 4 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length < 6 && <>,</>}
                                        {i === 0 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length > 3 &&
                                          finalRate(candidate.minimum_amount)
                                            ?.length < 5 && <>,</>}
                                      </span>
                                    );
                                  }
                                )}
                                <span className="font-regular">
                                  /{candidate.minimum_amount_type}
                                </span>
                              </>
                            )}
                        </label>
                        <label className="row2">
                          {candidate.city?.toUpperCase()},
                          {candidate.state?.toUpperCase()}
                        </label>
                        <label className="row3">
                          {getDropValue("employmentType", "desired_employment")}
                        </label>
                      </div>
                    </div>
                    <img
                      src={
                        "/images/bookmark-filled.png"
                      }
                      className="bookamark"
                      alt="active"
                    />
                  </div>
                </div>

                <div className="innerBlock">
                  <h3>Contact</h3>

                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      <div className="info-row-with-icon">
                        <Button
                          className="cta-type-3 myturn-wpx-80 h-20 br-16 btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            commonActions.getConatct(
                              { id: candidate.id },
                              (res) => {
                                if (
                                  res.status === utilConstants.sucessState200
                                ) {
                                  setPrivateInfoModal({
                                    isOpen: true,
                                    res,
                                  });
                                  commonActions.getUserProfile(
                                    { id: candidate.id },
                                    (respose) => {
                                      if (
                                        respose.status ===
                                        utilConstants.sucessState200
                                      ) {
                                        let tempData = { ...respose.data };
                                        delete tempData.CandidateProfile;
                                        delete tempData.UserSkill;
                                        setCandidate({
                                          ...tempData,
                                          ...respose.data.CandidateProfile,
                                          skill:
                                            respose.data.UserSkill &&
                                            JSON.parse(
                                              respose.data.UserSkill.skill
                                            ),
                                        });
                                      }
                                    }
                                  );
                                  commonActions.downloadCandidateProfilePDF(
                                    candidate.id,
                                    (res) => {
                                      setMyturnPdf(res);
                                    }
                                  );
                                } else setNoLimitPopUp(true);
                              }
                            );
                          }}
                        >
                          Contact
                        </Button>
                      </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      <div className="info-row-with-icon">
                        <div className="icon-row">
                          <img src="/images/link.png" alt="link" />
                        </div>
                        <div className="data-row">
                          Link Share
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                Copy!
                              </Tooltip>
                            )}
                          >
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(
                                  process.env.REACT_APP_BASE_URL +
                                    "/Employer/publicprofile/" +
                                    candidate.id
                                );
                              }}
                            >
                              <img src="/images/share.png" alt="share" />
                            </a>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="seperator"></div>
                </div>

                <div className="innerBlock">
                  <Row>
                    <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
                      <h3>Personal information</h3>
                      <Row>
                        {candidate.degree && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/degree.png" alt="telephone" />
                              </div>
                              <div className="data-row">
                                {getDropValue("highestEducation", "degree")}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.job_shift_type && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row ">
                                <img src="/images/shift.png" alt="email" />
                              </div>
                              <div className="data-row ">
                                {getDropValue("workShift", "job_shift_type")}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.need_visa_sponsorship && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/sponsor.png" alt="link" />
                              </div>
                              <div className="data-row ">
                                Sponsorship Required&nbsp;
                                {candidate.need_visa_sponsorship === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.willing_remote_work && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/desktop.png" alt="email" />
                              </div>
                              <div className="data-row">
                                Remote work preference &nbsp;
                                {candidate.willing_remote_work === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.willing_relocate && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/place.png" alt="link" />
                              </div>
                              <div className="data-row">
                                Willing to relocate &nbsp;
                                {candidate.willing_relocate === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.willing_travel && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/house.png" alt="telephone" />
                              </div>
                              <div className="data-row">
                                Willing to travel&nbsp;
                                {candidate.willing_travel === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.willing_hazardous && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/prize.png" alt="email" />
                              </div>
                              <div className="data-row">
                                Able to work in hazardous conditions&nbsp;
                                {candidate.willing_hazardous === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                        {candidate.willing_heavy_lift && (
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="info-row-with-icon">
                              <div className="icon-row">
                                <img src="/images/leaderboard.png" alt="link" />
                              </div>
                              <div className="data-row">
                                Able to work lifting heavy items&nbsp;
                                {candidate.willing_heavy_lift === "1"
                                  ? "(YES)"
                                  : "(NO)"}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
                      {candidate.video_resume ? (
                        <iframe
                          width="250"
                          height="190"
                          src={`${candidate.video_resume}`}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        getAuthentionInfo().type ===
                          utilConstants.USER_CANDIDATE && (
                          <div className="videoBox  mt-5">
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                helper.isFutureDate(candidate.valid_till)
                                  ? setShowMyturn(true)
                                  : setNoLimitPopUp(true);
                              }}
                              className="fileIcon"
                              src="/images/videoResum.png"
                              alt="file"
                            />
                            <label className="infoText">
                              <span className="font-bold d-block">
                                Add your youtube video resume link here
                              </span>
                              <span className="font-regular d-block">
                                Your video resume will be shown here
                              </span>
                            </label>
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                  <div className="seperator"></div>
                </div>

                <div className="innerBlock">
                  <h3>Skills/competency</h3>
                  <ul className="indicatorLabels">
                    {candidate.skill &&
                      candidate.skill.map((uk, index) => (
                        <li key={index}>
                          <label
                            className={
                              utilConstants.lableCssClass[
                                index % utilConstants.lableCssClass.length
                              ]
                            }
                          >
                            {uk}
                          </label>
                        </li>
                      ))}
                  </ul>

                  <div className="seperator"></div>
                </div>

                <div className="innerBlock">
                  <h3>Job history</h3>
                  {candidate.JobHistories &&
                    candidate.JobHistories.map((jh, index) => (
                      <div className="wrapperRow" key={index}>
                        <div className="left d-flex align-items-center job-history-row myturn-w-100">
                          <p className="row1 w-auto">{jh.company}</p>
                          <p className="row1 w-auto  ms-4">{jh.position}</p>
                          <p className="row2 w-auto ms-4">
                            {helper.formatDate(jh.start_date, "MM / yyyy")} -
                            {helper.formatDate(jh.end_date, "MM / yyyy")}
                          </p>
                          <p
                            className="row2 w-auto ms-4 myturn-w-55 text-ellipsis"
                            title={jh.summary}
                          >
                            {jh.summary}
                          </p>
                        </div>
                      </div>
                    ))}
                  <div className="seperator"></div>
                </div>

                <div className="innerBlock">
                  {/* <h3>Attachments</h3> */}

                  <div className="wrapperRow flex-column">
                    {/* <p className="row1">Resume</p> */}
                    <p className="row2">
                      {candidate.id === getAuthentionInfo().user_id && (
                        <div className="uploadBox2">
                          <span className="uploadIcon">
                            <img src="/images/uploadIcon.png" alt="user" />
                          </span>
                          <img
                            className="fileIcon"
                            src="/images/fileText.png"
                            alt="file"
                          />
                          <label className="fileName">
                            Resume - {candidate.cv_path}
                          </label>
                          <div className="cta-panel">
                            <p
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  process.env.REACT_APP_API_BASE_URL +
                                    "/user_resume/" +
                                    candidate.cv_path,
                                  "_blank",
                                  "width=500,height=500, top=100, left=500"
                                );
                              }}
                              className="cursor-pointer"
                            >
                              <img src="/images/Eye.png" alt="preview" />
                            </p>
                            <a
                              href={
                                process.env.REACT_APP_API_BASE_URL +
                                "/user_resume/" +
                                candidate.cv_path
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              <img src="/images/download.png" alt="download" />
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="uploadBox2">
                        <span className="uploadIcon">
                          <img src="/images/uploadIcon.png" alt="user" />
                        </span>
                        <img
                          className="fileIcon"
                          src="/images/fileText.png"
                          alt="file"
                        />
                        <label className="fileName">
                          Myturn-Resume
                          <br />
                        </label>
                        <div className="cta-panel">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                myTurnPdf,
                                "_blank",
                                "width=500,height=500, top=100, left=500"
                              );
                            }}
                            className="button-design cursor-pointer"
                          >
                            <p className="btn-text-design">View Resume</p>
                          </button>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <CustomeModel
          modalBody={
            <>
              <h3 className="heading-box">Ready to hire !</h3>
              <p className="contant-box">
                The <b>Basic Pack</b> lets you view contact info
              </p>
              <Link
                to="/employer/account-settings/membership"
                className="box-link"
              >
                Upgrade!
              </Link>
            </>
          }
          show={noLimitPopUp}
          handleClose={() => {
            setNoLimitPopUp(false);
          }}
        />
        <CustomeModel
          className={"candidate-info-modal"}
          handleClose={() => {
            setPrivateInfoModal({ isOpen: false });
          }}
          show={privteInfoModal.isOpen}
          headerImage={"/images/wallet.png"}
          modalType="Custome"
          customeButton={
            <CustomButton
              label="Continue"
              className="cta-type-2 h-40 w-50 br-16 btn-cpc"
              onClick={(e) => {
                e.preventDefault();
                setPrivateInfoModal(false);
              }}
            />
          }
          modalBody={
            privteInfoModal.res && (
              <p>
                {privteInfoModal.res.status === utilConstants.sucessState200 ? (
                  <ul>
                    <li className="text-start">
                      <b>Email:&nbsp;</b>
                      {privteInfoModal.res.data.email}
                    </li>
                    <li className="text-start">
                      <b>Phone:&nbsp;</b>
                      {privteInfoModal.res.data.phone}
                    </li>
                  </ul>
                ) : (
                  privteInfoModal.res.message
                )}
              </p>
            )
          }
        />
      </Container>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile);
