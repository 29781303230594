import { NotificationManager } from "react-notifications";
import * as utilConstants from "../../../utility/constants/utilConstants";
export default function ReactNotifications(
  message,
  type = utilConstants.getSuccessStatus,
  title,
  timeOut = utilConstants.getNotificationTimeOut,
  callback,
  priority
) {
  switch (type) {
    case utilConstants.getInfoStatus:
      return NotificationManager.info(
        message,
        title,
        timeOut,
        callback,
        priority
      );
    case utilConstants.getSuccessStatus:
      return NotificationManager.success(
        message,
        title,
        timeOut,
        callback,
        priority
      );
    case utilConstants.getWarningStatus:
      return NotificationManager.warning(
        message,
        title,
        timeOut,
        callback,
        priority
      );
    case utilConstants.getErrorStatus:
      return NotificationManager.error(
        message,
        title,
        timeOut,
        callback,
        priority
      );
    default:
      return NotificationManager.success(
        message,
        title,
        timeOut,
        callback,
        priority
      );
  }
}
