import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import jobtype from '../../../assets/images/fulltime.svg';
import location from '../../../assets/images/locatuion.svg';
import payrange from '../../../assets/images/payrange.svg';
import status from "../../../assets/images/ClockCountdown.svg";
import TimeAgo from "components/common/postedDateFunctionality/TimeAgo";
import InfiniteScroll from "react-infinite-scroller";

export default function MyJobListing(props) {
  const [hasMore, setHasMore] = useState(true);
  const fetchMoreData = () => {
    if (props.jobs.length >= props.count) {
      setHasMore(false);
      return;
    }
    props.loadJobs(false)
  }
  return (
    <>

      {props.jobs && props.jobs.length > 0 ? (
        <Col
          xxl={{ span: 4 }}
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          md={4}
          sm={12}
          xs={12}
        >
          <div className="left_right_dv_wrrpr">
            <div style={{ height: 600, overflow: "auto" }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
                useWindow={false}
              >
                {<div className="left_right_inner_wrrpr">
                  {props.jobs.map((j, index) => (
                    <div
                      className={`left-job-dv ${props.activeIndex === index && 'active'}`}
                      onClick={() => props.selectThisJob(index)}
                    >
                      <div className="right_dv_data_wrrpr">
                        <div className="left_dv_data">
                          <div className="right_dv_body">
                            <div className="right_dv_title">
                              <h3>{j.job_title}</h3>
                              {j?.company && (
                                <div className="left_dv_ineer_data">
                                  {j?.company_logo_url ? <img src={j?.company_logo_url} className="company-avatar-listing" />
                                    : <div className="company-avatar-listing">{j?.company.charAt(0)}</div>}
                                  <p>{j?.company}</p>
                                </div>
                              )}


                              {j.job_location && (
                                <div className="left_dv_ineer_data">
                                  <img src={location} />
                                  <p>{j.job_location}</p>
                                </div>
                              )}
                            </div>
                            {j.job_type && (
                              <div className="left_dv_ineer_data">
                                <img src={jobtype} />
                                <p style={{ textAlign: "end" }}>{j.job_type}</p>
                              </div>
                            )}
                          </div>
                          {(j?.minimum_pay || j?.maximum_pay) && (
                            <div className="left_dv_ineer_data">
                              <img src={payrange} />
                              <p className="pay_range">
                                ${j.minimum_pay.toLocaleString()} - ${j.maximum_pay.toLocaleString()}
                              </p>
                            </div>
                          )}
                          {j.job_status && (
                            <div className="left_dv_ineer_data">
                              <img src={status} />{" "}<span >Job Status :</span>
                              <p>
                                {j.job_status === "1"
                                  ? "Active"
                                  : j.job_status === "0"
                                    ? "Suspended"
                                    : j.job_status === "2"
                                      ? "Expired"
                                      : ""
                                }
                              </p>
                            </div>
                          )}
                          {console.log(j, "ani")}
                        </div>
                        <div className="btns_dv">
                          <Button
                            className="tertiary-button"
                            onClick={() => props.handleEditFormShow(j)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="tertiary-button"
                            onClick={() => props.handleShowDel(j.id)}
                          >
                            Trash
                          </Button>
                        </div>
                        <div className="posted">
                          <p><TimeAgo createdAt={j?.created_at} /></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                }
              </InfiniteScroll>
            </div>
          </div>
        </Col>
      ) : (
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={12}
          sm={12}
          xs={12}
        >
          <div className="no_job_right_dv">
            <h1>No Jobs Posted</h1>
          </div>
        </Col>
      )}
    </>
  );
}
