import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sucessState200 } from "../../../utility/constants/utilConstants";
import OnBoarding from "./OnBoarding";
import { candidateBoarding } from "./onboardingActions";
import { connect } from "react-redux";

import InputRadio from "./common/InputRadio";
import { getPackageList } from "../../../actions/account";
import Stripe from "../../common/stripe/stripe";
import { Row, Col } from "react-bootstrap";
import * as commonActions from "../../../actions/commonActions";
import { bindActionCreators } from "redux";

function SignPayment(props) {
  const user = props?.state?.commonReducer?.signedUserDetail || {};
  const reloadProfile = props.commonActions.signinUserDetails;
  let history = useHistory();
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (user.role_id) {
      getPackages(user.role_id);
    }
  }, [user.role_id]);

  const getPackages = (role) => {
    getPackageList({ role }, (res) => {
      setPackages(res?.data?.rows);
    });
  };
  const onSubmit = () => {
    if (props.location.state && props.location.state.fileName) {
      candidateBoarding(props.location.state, (Response) => {
        if (Response.status !== sucessState200) {
        } else {
          history.push("/"); 
        }
      });
    } else props.history.goBack();
    reloadProfile();
    history.push("/Candidate/account-settings/profile");
  };

  var selectedPackageDetails =
    packages.find((pkg) => pkg.id == selectedPackage) || {};
  return (
    <OnBoarding cureentStep={4} previousStep="UploadResume">
      <h2 className="section-heading-2 text-center mx-auto mw-552">
        Subscription Packages
      </h2>

      <InputRadio
        ak={onSubmit}
        className="text-center"
        value={selectedPackage}
        name="VisaSponsorship"
        listOfOption={[
          ...packages.map((p) => ({
            ...p,
            value: p.id,
            label: `${p.title} (${
              user.role_id === 3
                ? `Validity ${p.validity}`
                : `View Requests ${p.view_requests}`
            })  - $${p.price} `,
          })),
        ]}
        onChange={(e) => {
          setSelectedPackage(e.target.value);
        }}
      />
      <Row>
        <Col xs={12} md={3}></Col>
        <Col xs={12} md={6}>
          {selectedPackage && (
            <Stripe
              selectedPackageDetails={selectedPackageDetails}
              packageId={selectedPackage}
              onSucess={onSubmit}
            ></Stripe>
          )}{" "}
        </Col>
        <Col xs={12} md={3}></Col>
      </Row>
    </OnBoarding>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignPayment);
