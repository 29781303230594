import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import InputFile from "components/common/inputFile/InputFile";
import * as commonActions from "actions/commonActions";
import * as candidateActions from "components/candidate/candidateActions";
import { useParams, useLocation } from "react-router-dom";
import ReactNotifications from "components/common/reactNotifications/ReactNotifications";
import * as utilConstants from "utility/constants/utilConstants";
import { AuthContext } from "context/authContext";

export default function UploadResume() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const jobId = params.id;
  let user = localStorage.getItem("candidate_id");
  const {setIsSearchTabClicked, ipAddress } = useContext(AuthContext);
  const [profile, setProfile] = useState({});
  const uploadResume = (file) => {
    file = file[0];
    const formData = new FormData();
    formData.append("resume", file);
    commonActions.uploadResume(formData, (response) => {
      if (response.file) setProfile({ ...profile, cv_path: response.file });
      else ReactNotifications(response.msg, utilConstants.getErrorStatus);
    });
  };

  const applyFroJob = (e) => {
    e.preventDefault();
    let data = {
      job_id: jobId,
      resume: profile.cv_path,
    };
    commonActions.applyForJob(data, (response) => {
      if (response.status === utilConstants.sucessState200) {
        ReactNotifications(response.message, utilConstants.sucessState200);
        const data = {
          ip_address: ipAddress,
          user_id: user || "",
          user_from: "JOB APPLY",
          job_id: jobId || "",
        }
        candidateActions.measureUserClicks(data, (response) => { });
        history.push("/");
        setIsSearchTabClicked(true);
      } else {
        ReactNotifications(response.msg, utilConstants.getErrorStatus);
        history.push("/");
        setIsSearchTabClicked(true);
      }
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <div className="upload_resume_body">
              <h3 className="mb-3">Please upload your resume</h3>
              <div className="contentBox">
                <InputFile
                  onDrop={(files) => {
                    uploadResume(files, false);
                  }}
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  label="resume"
                  setProfile={setProfile}
                />
              </div>
            </div>
            <div className="upload_resume_btn">
              <Button
                onClick={(e) => applyFroJob(e)}
                disabled={!profile.cv_path ? true : false}
                className="apply_btn"
              >
                {location?.state?.data?.some(
                  (appliedJob) => appliedJob.candidate_id === parseInt(user)
                )
                  ? "Applied"
                  : "Apply"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
