import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    FormControl,
    InputGroup,
    Accordion,
} from "react-bootstrap";
import { getFAQs } from "../../../actions/faqs";
import { cmsPageId } from "../../../utility/constants/utilConstants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContentByPageId } from "../../../actions/cms";


const PopularSearch = ({ getPageContent, state, handlePopularSearch }) => {
    const pageId = cmsPageId.faq;
    const content = state[pageId] || {};
    const [faqs, setFaqs] = useState([]);
    const [tab, setTab] = useState("candidate");
    const [searchText, setSearchText] = useState("");

    const loadFaqs = () => {
        getFAQs((res) => {
            setFaqs(res.data);
        });
    };
    useEffect(() => {
        loadFaqs();
    }, []);
    useEffect(() => {
        if (pageId && !Object.keys(content).length) {
            getPageContent({ page_id: pageId });
        }
    }, [pageId]);
    return (
        <>
            <div className="popular-search-section">
                <div className="popular-search-body">
                    <div className="popular-search-title">
                        <h4>Popular {" "}
                            <span style={{ color: "#278E69" }}>Searches</span>
                        </h4>
                    </div>
                    <div className='popular-search-tags'>
                        <ul>
                            {[
                                "Cryptographer",
                                "Analyst",
                                "Specialist",
                                "Security Consultant",
                                "Incident Responder"
                            ].map((title, index) => (
                                <li onClick={() => handlePopularSearch(title)} key={index} style={{cursor: 'pointer'}}>{title}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="popular-search-section mb-50">
                <div className="popular-search-body">
                    <div className="popular-search-title">
                        <h4>People Also {" "}
                            <span style={{ color: "#278E69" }}>Ask</span>
                        </h4>
                    </div>
                    <div className='popular-search-tags'>
                                    <Accordion defaultActiveKey="-1">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Is cyber security good for a career?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, cyber security is a rapidly growing field with a shortage of skilled professionals, making it a lucrative career choice. The demand for cyber security experts spans across all industries, ensuring job security and advancement opportunities.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Does cyber security really pay well?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, cyber security professionals enjoy competitive salaries, with compensation reflecting the critical importance and demand for their skills. Advanced roles and specializations can lead to even higher earnings.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Does cyber security work from home?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, many cyber security jobs offer the flexibility to work remotely, leveraging digital tools to protect systems and data. This flexibility is a significant perk for those seeking a balance between work and personal life.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>I am an absolute beginner, can I still get into cyber security?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, with dedication and the right learning path, including certifications and practical experience, beginners can successfully enter the cyber security field. Resources like online courses and community forums are great starting points for building foundational knowledge.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>What skills are important for a successful career in cyber security?</Accordion.Header>
                                            <Accordion.Body>
                                                A strong grasp of IT fundamentals and a keen analytical mindset are crucial for success in cyber security. Continuous learning and adaptability are also key, given the fast-evolving nature of cyber threats.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Which type of cyber security pays the most?</Accordion.Header>
                                            <Accordion.Body>
                                                Roles focusing on information security governance and risk management, such as a Chief Information Security Officer (CISO), typically offer the highest salaries. These positions require extensive experience and expertise, reflecting their compensation.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>What are cyber security jobs for beginners?</Accordion.Header>
                                            <Accordion.Body>
                                                Entry-level positions, such as Security Analyst or IT Technician with a security focus, are accessible for beginners and provide a solid foundation in cyber security principles. These roles often serve as stepping stones to more advanced positions.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>Will this site help me find a good job in the cyber security industry?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, MyTurn’s cyber security job board can connect you with specialized roles that match your skills and career aspirations. It serves as a valuable resource for discovering opportunities in the rapidly evolving cyber security landscape, from entry-level positions to high-level executive roles.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>Do you offer any resume writing tips and guidelines?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, for impactful cybersecurity resume tips, emphasize technical skills, certifications, and relevant experiences, aligning closely with the job description to stand out. Visit our article on cyber security resume tips for detailed guidelines on crafting a resume that showcases your abilities to protect against cyber threats effectively.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header>Is it necessary to have programming skills for a career in cyber security?</Accordion.Header>
                                            <Accordion.Body>
                                                No, programming skills are not mandatory for all cyber security roles, but they can be beneficial, especially for technical positions involving system vulnerabilities and the development of security solutions. A basic understanding of programming languages like Python can enhance your problem-solving capabilities.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header>What are the typical job roles in the field of cyber security?</Accordion.Header>
                                            <Accordion.Body>
                                                Security Analysts, responsible for identifying and mitigating vulnerabilities, and cyber security Engineers, who design and implement secure network solutions, are among the most common entry points in the field. These foundational roles provide a broad exposure to cyber security practices and pave the way for specialized career paths.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header>What are some important industry trends in cyber security jobs?</Accordion.Header>
                                            <Accordion.Body>
                                                The rise of cloud computing, AI, and machine learning in cyber security strategies, and the increasing threat of sophisticated cyber attacks are key trends shaping the industry. Staying abreast of these trends is crucial for professionals looking to advance their careers in cyber security.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="12">
                                            <Accordion.Header>Which cities in the United States offer the highest salaries for work-from-home jobs?</Accordion.Header>
                                            <Accordion.Body>
                                                Cities with a high cost of living and a strong tech industry presence, such as San Francisco and New York, tend to offer the highest salaries for cyber security work-from-home positions. These cities' competitive job markets reflect the higher compensation offered to attract top talent.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                    </div>
                </div>
            </div>

        </>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getPageContent: bindActionCreators(getContentByPageId, dispatch),
    };
}
function mapStateToProps(state) {
    return {
        state: state.cmsReducer,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PopularSearch);