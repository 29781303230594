import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Form } from "react-bootstrap";

import { externalAPIcalling } from "../../../utility/config/api/index";
export default function AdressAutocomplete({
  country = "us",
  placeholder = "Select",
  value,
  className,
  controlId,
  onSelect = () => {},
  label,
  error,
  defaultInputValue,
}) {
  const [options, setOptions] = useState([]);
  function parseReverseGeo(geoData) {
    let locality, region, country, postcode, returnStr;
    if (geoData.context) {
      geoData.context.forEach((v) => {
        if (v.id.indexOf("locality") >= 0) {
          locality = v.text;
        }
        if (v.id.indexOf("postcode") >= 0) {
          postcode = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          region = v.text;
        }
        if (v.id.indexOf("country") >= 0) {
          country = v.text;
        }
      });
    }
    if (postcode && region && country) {
      returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`;
    } else {
      returnStr = geoData.place_name;
    }
    onSelect({
      number: geoData.address,
      address: geoData.text,
      locality,
      region,
      postcode,
      country,
      concat: returnStr,
      complete: geoData.place_name,
    });
  }

  console.log(placeholder, "hhh")
  const handleChange = (value) => {
    if (value)
      externalAPIcalling(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" + value + ".json/",
        "GET",
        {
          access_token: process.env.REACT_APP_MAP_BOX_Gl_API,
          type: "locality, city, state, postcode",
          country: country,
        }
      ).then((response) => {
        if (response) setOptions(response.features);
      });
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Search {label}</span>
      </components.NoOptionsMessage>
    );
  };
  useEffect(() => {
    handleChange(value);
  }, []);

  return (
    <div className={(className || "") + " adress-autocomplete"}>
      <Form.Group className="form-group" controlId={controlId}>
        <Form.Label>
          {typeof label === "string" ? label.toUpperCase() : label}
        </Form.Label>
        {/* {defaultInputValue ? ( */}
          <Select
            defaultValue={{ place_name: defaultInputValue, id: 0 }}
            defaultInputValue={defaultInputValue}
            components={{ NoOptionsMessage }}
            onInputChange={handleChange}
            onChange={parseReverseGeo}
            placeholder={placeholder}
            getOptionLabel={(option) => option.place_name}
            getOptionValue={(option) => option.id}
            options={options}
            className={error ? "is_valid" : ""}
          />
         {/* ) : null} */}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
