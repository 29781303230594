import React from "react";
import { Button } from "react-bootstrap";
export default function CustomButton({
  variant,
  type = "button",
  className,
  isDisabled = false,
  label,
  onClick = () => {},
  isLoading,
  children,
}) {
  return (
    <Button
      variant={variant}
      type={type}
      className={"custom-button " + className}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <img src="/images/reload-white.png" className="loading-icon" />
      ) : (
        label || children
      )}
    </Button>
  );
}
