import React from "react";
import { Container, ProgressBar } from "react-bootstrap";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as helper from "../../../utility/helper/helper";
import { useHistory } from "react-router-dom";
import CustomButton from "../../common/button/CustomButton";
import InputText from "../../common/inputText/InputText";
export default function Privacy() {
  let history = useHistory();
  const onSubmit = () => {
    history.push("/Candidate/account-settings/profile");
  };
  const onChange = () => {};
  return (
    <div className="onboardingContentWrapper">
      <Container>
        <div className="backLink">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/Candidate/Onboarding/SecurityClearance");
            }}
          >
            <img src="/images/back.png" alt="upload" />
            Previous step
          </a>
        </div>
        <ProgressBar
          now={helper.fillProgressBarParentage(
            utilConstants.getOnBoardingTotalSteps,
            18
          )}
        />
        <h2 className="section-heading-2 text-center mx-auto mw-552">
          Do you want to hide your resume from your current employer?
        </h2>
        <div className="w-360 mx-auto mt-3">
          <InputText
            controlId="formBasicFirstName"
            label="Company Name"
            type="text"
            name="firstName"
            onChange={onChange}
            placeholder="Company Name"
          />
        </div>
        <div className="w-360 mx-auto mt-3">
          <InputText
            controlId="formBasicFirstName"
            label="Company URL"
            type="text"
            name="firstName"
            onChange={onChange}
            placeholder="Company URL"
          />
        </div>
        <div className="w-360 mx-auto mt-3">
          <InputText
            controlId="formBasicFirstName"
            label="Company URL 2 (optional)"
            type="text"
            name="firstName"
            onChange={onChange}
            placeholder="Company URL 2"
          />
        </div>
        <CustomButton
          type="button"
          className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
          onClick={onSubmit}
          label="Next"
        />
      </Container>
    </div>
  );
}
