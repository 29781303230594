import { async } from "../utility/config/api/index";

export function acceptPayment(data, callBack = () => {}) {
  async("frontend/buy/package", "POST", data).then((response) => {
    callBack(response);
  });
}
export function getPaytmentHistory(data, callBack = () => {}) {
  async("frontend/payments/getPaymentHistory", "GET", data).then((response) => {
    callBack(response);
  });
}
