import React from "react";
import { Row, Col, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function CandidateList({ candidate, markAsA }) {
  const history = useHistory();
  return candidate ? (
    <div className="contentBox b-1">
      <div className="personInfoHeader publicProfileHeaderDesktop">
        <div className="column1">
          <img
            className="userpic"
            src="/images/userpic2.png"
            alt="user"
            onClick={(e) => {
              e.preventDefault();
              history.push("/employer/PublicProfile");
            }}
          />
        </div>
        <div className="column2">
          <label className="row1 d-flex align-items-center">
            Mary Pittman
            <span className="status active">
              <img src="/images/rocket-active.png" alt="active" />
              Active
            </span>
          </label>
          <label className="row2">(542) 710-6018</label>
          <label className="row3">pittman@gmail.com</label>
        </div>
        <div className="column3">
          <div>
            <label className="row1 test-end">
              $120,000.00 / <span className="font-regular">year</span>
            </label>
            <label className="row2 text-end">Available in 1 month</label>
            <label className="row3 text-end">Part time employment</label>
          </div>
          <Dropdown className="kebabDropDown" align="end">
            <Dropdown.Toggle id="dropdown-basic">
              <img className="kebabIcon" src="/images/kebab.png" alt="menu" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {markAsA.map((opt, k) => {
                return <Dropdown.Item key={k}>Mark as a {opt}</Dropdown.Item>;
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="personInfoHeader publicProfileHeaderMobile">
        <div className="topRow">
          <div className="column1">
            <img className="userpic" src="/images/userpic2.png" alt="user" />
          </div>
          <div className="column2">
            <label className="row1 d-flex align-items-center">
              Mary P.
              <span className="status active">
                <img src="/images/rocket-active.png" alt="active" />
                Active
              </span>
            </label>
            <label className="row2">(542) 710-6018</label>
            <label className="row3">pittman@gmail.com</label>
          </div>
          <div className="column3">
            <Dropdown className="kebabDropDown" align="end">
              <Dropdown.Toggle id="dropdown-basic">
                <img className="kebabIcon" src="/images/kebab.png" alt="menu" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#">View profile as employer</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="bottomRow">
          <div className="column3">
            <div>
              <label className="row1">
                $120,000.00 / <span className="font-regular">year</span>
              </label>
              <label className="row2">Available in 1 month</label>
              <label className="row3">Part time employment</label>
            </div>
          </div>
        </div>
      </div>

      <div className="innerBlock">
        <h3>Personal information</h3>
        <Row>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/telephone.png" alt="telephone" />
              </div>
              <div className="data-row">(305) 778-3951</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/email2.png" alt="email" />
              </div>
              <div className="data-row">user@company.com</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/link.png" alt="link" />
              </div>
              <div className="data-row">
                Link Share
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      Copy!
                    </Tooltip>
                  )}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        process.env.REACT_APP_BASE_URL +
                          "/Employer/publicprofile/"
                      );
                    }}
                  >
                    <img src="/images/share.png" alt="share" />
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/degree.png" alt="telephone" />
              </div>
              <div className="data-row">Bachelor&apos;s Degree graduated</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/shift.png" alt="email" />
              </div>
              <div className="data-row">
                Morning, Noon, Evening, Night shifts
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/sponsor.png" alt="link" />
              </div>
              <div className="data-row ">Sponsorship Required</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/agent.png" alt="telephone" />
              </div>
              <div className="data-row">FBI Agent</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/desktop.png" alt="email" />
              </div>
              <div className="data-row">Remote work preference</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/place.png" alt="link" />
              </div>
              <div className="data-row">Willing to relocate</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/house.png" alt="telephone" />
              </div>
              <div className="data-row">Willing to travel</div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/prize.png" alt="email" />
              </div>
              <div className="data-row">
                Able to work in hazardous conditions
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="info-row-with-icon">
              <div className="icon-row">
                <img src="/images/leaderboard.png" alt="link" />
              </div>
              <div className="data-row">Able to work lifting heavy items</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <></>
  );
}
