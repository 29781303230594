import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import {
  getAuthentionInfo,
  IsAuthenticated,
} from "../../../utility/helper/authenticationHelper";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as commonActions from "./../../../actions/commonActions";
import { getdaysbetween2, IsEmpty } from "../../../utility/helper/helper";
import * as lndingActions from "../../landing/lndingActions";
import Swal from "sweetalert2";
import moment from "moment";
import logo from "../../../assets/images/header-footer-logo-New.svg";
import MobileLogo from "../../../assets/images/Mobileheaderlogo.svg"
import { AuthContext } from "context/authContext";

function Header(props) {
  let history = useHistory();
  const [user, setUser] = useState({});
  const login_time = localStorage.getItem(utilConstants.LOGIN_TIME);
  const token = localStorage.getItem(utilConstants.ACCESS_TOKEN);
  const now_time = moment().format();
  const getdaysbetween = getdaysbetween2(now_time, login_time);
  const path = window.location;
  const pathStrSplit = path.pathname.split("/")[1];
  const { setIsSearchTabClicked } = useContext(AuthContext);

  let abc = `/${pathStrSplit}`;

  useEffect(() => {
    if (IsEmpty(user) && IsAuthenticated()) {
      if (
        !props.state.commonReducer.isLoading &&
        IsEmpty(props.state.commonReducer.signedUserDetail)
      )
        props.commonActions.signinUserDetails();
    } else setUser(props.state.commonReducer.signedUserDetail);
  }, []);

  const fireAlert = () => {
    Swal.fire({
      title: "Session Expired!",
      text: "Do you want to stay logged in?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "##fe5f6b",
      cancelButtonColor: "#fe5f6b",
      reverseButtons: true,
      imageWidth: 450,
      imageHeight: 250,
      imageAlt: "Custom image",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem(utilConstants.LOGIN_TIME, moment().format());
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        props.commonActions.signOut();
        history.replace(`/signin`);
        lndingActions.logout({ id: user.id, token: token }, (response) => {
          console.log(response);
        });
      }
    });
  };

  useEffect(() => {
    if (getdaysbetween > 0) {
      fireAlert();
    }
  }, [getdaysbetween]);

  useEffect(() => {
    setUser(props.state.commonReducer.signedUserDetail);
  }, [props.state.commonReducer.signedUserDetail]);

  let employerHeader = () => {
    return (
      <>
        <Nav className="me-auto menuNav loggedMenu">
          {/* <Nav.Link
            as={Link}
            to="/employer/SearchResults"
            href="/SearchResults"
            key="/SearchResults"
          >
            Resume Database
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/employer/SavedCandidate"
            href="/employer/SavedCandidate"
            key="/employer/SavedCandidate"
          >
           Applicant Tracking
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/employer/contact-list"
            href="/employer/contact-list"
            key="/employer/contact-list"
          >
            Saved Profiles 
          </Nav.Link> */}
        </Nav>
        <Nav className="settingsMenuNav ctaNav">
          <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic">
              <img
                // src={
                //   user?.EmployerProfile?.profile_image && user?.sign_in_with_google === "0"
                //     ? process.env.REACT_APP_API_BASE_URL +
                //     "/user_profile/" +
                //     user?.EmployerProfile?.profile_image
                //     : user?.EmployerProfile?.profile_image && user?.sign_in_with_google === "1"
                //       ? user?.EmployerProfile?.profile_image
                //       : "/images/userpic.png"
                // }
                src={
                  user?.EmployerProfile?.profile_image ? user?.EmployerProfile?.profile_image_url : "/images/userpic.png"
                }
                alt="user"
                className="userPic"
              />
              <div className="userInfo">
                <label className="username">
                  {user.first_name + " " + user.last_name}
                </label>
                <label className="usermail">{user.email}</label>
              </div>
              <div className="dropIndicator">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.06222 7.24745L11.1587 5.31289C11.3502 5.13617 11.6454 5.13617 11.8369 5.31289L12.6018 6.01873C12.8163 6.21672 12.8163 6.55565 12.6018 6.75364L8.33908 10.6871C8.14757 10.8638 7.85244 10.8638 7.66092 10.6871L3.39822 6.75364C3.18365 6.55565 3.18365 6.21672 3.39822 6.01873L4.16313 5.31289C4.35465 5.13617 4.64978 5.13617 4.84129 5.31289L6.93778 7.24745L8 8.16711L9.06222 7.24745Z"
                    fill="#252938"
                  />
                </svg>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to="/employer/my-jobs"
                key="/employer/my-jobs"
              >
                My Jobs
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/SearchResults"
                href="/SearchResults"
                key="/SearchResults"
              >
                Resume Database
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/SavedCandidate"
                href="/employer/SavedCandidate"
                key="/employer/SavedCandidate"
              >
                Applicant Tracking
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/contact-list"
                href="/employer/contact-list"
                key="/employer/contact-list"
              >
                Saved Profiles
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/account-settings"
                key="/employer/account-settings"
              >
                Account Settings
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/account-settings/membership"
                key="/employer/account-settings/membership"
              >
                Membership Status
              </Dropdown.Item>

              {/* <Dropdown.Item
                as={Link}
                to="/employer/account-settings/billing"
                key="/employer/account-settings/billing"
              >
                Billing
              </Dropdown.Item> */}
              <Dropdown.Item
                as={Link}
                to="/employer/account-settings/company"
                key="/employer/account-settings/company"
              >
                Company Profile
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/employer/account-settings/support"
                key="/employer/account-settings/support"
              >
                Support
              </Dropdown.Item>

              {logOut()}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </>
    );
  };
  const logOut = () => {
    return (
      <Dropdown.Item
        href="#"
        className="shadowInset"
        onClick={(e) => {
          e.preventDefault();
          props.commonActions.signOut();
          history.replace("/");
          lndingActions.logout({ id: user.id, token: token }, (response) => {
            console.log(response);
          });
        }}
      >
        Log Out
      </Dropdown.Item>
    );
  };

  const candidateHeader = () => {
    return (
      <>
        <Nav className="me-auto menuNav loggedMenu myturn-header">
          {/* <Nav.Link as={Link} to="/Candidate/account-settings/profile" href="/" key="/">
            Profile
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/Candidate/SearchJob"
            key="/Candidate/SearchJob"
            href="/Candidate/SearchJob"
          >
            Job Search
          </Nav.Link> */}
        </Nav>
        <Nav className="settingsMenuNav ctaNav">
          <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic">
              <img
                // src={
                //   user.sign_in_with_google === "0"
                //     ? user?.CandidateProfile?.profile_image
                //       ? process.env.REACT_APP_API_BASE_URL +
                //       "/user_profile/" +
                //       user?.CandidateProfile?.profile_image
                //       : "/images/userpic.png"
                //     : user?.CandidateProfile?.profile_image && user.sign_in_with_google === "1"
                //       ? user?.CandidateProfile?.profile_image
                //       : "/images/userpic.png"
                // }
                src={user?.CandidateProfile?.profile_image ? user?.CandidateProfile?.profile_image_url : "/images/userpic.png"}
                alt="user"
                className="userPic"
              />
              <div className="userInfo">
                <label className="username">
                  {(user?.first_name) && user?.first_name + " " + user?.last_name.charAt(0) + "."}
                </label>
                {/* <label className="usermail"> {user.email}</label> */}
              </div>
              <div className="dropIndicator">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.06222 7.24745L11.1587 5.31289C11.3502 5.13617 11.6454 5.13617 11.8369 5.31289L12.6018 6.01873C12.8163 6.21672 12.8163 6.55565 12.6018 6.75364L8.33908 10.6871C8.14757 10.8638 7.85244 10.8638 7.66092 10.6871L3.39822 6.75364C3.18365 6.55565 3.18365 6.21672 3.39822 6.01873L4.16313 5.31289C4.35465 5.13617 4.64978 5.13617 4.84129 5.31289L6.93778 7.24745L8 8.16711L9.06222 7.24745Z"
                    fill="#252938"
                  />
                </svg>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to="/"
                key="/"
                onClick={() => setIsSearchTabClicked(true)}
              >
                Search Jobs
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings/resume"
                key="/Candidate/account-settings/resume"
              >
                My Resume
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings/profile"
                key="/Candidate/account-settings/profile"
              >
                My Profile
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings"
                key="/Candidate/account-settings"
              >
                Account Settings
              </Dropdown.Item>
              {/* <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings/billing"
                key="/Candidate/account-settings/billing"
              >
                Billing
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings/membership"
                key="/Candidate/account-settings/membership"
              >
                Subscription
              </Dropdown.Item> */}

              <Dropdown.Item
                as={Link}
                to="/Candidate/account-settings/support"
                key="/Candidate/account-settings/support"
              >
                Support
              </Dropdown.Item>


              {logOut()}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </>
    );
  };
  const getHeader = () => {
    switch (getAuthentionInfo().type) {
      case utilConstants.USER_EMPLOYER:
        return user && employerHeader();
      case utilConstants.USER_CANDIDATE:
        return user && candidateHeader();
      default:
        return (
          <Nav className="ms-auto menuNav">
            <Nav.Link
              as={Link}
              href="#"
              to="/post-a-job"
              className="bg-white emp_orange"
            >
              Post Job
            </Nav.Link>
          </Nav>
        );
    }
  };
  return (
    <header className={IsAuthenticated() ? "staticHeader" : ""}>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="headerNavbar myturn-header"
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to={getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? "/employer" : "/"}
            href={"/"}
            key={"/"}
            className="brandNav"
          >
            <img
              src={logo}
              width={135}
              height={49}
              className="d-inline-block align-top logo"
              alt="MyTurn logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <DesktopMenu getHeader={getHeader} history={history} />
            <MobileMenu
              getHeader={getHeader}
              history={history}
              props={props}
              user={user}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

const MobileMenu = ({ props, history, user }) => {
  return (
    <>
      {!IsAuthenticated() ? (
        <Navbar.Offcanvas
          id="responsive-navbar-nav"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="sideMenuVertical"
        >
          <Offcanvas.Header closeButton>
            <div className="mobile_title">
              <img className="mobile_logo" src={MobileLogo} />
              <h5 className="green_mv">My<span className="orange">Turn</span></h5>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? (
              <Nav className="flex-column nav">
                <Nav.Link
                  eventKey="link-7"
                  href="#"
                  className="shadowInset"
                  onClick={(e) => {
                    e.preventDefault();

                    history.push("/signin");
                  }}
                >
                  Sign In
                </Nav.Link>
                <Nav.Link as={Link} href="#" to="/Employer">
                  Employer
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="flex-column nav">
                <Nav.Link
                  eventKey="link-7"
                  href="#"
                  className="shadowInset"
                  onClick={(e) => {
                    e.preventDefault();

                    history.push("/signin");
                  }}
                >
                  Sign In
                </Nav.Link>
                <Nav.Link as={Link} href="#" to="/post-a-job">
                  Post Job
                </Nav.Link>
              </Nav>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      ) : (
        <Navbar.Offcanvas
          id="responsive-navbar-nav"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="sideMenuVertical"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className="userInfoMiniBlock">
                <img
                  src={
                    getAuthentionInfo().type === utilConstants.USER_EMPLOYER
                      ? user?.EmployerProfile?.profile_image
                        ? process.env.REACT_APP_API_BASE_URL +
                        "/user_profile/" +
                        user?.EmployerProfile?.profile_image
                        : "/images/userpic.png"
                      : user?.CandidateProfile?.profile_image
                        ? process.env.REACT_APP_API_BASE_URL +
                        "/user_profile/" +
                        user?.CandidateProfile?.profile_image
                        : "/images/userpic.png"
                  }
                  alt="user"
                  className="userPic"
                />
                <div className="userInfo">
                  <label className="username">{`${user?.first_name} ${user?.last_name}`}</label>
                  {/* <label className="usermail">{user?.email}</label> */}
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {getAuthentionInfo().type === utilConstants.USER_EMPLOYER ? (
              <Nav className="flex-column nav">
                {/* <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/Dashboard"
                  href="/employer/Dashboard"
                  key="/employer/Dashboard"
                >
                  Dashboard
                </Nav.Link> */}
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/my-jobs"
                  href="/employer/my-jobs"
                  key="/employer/my-jobs"
                >
                  My Jobs
                </Nav.Link>
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/SearchResults"
                  href="/SearchResults"
                  key="/SearchResults"
                >
                  Resume Database
                </Nav.Link>
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/SavedCandidate"
                  href="/employer/SavedCandidate"
                  key="/employer/SavedCandidate"
                >
                  Applicant Tracking
                </Nav.Link>
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/contact-list"
                  href="/employer/contact-list"
                  key="/employer/contact-list"
                >
                  Saved Profiles
                </Nav.Link>
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/employer/account-settings"
                  key="/employer/account-settings"
                  href="/employer/account-settings"
                >
                  Account Settings
                </Nav.Link>

                <Nav.Link
                  eventKey="link-2"
                  as={Link}
                  to="/employer/account-settings/membership"
                  key="/employer/account-settings/membership"
                  href="/employer/account-settings/membership"
                >
                  Membership Status
                </Nav.Link>

                {/* <Nav.Link
                  eventKey="link-3"
                  as={Link}
                  to="/employer/account-settings/billing"
                  key="/employer/account-settings/billing"
                  href="/employer/account-settings/billing"
                >
                  Billing
                </Nav.Link> */}

                <Nav.Link
                  eventKey="link-5"
                  as={Link}
                  to="/employer/account-settings/company"
                  key="/employer/account-settings/company"
                  href="/employer/account-settings/company"
                >
                  Company Profile
                </Nav.Link>

                <Nav.Link
                  eventKey="link-4"
                  as={Link}
                  to="/employer/account-settings/support"
                  key="/employer/account-settings/support"
                  href="/employer/account-settings/support"
                >
                  Support
                </Nav.Link>

                <Nav.Link
                  eventKey="link-7"
                  href="/"
                  className="shadowInset"
                  onClick={(e) => {
                    e.preventDefault();
                    props.commonActions.signOut();
                    history.replace('/');
                  }}
                >
                  Log Out
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="flex-column nav">
                {/* <Nav.Link
                  eventKey="link-0"
                  as={Link}
                  to="/Candidate/Dashboard"
                  key="/Candidate/Dashboard"
                  href="/Candidate/Dashboard"
                >
                  Dashboard
                </Nav.Link> */}
                <Nav.Link
                  // eventKey="link-4"
                  as={Link}
                  to="/"
                  key="/"
                  href="/"
                >
                  Search Jobs
                </Nav.Link>
                <Nav.Link
                  // eventKey="link-4"
                  as={Link}
                  to="/Candidate/account-settings/resume"
                  key="/Candidate/account-settings/resume"
                  href="/Candidate/account-settings/resume"
                >
                  My Resume
                </Nav.Link>
                <Nav.Link
                  // eventKey="link-4"
                  as={Link}
                  to="/Candidate/account-settings/profile"
                  key="/Candidate/account-settings/profile"
                  href="/Candidate/account-settings/profile"
                >
                  My Profile
                </Nav.Link>
                <Nav.Link
                  eventKey="link-1"
                  as={Link}
                  to="/Candidate/account-settings"
                  key="/Candidate/account-settings"
                  href="/Candidate/account-settings"
                >
                  Account Settings
                </Nav.Link>

                {/* <Nav.Link
                  eventKey="link-2"
                  as={Link}
                  to="/Candidate/account-settings/membership"
                  key="/Candidate/account-settings/membership"
                  href="/Candidate/account-settings/membership"
                >
                  Subscription
                </Nav.Link>

                <Nav.Link
                  eventKey="link-3"
                  as={Link}
                  to="/Candidate/account-settings/billing"
                  key="/Candidate/account-settings/billing"
                  href="/Candidate/account-settings/billing"
                >
                  Billing
                </Nav.Link> */}

                <Nav.Link
                  eventKey="link-4"
                  as={Link}
                  to="/Candidate/account-settings/support"
                  key="/Candidate/account-settings/support"
                  href="/Candidate/account-settings/support"
                >
                  Support
                </Nav.Link>
                <Nav.Link
                  eventKey="link-7"
                  href="/"
                  className="shadowInset"
                  onClick={(e) => {
                    e.preventDefault();
                    props.commonActions.signOut();
                    history.replace('/');
                  }}
                >
                  Log Out
                </Nav.Link>
              </Nav>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      )}
    </>
  );
};

const DesktopMenu = ({ getHeader }) => {
  return (
    <>
      {getHeader()}
      {!IsAuthenticated() && (
        <Nav className="ml-35">
          <Nav.Link
            as={Link}
            to="/signin"
            href="/"
            key="/signin"
            className="primary-filed-orange"
          >
            Sign In

          </Nav.Link>
        </Nav>
      )}
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
