import * as React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../button/CustomButton";
export default function CustomeModel({
  modalBody,
  modalType,
  show,
  className,
  headerImage,
  headerImageAlt,
  title,
  noImage,
  positiveButtonType = "button",
  positiveButtonClassName = "cta-type-3 w-168 h-40 br-16 mx-auto",
  positiveButtonLable = "",
  negativeButtonType = "button",
  negativeButtonClassName = "cta-type-2 w-168 h-40 br-16 mx-auto",
  negativeButtonLable = "",
  handleClose = () => { },
  onPositive = () => { },
  onNegative = () => { },
  customeButton,
  type,
}) {
  const getAction = () => {
    switch (modalType) {
      case "Positive/Negative":
        return (
          <>
            {negativeButtonLable && (
              <CustomButton
                type={negativeButtonType}
                className={negativeButtonClassName}
                label={negativeButtonLable}
                onClick={onNegative}
              />
            )}
            <CustomButton
              type={positiveButtonType}
              className={positiveButtonClassName}
              label={positiveButtonLable}
              onClick={onPositive}
            />
          </>
        );
      case "Custome":
        return customeButton;
      default:
        return null;
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`${className || ""} successModal ${type === "subscription" ? "package-modal" : ""
        } custom-modal`}
      centered
    >
      <Modal.Header closeButton>
        {title && <h2 className="top">{title}</h2>}
      </Modal.Header>
      {noImage && <hr></hr>}
      <Modal.Body>
        {modalBody}
        {noImage && <hr className="mt-5"></hr>}
        <div
          className={`cta-panel d-flex justify-content-between   ${noImage ? "mt-4" : ""
            }`}
        ></div>
      </Modal.Body>
      <Modal.Footer className="border-none">{getAction()}</Modal.Footer>
    </Modal>
  );
}
