import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import moment from "moment";
import CustomDateFormat from "utility/CustomDateFormat";

export default function ReviewJob({
  handleReviewClose,
  showReview,
  backtoForm,
  submitForm,
  formdata,
}) {
  return (
    <>
      <Modal size="lg" show={showReview}>
        <Modal.Body className="modal_body_wrrpr">
          <div className="post_job_wrrpr">
            <div className="review_job_body">
              <div className="about_job">
                <p>
                  Job Title: <span>{formdata.job_title} </span>
                </p>
                <p>
                  Pay Range:
                  <span>
                    {" "}
                    ${formdata.minimum_pay} - ${formdata.maximum_pay}{" "}
                  </span>
                </p>
                <p>
                  Location: <span>{formdata.job_location} </span>
                </p>
                <p>
                  Hiring Timeframe:{" "}
                  <span>{CustomDateFormat(formdata.hiring_timeframe)} </span>
                </p>
                <p>
                  Job Shift: <span>{formdata.job_shift} </span>
                </p>
                <p>
                  Job Type: <span>{formdata.job_type} </span>
                </p>
                <p>
                  Required Onsite: <span>{formdata.required_onsite} </span>
                </p>
                <p>
                  Required Travel: <span>{formdata.required_travel}</span>
                </p>
                <p>
                  Government Clearance:{" "}
                  <span>{formdata.required_clearance} </span>
                </p>
                <p>
                  Sponsorship: <span>{formdata.sponsorship} </span>
                </p>
              </div>
              <div className="job_description">
                <p>
                  Job Description:
                  <div dangerouslySetInnerHTML={{ __html: formdata.job_description }} />
                </p>
              </div>
            </div>
            <div className="post_job_btns_chckbox mt-3">
              <Button
                className="cta-type-2 h-40 btn btn-primaryfalse post_job"
                onClick={backtoForm}
              >
                Back
              </Button>
              <Button
                className="cta-type-2 h-40 btn btn-primaryfalse post_job"
                onClick={submitForm}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
