import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as helper from "../../../utility/helper/helper";
import { useHistory } from "react-router-dom";
import InputRadio from "./common/InputRadio";
import CustomButton from "../../common/button/CustomButton";
import CustomeModel from "../../common/model/CustomeModel";
import * as commonActions from "./../../../actions/commonActions";
import OnBoarding from "./OnBoarding";
function WorkPermit() {
  let history = useHistory();
  const [noWorkPermit, setNoWorkPermit] = useState();
  const [haveWorkPermit, setHaveWorkPermit] = useState();
  const onSubmit = () => {
    if (haveWorkPermit === "Yes")
      history.push({
        pathname: "/Candidate/Onboarding/VisaSponsorship",
        state: { is_us_authorized_emp: 1 },
      });
    else setNoWorkPermit(true);
  };
  return (
    <OnBoarding cureentStep={1}>
      <h2 className="section-heading-2 text-center mx-auto mw-552">
        Are you authorized to work lawfully in the United States for the hiring
        company?
      </h2>
      <div className="text-center">
        <InputRadio
          name="workPermit"
          listOfOption={[
            {
              id: "workPermitYes",
              value: "Yes",
              label: "Yes",
            },
            {
              id: "workPermitNo",
              value: "No",
              label: "No",
            },
          ]}
          onChange={(e) => {
            setHaveWorkPermit(e.target.value);
          }}
          selectedValue={haveWorkPermit}
        />
      </div>
      <CustomButton
        type="button"
        className="cta-type-2 w-360 h-40 br-16 mx-auto mt-64"
        isDisabled={helper.IsEmpty(haveWorkPermit)}
        onClick={onSubmit}
        label="Next"
      />
      <CustomeModel
        modalBody={
          <>
            <h2>We&apos;re Sorry!</h2>
            <p>
              Because you are not authorized to work in the USA, your resume
              will not be displayed to employers.
            </p>
          </>
        }
        headerImage="/images/doc.png"
        headerImageAlt="doc"
        show={noWorkPermit}
        handleClose={() => {
          setNoWorkPermit(false);
        }}
        positiveButtonLable="Got it"
        modalType="Positive/Negative"
        onPositive={() => {
          history.push({
            pathname: "/Candidate/Onboarding/VisaSponsorship",
            state: { is_us_authorized_emp: 0 },
          });
        }}
      />
    </OnBoarding>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkPermit);
