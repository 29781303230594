import React, { useContext, useEffect,} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import InputText from "../../common/inputText/InputText";
import InputPassword from "../../common/inputPassword/InputPassword";
import CustomButton from "../../common/button/CustomButton";
import IsValidSignUpData from "./validation";
import * as helper from "../../../utility/helper/helper";
import { Link } from "react-router-dom";
import * as lndingActions from "./../lndingActions";
import * as commonActions from "./../../../actions/commonActions";
import VerifyOtp from "./../VerifyOtp";
import ReCAPTCHA from "react-google-recaptcha";
import VerifyUser from "../VerifyUser";
import verifyemail from "../../../assets/images/verifyemail.png";
import UploadResume from "components/candidate/onboarding/UploadResume";
import { AuthContext } from "context/authContext";

function CandidateSignUP(props) {
  const { userInfo, setUserInfo,
    setVerifyUserPopUp,
    setSignupType, otpPopUp, setOtpPopUp, setVerifyResumePopUp,
    candidate, setCandidate, otpResponseMessage, setOtpResponseMessage, candidateErrors, setCandidateErrors,
    isLoading, isInitialButtonLoading, setIsInitialButtonLoading,
    setIsRecaptcha, onOtpConfirm, onChange2, isSubscription, isRecaptcha } = useContext(AuthContext);

  let { isValid, errors } = IsValidSignUpData(candidate);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("here in handleSubmit");
    setSignupType("");
    let { isValid, errors } = IsValidSignUpData(candidate);
    if (isValid && isRecaptcha) {
      setCandidateErrors({})
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        sign_in_with_google: false,
      }));
      setIsInitialButtonLoading(true);
      if(isSubscription === true) {
        setSignupType("Employer");
      } else {
        setVerifyUserPopUp(true);
      }
    } else {
      console.log("isRecaptcha >>", isRecaptcha);
      if(!isRecaptcha) {
        console.log("Captcha error")
        errors.captcha = "Captcha is required"
      }
      setCandidateErrors(errors);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    let tempCandidate = candidate;
    console.log(e);
    Object.assign(tempCandidate, helper.setDataAccordingInput(e, true));
    let errors = { ...candidateErrors };
    delete errors[e.target.name];
    setCandidate(tempCandidate);
    setCandidateErrors(errors);
  };

  const handleChange = () => {
    setCandidate({
      ...candidate,
      candidateAgree: !candidate.candidateAgree,
    });
    setCandidateErrors({});
  };
  function onRecaptchaChange(value) {
    let { captcha, ...newError } = candidateErrors;
    setCandidateErrors(newError);
    setIsRecaptcha(value);
  }

  React.useEffect(() => {
    setOtpResponseMessage("");
  }, [otpPopUp]);

  useEffect(() => {
    setVerifyUserPopUp(false);
    setOtpPopUp(false);
    setOtpResponseMessage();
    setVerifyResumePopUp(false);
    setIsInitialButtonLoading(false);
    setCandidate({candidateAgree: false});
  }, [])

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col
            xxl={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            sm={12}
            xs={12}
          >
            <InputText
              controlId="formBasicfirst_name"
              label="First name"
              type="text"
              name="first_name"
              onChange={onChange}
              placeholder="Jane"
              value={candidate.first_name}
              error={candidateErrors.first_name}
              maxLength="255"
            />
          </Col>
          <Col
            xxl={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            sm={12}
            xs={12}
          >
            <InputText
              controlId="formBasiclast_name"
              label="Last name"
              type="text"
              name="last_name"
              onChange={onChange}
              placeholder="Smith"
              value={candidate.last_name}
              error={candidateErrors.last_name}
              maxLength="255"
            />
          </Col>
        </Row>
        <InputText
          controlId="formBasicEmail"
          label="Email Address"
          type="email"
          placeholder="Name@example.com"
          name="email"
          value={candidate.email}
          error={candidateErrors.email}
          onChange={onChange}
          maxLength="255"
        />
        <InputPassword
          isVisible={false}
          label="Password"
          type="password"
          placeholder="At least 8 characters"
          controlId="Password"
          name="password"
          value={candidate.password}
          error={candidateErrors.password}
          onChange={onChange}
          strenghtCheck={true}
        />
        <div className="password-strenght" />

        <span className="custom-checkbox-signup d-flex">
          <input
            type="checkbox"
            checked={candidate?.candidateAgree}
            onChange={handleChange}
          />
          <span>
            <label className="l_sinup">
              {" "}
              By proceeding, you agree to MyTurn's &nbsp;
              <a href="/terms-and-conditions" target="_blank">
                Terms and Condition
              </a>{" "}
              and&nbsp;
              <a href="/privacy-policy" target="_blank">
                {" "}
                Privacy Policy
              </a>
              .
            </label>
          </span>
        </span>
        {candidateErrors.candidateAgree !== "" && (
          <div className="invalid-error">{candidateErrors.candidateAgree}</div>
        )}
        {/* {isValid && (
          <ReCAPTCHA
            sitekey="6Leq6JYmAAAAAMf3O5LOFe4PDO_TfRwuTxEBbjAv"
            onChange={onRecaptchaChange}
            className="recaptcha-custom-design"
          />
        )} */}
        <ReCAPTCHA
          sitekey="6Leq6JYmAAAAAMf3O5LOFe4PDO_TfRwuTxEBbjAv"
          onChange={onRecaptchaChange}
          className="recaptcha-custom-design"
        />
        {candidateErrors.captcha !== "" && (
          <div className="invalid-error">{candidateErrors.captcha}</div>
        )}

        <CustomButton
          variant="primary"
          type="submit"
          className="w-100 primary-filed-orange"
          label="Sign Up"
          isLoading={isInitialButtonLoading}
        />
        <div className="other-link">
          Already have an account? <Link to={"/signin"}>Sign In</Link>
        </div>
      </Form>
      <VerifyOtp
        show={otpPopUp}
        handleClose={() => {
          setOtpPopUp(false);
          setCandidate((candidate) => {
            return {
              ...candidate,
              verify_code: "",
            };
          });
        }}
        modalBody={
          <>
            <img src={verifyemail} alt="Site logo" className="verify_email" />
            <p>{`An email verification code has been sent to ${candidate?.email || userInfo?.email}`}</p>
            {otpResponseMessage && (
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {otpResponseMessage}
              </p>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onOtpConfirm();
              }}
              className="signup-form"
            >
              <InputText
                controlId=""
                name="verify_code"
                onChange={(e) => {
                  if (e.target.value.length > 4) return false;
                  onChange2(e);
                }}
                placeholder="X-X-X-X"
                value={candidate.verify_code}
              />
              <span
                className="b-link resend_email cursor-pointer"
                onClick={() => {
                  const requestData = userInfo.sign_in_with_google ? { ...userInfo, type: "signup" } : { ...candidate, type: "signup" };
                  lndingActions.reSendOTP(
                    requestData,
                    (response) => {
                      setOtpResponseMessage(response.message);
                    }
                  );
                }}
              >
                Did not get an email? Resend
              </span>
              <CustomButton
                label="Verify"
                type="button"
                className="w-48 cta-type-2 h-40 btn btn-primary mx-auto"
                isDisabled={helper.IsEmpty(candidate.verify_code)}
                onClick={(e) => {
                  e.preventDefault();
                  onOtpConfirm();
                }}
                isLoading={isLoading}
              />
            </form>
          </>
        }
      />
      <VerifyUser />
      <UploadResume />
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    lndingActions: bindActionCreators(lndingActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateSignUP);
