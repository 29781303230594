import * as typesConstants from "../utility/constants/typesConstants";

let initialState = {};

export default function cmsReducer(state = initialState, action) {
  const content = {};
  switch (action.type) {
    case typesConstants.GET_PAGE_CONTENT:
      action.response.content.map((c) => {
        content[c.key] = c.content;
        return c;
      });
      return { ...state, [action.response.key]: content };
    default:
      return state;
  }
}
