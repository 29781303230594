import { components } from "react-select";
import React from "react";
import OptionsOutsideSelect from "./OptionsOutsideSelect";
import { Form } from "react-bootstrap";
import { async } from "../../../utility/config/api/index";
export default function MultiInputSelect({
  placeholder = "Select",
  value,
  className,
  controlId,
  onSelect = () => {},
  label,
  error,
  defaultInputValue,
  optionLabel = "label",
  optionValue = "value",
  options,
  type,
  isMulti = false,
  customCreate = false,
  onClear,
  showClearFilter,
}) {
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Search {label}</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChange = async (value) => {
    if (value.length > 255) return;
    let response = "";
    if (type === "position") {
      response = await async("get-jobprofiles", "GET", { st: value });
    }
    if (type === "skills") {
      response = await async("get-skills", "GET", { st: value });
    }

    if (type === "city") {
      response = await async("city-list", "GET", { st: value });
    }

    if (type === "state") {
      response = await async("state-list", "GET", { st: value });
    }
    if (type === "dropdownlist") {
      response = await async("job/dropDownListJobPost", "GET", { st: value });
    }

    if (type === "jobTitle") {
      response = await async("get-jobprofiles", "GET", { st: value });
    }

    const data = await response.data;
    const options = [];
    if (type === "city") {
      data?.rows?.map((v) => {
        options.push({ label: v.city, value: v.city });
      });
    } else if (type === "state") {
      data?.rows?.map((v) => {
        options.push({ label: v.state, value: v.state });
      });
    } else {
      data.map((v) => {
        options.push({
          label: v.job_title ? v.job_title + "-" + v.id : v,
          value: v.job_title ? v.job_title : v,
        });
      });
    }
    return options;
  };
  return (
    <div className={(className || "") + " adress-autocomplete multipal-select"}>
      <Form.Group className="form-group" controlId={controlId}>
        <Form.Label>
          {typeof label === "string" ? label.toUpperCase() : label}
        </Form.Label>
        <OptionsOutsideSelect
          customCreate={customCreate}
          type={type}
          onChange={onSelect}
          isMulti={isMulti}
          options={options}
          value={value || []}
          defaultInputValue={defaultInputValue}
          components={{ NoOptionsMessage }}
          onInputChange={handleChange}
          isSearchable
          placeholder={placeholder}
          getOptionLabel={(option) => option[optionLabel]}
          getOptionValue={(option) => option[optionValue]}
          className={`form-control ` + (error && ` form-control is-invalid`)}
        />
        {showClearFilter && defaultInputValue && (
          <span className="clear-filter" onClick={() => onClear?.()}>
            clear selection
          </span>
        )}
        <span style={{ color: "#dc3545", fontSize: ".875em" }}>{error}</span>
      </Form.Group>
    </div>
  );
}
