import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import jobtype from '../../../assets/images/fulltime.svg';
import location from '../../../assets/images/locatuion.svg';
import payrange from '../../../assets/images/payrange.svg';
import TimeAgo from "components/common/postedDateFunctionality/TimeAgo";
import Pagination from 'react-bootstrap/Pagination';
import PopularSearch from "../PopolarSearch/PopularSearch";

export default function SearchJobListing(props) {
  const itemsPerPage = 15;
  const totalPages = Math.ceil(props.count / itemsPerPage);

  const handlePageClick = (pageNumber, event) => {
    event.preventDefault();
    props.setActive(pageNumber);
    window.scrollTo({ top: 100, behavior: 'smooth' });
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.setActive(prevActive => Math.max(prevActive - 1, 1));
    window.scrollTo({ top: 100, behavior: 'smooth' });
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.setActive(prevActive => Math.min(prevActive + 1, totalPages));
    window.scrollTo({ top: 100, behavior: 'smooth' });
  };

  const renderPaginationItems = () => {
    const items = [];
    const ellipsis = <Pagination.Ellipsis key="ellipsis" disabled />;

    if (totalPages <= 3) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === props.active}
            onClick={(e) => handlePageClick(number, e)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      if (props.active <= 2) {
        for (let number = 1; number <= 3; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === props.active}
              onClick={(e) => handlePageClick(number, e)}
            >
              {number}
            </Pagination.Item>
          );
        }
        items.push(ellipsis);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === props.active}
            onClick={(e) => handlePageClick(totalPages, e)}
          >
            {totalPages}
          </Pagination.Item>
        );
      } else if (props.active > 2 && props.active < totalPages - 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={1 === props.active}
            onClick={(e) => handlePageClick(1, e)}
          >
            {1}
          </Pagination.Item>
        );
        items.push(ellipsis);
        for (let number = props.active - 1; number <= props.active + 1; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === props.active}
              onClick={(e) => handlePageClick(number, e)}
            >
              {number}
            </Pagination.Item>
          );
        }
        items.push(ellipsis);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === props.active}
            onClick={(e) => handlePageClick(totalPages, e)}
          >
            {totalPages}
          </Pagination.Item>
        );
      } else {
        items.push(
          <Pagination.Item
            key={1}
            active={1 === props.active}
            onClick={(e) => handlePageClick(1, e)}
          >
            {1}
          </Pagination.Item>
        );
        items.push(ellipsis);
        for (let number = totalPages - 2; number <= totalPages; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === props.active}
              onClick={(e) => handlePageClick(number, e)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
    }

    return items;
  };





  return (
    <>
      {props?.jobs?.length > 0 ? (
        <Col
          xxl={{ span: 4 }}
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          md={5}
          sm={12}
          xs={12}
        >
          <div className="left_right_dv_wrrpr">
            <div className="sorted_dv">
              <p>Found {props?.count} Jobs</p>
            </div>
            <div>
              <div className="left_right_inner_wrrpr">
                {props?.jobs?.map((j, index) => (
                  <div
                    key={index}
                    className={`left-job-dv ${props.activeIndex === index && 'active'}`}
                    onClick={() => {
                      props.selectThisJob(index, j.id)
                      props.adjustDetailsForMobileView()
                    }}
                  >
                    <div className="right_dv_data_wrrpr">
                      <div className="right_dv_body">
                        <div className="right_dv_title">
                          <h3>{j.job_title}</h3>
                          {j.company && (
                            <div className="left_dv_ineer_data">
                              {j?.company_logo_url ? <img src={j?.company_logo_url} className="company-avatar-listing" />
                                : <div className="company-avatar-listing">{j?.company.charAt(0)}</div>}
                              <p>{j.company}</p>
                            </div>
                          )}
                          {j.job_location && (
                            <div className="left_dv_ineer_data">
                              <img src={location} />
                              <p>{j.job_location}</p>
                            </div>
                          )}
                        </div>
                        {j.job_type && (
                          <div className="left_dv_ineer_data">
                            <img src={jobtype} />
                            <p style={{ textAlign: "end" }}>{j.job_type}</p>
                          </div>
                        )}
                        {(j?.minimum_pay || j?.maximum_pay) !== undefined && (
                          <div className="left_dv_ineer_data">
                            {j?.minimum_pay && j?.maximum_pay && <img src={payrange} />}
                            {j?.minimum_pay && j?.maximum_pay && (
                              <p className="pay_range">
                                ${j.minimum_pay.toLocaleString()} - ${j.maximum_pay.toLocaleString()}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="posted">
                          <TimeAgo createdAt={j.created_at} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination size="sm">
                <Pagination.Prev onClick={handlePrevClick} disabled={props.active === 1} />
                {/* {Array.from({ length: totalPages }, (_, i) => (
                  <Pagination.Item
                    key={i + 1}
                    active={i + 1 === props.active}
                    onClick={(e) => handlePageClick(i + 1, e)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))} */}
                {renderPaginationItems()}
                <Pagination.Next onClick={handleNextClick} disabled={props.active === totalPages} />
              </Pagination>
            </div>
          </div>
          <PopularSearch handlePopularSearch={props.handlePopularSearch} />
        </Col>
      ) : (
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={12}
          sm={12}
          xs={12}
        >
          {/* <div className="left_right_dv_wrrpr">
            <h1>No Jobs Posted Here</h1>
          </div> */}
          <PopularSearch handlePopularSearch={props.handlePopularSearch} />
        </Col>
      )}
    </>
  );
}
