import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import InputText from "../common/inputText/InputText";
import InputPassword from "../common/inputPassword/InputPassword";
import {
  changeAccountEmail,
  updateDetails,
  verifyOTP,
  deleteAccount,
  pauseAccount,
  resumeAccount,
} from "../../actions/account";
import { IsValidEmail, isValidPhoneNumber } from "../../utility/helper/helper";
import { getAuthentionInfo } from "../../utility/helper/authenticationHelper";
import ReactNotifications from "../common/reactNotifications/ReactNotifications";
import * as commonActions from "actions/commonActions";
import {
  getErrorStatus,
  getSuccessStatus,
} from "../../utility/constants/utilConstants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomeModel from "components/common/model/CustomeModel";

export default function AccountSettings({ user, reloadProfile }) {
  const [otpPopup, setOtpPopup] = useState(false);
  const [checkInboxPopup, setInboxPopup] = useState(false);
  const [changeNumberPopup, setNumberPopup] = useState(false);
  const [isNumberChanged, setNumberChanged] = useState(false);
  const [deactivePopup, setDeactivePopup] = useState(false);
  const [emailOtpPopup, setEmailOtpPopup] = useState(false);
  const [changeEmailPopup, setEmailPopup] = useState(false);
  const [isEmailChanged, setEmailChanged] = useState(false);
  const [verifyPhoneNumberPopup, setVerifyPhoneNumberPopup] = useState(false);
  const [isNumberVerified, setIsNumberVerified] = useState(false);
  const [pauseAccountPopup, setPauseAccountPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [OTP, setOTP] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isVerifyingNewEmail, setIsVerifyingNewEmail] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isPausingAccount, setIsPausingAccount] = useState(false);
  const [profile, setProfile] = useState({});
  const [removeImagePopup, setRemoveImagePopup] = useState(false);
  const [isUpdateImagePopup, setIsUpdateImagePopup] = useState(false);
  const [isRemoveProfileImg, setIsRemoveProfileImg] = useState(false);
  const employer_user = localStorage.getItem("employer_id");

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    reloadProfile();
  }, []);
  const handleClose = () => {
    setOtpPopup(false);
    setEmailOtpPopup(false);
    setInboxPopup(false);
    setNumberPopup(false);
    setEmailPopup(false);
    setNumberChanged(false);
    setDeactivePopup(false);
    setEmailChanged(false);
    setIsNumberVerified(false);
    setVerifyPhoneNumberPopup(false);
    setPauseAccountPopup(false);
    setEmail("");
    setOTP("");
  };


  useEffect(() => {
    setPhoneNumber(user.phone);
  }, [user.phone]);
  useEffect(() => {
    setProfile(user);
  }, [user?.EmployerProfile?.profile_image]);
  const handlePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };
  const changeEmailAddress = () => {
    if (IsValidEmail(email)) {
      setIsVerifyingNewEmail(true);
      changeAccountEmail({ new_email: email, type: 0 }, (res) => {
        setIsVerifyingNewEmail(false);
        if (res.status === 200) {
          setEmailPopup(false);
          setEmailOtpPopup(true);
          setErrors({ ...errors, email: "" });
          reloadProfile();
        } else {
          ReactNotifications(res.message, getErrorStatus);
        }
      });
    } else {
      setErrors({ ...errors, email: "Please enter valid email" });
    }
  };
  const verifyUserOTP = () => {
    if (OTP.length === 4) {
      setIsVerifyingNewEmail(true);
      verifyOTP({ code: OTP, type: 0, new_email: email }, (res) => {
        setIsVerifyingNewEmail(false);
        if (res.status === 200) {
          setEmailPopup(false);
          setEmailOtpPopup(false);
          setErrors({ ...errors, OTP: "" });
          reloadProfile();
          ReactNotifications("Email updated successfully", getSuccessStatus);
        } else {
          ReactNotifications(res.message, getErrorStatus);
        }
      });
    } else {
      setErrors({ ...errors, OTP: "Please enter 4 digit otp" });
    }
  };

  const handleDeleteAccount = () => {
    setIsDeletingAccount(true);
    deleteAccount({}, (res) => {
      setIsDeletingAccount(false);
      if (res.status === 200) {
        setDeactivePopup(false);
        ReactNotifications("Account Deleted Successfully", getSuccessStatus);
        console.log(getSuccessStatus, "status")

        dispatch({ type: "SIGN_OUT", response: null });
        history.push(`/`)
      } else {
        ReactNotifications(res.message, getErrorStatus);
      }
    });
  };

  const handlePauseAccount = () => {
    setIsPausingAccount(true);
    pauseAccount({}, (res) => {
      setIsPausingAccount(false);
      if (res.status === 200) {
        setPauseAccountPopup(false);
        ReactNotifications("Account Paused Successfully", getSuccessStatus);
        history.push(`/${getAuthentionInfo().type}/Dashboard`);
      } else {
        ReactNotifications(res.message, getErrorStatus);
      }
    });
  };

  const handleResumeAccount = () => {
    setIsPausingAccount(true);
    resumeAccount({}, (res) => {
      setIsPausingAccount(false);
      if (res.status === 200) {
        setPauseAccountPopup(false);
        ReactNotifications("Account Resumed Successfully", getSuccessStatus);
        history.push(`/${getAuthentionInfo().type}/Dashboard`);
      } else {
        ReactNotifications(res.message, getErrorStatus);
      }
    });
  };
  const onSaveChanges = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSavingData(true);
      let data = {
        phone: phoneNumber,
      };

      if (password && newPassword) {
        data = {
          ...data,
          password,
          new_password: newPassword,
        };
      }
      updateDetails(data, (res) => {
        setIsSavingData(false);
        if (res.status === 200) {
          ReactNotifications("Details updated successfully", getSuccessStatus);
          setPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          reloadProfile();
        } else {
          ReactNotifications(res.message, getErrorStatus);
        }
      });
    }
  };
  const validateForm = () => {
    let newErrors = {};
    if (phoneNumber > "" && !isValidPhoneNumber(`${phoneNumber}`)) {
      newErrors.phoneNumber = "Please enter valid phone number";
    }
    if (newPassword || password || confirmNewPassword) {
      if (!password) {
        newErrors.password = "Please enter password";
      }
      if (!newPassword) {
        newErrors.newPassword = "Please enter new password";
      } else if (newPassword.length < 8) {
        newErrors.newPassword = "Password must be 8 characters long";
      }

      if (!confirmNewPassword) {
        newErrors.confirmNewPassword = "Please confirm new password";
      } else if (newPassword !== confirmNewPassword) {
        newErrors.confirmNewPassword = "Password does not match";
      }
      if (password === newPassword) {
        newErrors.newPassword =
          "New password cannot be same as current password";
      }
    }
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };
  const enterOTPContent = (
    <>
      <h2>Change Phone Number</h2>
      <p>An authorization code has been sent to your mobile number</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="authorization code"
          type="number"
          name="otp"
          placeholder="X-X-X-X"
          isMobileNumber
        />
        <button
          type="submit"
          className=" w-48 cta-type-2 h-40 btn btn-primary mx-auto"
          onClick={(e) => {
            e.preventDefault();
            setNumberChanged(true);

            setOtpPopup(false);
          }}
        >
          Submit
        </button>
        <br></br>
        <a
          href="#/"
          className="b-link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Send Code Again
        </a>
      </form>
    </>
  );
  const verifyPhoneNumber = (
    <>
      <h2>Verify Phone Number</h2>
      <p>An authorization code has been sent to your mobile number</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="authorization code"
          type="number"
          name="OTP"
          onChange={(e) => {
            if (e.target.value.length > 4) return false;
            setOTP(e.target.value);
          }}
          placeholder="X-X-X-X"
          value={OTP}
          error={errors.OTP}
          isMobileNumber
        />
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary mx-auto"
          onClick={(e) => {
            e.preventDefault();
            setIsNumberVerified(true);
            setVerifyPhoneNumberPopup(false);
          }}
        >
          Submit
        </button>
        <br></br>
        <a href="#/" className="b-link">
          Send Code Again
        </a>
      </form>
    </>
  );
  const phoneNumberVerifiedContent = (
    <>
      <p className="title-lg">Congratulations! Your phone number is verified</p>
      <button
        type="submit"
        className="w-48 mt-4 cta-type-2 h-40 btn btn-primary mx-auto"
        onClick={handleClose}
      >
        Got it
      </button>
    </>
  );
  const enterEmailOTPContent = (
    <>
      <h2>Change email</h2>
      <p>An authorization code has been sent to your email</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="authorization code"
          type="number"
          name="OTP"
          onChange={(e) => {
            if (e.target.value.length > 4) return false;
            setOTP(e.target.value);
          }}
          placeholder="X-X-X-X"
          value={OTP}
          error={errors.OTP}
          isMobileNumber
        />
        <button
          disabled={isVerifyingNewEmail}
          className={`w-48 cta-type-2 h-40 btn btn-primary mx-auto ${isVerifyingNewEmail && "button-loading"
            }`}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            verifyUserOTP();
          }}
        >
          Submit
        </button>
        <br></br>
        <a
          href="#/"
          className="b-link"
          onClick={(e) => {
            e.preventDefault();
            changeEmailAddress();
          }}
        >
          Send Code Again
        </a>
      </form>
    </>
  );
  const changePhoneNumber = (
    <>
      <h2>Change Phone Number</h2>
      <p>Enter your new phone number</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="New phone number"
          type="number"
          name="number"
          placeholder="(XXX) XXX-XXXX"
          isMobileNumber
        />
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary mx-auto"
          onClick={(e) => {
            e.preventDefault();
            setNumberPopup(false);
            setOtpPopup(true);
          }}
        >
          Change
        </button>
      </form>
    </>
  );
  const changeEmail = (
    <>
      <h2>Change email</h2>
      <p>Enter your new email</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="New email"
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@mail.com"
          value={email}
          error={errors.email}
        />
        <button
          disabled={isVerifyingNewEmail}
          className={`w-48 cta-type-2 h-40 btn btn-primary mx-auto ${isVerifyingNewEmail && " button-loading"
            }`}
          type="submit"
          onClick={changeEmailAddress}
        >
          <i className="fa fa-refresh fa-spin"></i>
          Change
        </button>
      </form>
    </>
  );
  const checkInboxContent = (
    <>
      <p className="title-lg">Check your inbox to verify your email</p>
      <button
        type="submit"
        className="w-48 mt-4 cta-type-2 h-40 btn btn-primary mx-auto"
        onClick={handleClose}
      >
        Got it
      </button>
    </>
  );
  const phoneNumberChangedContent = (
    <>
      <p className="title-lg">Congratulations! Your phone number has changed</p>
      <button
        type="submit"
        className="w-48 mt-4 cta-type-2 h-40 btn btn-primary mx-auto"
        onClick={handleClose}
      >
        Got it
      </button>
    </>
  );
  const emailChangedContent = (
    <>
      <p className="title-lg">Congratulations! Your email has changed</p>
      <button
        type="submit"
        className="w-48 mt-4 cta-type-2 h-40 btn btn-primary mx-auto"
        onClick={handleClose}
      >
        Got it
      </button>
    </>
  );
  const deactiveAccountContent = (
    <>
      <p className="title-lg">Delete Account?</p>
      <p>
        Deleting your account will permanently remove your profile from MyTurn .
        <b style={{ color: "black" }}>This step cannot be undone.</b>
      </p>
      <div className="justify-content-between d-flex mt-4">
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary deactive-btn mt-0"
          onClick={handleClose}
        >
          No
        </button>
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            handleDeleteAccount();
          }}
        >
          Yes
        </button>
      </div>
    </>
  );
  const pauseAccountContent = (
    <>
      <p className="title-lg">Pause Account?</p>
      <p>
        {getAuthentionInfo().type === "Candidate" ? (
          <>
            <p>
              Your profile will not be visible to recruiters while paused, but
              you may resume your account when ready.
            </p>
            <p>
              Note: Pausing your account will not pause any paid subscription.
            </p>
          </>
        ) : (
          <>
            <p>You may resume your account when ready.</p>
            <p>
              Note: Pausing your account will not pause any paid subscription.
            </p>
          </>
        )}
      </p>
      <div className="justify-content-between d-flex mt-4">
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary deactive-btn mt-0"
          onClick={handleClose}
        >
          No
        </button>
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            handlePauseAccount();
          }}
        >
          Yes
        </button>
      </div>
    </>
  );

  const handleCloseRemoveImgModal = () => {
    setRemoveImagePopup(false);
    setIsRemoveProfileImg(false);
  };

  const removeImg = () => {
    setProfile({
      ...profile,
      profile_image: "",
      imageURL: "",
      EmployerProfile: {
        ...profile.EmployerProfile,
        profile_image: "",
      },
    });
    handleProfileImgUpdate();
  };

  const profilePicConfirmation = (
    <>
      <p className="title-lg">Remove Profile Picture?</p>
      <p className="mt-3">Are you sure you want to remove this image?</p>
      <div className="justify-content-between d-flex mt-4">
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary deactive-btn mt-0"
          onClick={handleCloseRemoveImgModal}
        >
          No
        </button>
        <button
          type="submit"
          className="w-48 cta-type-2 h-40 btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setRemoveImagePopup(false);
            removeImg();
          }}
        >
          Yes
        </button>
      </div>
    </>
  );

  const onImageChange = (e) => {
    const file = e.target.files[0];
    const imageURL = URL.createObjectURL(file);
    const formData = new FormData();
    formData.append("profile", file);
    commonActions.uploadProfile(formData, (respoce) => {
      if (respoce.file)
        setProfile({
          ...profile,
          profile_image: respoce.file,
          imageURL: imageURL,
          EmployerProfile: {
            ...profile.EmployerProfile,
            profile_image: respoce.file,
          },
        });
      // setIsUpdateImagePopup(true);
    });
  };

  const handleProfileImgUpdate = () => {
    let data = {
      profile_image: profile?.profile_image
    }

    const payload = isRemoveProfileImg === true ? { profile_image: "" } : data;

    updateDetails(payload, (res) => {
      setIsSavingData(false);
      if (res?.status === 200) {
        ReactNotifications(res?.message, getSuccessStatus);
        reloadProfile();
        setIsUpdateImagePopup(false);
        setIsRemoveProfileImg(false);
      } else {
        ReactNotifications(res.message, getErrorStatus);
        setIsRemoveProfileImg(false);
      }
    });

  }

  const handleCloseImgPopup = () => {
    console.log("Hii", user?.EmployerProfile)
    setIsUpdateImagePopup(false);
    if (user?.EmployerProfile?.profile_image) {
      setProfile(user);
    } else {
      setProfile({});
    }
  }

  return (
    <div className="account-settings-tab " key={isSavingData}>
      <Modal
        show={
          otpPopup ||
          checkInboxPopup ||
          changeNumberPopup ||
          isNumberChanged ||
          deactivePopup ||
          emailOtpPopup ||
          changeEmailPopup ||
          isEmailChanged ||
          verifyPhoneNumberPopup ||
          isNumberVerified ||
          pauseAccountPopup
        }
        onHide={handleClose}
        className="successModal"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="modalbody text-center modal-position">
          {otpPopup
            ? enterOTPContent
            : checkInboxPopup
              ? checkInboxContent
              : changeNumberPopup
                ? changePhoneNumber
                : isNumberChanged
                  ? phoneNumberChangedContent
                  : deactivePopup
                    ? deactiveAccountContent
                    : emailOtpPopup
                      ? enterEmailOTPContent
                      : changeEmailPopup
                        ? changeEmail
                        : isEmailChanged
                          ? emailChangedContent
                          : verifyPhoneNumberPopup
                            ? verifyPhoneNumber
                            : isNumberVerified
                              ? phoneNumberVerifiedContent
                              : pauseAccountPopup
                                ? pauseAccountContent
                                : ""}
        </Modal.Body>
      </Modal>
      <Modal
        show={removeImagePopup}
        onHide={handleCloseRemoveImgModal}
        className="successModal"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="modalbody text-center">
          {removeImagePopup ? profilePicConfirmation : ""}
        </Modal.Body>
      </Modal>
      <div className='edit_heading'>
        <h2>Account Settings</h2>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={7} xxl={7} className="">
            {employer_user && <Row style={{ marginBottom: 20 }}>
              <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                <p>                     <img
                  className="profile-img"
                  // src={
                  //   profile?.EmployerProfile?.profile_image
                  //     ? process.env.REACT_APP_API_BASE_URL +
                  //     "/user_profile/" +
                  //     profile?.EmployerProfile?.profile_image
                  //     : '/images/userpic.png'
                  // }
                  src={profile?.EmployerProfile?.profile_image ?profile?.imageURL || profile?.EmployerProfile?.profile_image_url : '/images/userpic.png'}
                  alt="upload"
                  height="65px"
                  width="65px"
                ></img></p>
              </Col>
              <Col
                xxl={7}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="updatePhone"
              >
                <label
                  className="bold-label"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsUpdateImagePopup(true);
                  }}
                  style={{
                    marginTop: 15,
                    backgroundColor: '#FF7653',
                    padding: '5px 9px 5px',
                    borderRadius: 20,
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: 14,
                  }}
                >
                  Update Photo
                </label>
              </Col>
            </Row>}
            <CustomeModel
              show={isUpdateImagePopup}
              modalType="Positive/Negative"
              modalBody={
                <>
                  <h3 style={{ marginBottom: 20 }}>Update profile image.</h3>
                  <div className="innerBlock">
                    <div className="ctaRow">
                      {(profile.profile_image || user?.EmployerProfile?.profile_image) && <img
                        src="/images/close3.png"
                        alt="remove"
                        className="removePic"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemoveImagePopup(true);
                          setIsRemoveProfileImg(true);
                          setIsUpdateImagePopup(false);
                        }}

                      />}
                      <label className="file-input bold-label">
                        <img
                          className="profile-img"
                          // src={
                          //   profile?.EmployerProfile?.profile_image
                          //     ? process.env.REACT_APP_API_BASE_URL +
                          //     "/user_profile/" +
                          //     profile?.EmployerProfile?.profile_image
                          //     : profile?.imageURL || '/images/userpic.png'
                          // }
                          src={profile?.EmployerProfile?.profile_image ? profile?.imageURL || profile?.EmployerProfile?.profile_image_url : '/images/userpic.png'}
                          alt="upload"
                          height="65px"
                          width="65px"
                        ></img>
                        <input
                          accept="image/*"
                          type="file"
                          name="file"
                          onChange={onImageChange}
                        />
                        {/* &nbsp; Upload Photo */}
                      </label>
                    </div>
                  </div>
                </>
              }
              positiveButtonLable="Update"
              negativeButtonLable="Cancel"
              onPositive={handleProfileImgUpdate}
              onNegative={handleCloseImgPopup}
              handleClose={handleCloseImgPopup}
            />
            <p className="title">Email </p>
            <label className="form-label" htmlFor="">
              Current Email Address
            </label>
            <Row>
              <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                <p>{user.email}</p>
              </Col>
              <Col
                xxl={7}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="updatePhone"
              >
                <label
                  className="bold-label"
                  onClick={(e) => {
                    e.preventDefault();
                    setEmailPopup(true);
                  }}
                  style={{
                    backgroundColor: '#FF7653',
                    padding: '5px 9px 5px',
                    borderRadius: 20,
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: 14,
                  }}
                >
                  Update Email
                </label>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} sm={12} md={4} lg={4} xl={5} xxl={5}>
            <form onSubmit={(e) => e.preventDefault()}>
        <InputText
          label="New email"
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@mail.com"
          value={email}
          error={errors.email}
        />
        <button
          disabled={isVerifyingNewEmail}
          className={`w-48 cta-type-2 h-40 btn btn-primary mx-auto ${isVerifyingNewEmail && " button-loading"
            }`}
          type="submit"
          onClick={changeEmailAddress}
        >
          <i className="fa fa-refresh fa-spin"></i>
          Change
        </button>
      </form>
          </Col> */}
        </Row>
        <Row>
          <Col xs={12} sm={12} md={9} lg={7} xl={6} xxl={6}>
            <p className="title">Password</p>
            <InputPassword
              isVisible={false}
              label="Current Password"
              type="password"
              placeholder="Enter your current password"
              controlId="cPassword"
              name="cPassword"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              error={errors.password}
            />
            <InputPassword
              isVisible={false}
              label="New Password"
              type="password"
              placeholder="Enter your new password"
              controlId="newPassword"
              name="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              error={errors.newPassword}
              strenghtCheck={true}
            />
            <InputPassword
              isVisible={false}
              label="Confirm Password"
              type="password"
              placeholder="Enter again new password"
              controlId="confirmNewPassword"
              name="confirmNewPassword"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              value={confirmNewPassword}
              error={errors.confirmNewPassword}
              strenghtCheck={true}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12} sm={12} md={9} lg={7} xl={6} xxl={6}>
            <p className="title">Phone Number (Optional)</p>
            <InputText
              label="Current Phone Number"
              value={phoneNumber}
              onChange={(event) => {
                if (event.target.value.length > 10) return false;
                handlePhoneNumber(event);
              }}
              error={errors.phoneNumber}
            />
          </Col>
        </Row> */}
        <div className="ctaPanel">
          <Row>
            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <button
                disabled={isSavingData}
                className={`primary-filed-orange mt-40 ${isSavingData && " button-loading"
                  }`}
                type="submit"
                onClick={onSaveChanges}
              >
                Save Changes
              </button>
            </Col>
            {getAuthentionInfo().type === "Candidate" && (
              <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                <button
                  disabled={isPausingAccount}
                  type="button"
                  className="tertiary-button mt-40"
                  onClick={() => {
                    +user.pause_account === 1
                      ? handleResumeAccount()
                      : setPauseAccountPopup(true);
                  }}
                >
                  {+user.pause_account === 1
                    ? "Resume account"
                    : "Pause account"}
                </button>
              </Col>
            )}
            {user?.EmployerProfile !== undefined &&
              user?.EmployerProfile?.contact_wallet == "0" && (
                <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <button
                    disabled={isDeletingAccount}
                    type="button"
                    className="tertiary-button mt-40"
                    onClick={() => {
                      setDeactivePopup(true);
                    }}
                  >
                    Delete account
                  </button>
                </Col>
              )}
            {user?.CandidateProfile !== undefined &&
              user?.CandidateProfile?.current_package_validity == null && (
                <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <button
                    disabled={isDeletingAccount}
                    type="button"
                    className="tertiary-button mt-40"
                    onClick={() => {
                      setDeactivePopup(true);
                    }}
                  >
                    Delete account
                  </button>
                </Col>
              )}
          </Row>
        </div>
      </form>
    </div>
  );
}
