import React, { useContext, useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InputFile from "../../common/inputFile/InputFile";
import { useHistory, Link } from "react-router-dom";
import * as utilConstants from "../../../utility/constants/utilConstants";
import * as onboardingActions from "./onboardingActions";
import { candidateBoarding } from "./onboardingActions";
import * as commonActions from "../../../actions/commonActions";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import { getAuthentionInfo } from "../../../utility/helper/authenticationHelper";
import backerrow from "../../../../src/assets/images/backerrow.png"
import Modal from 'react-bootstrap/Modal';
import { Spinner } from "react-bootstrap";
import { AuthContext } from "context/authContext";
import { deleteUser } from "../candidateActions";

function UploadResume(props) {
  const { verifyResumePopUp, setVerifyResumePopUp } = useContext(AuthContext);
  const { setIsSearchTabClicked } = useContext(AuthContext);
  const [resume, setResume] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const modalRef = useRef(null);

  useEffect(() => {
    onSubmit();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resume]);

  const onSubmit = () => {
    if (resume) {
      setIsLoading(true)
      candidateBoarding(
        {
          fileName: resume,
        },
        (Response) => {
          if (Response.status !== utilConstants.sucessState200) {
            ReactNotifications(Response.message, utilConstants.sucessState200);
            if(getAuthentionInfo().type === "Candidate") {
              history.push(`/Candidate/account-settings/profile`);
            }else {
              history.push(`/${getAuthentionInfo().type}/Dashboard`);
            }
          } else {
            history.push(`/Candidate/account-settings/profile`);
          }
          setIsLoading(false);

        }
      );
      props.onboardingActions.uploadResume(resume);
    }
  };

  const uploadResume = (file) => {
    file = file[0];
    const formData = new FormData();
    formData.append("resume", file);
    commonActions.uploadResume(formData, (response) => {
      if (response.file) setResume(response.file);
      else ReactNotifications(response.msg, utilConstants.getErrorStatus);
    });
  };

  const handleClose = () => {
    setVerifyResumePopUp(false);
    history.push("/Candidate/account-settings/profile")
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      deleteUser({}, (response) => {
        if (response?.status === 200) {
          setVerifyResumePopUp(false);
          history.push("/signin");
          localStorage.clear();
        }
      })
    }
  };

  return (
    <Modal
      show={verifyResumePopUp}
      className="successModal"
      // onHide={handleClose}
      centered
      size="sm">
      <Modal.Header />
      <div className="upload_wrrpr mx-auto" ref={modalRef}>
        <Modal.Body>

          <div className="upload_body">
            <h2 className="section-heading-2 mw-552">
              Upload Your Resume
            </h2>
            <InputFile
              onDrop={(files) => {
                uploadResume(files);
              }}
            />
          </div>
          <div className="create_profile_dv">
            <Link to={`/Candidate/account-settings/profile`}>Don’t have a resume!</Link>
            {isLoading && <Spinner animation="border" variant="success" />}
          </div>

        </Modal.Body>
      </div>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onboardingActions: bindActionCreators(onboardingActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    state: state,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadResume);
