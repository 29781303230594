import * as typesConstants from "../../utility/constants/typesConstants";
import * as utilConstants from "../../utility/constants/utilConstants";
let initialState = {
  isLoading: false,
  error: "",
};

export default function employerReducer(state = initialState, action) {
  switch (action.type) {
    case typesConstants.FLUSH_EMPLOYER_REDUCER:
      return initialState;
    case typesConstants.EMPLOYER_SEARCH_CANDIDATE:
      if (action.response.status === utilConstants.sucessState200)
        return {
          ...state,
          searchCandidate: action.response.data,
          totalCandidate: action.response.count,
        };
      else return { ...state, error: action.response.message };
    case typesConstants.SAVE_SEARCH_CRITERIA_LOCAL:
      return {
        ...state,
        searchCriteria: action.response,
      };
      case typesConstants.CANDIDATE_REMOVER:
        console.log("yes2");
      return {
        ...state,
        searchCandidate:[]
      };
      case typesConstants.GET_SCROLL_VALUE:
        console.log("ok remove");
      return {
        ...state,
        scrolldata:action.response
      };
    default:
      return state;
  }
}
