import * as typesConstants from "../../utility/constants/typesConstants";
import { updateReducer } from "../../utility/helper/helper";
import { async } from "../../utility/config/api/index";

/**
 *
 * @param {'provide user candidate detail'} signUpData
 * @description {'return user candidate detail'}
 */
export function getEmployerDetail(data) {
  return (dispatch) => {
    async("employer/view", "GET", data).then((response) => {
      return dispatch(
        updateReducer(typesConstants.SIGNED_USER_DETAIL, response)
      );
    });
  };
}

export function candidateSearch(data) {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.FLUSH_EMPLOYER_REDUCER, {}));
    async("frontend/candidate/search", "POST", data).then((response) => {
      return dispatch(
        updateReducer(typesConstants.EMPLOYER_SEARCH_CANDIDATE, response)
      );
    });
  };
}
export function candidateRemove() {
  console.log("yes");
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.CANDIDATE_REMOVER, {}));
  };
}
export function savedCandidate(data, callback = () => { }) {
  async("frontend/employer/saved", "GET", data).then((response) =>
    callback(response)
  );
}
export function candidateChangeStatus(data, callback = () => { }) {
  async("frontend/employer/saved/change", "PUT", data).then((response) =>
    callback(response)
  );
}
export function appliedJobs(data, callback = () => { }) {
  async("job/appliedJobList", "GET", data).then((response) =>
    callback(response)
  );
}

export function saveSearchCriteraLocal(data) {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.SAVE_SEARCH_CRITERIA_LOCAL, data));
  };
}
export function getConatctsList(data, callback = () => { }) {
  async("frontend/employer/conatcts", "GET", data).then((response) =>
    callback(response)
  );
}
export function updateEmployer(data, callback = () => { }) {
  async("frontend/employer/profile", "PUT", data).then((response) =>
    callback(response)
  );
}

export function removeCandidate(data, callback = () => { }) {
  async("frontend/employer/removeCandidate", "GET", data).then((response) =>
    callback(response)
  );
}
export function removeProject(data, callback = () => { }) {
  async(`frontend/employer/project`, "DELETE", data).then((response) =>
    callback(response)
  );
}
export function getScrolldata(data) {
  return (dispatch) => {
    dispatch(updateReducer(typesConstants.GET_SCROLL_VALUE, data));
  };
}
export function updateProject(data, callback = () => { }) {
  async("frontend/employer/project", "PUT", data).then((response) =>
    callback(response)
  );
}

export function getMyJobs(limit, skip, searchText, data, callBack = () => { }) {
  var url = `job/allListJobPost?limit=${limit}&skip=${skip}`;
  if (searchText !== "") {
    url = `job/allListJobPost?searchText=${searchText}&limit=${limit}&skip=${skip}`;
  }

  async(url, "GET", data).then((response) => {

    callBack(response);
  });
}

export function postJob(jobID, data, callback = () => { }) {
  if (jobID === "new") {
    async("job/createJobPost", "POST", data).then((response) =>
      callback(response)
    );
  } else {
    async(`job/updateJobPost/${jobID}`, "PUT", data).then((response) =>
      callback(response)
    );
  }
}
export function deleteJob(jobID, callback = () => { }) {
  async(`job/deleteJobPost/${jobID}`, "DELETE").then((response) =>
    callback(response)
  );
}

export function getCompanyDetails(id, callback = () => { }) {
  async(`frontend/user/employerCompanyDetail/${id}`, "GET").then((response) =>
    callback(response)
  );
}

export function getCurrentOpenings(id, limit, skip, data, callback = () => { }) {
  const query = `?limit=${limit}&skip=${skip}`;
  async(`job/allListJobPostEmployerCompany/${id}${query}`, "GET", data).then(
    (response) => callback(response)
  );
}

export function checkSubscriptionStatus(data, callback = () => { }) {
  async("job/checkSubscriptionStatus", "GET", data).then((response) =>
    callback(response)
  );
}

export function buySubscriptionPlan(data, callback = () => { }) {
  async("job/buySubscriptionPackage", "POST", data).then((response) =>
    callback(response)
  );
}

export function upgradeSubscriptionPlan(data, callback = () => { }) {
  async("job/upgradeSubscription", "POST", data).then((response) =>
    callback(response)
  );
}

export function viewMemebershipDetail(limit, skipsubscriptionId, data, callback = () => { }) {
  async(`job/getSubscriptionStatusAndDetail?limit=${limit}`, "GET", data).then((response) =>
    callback(response)
  );
}

export function cancelMembership(data, callback = () => { }) {
  async("job/cancelSubscription", "DELETE", data).then((response) =>
    callback(response)
  );
}
