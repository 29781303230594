import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import FirstCarousel from '../../../assets/images/FirstCarousel.svg';
import SecondCarousel from '../../../assets/images/SecondCarousel.svg';
import ThirdCarousel from '../../../assets/images/ThirsCarousel.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PricingPlanCarousel = () => {

    return (
        <div className='pricing-carousel-wrrpr'>
            <h2 className="section-title" style={{ textAlign: 'center' }}>
                Why Choose MyTurn for Your<br />
                <span className="green-pricing-pg">Cyber</span>
                <span className="orange">Security </span>Recruitment?
            </h2>
            <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                interval={3000}
                stopOnHover={true}
                swipeable={true}
                dynamicHeight={true}
                showIndicators={true}>
                <div className='pricing-carousel'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src={FirstCarousel} alt="Carousel Slide" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className='pricing-carousel-right-dv'>
                                    <h2>
                                        Discover Our <span className="orange">Valuable Insights</span> to<br /> empower Your Hiring Journey!
                                    </h2>
                                    <ol className='pricing-carousel-lists'>
                                        <li>
                                            <span className="green-pricing-pg"> Over 150k job postings: </span>
                                            Connect with highly qualified talent through our extensive database.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg">1000+ registered employers: </span>
                                            Join our growing network of successful recruiters.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg">Partner with 50+ trusted collaborators: </span>
                                            Access a network of industry experts and resources to enhance your recruitment journey.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pricing-carousel'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src={SecondCarousel} alt="Carousel Slide" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className='pricing-carousel-right-dv'>
                                    <h2>
                                        Hire the Right Candidates in Just 3 Simple Steps!
                                    </h2>
                                    <ol className='pricing-carousel-lists'>
                                        <li>
                                            <span className="green-pricing-pg">Get Yourself Registered: </span>
                                            Select the perfect plan that fits your needs.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg">Pay as you Go: </span>
                                            Join our growing network of successful recruiters.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg"> Access Job-seekers’ Database: </span>
                                            Explore into our database of job-seekers and find your ideal match.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pricing-carousel'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src={ThirdCarousel} alt="Carousel Slide" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className='pricing-carousel-right-dv'>
                                    <h2>
                                        Benefits to Choose MyTurn for Seamless Hiring Solutions!
                                    </h2>
                                    <ol className='pricing-carousel-lists'>
                                        <li>
                                            <span className="green-pricing-pg">Unlimited Job Postings: </span>
                                           Reach a broad pool of talent without limits.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg">Applicant Tracking System (ATS): </span>
                                            Manage candidates seamlessly from start to finish.
                                        </li>
                                        <li>
                                            <span className="green-pricing-pg">Dedicated Account Support: </span>
                                            Get personalized assistance whenever you need it.
                                        </li>
                                        {/* <li>
                                            <span className="green-pricing-pg">Partner with 50+ trusted collaborators: </span>
                                            Access a network of industry experts and resources to enhance your recruitment journey.
                                        </li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export default PricingPlanCarousel;
