import React, { useContext, useEffect } from "react";
import HomeSearchJob from "components/candidate/searchjob/HomeSearchJob";
import engageImage from "../../assets/images/engage-image.webp";
import { AuthContext } from "context/authContext";
import JobInsights from "components/candidate/JobInsights/JobInsights";

const HomeNewOne = () => {
  const employer = localStorage.getItem("employer_id");
  const { isSearchTabClicked, setIsSearchTabClicked } = useContext(AuthContext);

  useEffect(() => {
    if (isSearchTabClicked === true) {
      window.scrollTo({
        top: window.innerHeight * 0.74,
        behavior: 'smooth'
      });
    }
    setIsSearchTabClicked(false);
  }, []);

  return (
    <section>
      {!employer && <HomeSearchJob />}
      <div className="engage-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7 col-12">
              <h3>
                Welcome to MyTurn, the ultimate stop for all things cybersecurity careers!
              </h3>
              <p>Here at MyTurn, we’re committed to reducing the "cybersecurity talent gap" by offering a platform designed to meet your cybersecurity job requirements. </p>
              <p>With our easy-to-use platform, employers can connect with top-notch cybersecurity job seekers, while job seekers themselves can discover cybersecurity job opportunities that align with their goals.</p>
              <p>So, whether you’re just starting a career in cybersecurity or are a seasoned cybersecurity professional, come join us at MyTurn and become part of a community where your talent connects with our opportunities.</p>
            </div>
            <div className="col-lg-5 col-md-5 col-12 text-align-center specialize_img">
              <img alt="engage with employers" className="img-fluid" width={478} height={478} src={engageImage} />
            </div>
          </div>
        </div>
      </div>
      <JobInsights />
    </section>
  );
};
export default HomeNewOne;
