import * as typesConstants from "./../../../utility/constants/typesConstants";
let defaultState = {};

export default function onBoardingReducer(state = defaultState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case typesConstants.ONBOARDING_UPLOAD_RESUME:
      newState.candidateResume = action.response;
      return newState;
    default:
      return state;
  }
}
