import React from 'react'
import { Spinner } from 'react-bootstrap';

function Loader() {
    return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {" "}
          <Spinner
            animation="border"
            role="status"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              width: "200px",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
}

export default Loader