import * as typesConstants from "../../utility/constants/typesConstants";
import { updateReducer } from "../../utility/helper/helper";
import { async } from "../../utility/config/api/index";

/**
 *
 * @param {'provide user candidate detail'} signUpData
 * @description {'return user candidate detail'}
 */
export function getCandidateDetail(data) {
  return (dispatch) => {
    async("candidate/view", "GET", data).then((response) => {
      return dispatch(
        updateReducer(typesConstants.SIGNED_USER_DETAIL, response)
      );
    });
  };
}
export function updateCandidateProfile(data, callback = () => { }) {
  delete data.JobHistories;
  async("frontend/candidate/profile", "PUT", data).then((response) => {
    callback(response);
  });
}
export function getSkills(data, callback = () => { }) {
  async("get-skills", "GET", data).then((response) => {
    callback(response);
  });
}
export function getSearchKeyWord(data, callback = () => { }) {
  async("keyword-suggestion", "GET", data).then((response) => {
    callback(response);
  });
}

export function getPosition(data, callback = () => { }) {
  async("get-jobprofiles", "GET", data).then((response) => {
    callback(response);
  });
}

// export function getMyJobs(limit, skip, searchText, filterOption, data, callBack = () => { }) {
//   var url = `job/allListJobPostForCandidate?limit=${limit}&skip=${skip}`;
//   if (searchText !== "" && filterOption === "") {
//     url = `job/allListJobPostForCandidate?searchText=${searchText}&limit=${limit}&skip=${skip}`;
//   }
//   if (filterOption !== "" && searchText === "") {
//     url = `job/allListJobPostForCandidate?experienceFilter=${filterOption}&limit=${limit}&skip=${skip}`;
//   }
//   if (searchText && filterOption) {
//     url = `job/allListJobPostForCandidate?searchText=${searchText}&experienceFilter=${filterOption}&limit=${limit}&skip=${skip}`;
//   }
//   async(url, "GET", data).then((response) => {
//     callBack(response);
//   });
// }

export function getMyJobs(limit, skip, searchText, filterOption, remoteFilter, data, callBack = () => { }) {

  let baseUrl = `job/allListJobPostForCandidate?`;

  const params = {
    searchText: searchText,
    experienceFilter: filterOption,
    remoteFilter: remoteFilter,
  };

  let queryParts = [];

  for (const key in params) {
    if (params[key]) {
      queryParts.push(`${key}=${encodeURIComponent(params[key])}`);
    }
  }

  queryParts.push(`limit=${limit}`);
  queryParts.push(`skip=${skip}`);

  let url = baseUrl + queryParts.join('&');

  async(url, "GET", data).then((response) => {
    callBack(response);
  });
}



export function checkToUploadCandidateResume(data, callback = () => { }) {
  async("job/checkCandidateUploadedResume", "POST", data).then((response) => {
    callback(response);
  });
}

export function measureUserClicks(data, callback = () => { }) {
  async("job/userMeasureLogs", "POST", data).then((response) =>
    callback(response)
  );
}

export function deleteUser(data, callback = () => { }) {
  async("frontend/delete-user-account", "GET", data).then((response) => {
    callback(response);
  });
}

