import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as helper from "./../../../utility/helper/helper";
import CustomButton from "../../common/button/CustomButton";
import InputPassword from "../../common/inputPassword/InputPassword";
import { resetPassword } from "../../../actions/commonActions";
import ReactNotifications from "../../common/reactNotifications/ReactNotifications";
import {
  getErrorStatus,
  sucessState200,
} from "../../../utility/constants/utilConstants";

export default function ResetPassword(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const onChange = (e) => {
    let tempData = { ...data };
    Object.assign(tempData, helper.setDataAccordingInput(e));
    setError({});
    setData(tempData);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid()) {
      resetPassword(
        { ...data, remember_token: props.match.params.token },
        (response) => {
          if (response.status === sucessState200) {
            ReactNotifications(response.message);
            if (response?.data?.user_type === "Admin") {
              window.location.href = "https://admin.myturn.careers";
            } else {
              props.history.push("/signin");
            }
          } else ReactNotifications(response.message, getErrorStatus);
        }
      );
    }
  };
  const isValid = () => {
    let isError = false,
      passwordErr,
      confirmPasswordErr;
    if (helper.IsEmpty(data.password)) {
      passwordErr = "Please enter new password";

      isError = true;
    } else if (helper.passwordStrength(data.password) !== "STRONG") {
      passwordErr = "Password is too week";
      isError = true;
    }

    if (data.password !== data.newpassword) {
      confirmPasswordErr = "Password does not match";
      isError = true;
    }
    setError({
      password: passwordErr,
      newpassword: confirmPasswordErr,
    });
    return !isError;
  };
  return (
    <div className="signupContentWrapper">
      <div className="background">
        <div className="left"></div>
        <div className="right">
          <img src="/images/signup-bg.png" alt="Site logo" />
        </div>
      </div>

      <div className="content">
        <Container>
          <Row>
            <Col
              xxl={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 6, offset: 3 }}
              md={{ span: 6, offset: 3 }}
              sm={12}
              xs={12}
            >
              <h1>Restore Access</h1>
              <p className="info-text">
                Fill in info to restore access to your account
              </p>

              <Form noValidate onSubmit={handleSubmit}>
                <InputPassword
                  label="Password"
                  name="password"
                  value={data.password}
                  onChange={onChange}
                  strenghtCheck={true}
                  error={error.password}
                />
                <InputPassword
                  label="Confirm Password"
                  name="newpassword"
                  onChange={onChange}
                  value={data.newpassword}
                  strenghtCheck={true}
                  error={error.newpassword}
                />
                <CustomButton
                  variant="primary"
                  type="submit"
                  className="w-100 primary-filed-orange"
                  isDisabled={!helper.IsEmpty(error)}
                  label="Reset Password"
                  onClick={handleSubmit}
                />
              </Form>
            </Col>
            <Col
              xxl={{ span: 8, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              sm={12}
              xs={12}
            ></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
