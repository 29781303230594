import React from 'react';
import { Form } from 'react-bootstrap';

export default function InputText({
  label = '',
  type = 'text',
  placeholder,
  controlId,
  required = false,
  onChange = () => {},
  onBlur = () => {},
  onKeypress, 
  value,
  error,
  name,
  className,
  disabled,
  min,
  isMobileNumber,
  maxLength,
  displayToolTip = false,
  toolTipText = '',
  autoComplete = 'off',
}) {
  return (
    <div className={className || ''}>
      <Form.Group className='form-group' controlId={controlId}>
        <Form.Label>
          <div className='tooltip-container'>
            {typeof label === 'string' ? label.toLowerCase() : label}
            {displayToolTip && (
              <img
                style={{ margin: '0 8px' }}
                src='/images/tooltip.png'
                alt='Question mark'
                title={toolTipText}
              />
            )}
          </div>
        </Form.Label>
        <Form.Control
          onBlur={onBlur}
          onKeyPress={event => {
            if (event.key === "Enter") {
             onKeypress()
            }
          }}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value || ''}
          required={required}
          name={name}
          isInvalid={error}
          disabled={disabled}
          min={min}
          maxLength={maxLength}
          onWheel={(e) => type === 'number' && e.target.blur()}
          autoComplete={autoComplete}
          onKeyDown={(e) => {
            if (isMobileNumber) {
              if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
              }
            }
          }}
        />

        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
