import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  IsAuthenticated,
  getAuthentionInfo,
} from "./../utility/helper/authenticationHelper";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  document.title = `Networking | ${rest.name}`;
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        IsAuthenticated() ? (
          rest.path.includes(getAuthentionInfo().type) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { referrer: location.pathname },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { referrer: location.pathname },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
