import React from "react";
import { Modal } from "react-bootstrap";

export default function VerifyOtp({ show, modalBody, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="successModal"
      centered
      size="sm"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="modalbody text-center">{modalBody}</Modal.Body>
    </Modal>
  );
}
