import React from "react";
import { Form } from "react-bootstrap";

export default function InputCheckbox({
  controlId,
  label,
  onChange = () => {},
  error,
  name,
  checked,
  className,
}) {
  return (
    <Form.Group
      className={`form-group ${className || ""}`}
      controlId={"formBasicCheckbox" + controlId}
    >
      <label
        htmlFor={"agreeCheck" + controlId}
        className="custom-check-box d-flex "
      >
        <input
          type="checkbox"
          name={name}
          id={"agreeCheck" + controlId}
          onChange={onChange}
          checked={checked}
        />
        <span className={"checkmark " + (error && "error ")}></span>
        <span className="check-text">{label}</span>
      </label>
      <p style={{color: '#dc3545'}}>{error}</p>
    </Form.Group>
  );
}
